import React, {useEffect, useState} from "react";
import {PGET} from "../../../api/adminApi";
import DatePicker from "react-datepicker";
import {Bar} from "react-chartjs-2";
import PropTypes from "prop-types";

export const MasterRegionChart = props => {
    const {options, height, layout} = props;
    const [dashboardData, setDashboardData] = useState([]);
    const [dateRange, setDateRange] = useState([
        new Date('2024-01-01'),
        new Date('2025-01-01'),
    ]);
    const [startDate, endDate] = dateRange;

    async function getDashboardMonthCounts() {
        if (startDate && endDate) {

            const data = await PGET("/services/admin/api/dashboard-reg-masters-by-regions/", true)
            //     {
            //     dateFrom: formattedStartDate,
            //     dateTo: formattedEndDate
            // });
            if (data) {
                setDashboardData(data.data);
            }
        }
    }

    const data = {
        labels: dashboardData?.map((item, i) => item.regionName),
        datasets: [
            {
                label: "Мастеры",
                data: dashboardData?.map((item, i) => item.masterCount),
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
        ],
    };
    useEffect(() => {
        getDashboardMonthCounts();
    }, [startDate, endDate]);

    return (
        <div style={{display: ''}}>
            {/*<div style={{width: 200, position: "absolute", right: 10, top: 20}}>*/}
            {/*    <DatePicker*/}
            {/*        selectsRange={true}*/}
            {/*        startDate={startDate}*/}
            {/*        endDate={endDate}*/}
            {/*        onChange={(update) => {*/}
            {/*            setDateRange(update);*/}
            {/*        }}*/}
            {/*        isClearable={true}*/}
            {/*        className="datepicker-custom-input"*/}
            {/*    />*/}
            {/*</div>*/}
            <Bar data={data} height={height} options={{...options, ...layout}}/>
        </div>
    )

};

MasterRegionChart.defaultProps = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
        {
            data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
            backgroundColor: 'rgb(54,122,215, 0.9)',
            barPercentage: 0.6,
        },
        {
            data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
            backgroundColor: 'rgba(250,0,54,0.5)',
            barPercentage: 0.6,
        },
    ],

    options: {
        maintainAspectRatio: true,
        responsive: true,
        layout: {
            padding: {
                left: '0',
                right: 0,
                top: 0,
                bottom: '0',
            },
        },
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
            },
        },

    },
};

MasterRegionChart.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.object,
    layout: PropTypes.object,
};