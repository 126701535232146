import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { DebounceInput } from 'react-debounce-input';
import ReactPaginate from 'react-paginate';
import { useForm } from "react-hook-form";
import { Trash2, Edit3, Search, PlusCircle } from 'react-feather';

import { GET, PUT, POST, PGET, DELETE } from '../../../api/adminApi'
function Currency() {
	const [items, setItems] = useState([])
	const [method, setMethod] = useState('create')
	const [showModal, setShowModal] = useState(false)
	const [pageCount, setPageCount] = useState(0)
	const { register, handleSubmit, formState: { errors }, setValue, setError } = useForm({
		nameRu: '',
		nameUz: '',
		descriptionRu: '',
		descriptionUz: '',
	})

	async function getItems() {
		const data = await PGET('/services/admin/api/units-pageList', true, { page: 0, size: 20 })
		setPageCount(Math.ceil(data.headers['x-total-count'] / 20))
		setItems(data.data)
	}
	
	async function getItem(id) {
		const response = await GET('/services/admin/api/units/' + id,)
		for (let key in response) {
			setValue(`${key}`, response[key])
		}
		setShowModal(true)
		getItems()
	}

	function onHide() {
		setValue('id', '')
		setValue('nameRu', '')
		setValue('nameUz', '')
		setValue('descriptionRu', '')
		setValue('descriptionUz', '')
		setError('nameRu', '')
		setError('nameUz', '')
		setError('descriptionRu', '')
		setError('descriptionUz', '')
		setShowModal(false)
	}

	async function createItem(data) {
		await POST('/services/admin/api/units', data)
		setShowModal(false)
		getItems()
	}
	async function updateItem(data) {
		await PUT('/services/admin/api/units', data)
		setShowModal(false)
		getItems()
	}
	async function search(e) {
		const data = await PGET('/services/admin/api/units-pageList?search=' + e.target.value, true)
		setPageCount(Math.ceil(data.headers['x-total-count'] / 20))
		setItems(data.data);
	};
	async function paginate(data) {
		const response = await PGET('/services/admin/api/units-pageList', true, { page: data.selected, size: 20 })
		setItems(response.data);
	};
	async function deleteItem(id) {
		await DELETE('/services/admin/api/units/' + id,)
		getItems()
	}

	useEffect(() => {
		getItems()
	}, [])

	return (
		<>
			<div className="background-white my-2 mx-3 box-shadow br-5">
				<div className="fz20 border-bottom pl-3 py-3 my-2 d-flex align-items-center">
					Единица измерения
				</div>
				<div className='px-3 pb-4 pt-2'>
					<div className="mb-4 d-flex justify-content-between">
						<button onClick={() => (setMethod('create') + setShowModal(true))} className="btn btn-success d-flex align-items-center">
							<PlusCircle size={18} className='mr-1' />
							<div>Добавить новый</div>
						</button>
						<div className="position-relative">
							<Search size={14} color='#9D9BA3' className="input-search-icon" />
							<DebounceInput minLength={3} debounceTimeout={400} onChange={(e) => search(e)} type="text" className="input-search" size={24} placeholder='Поиск' />
						</div>
					</div>
					<div style={{ overflowX: 'auto' }}>
						<table className="table table-bordered">
							<thead>
								<tr className='backgroung-grey'>
									<th >Название </th>
									<th >Описание </th>
									<th className="text-center">Действие </th>
								</tr>
							</thead>

							<tbody style={{ overflowX: 'auto' }}>
								{items.map((item) => {
									return (
										<tr key={item.id}>
											<td className="vertical-middle" >{item.nameRu}</td>
											<td className="vertical-middle">{item.descriptionRu}</td>
											<td className="vertical-middle">
												<div className="d-flex justify-content-center">
													<button onClick={() => (setMethod('update') + getItem(item.id))} className="btn btn-table" style={{ backgroundColor: '#F4F4F5' }}><Edit3 color={'#189ED3'} size={16} /></button>
													<button onClick={() => deleteItem(item.id)} className="btn btn-table mr-0" style={{ backgroundColor: '#F4F4F5' }}><Trash2 color={'#E63950'} size={16} /></button>
												</div>
											</td>
										</tr>
									)
								})
								}

							</tbody>
						</table>
					</div>
					{
						pageCount > 1 &&
						<ReactPaginate
							// breakLabel={'...'}
							// breakClassName={'break-me'}
							pageCount={pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={5}
							onPageChange={paginate}
							containerClassName={'pagination'}
							activeClassName={'active'}
							pageClassName={'page-item'}
						/>
					}
				</div>
			</div>
			<Modal
				show={showModal}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				onHide={() => onHide()}
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						Добавить Валюту
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form autoComplete='off' autoCorrect='off' onSubmit={method === 'create' ? handleSubmit(createItem) : handleSubmit(updateItem)} >
						<div className='d-flex flex-column w-100'>
							<div className='p-2'>
								<div className="d-flex flex-column mb-3 color-grey">
									<label className="mb-1">Название(рус)</label>
									<input {...register("nameRu", { required: true })} type="text" className="input-control" />
									{errors.nameRu?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
								</div>
								<div className="d-flex flex-column mb-3 color-grey">
									<label className="mb-1">Название(узб)</label>
									<input {...register("nameUz", { required: true })} type="text" className="input-control" />
									{errors.nameUz?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
								</div>
								<div className="d-flex flex-column mb-3 color-grey">
									<label className="mb-1">Описание(рус)</label>
									<input {...register("descriptionRu", { required: true })} type="text" className="input-control" />
									{errors.descriptionRu?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
								</div>
								<div className="d-flex flex-column mb-3 color-grey">
									<label className="mb-1">Описание(узб)</label>
									<input {...register("descriptionUz", { required: true })} type="text" className="input-control" />
									{errors.descriptionUz?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
								</div>
							</div>
						</div>
						<div className='p-2 w-100'>
							<button type='submit' className='btn btn-outline-purple w-100'>Сохранить</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default Currency
