import React, {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import {PGET, GET} from "../../../api/adminApi";
import DatePicker from "react-datepicker";
import moment from "moment";


export default function TopMasterByProduct() {
    const [items, setItems] = useState([]);
    const [productFilter, setProductFilter] = useState([{id: 0, name: "Все"}])
    const [filteredProduct, setFilteredProduct] = useState(productFilter[0])
    const [selectedOption, setSelectedOption] = useState({label: "Общий", value: 0})
    const [dateRange, setDateRange] = useState([
        new Date('2024-01-01'),
        new Date('2025-01-01'),
    ]);
    const [startDate, endDate] = dateRange;

    const options = [
        {label: "Общий", value: 0},
        {label: "Панельный", value: 1},
        {label: "Секционный", value: 2},
    ]

    async function getItems() {
        if (startDate && endDate) {
            const data = await PGET("/services/admin/api/dashboard-topten-masters/", true, {
                dateFrom: moment(startDate).format("YYYY-MM-DD"),
                dateTo: moment(endDate).add(1, 'day').format("YYYY-MM-DD"),
                orderById: selectedOption.value
            });
            if (data) {
                setItems(data.data);
            }
        }
    }
    const handleChange = (selected) => {
        setSelectedOption(selected)
    }

    useEffect(() => {
        getItems();
    }, [selectedOption,startDate, endDate]);

    return (
        <div style={{minHeight: 400}}>
            <div style={{display: "flex"}}>
                <div style={{width: 200, position: "absolute", right: 220, top: 20}}>
                    <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            setDateRange(update);
                        }}
                        isClearable={true}
                        className="datepicker-custom-input"
                    />
                </div>
                <div style={{width: 200, position: "absolute", right: 10, top: 15}}>
                    <Select
                        name="regionId"
                        className="custom-react-select"
                        options={options}
                        value={selectedOption}
                        style={{height: 30}}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div style={{overflowX: "auto"}}>
                <table className='table bg-white table-rounded table-striped' style={{fontSize: 12}}>
                    <thead>
                    <tr className='fw-bold text-gray-800 border-bottom-2 border-gray-200'>
                        <th style={{width: "5%"}} className="text-center">
                            №
                        </th>
                        <th style={{width: "20%"}} className="text-start">ФИО</th>
                        <th style={{width: "10%"}} className="text-start">Регион</th>
                        <th style={{width: "10%"}} className="text-center">Панельный</th>
                        <th style={{width: "10%"}} className="text-center">Секционный</th>
                        <th style={{width: "10%"}} className="text-center">Котел</th>
                        <th style={{width: "10%"}} className="text-center">Общий</th>
                    </tr>
                    </thead>

                    <tbody style={{overflowX: "auto"}}>
                    {items.map((item, index) => {
                        return (<tr key={item.id}>
                            <td className="text-center">
                                {index + 1}
                            </td>
                            <td className="text-start">
                                {item.soldMasterName}
                            </td>
                            <td className="text-start">{item.regionName}</td>
                            <td className="text-center">{item.soldCountPanel}</td>
                            <td className="text-center">{item.soldCountSection}</td>
                            <td className="text-center">{item.soldCountKotel}</td>
                            <td className="text-center">{item.soldCount}</td>
                        </tr>);
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

