import React from 'react'
// import { Link } from 'react-router-dom';
// import { Image } from 'react-bootstrap';
import { Star, Plus, Minus } from 'react-feather';

function Basket() {
  return (
    <div className="container-fluid m-auto">
      <div className="row">
        <div className="col-lg-7 offset-2 h-100 p-3 bg-white  border-radius-21">
          <div className="row">
            <div className="col-xl-5 col-lg col-md-12">
              <img src="img/list/product1.png" alt="" className="border-radius-15 mb-4" />
              <span className="d-flex rounded-text">
                <div className="d-flex mr-2"><Star size={18} color='#FFA91A' fill='#FFA91A' /></div>
                <div className="w-fit-content">4.7  •  99 отзывов</div>
              </span>
            </div>
            <div className="col-xl-7 col-md-12">
              <div className="d-flex flex-column">
                <h4>Название блюда</h4>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere, alias. Minus rerum quibusdam ratione consectetur,
                  veritatis quae odit non, autem enim dolores voluptatem doloremque soluta, assumenda ab quis iste cum?
                </p>
                <h4>Ингредиенты</h4>
                <div className="d-flex justify-content-between mb-3">
                  <div className="d-flex align-items-center">
                    <input type="checkbox" className="mr-2" />
                    <span>Наименование ингредиента</span>
                  </div>
                  <span className="font-weight-bold">10`000 сум</span>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  <div className="d-flex align-items-center">
                    <input type="checkbox" className="mr-2" />
                    <span>Наименование ингредиента</span>
                  </div>
                  <span className="font-weight-bold">10`000 сум</span>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  <div className="d-flex align-items-center">
                    <input type="checkbox" className="mr-2" />
                    <span>Наименование ингредиента</span>
                  </div>
                  <span className="font-weight-bold">10`000 сум</span>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  <div className="d-flex align-items-center">
                    <input type="checkbox" className="mr-2" />
                    <span>Наименование ингредиента</span>
                  </div>
                  <span className="font-weight-bold">10`000 сум</span>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  <div className="d-flex align-items-center">
                    <input type="checkbox" className="mr-2" />
                    <span>Наименование ингредиента</span>
                  </div>
                  <span className="font-weight-bold">10`000 сум</span>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="p-1 bg-grey-light br-5 cursor">
                    <Plus color="#9D9BA3" />
                  </div>
                  <div className="fz18 px-2 text-dark"> 1 </div>
                  <div className="p-1 bg-grey-light br-5 cursor">
                    <Minus color="#9D9BA3" />
                  </div>
                </div>
                <button className="btn btn-purple d-flex justify-content-between">
                  <span>Заказать</span>
                  <span>10`000 сум</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="basket col-md-2">
          <div className="w-100 background-white p-2 d-flex justify-content-between align-items-center">
            <div className="">
              <span className="fz14">Ваш заказ в ресторане</span> <br />
              <span className="fz18 font-weight-bold">Название ресторана</span>
            </div>
            <div>
              <Image src="./img/basket-logo.png" alt="" />
            </div>
          </div>
          <div className="w-100 background-white p-2 mt-1 row m-0 d-flex justify-content-between align-items-center">
            <div className="col-8 p-0 fz14">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </div>
            <div className="col-4 p-0 d-flex flex-column align-items-end">
              <div>10`000 сум</div>
              <div>x2</div>
            </div>
          </div>
          <div className="w-100 background-white p-2 mt-1 row m-0 d-flex justify-content-between align-items-center">
            <div className="col-8 p-0 fz14">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </div>
            <div className="col-4 p-0 d-flex flex-column align-items-end">
              <div>10`000 сум</div>
              <div>x2</div>
            </div>
          </div>
          <div className="w-100 background-white p-2 mt-1 row m-0 d-flex justify-content-between align-items-center">
            <div className="col-8 p-0 fz14">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </div>
            <div className="col-4 p-0 d-flex flex-column align-items-end">
              <div>10`000 сум</div>
              <div>x2</div>
            </div>
          </div>
          <div className="w-100 background-white p-2 mt-1 row m-0 d-flex justify-content-between align-items-center">
            <div className="col-8 p-0 fz14">Доставка </div>
            <div className="col-4 p-0">
              <div>10`000 сум</div>
            </div>
          </div>
          <div className="px-2 position-absolute w-100" style={{ bottom: 10 }}>
            <Link to='/checkout'><button className="btn btn-blue p-3 w-100"><div className="d-flex justify-content-between font-weight-bold"><div>Заказать</div> <div>40`000 сум</div> </div></button></Link>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Basket
