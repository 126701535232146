import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { DebounceInput } from 'react-debounce-input';
import ReactPaginate from 'react-paginate';
import { CheckCircle, Edit3, Search, XCircle, Bookmark, PlusCircle, Trash2 } from 'react-feather';

import { PUT, PGET, DELETE } from '../../../api/adminApi'

function Branchs() {
	const [items, setItems] = useState([])
	const [pageCount, setPageCount] = useState(0)
	const [value, setValue] = useState('')

	async function getItems() {
		const data = await PGET('/services/admin/api/branch-pageList', true)
		setPageCount(Math.ceil(data.headers['x-total-count'] / 20))
		setItems(data.data)
	};

	async function active(item) {
		item.activated = !item.activated
		await PUT('/services/admin/api/branch-activate', item)
		getItems()
	}

	async function search(e) {
		const data = await PGET('/services/admin/api/branch-pageList?search=' + e.target.value, true)
		setPageCount(Math.ceil(data.headers['x-total-count'] / 20))
		setValue(e.target.value)
		setItems(data.data);
	};

	async function paginate(data) {
		const response = await PGET('/services/admin/api/branch-pageList', true, { page: data.selected, size: 20, search: value })
		setItems(response.data);
	};

	async function deleteItem(id) {
		await DELETE('/services/admin/api/branch/' + id)
		getItems()
	}

	useEffect(() => {
		getItems()
	}, [])

	return (
		<div className="background-white my-2 mx-3 box-shadow br-5">
			<div className="fz20 border-bottom pl-3 py-3 my-2 d-flex align-items-center">
				<Bookmark className='mr-2' color='#5C3EBA' /> <span>Филиал Брендов</span>
			</div>
			<div className="px-3 pb-4 pt-2">
				<div className="mb-4 d-flex justify-content-between">
					<Link to='/admin/branch/create'>
						<button className="btn btn-success d-flex align-items-center">
							<PlusCircle size={18} className='mr-1' />
							<div>Добавить новый</div>
						</button>
					</Link>
					<div className="position-relative">
						<DebounceInput minLength={3} debounceTimeout={400} onChange={(e) => search(e)} type="text" className="input-search search-icon br-5 " size={24} placeholder='Поиск' />
					</div>
				</div>
				<div style={{ overflowX: 'auto' }}>
					<table className="table table-bordered">
						<thead>
							<tr className='backgroung-grey'>
								<th className="text-center">Название бренда</th>
								<th className="text-center">Краткое название</th>
								<th className="text-center">Адрес</th>
								<th className="text-center">Описание</th>
								<th className="text-center">Страна</th>
								<th className="text-center">Регион</th>
								<th className="text-center">Город</th>
								<th className="text-center">Название бренда</th>
								<th className="text-center">Статус</th>
								<th className="text-center">Действия</th>
							</tr>
						</thead>

						<tbody style={{ overflowX: 'auto' }}>

							{items.map((item) => {
								return (
									<tr key={item.id}>
										<td className="text-center">{item.name}</td>
										<td className="text-center">{item.shortName}</td>
										<td className="text-center">{item.address}</td>
										<td className="text-center">{item.description}</td>
										<td className="text-center">{item.countryName}</td>
										<td className="text-center">{item.regionName}</td>
										<td className="text-center">{item.cityName}</td>
										<td className="text-center">{item.brandName}</td>
										<td className="text-center">
											{
												item.activated ?
													<button className="btn p-2 br-5 text-white d-flex align-items-center justify-content-center m-auto" style={{ backgroundColor: '#189ED3' }}><CheckCircle size={16} /></button>
													: <button className="btn p-2 br-5 text-white d-flex align-items-center justify-content-center m-auto" style={{ backgroundColor: '#E63950' }}><XCircle size={16} /> </button>
											}
										</td>
										<td className="text-center">
											<div className="d-flex justify-content-center">
												<button onClick={() => active(item)} className="btn btn-table" style={{ backgroundColor: '#F4F4F5' }}><CheckCircle color={'#5C3EBA'} size={16} /></button>
												<Link to={'/admin/branch/update/' + item.id}><button className="btn btn-table" style={{ backgroundColor: '#F4F4F5' }}><Edit3 color={'#189ED3'} size={16} /></button></Link>
												<button onClick={() => deleteItem(item.id)} className="btn btn-table mr-0" style={{ backgroundColor: '#F4F4F5' }}><Trash2 color={'#E63950'} size={16} /></button>
											</div>
										</td>
									</tr>
								)
							})
							}
						</tbody>
					</table>
				</div>
				{
					pageCount > 1 &&
					<ReactPaginate
						breakLabel={'...'}
						breakClassName={'break-me'}
						pageCount={pageCount}
						marginPagesDisplayed={1}
						pageRangeDisplayed={1}
						onPageChange={paginate}
						containerClassName={'pagination'}
						activeClassName={'active'}
						pageClassName={'page-item'}

					/>
				}
			</div>
		</div>
	)
}

export default Branchs
