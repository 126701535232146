import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { XCircle, Save } from 'react-feather';
import { useForm } from "react-hook-form";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import moment from 'moment'

import Map from '../Map'
import { GET, POST, PUT, FILE } from '../../../api/adminApi'
import { showLoader, hideLoader } from '../../../store/actions/loader';
import { store } from '../../../store';

import "react-datepicker/dist/react-datepicker.css";

const animatedComponents = makeAnimated();

function BranchsAddUpdate() {
	let { id } = useParams();
	let history = useHistory();
	const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
		brandTypeIdList: [0],
		cuisinesIdList: [0],
		cityId: 0,
		countryId: 0,
		regionId: 0,
		brandId: 0,
		gpsPointX: 41.311081,
		gpsPointY: 69.240562,
		address: "",
		name: "",
		shortName: "",
		deliveryFrom: 10,
		deliveryTo: 60,
		deliveryPrice: "",
	})
	const [countries, setСountries] = useState([])
	const [regions, setRegions] = useState([])
	const [brands, setBrands] = useState([])
	const [brandsTypes, setBrandsTypes] = useState([])
	const [cities, setCities] = useState([])
	const [cuisines, setCuisines] = useState([])
	const [data, setData] = useState({ gpsPointX: 41.311081, gpsPointY: 69.240562, imageUrl: '', activated: true })
	const [workingDaysIdList, setWorkingDaysIdList] = useState([
		{
			activated: true,
			days: 1,
			workingEndTime: new Date().setHours(18, 0),
			workingStartTime: new Date().setHours(9, 0),
			name: 'Понедельник'
		},
		{
			activated: true,
			days: 2,
			workingEndTime: new Date().setHours(18, 0),
			workingStartTime: new Date().setHours(9, 0),
			name: 'Вторник'
		},
		{
			activated: true,
			days: 3,
			workingEndTime: new Date().setHours(18, 0),
			workingStartTime: new Date().setHours(9, 0),
			name: 'Среда'
		},
		{
			activated: true,
			days: 4,
			workingEndTime: new Date().setHours(18, 0),
			workingStartTime: new Date().setHours(9, 0),
			name: 'Четверг'
		},
		{
			activated: true,
			days: 5,
			workingEndTime: new Date().setHours(18, 0),
			workingStartTime: new Date().setHours(9, 0),
			name: 'Пятница'
		},
		{
			activated: true,
			days: 6,
			workingEndTime: new Date().setHours(18, 0),
			workingStartTime: new Date().setHours(9, 0),
			name: 'Суббота'
		},
		{
			activated: true,
			days: 7,
			workingEndTime: new Date().setHours(18, 0),
			workingStartTime: new Date().setHours(9, 0),
			name: 'Воскресенье'
		},
	])
	const [valuesBrandsTypes, setValuesBrandsTypes] = useState([])
	const [valuesCuisines, setValuesCuisines] = useState([])

	async function getProduct() {
		const response = await GET('/services/admin/api/branch/' + id, false)

		for (let i = 0; i < response.workingDays.length; i++) {
			for (let j = 0; j < workingDaysIdList.length; j++) {
				if (
					response.workingDays[i]['days'] === workingDaysIdList[j]['days'] &&
					response.workingDays[i]['activated'] === workingDaysIdList[j]['activated']
				) {
					workingDaysIdList[j]['workingStartTime'] = new Date().setHours(response.workingDays[i]['workingStartTime'].slice(0, 2), 0)
					workingDaysIdList[j]['workingEndTime'] = new Date().setHours(response.workingDays[i]['workingEndTime'].slice(0, 2), 0)
				} else {
					response.workingDays[i]['activated'] = false
				}
			}
		}
		setWorkingDaysIdList(workingDaysIdList)
		for (let key in response) {
			if (response[key]) {
				setValue(`${key}`, response[key])
			}
		}
		getCountries()
		setData({ ...data, gpsPointX: response.gpsPointX, gpsPointY: response.gpsPointY, imageUrl: response.imageUrl })
		const cuisinesHelper = await GET('/services/admin/api/cuisines-helper', false)
		var arr = []
		for (let i = 0; i < cuisinesHelper.length; i++) {
			for (let j = 0; j < response.cuisinesIdList.length; j++) {
				if (cuisinesHelper[i].id === response.cuisinesIdList[j]) {
					arr.push({
						id: cuisinesHelper[i].id,
						name: cuisinesHelper[i].name,
					});
				}
			}
		}
		setValuesCuisines(arr)
		const brandsTypesHelper = await GET('/services/admin/api/brand-type-helper', false)
		const brandsTypesList = []
		for (let i = 0; i < brandsTypesHelper.length; i++) {
			for (let j = 0; j < response.brandTypeIdList.length; j++) {
				if (brandsTypesHelper[i].id === response.brandTypeIdList[j]) {
					brandsTypesList.push({
						id: brandsTypesHelper[i].id,
						name: brandsTypesHelper[i].name,
					});
				}
			}
		}
		setValuesBrandsTypes(brandsTypesList)
	}

	async function getBrandType() {
		const response = await GET('/services/admin/api/brand-type-helper', false)
		setBrandsTypes(response)
	}
	async function getCuisines() {
		const response = await GET('/services/admin/api/cuisines-helper', false)
		setCuisines(response)
	}
	async function getBrands() {
		const response = await GET('/services/admin/api/brand-helper', false)
		if (response.length > 0) {
			setBrands(response)
			setValue('brandId', response[0].id)
		}
	}
	async function getCountries() {
		const response = await GET('/services/admin/api/country-helper', false)
		setСountries(response)
		if (!id) {
			setValue('countryId', response[0].id)
			getRegions(response[0].id)
		} else {
			getRegions(getValues('countryId'))
		}
	}
	async function getRegions(data) {
		const response = await GET('/services/admin/api/region-helper/' + data, false)
		setRegions(response)
		if (!id) {
			setValue('regionId', response[0].id)
			getCities(response[0].id)
		} else {
			setValue('regionId', getValues('regionId'))
			getCities(getValues('regionId'))
		}
	}
	async function getCities(data) {
		const response = await GET('/services/admin/api/city-helper/' + data, false)
		setCities(response)
		if (!id) {
			setValue('cityId', response[0].id)
		} else {
			setValue('cityId', getValues('cityId'))
		}
	}
	function onClick(t) {
		setData({ ...data, gpsPointX: t.lat, gpsPointY: t.lng })
		setValue('gpsPointX', t.lat)
		setValue('gpsPointY', t.lng)
	}

	async function create(data) {
		var newWorkingDaysIdList = []
		for (let i = 0; i < workingDaysIdList.length; i++) {
			newWorkingDaysIdList.push({
				activated: workingDaysIdList[i].activated,
				days: workingDaysIdList[i].days,
				workingEndTime: moment(workingDaysIdList[i]['workingEndTime']).format("HH:mm"),
				workingStartTime: moment(workingDaysIdList[i]['workingStartTime']).format("HH:mm")
			})
		}
		var body = {
			...data,
			workingDaysIdList: newWorkingDaysIdList
		}
		var response;
		if (id) {
			response = await PUT('/services/admin/api/branch', body)
		}
		else {
			response = await POST('/services/admin/api/branch', body)
		}
		if (response) {
			history.push('/admin/branch')
		}

	}

	const handleChange = (selectedOption) => {
		setValuesBrandsTypes(selectedOption)
		const arr = []
		for (let i = 0; i < selectedOption.length; i++) {
			arr.push(selectedOption[i].id);
		}
		setValue('brandTypeIdList', arr)
	};
	const handleCuisines = (selectedOption) => {
		setValuesCuisines(selectedOption)
		const arr = []
		for (let i = 0; i < selectedOption.length; i++) {
			arr.push(selectedOption[i].id);
		}
		setValue('cuisinesIdList', arr)
	};

	async function getImageUrl(e) {
		if (e.target.files[0]) {
			const image = e.target.files[0];
			const formData = new FormData();
			formData.append('image', image);
			const response = await FILE("/services/admin/api/branch-image-upload", formData);
			setData({ ...data, imageUrl: response.url })
			setValue('imageUrl', response.url)
		}
	}

	function activateDay(index) {
		const copy = workingDaysIdList
		copy[index].activated = !copy[index].activated
		setWorkingDaysIdList([...workingDaysIdList])
	}
	function setWorkingStartTime(index, date) {
		const arr = [...workingDaysIdList]
		arr[index].workingStartTime = date
		setWorkingDaysIdList(arr)
	}
	function setWorkingEndTime(index, date) {
		const arr = [...workingDaysIdList]
		arr[index].workingEndTime = date
		setWorkingDaysIdList(arr)
	}

	const CustomInput = React.forwardRef((props, ref) => {
		return (
			<div onClick={props.onClick} ref={ref} className='position-relative cursor' >
				<input className={props.className} value={props.value || props.placeholder} readOnly />
			</div>
		);
	});

	async function getData() {
		store.dispatch(showLoader())
		await getBrands()
		await getBrandType()
		await getCuisines()
		setValue('gpsPointX', 41.311081)
		setValue('gpsPointY', 69.240562)
		setValue('brandTypeIdList', [])
		setValue('cuisinesIdList', [])
		if (id) {
			await getProduct()
		} else {
			await getCountries()
		}
		store.dispatch(hideLoader())
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="mb-2 mx-3 ">
			<h3 className="mb-3 mt-1">Добавить новый</h3>
			<div className="admin-card p-4" >
				
				<form onSubmit={handleSubmit(create)} autoComplete='off' action=''>
					<div className="row">
						{/* <div className="col-md-4">
							<div className="admin-field">
								<div className="admin-label mr-md-3"><label className="mb-0 text-right">Название филиала</label></div>
								<div className="w-50">
									<input {...register("name", { required: true })} type="text" placeholder="Название филиала " className="input-control" />
									{errors.name?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
								</div>
							</div>
						</div> */}
						<div className="col-lg-4">
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0 text-right">Название филиала</label></div>
									<input {...register("name", { required: true })} type="text" placeholder="Название филиала " className="input-control" />
								</div>
								{errors.name?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0 text-right">Краткое название филиала</label></div>
									<input {...register("shortName", { required: true })} type="text" placeholder="Краткое название филиала" className="input-control" />
								</div>
								{errors.shortName?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Адрес филиала</label></div>
									<input {...register("address", { required: true })} type="text" placeholder="Адрес филиала " className="input-control" />
								</div>
								{errors.address?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Тип бренда</label></div>
									<Select
										defaultValue={brandsTypes[0]}
										placeholder="Выберите тип"
										components={animatedComponents}
										value={valuesBrandsTypes}
										isMulti
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										name="brandsTypes"
										options={brandsTypes}
										className="basic-multi-select w-100"
										classNamePrefix="Выбирать"
										onChange={handleChange}
									/>
								</div>
								{errors.brandTypeIdList?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Кухня</label></div>
									<Select
										defaultValue={cuisines[0]}
										placeholder="Выберите кухню"
										components={animatedComponents}
										value={valuesCuisines}
										isMulti
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										name="cuisines"
										options={cuisines}
										className="basic-multi-select w-100"
										classNamePrefix="Выбирать"
										onChange={handleCuisines}
									/>
								</div>
								{errors.cuisinesIdList?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Страна</label></div>
									<select className="admin-select" {...register("countryId", { required: true })} onChange={(e) => (setValue('countryId', e.target.value) + getRegions(e.target.value))}>
										{countries.map((item) => {
											return (
												<option key={item.id} value={item.id}>{item.name} </option>
											)
										})
										}
									</select>
								</div>
								{errors.countryId?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Регион</label></div>
									<select className="admin-select" {...register("regionId", { required: true })} onChange={(e) => (setValue('regionId', e.target.value) + getCities(e.target.value))}>
										{regions.map((item) => {
											return (
												<option key={item.id} value={item.id}>{item.name} </option>
											)
										})
										}
									</select>
								</div>
								{errors.regionId?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Город (район)</label></div>
									<select className="admin-select" {...register("cityId", { required: true })} onChange={(e) => setValue('cityId', e.target.value)}>
										{cities.map((item) => {
											return (
												<option key={item.id} value={item.id}>{item.name} </option>
											)
										})
										}
									</select>
								</div>
								{errors.cityId?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0 text-right">Название бренда</label></div>
									<select className="admin-select" {...register("brandId", { required: true })} onChange={(e) => setValue('brandId', e.target.value)}>
										{brands.map((item) => {
											return (
												<option key={item.id} value={item.id}>{item.name} </option>
											)
										})
										}
									</select>
								</div>
								{errors.brandId?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0 text-right">Телефон</label></div>
									<InputMask mask='+999-99-999-99-99' {...register("phone", { required: true })}
										type="text" placeholder="+999-99-999-99-99" className="input-control" />
								</div>
								{errors.phone?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Описание</label></div>
									<textarea {...register("description", { required: true })} style={{ resize: 'none' }} className='input-control w-100' cols="10"></textarea>
								</div>
								{errors.description?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0 text-right">Цена доставки</label></div>
									<input {...register("deliveryPrice", { required: true })} type="text" placeholder="Цена доставки" className="input-control" />
								</div>
								{errors.deliveryPrice?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Статус</label></div>
									<div className="w-50 d-flex">
										<input type="radio" onChange={(e) => setData({ ...data, activated: true }) + setValue('activated', true)}
											checked={data.activated} id="activated-1" name='activated' className="custom-rb" />
										<label className="mb-0" htmlFor="activated-1">Активный</label>
										<div className="mr-3"></div>
										<input type="radio" onChange={(e) => setData({ ...data, activated: false }) + setValue('activated', false)}
											checked={!data.activated} id="deactivated-1" name='activated' className="custom-rb" />
										<label className="mb-0" htmlFor="deactivated-1">Заблокирован</label>
									</div>
								</div>
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className='admin-label'><label className="mb-0">Логотип бренда</label></div>
									<div className='input-control border-none'>
										<div className="position-relative">
											<div className="image-block" style={{ width: '100%', maxWidth: '300px', height: '100px' }}>Перетащите сюда файлы для загрузки</div>
											<input onChange={(e) => getImageUrl(e)} type="file" style={{ width: '100%', maxWidth: '300px', height: '100px', }} className="image-input" />
											{/* {errors.imageUrl?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>} */}
											{data.imageUrl &&
												<div className="position-absolute cursor border-radius-3" style={{ width: '100%', maxWidth: '300px', height: '100px', top: 0, zIndex: 2 }}>
													<img className="w-100 h-100 background-white border box-shadow admin-image" src={'https://colibri24.uz' + (data.imageUrl)} alt="" />
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3 mb-3 d-flex flex-column color-grey">
							<div className="mb-3">Время работы</div>
							{workingDaysIdList.map((item, index) => {
								return (
									<div key={item.days} className='d-flex mb-2 align-items-center'>
										<div className='col-md-4 d-flex align-items-center mb-2'>
											<input
												checked={item.activated}
												defaultValue='false'
												onChange={() => activateDay(index)}
												type="checkbox" className="p-2 mr-1" style={{ transform: 'scale(1.0)' }} />
											<span>{item.name}</span>
										</div>
										{item.activated ?
											<div className='col-md-8 d-flex justify-content-between align-items-center'>
												<DatePicker
													customInput={<CustomInput />}
													className='input-control'
													dateFormat="HH:mm"
													showTimeSelect
													showTimeSelectOnly
													timeFormat="HH:mm"
													timeIntervals={60}
													selected={item.workingStartTime}
													onChange={(date) => setWorkingStartTime(index, date)}
												/>
												<span className='mx-1 color-grey fz18'>-</span>
												<DatePicker customInput={<CustomInput />}
													className='input-control'
													dateFormat="HH:mm"
													showTimeSelect
													showTimeSelectOnly
													timeFormat="HH:mm"
													timeIntervals={60}
													selected={item.workingEndTime}
													onChange={(date) => setWorkingEndTime(index, date)}
												/>
											</div>
											: <div className='col-md-8 d-flex justify-content-between align-items-center'>
												<DatePicker
													customInput={<CustomInput />}
													className='input-control input-disabled'
													dateFormat="HH:mm"
													showTimeSelect
													showTimeSelectOnly
													timeFormat="HH:mm"
													timeIntervals={60}
													disabled
													selected={item.workingStartTime}
													onChange={(date) => setWorkingStartTime(index, date)}
												/>
												<span className='mx-1 color-grey fz18'>-</span>
												<DatePicker customInput={<CustomInput />}
													className='input-control input-disabled'
													dateFormat="HH:mm"
													showTimeSelect
													showTimeSelectOnly
													timeFormat="HH:mm"
													timeIntervals={60}
													disabled
													selected={item.workingEndTime}
													onChange={(date) => setWorkingEndTime(index, date)}
												/>
											</div>
										}
									</div>
								)
							})
							}
							<div className='d-flex justify-content-between align-items-center'>
								<div className="col-md-4 d-flex">Время доставки</div>
								<div className="col-md-8 d-flex justify-content-between align-items-center">
									<div className='row mb-2'>
										<div className='col-md-12 d-flex justify-content-between align-items-center'>
											<input {...register("deliveryFrom", { required: true })} type='number' min='0' className='input-control' />
											<span className='mx-1 color-grey fz18'>-</span>
											<input {...register("deliveryTo", { required: true })} type='number' min='0' className='input-control' />
										</div>
										<div className='col-md-12'>
											{/* {errors.deliveryFrom?.type === 'required' && <div className='text-danger text-start mt-1 fz12'>Обязательное поле</div>} */}
											{errors.deliveryTo?.type === 'required' || errors.deliveryFrom?.type === 'required' ? <div className='text-danger text-center mt-1 fz12'>Обязательное поле</div> : ''}
										</div>
									</div>
								</div>
							</div>
							{/* <div>
                <div className='d-flex align-items-center mb-2'>
                  <input type="checkbox" className="p-2 mr-2" style={{ transform: 'scale(1.0)' }} />
                  <span>Понедельник</span>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <DatePicker customInput={<CustomInput />} className='input-control' dateFormat="dd-MM-yyyy"  
                    {...register("birthday", { required: true })} selected={pickedDate}
                    onChange={(date) => (setPickedDate(date) + setValue('birthday', moment(date).format("DD-MM-YYYY")))} />
                    <span className='mx-1 color-grey fz18'>-</span>
                  <DatePicker customInput={<CustomInput />} className='input-control' dateFormat="dd-MM-yyyy"  
                    {...register("birthday", { required: true })} selected={pickedDate}
                    onChange={(date) => (setPickedDate(date) + setValue('birthday', moment(date).format("DD-MM-YYYY")))} />
                </div>
              </div>  */}
						</div>
						<div className="col-lg-5 mb-3 d-flex flex-column color-grey">
							<div className="mb-3 w-100">Геолокация основного офиса </div>
							<div className="d-flex justify-content-between w-100 mb-3">
								<div className='flex-column d-flex w-100 mr-4'>
									<label className="mb-0">Ширина</label>
									<input {...register("gpsPointX", { required: true })} type="text" placeholder='00.000000' className="input-control w-100" />
									{errors.gpsPointX?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
								</div>

								<div className='flex-column d-flex w-100'>
									<label className="mb-0">Долгота</label>
									<input {...register("gpsPointY", { required: true })} type="text" placeholder='00.000000' className="input-control w-100" />
									{errors.gpsPointY?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
								</div>
							</div>
							<div className='mb-5' style={{ height: '100%', width: '100%' }}>
								<Map
									center={{ lat: data.gpsPointX, lng: data.gpsPointY }}
									onClick={onClick}
									lat={data.gpsPointY}
									lng={data.gpsPointX}
								/>
							</div>
						</div>
					</div>
					<div className="btn-postion d-flex">
						<Link to='/admin/branch' className="mr-3"><button className="btn btn-outline-purple d-flex align-items-center"><XCircle size={14} className="mr-2" />Отмена </button></Link>
						<button type='submit' className="btn btn-purple d-flex align-items-center"><Save size={14} className="mr-2" />Сохранить </button>
					</div>
				</form>
			</div>
		</div>
	);
}


export default BranchsAddUpdate;
