import React, {useEffect, useState} from 'react'
import {StatisticsCard} from "./StatisticsCard";
import {PGET} from "../../../api/adminApi";

export default function     Statistics() {
    const [items, setItems] = useState([])

    async function getItems() {
        const data = await PGET("/services/admin/api/dashboard-counts/", true, {});
        setItems(data.data);
    }
    console.log(items)
    useEffect(() => {
        getItems()
    }, [])
    return (
            <div className='w-100 g-6 g-xl-8 stat-grid'>
                <div className='w-100'>
                    <StatisticsCard
                        titleColor="white"
                        className='card-xl-stretch mb-xl-8'
                        svgIcon='/icons/duotune/communication/com014.svg'
                        color='#141D76'
                        iconColor='white'
                        title={items.masterCount}
                        description='Мастеры'
                        descriptionColor="white"
                    />
                </div>
            <div className='w-100'>
                <StatisticsCard
                    className='card-xl-stretch mb-xl-8'
                    svgIcon='/icons/duotune/ecommerce/ecm004.svg'
                    color={'#E7372C'}
                    titleColor="white"
                    iconColor='white'
                    title={items.salesCount}
                    description='Продажа'
                    descriptionColor="white"
                />
            </div>



            <div className='w-100'>
                <StatisticsCard
                    className='card-xl-stretch mb-5 mb-xl-8'
                    svgIcon='/icons/duotune/abstract/abs025.svg'
                    color='#25C14C'
                    titleColor="white"
                    iconColor='white'
                    title={items.pointsTotal}
                    description='Набранные баллы'
                    descriptionColor="white"
                />
            </div>
            <div className='w-100'>
                <StatisticsCard
                    className='card-xl-stretch mb-5 mb-xl-8'
                    svgIcon='/icons/duotune/abstract/abs024.svg'
                    color='#998DEB'
                    titleColor="white"
                    iconColor='white'
                    title={items.usedPointsTotal}
                    description='Использованные баллы'
                    descriptionColor="white"
                />
            </div>
        </div>
    )
}