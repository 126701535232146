import React, { useState, useEffect } from 'react'
import { MoreVertical } from 'react-feather';
import Modal from 'react-bootstrap/Modal'

import { GET } from '../../../api/adminApi';

function HistoryOrders() {
  const [showModal, setShowModal] = useState(false)
  const [orders, setOrders] = useState(null)

  async function getOrders() {
    const response = await GET('/services/web/api/orders-history-pageList', true)
    setOrders(response)
  }

  async function getOrder(id) {
    const response = await GET('/services/web/api/orders/' + id, true)
    setOrders(response)
    setShowModal(true)
  }

  useEffect(() => {
    getOrders()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="mb-3 fz28">История заказов </div>
      <div className="row">
        <div className="col-md-12">
          { orders !== null && orders.length  ?
            orders.map((item) => {
              return (
                <div>
                  <div className="badge py-2 px-3 badge-grey badge-pill mb-3 fz12">
                    {item.orderDate}
                  </div>
                  {item.detailList.map((item2) => {
                    return (
                      <div className="profile-card p-3 mb-3" onClick={() => getOrder(item2.id)}>
                        <div className="d-flex justify-content-md-between justify-content-center flex-wrap align-items-center mb-2">
                          <div className="d-flex mb-sm-2">
                            <div>
                              <img src={'https://colibri24.uz' + (item2.branchImageUrl)} height="64" width="64" alt="" className="mr-md-4 mr-2" />
                            </div>
                            <div className="d-flex flex-column">
                              <div className="fz24">
                                <b>{item2.branchName}</b>
                              </div>
                              <div className="color-grey">
                                Номер заказа: {item2.orderNumber}    Дата: 00-00-0000
                              </div>
                            </div>
                          </div>
                          <div className="d-flex profile-order ">
                            <div>
                              <b>15 мин</b>
                              <b>15 единиц</b>
                              <b>40`000 сум</b>
                            </div>
                            <div className="d-flex">
                              <span className="order-status">
                                Статус
                              </span>
                              <div className="d-flex align-items-center">
                                <MoreVertical size={24} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })

                  }
                </div>
              )
            })
            :
            <div className="text-center">
              <h4>Нет заказов</h4>
            </div>
          }
        </div>

        {/* <div className="col-md-12">
          <div className="badge py-2 px-3 badge-grey badge-pill mb-3 fz12">
            03 Апрель 2021
          </div>
          <div className="profile-card p-3 mb-3" onClick={() => setShowModal(true)}>
            <div className="d-flex justify-content-md-between justify-content-center flex-wrap align-items-center mb-2">
              <div className="d-flex mb-sm-2">
                <div>
                  <img src="img/grey.png" alt="" className="mr-md-4 mr-2" />
                </div>
                <div className="d-flex flex-column">
                  <div className="fz24">
                    <b>Название ресторана</b>
                  </div>
                  <div className="color-grey">
                    Номер заказа: 123456    Дата: 00-00-0000
                  </div>
                </div>
              </div>
              <div className="d-flex profile-order ">
                <div>
                  <b>15 мин</b>
                  <b>15 единиц</b>
                  <b>40`000 сум</b>
                </div>
                <div className="d-flex">
                  <span className="order-status">
                    Статус
                  </span>
                  <div className="d-flex align-items-center">
                    <MoreVertical size={24} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="p-4"
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className='fz32 font-weight-bold'>
            Детали заказа
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">

          <div className="p-2 border-bottom mb-3 px-3 d-flex flex-column">

            <div className="d-flex justify-content-between w-100 mb-3">
              <div className="d-flex flex-column justify-content-center">
                <div className="color-grey d-flex flex-wrap">
                  <div className="mr-2">Номер заказа: 123456 </div>
                  <div>Дата: 00-00-0000</div>
                </div>
                <div className="fz24">
                  Название ресторана
                </div>
              </div>
              <img src="img/grey.png" alt="" className="h-fit-content" />
            </div>

            <div className="d-flex align-items-center w-100 mb-1 border-bottom pb-2 mb-3">
              <div className="d-flex align-items-center w-100">
                <img src="img/grey.png" alt="" className="mr-2" />
                <div className="ml-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="text-right">х2</div>
                <div className="text-right text-nowrap"><b>10`000 сум</b></div>
              </div>
            </div>
            <div className="d-flex align-items-center w-100 mb-1 border-bottom pb-2 mb-3">
              <div className="d-flex align-items-center w-100">
                <img src="img/grey.png" alt="" className="mr-2" />
                <div className="ml-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="text-right">х2</div>
                <div className="text-right text-nowrap"><b>10`000 сум</b></div>
              </div>
            </div>
            <div className="d-flex align-items-center w-100 mb-1 border-bottom pb-2 mb-3">
              <div className="d-flex align-items-center w-100">
                <img src="img/grey.png" alt="" className="mr-2" />
                <div className="ml-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="text-right">х2</div>
                <div className="text-right text-nowrap"><b>10`000 сум</b></div>
              </div>
            </div>


            <div className="d-flex justify-content-between px-2 mb-2">
              <span>Доставка</span>
              <b>0 сум</b>
            </div>
            <div className="d-flex justify-content-between px-2 mb-2">
              <span>Сервисный сбор</span>
              <b>0 сум</b>
            </div>
            <div className="d-flex justify-content-between fz24 px-2 mb-2">
              <b>Итого</b>
              <b>40`000 сум</b>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default HistoryOrders
