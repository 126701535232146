import React, { useEffect, useState } from 'react';
import {toAbsoluteUrl} from "./toAbsoluteUrl";

const CustomSvg = ({ className = '', path, svgClassName = 'mh-50px', color, ...atr }) => {
    const [svgContent, setSvgContent] = useState('');

    useEffect(() => {
        fetch(toAbsoluteUrl(path))
            .then((response) => response.text())
            .then((text) => {
                if (color) {
                    text = text.replace('<svg', `<svg fill="${color}"`);
                }
                setSvgContent(text);
            });
    }, [path, color]);

    return (
        <span
            className={`svg-icon ${className}`}
            dangerouslySetInnerHTML={{ __html: svgContent }}
            {...atr}
        />
    );
};

export { CustomSvg };
