import React from 'react'
import {CustomSvg} from "../../utils/CustomSvg";


export default function Test({className}) {
    return (<div className={`card ${className}`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Members Statistics</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 members</span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >
                    <a
                        href='#'
                        className='btn btn-sm btn-light-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_invite_friends'
                    >
                        <CustomSvg path='/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                        New Member
                    </a>
                </div>
            </div>
            <div className='card-body py-3'>
                <div className='table-responsive'>
                    <table className='table table-hover table-rounded table-striped border gy-7 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                            <th className='min-w-150px'>Authors</th>
                            <th className='min-w-140px'>Company</th>
                            <th className='min-w-120px'>Progress</th>
                            <th className='min-w-100px text-end'>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start flex-column'>
                                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                            Ana Simmons
                                        </a>
                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        HTML, JS, ReactJS
                      </span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                    Intertico
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    Web, UI/UX Design
                  </span>
                            </td>
                            <td>
                                <div className='d-flex justify-content-end flex-shrink-0'>
                                    <a
                                        href='#'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    >
                                        <CustomSvg
                                            path='/icons/duotune/general/gen019.svg'
                                            className='svg-icon-3'
                                        />
                                    </a>
                                    <a
                                        href='#'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    >
                                        <CustomSvg path='/icons/duotune/art/art005.svg' className='svg-icon-3'/>
                                    </a>
                                    <a
                                        href='#'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                    >
                                        <CustomSvg
                                            path='/icons/duotune/general/gen027.svg'
                                            className='svg-icon-3'
                                        />
                                    </a>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>)
}

