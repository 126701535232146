import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { XCircle, Save } from "react-feather";
import { useForm } from "react-hook-form";

import { GET, POST, PUT } from "../../../api/adminApi";
import { showLoader, hideLoader } from "../../../store/actions/loader";
import { store } from "../../../store";

function BazarAddUpdate() {
   let { id } = useParams();
   let history = useHistory();
   const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      getValues,
   } = useForm({
      regionHelperDTO: {
         id: 0,
         name: "",
      },
      cityHelperDTO: {
         id: 0,
         name: "",
      },
      activated: true,
      brandTypeIdList: [],
      cityId: 0,
      companyId: 0,
      countryId: 0,
      regionId: 0,
      gpsPointX: 41.311081,
      gpsPointY: 69.240562,
      mainOfficeAddress: "",
      name: "",
      description: "",
   });
   const [regions, setRegions] = useState([]);
   const [cities, setCities] = useState([]);
   const [data, setData] = useState({
      gpsPointX: 41.311081,
      gpsPointY: 69.240562,
      imageUrl: "",
      activated: true,
   });

   async function getProduct() {
      const response = await GET("/services/admin/api/bazar/" + id, false);
      for (let key in response) {
         setValue(`${key}`, response[key]);
      }
      getRegions();
      setData({
         ...data,
         gpsPointX: response.gpsPointX,
         gpsPointY: response.gpsPointY,
         imageUrl: response.imageUrl,
         activated: response.activated,
      });
   }

   async function getRegions() {
      const response = await GET("/services/admin/api/region-helper/1", false);
      setRegions(response);
      if (!id) {
         setValue("regionId", response[0].id);
         getCities(response[0].id);
         setValue("regionHelperDTO.name", response[0].name);
         setValue("regionHelperDTO.id", response[0].id);
      } else {
         getCities(getValues("regionId"));
         setValue("regionId", getValues("regionId"));
      }
   }

   async function getCities(data) {
      const response = await GET(
         "/services/admin/api/city-helper/" + data,
         false
      );
      setCities(response);
      if (!id) {
         setValue("cityId", response[0].id);
         setValue("cityHelperDTO.name", response[0].name);
         setValue("cityHelperDTO.id", response[0].id);
      }
   }

   async function create(data) {
      var response;
      if (id) {
         response = await PUT("/services/admin/api/bazar", data);
      } else {
         response = await POST("/services/admin/api/bazar", data);
      }
      if (response) {
         history.push("/admin/bazar");
      }
   }

   async function getData() {
      store.dispatch(showLoader());
      if (id) {
         await getProduct();
      } else {
         await getRegions();
      }
      store.dispatch(hideLoader());
   }

   useEffect(() => {
      getData();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div className="mb-2 mx-3 ">
         <h3 className="mb-5 mt-5">Добавить новый</h3>
         <div className="admin-card p-4">
            <form onSubmit={handleSubmit(create)} autoComplete="off" action="">
               <div className="row pt-12">
                  <div className="col-lg-6">
                     <div className="admin-field">
                        <div className="d-flex w-100">
                           <div className="admin-label mr-md-3">
                              <label className="mb-0">Название базара</label>
                           </div>
                           <input
                              {...register("name", { required: true })}
                              type="text"
                              placeholder=""
                              className="input-control"
                           />
                        </div>
                        {errors.name?.type === "required" && (
                           <div className="text-danger mt-1 fz12">
                              Обязательное поле
                           </div>
                        )}
                     </div>
                     <div className="admin-field">
                        <div className="d-flex w-100">
                           <div className="admin-label mr-md-3">
                              <label className="mb-0">Описание базара</label>
                           </div>
                           <input
                              {...register("description", { required: true })}
                              type="text"
                              placeholder=""
                              className="input-control"
                           />
                        </div>
                        {errors.description?.type === "required" && (
                           <div className="text-danger mt-1 fz12">
                              Обязательное поле
                           </div>
                        )}
                     </div>

                     <div className="admin-field">
                        <div className="d-flex w-100">
                           <div className="admin-label mr-md-3">
                              <label className="mb-0">Регион</label>
                           </div>
                           <select
                              className="admin-select"
                              {...register("regionId", { required: true })}
                              onChange={(e) =>
                                 setValue("regionId", e.target.value) +
                                 getCities(e.target.value)
                              }
                           >
                              {regions.map((item) => {
                                 return (
                                    <option key={item.id} value={item.id}>
                                       {item.name}{" "}
                                    </option>
                                 );
                              })}
                           </select>
                        </div>
                        {errors.regionId?.type === "required" && (
                           <div className="text-danger mt-1 fz12">
                              Обязательное поле
                           </div>
                        )}
                     </div>
                     <div className="admin-field">
                        <div className="d-flex w-100">
                           <div className="admin-label mr-md-3">
                              <label className="mb-0">Город (район)</label>
                           </div>
                           <select
                              className="admin-select"
                              {...register("cityId", { required: true })}
                              onChange={(e) =>
                                 setValue("cityId", e.target.value)
                              }
                           >
                              {cities.map((item) => {
                                 return (
                                    <option key={item.id} value={item.id}>
                                       {item.name}{" "}
                                    </option>
                                 );
                              })}
                           </select>
                        </div>
                        {errors.cityId?.type === "required" && (
                           <div className="text-danger mt-1 fz12">
                              Обязательное поле
                           </div>
                        )}
                     </div>
                  </div>
               </div>
               <div className="btn-postion d-flex">
                  <Link to="/admin/bazar" className="mr-3">
                     <button className="btn text-white btn-bg-primary d-flex align-items-center">
                        <XCircle size={14} className="mr-2" />
                        Отмена
                     </button>
                  </Link>
                  <button
                      className="btn text-white btn-bg-success d-flex align-items-center"
                      type="submit"
                  >
                     <Save size={14} className="mr-2" />
                     Сохранить
                  </button>
               </div>
            </form>
         </div>
      </div>
   );
}

export default BazarAddUpdate;
