import React, {useState, useEffect} from "react";
import {useParams, useHistory, Link} from "react-router-dom";
import Select from "react-select";
import {XCircle, Save, Calendar} from "react-feather";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import {isValidPhoneNumber} from "react-phone-number-input";

import {POST, GET, PUT} from "../../../api/adminApi";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import {getFullDate} from "../../../utils/Dates";

function MasterAddUpdate() {
    const {id: masterId} = useParams();
    const history = useHistory();
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        setValue,
        getValues,
    } = useForm();
    const [data, setData] = useState({
        approved: 0,
    });
    const [photo, setPhoto] = useState()
    const [birthday, setBirthday] = useState()
    const [region, setRegion] = useState(null);
    const [city, setCity] = useState(null);
    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);
    const [phoneErr, setPhoneErr] = useState(null);
    const [pickedDate, setPickedDate] = useState(new Date());
    const lastDateOfYear = `12/31/${new Date().getFullYear()}`;

    function onSubmit(values) {
        const obj = {
            ...data,
            name: values.name,
            description: values.description,
            phone: values.phone.slice(1),
            regionId: region ? region.id : null,
            cityId: city ? city.id : null,
            birthday: getFullDate(pickedDate)
        };
        checkPhoneNumber();

        async function create() {
            if (masterId) {
                const response = await PUT("/services/admin/api/master", obj);
                if (response?.success) {
                    history.push("/admin/master");
                }
            } else {
                const response = await POST("/services/admin/api/master", obj);
                if (response?.success) {
                    history.push("/admin/master");
                    toast.success("Успешно добавлен");
                }
            }
        }

        if (!phoneErr) {
            create();
        }
    }

    async function checkPhoneNumber() {
        setPhoneErr(null);
        const res = await POST(`/services/admin/api/master/phone-check`, {
            phone: getValues.phone,
        });

        if (res?.success) {
            setPhoneErr(null);
        }
        if (res?.response?.status === 400) {
            setPhoneErr(res.response?.data?.message);
        } else {
            setPhoneErr(" ");
        }
    }

    function selectRegion(selectedOption) {
        if (selectedOption !== region) {
            setRegion(selectedOption);
            getCities(selectedOption.id);
            setCity(null);
        }
    }

    function selectCity(selectedOption) {
        if (selectedOption !== city) {
            setCity(selectedOption);
        }
    }

    async function getData() {
        const response = await GET("/services/admin/api/master/" + masterId);
        if (response) {
            const {
                approved,
                id,
                name,
                phone,
                photo,
                description,
                regionHelperDTO,
                cityHelperDTO,
                birthday,
            } = response;
            setPhoto(photo)
            setBirthday(birthday)
            setValue("name", name);
            setValue("description", description);
            setValue("birthday", pickedDate);
            setValue("phone", "+" + phone);
            setData((prev) => ({
                ...prev,
                approved,
                id,
            }));

            const getRegions = await GET("/services/admin/api/region-helper/1");
            if (getRegions) {
                setRegions(getRegions);
                setRegion(
                    getRegions.find((item) => item?.id === regionHelperDTO?.id)
                );
            }

            const getCities = await GET(
                `/services/admin/api/city-helper/${regionHelperDTO?.id}`
            );
            if (getCities) {
                setCities(getCities);
                setCity(getCities.find((item) => item?.id === cityHelperDTO?.id));
            }
        }
    }

    async function getRegions() {
        const response = await GET("/services/admin/api/region-helper/1");
        if (response?.length > 0) {
            setRegions(response);
        }
    }

    async function getCities(id) {
        const response = await GET(`/services/admin/api/city-helper/${id || 0}`);
        if (response?.length > 0) {
            setCities(response);
        }
    }

    useEffect(() => {
        if (masterId) {
            getData();
        } else {
            getRegions();
            getCities();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className="mb-1 mx-3">
            <h3 className="mb-5 mt-5">
                {masterId ? "Изменить" : "Добавить мастер"}
            </h3>

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="admin-card p-4 overflow-y-auto pt-12 d-flex flex-column justify-content-between">
                    <div className="mb-1 d-flex">
                        <div className="">
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    ФИО
                                </label>
                                <div>
                                    <input
                                        {...register("name")}
                                        type="text"
                                        className="input-control"
                                        style={{width: "300px"}}
                                    />
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    День рождения
                                </label>
                                <div>
                                    <input
                                        disabled
                                        type="text"
                                        value={birthday}
                                        className="input-control"
                                        style={{width: "300px"}}
                                    />
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Описание
                                </label>
                                <div>
                           <textarea
                               {...register("description")}
                               type="text"
                               className="input-control"
                               style={{width: "300px"}}
                           />
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Номер телефона
                                </label>
                                <div>
                                    <PhoneInput
                                        name="phone"
                                        control={control}
                                        rules={{
                                            validate: isValidPhoneNumber,
                                        }}
                                        international
                                        withCountryCallingCode
                                        country="UZ"
                                        style={{width: "300px"}}
                                        className="input-control"
                                    />
                                    {errors.phone && (
                                        <div className="text-danger mt-1 fz12">
                                            "Заполните полностью и правильно"
                                        </div>
                                    )}

                                    {phoneErr && (
                                        <p className="text-danger m-0">{phoneErr}</p>
                                    )}

                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <div className="mb-2 d-flex align-items-center gap-2">
                                    <label
                                        style={{width: "120px"}}
                                        className="color-grey text-end text-nowrap"
                                    >
                                        Регион
                                    </label>
                                    <div style={{width: "300px", fontSize: "12px"}}>
                                        <Select
                                            {...register("region", {
                                                value: region,
                                            })}
                                            value={region}
                                            placeholder="Выберите регион"
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            options={regions}
                                            onChange={selectRegion}
                                        />
                                        {/*{errors.region && (*/}
                                        {/*   <div className="text-danger mt-1 fz12">*/}
                                        {/*      Обязательное поле*/}
                                        {/*   </div>*/}
                                        {/*)}*/}
                                    </div>
                                </div>
                                <div className="mb-2 d-flex align-items-center gap-2">
                                    <label
                                        style={{width: "120px"}}
                                        className="color-grey text-end text-nowrap"
                                    >
                                        Город / район
                                    </label>
                                    <div style={{width: "300px"}}>
                                        <Select
                                            {...register("city", {
                                                value: city,
                                            })}
                                            value={city}
                                            placeholder="Выберите город(район)"
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            options={cities}
                                            onChange={selectCity}
                                        />
                                        {/*{errors.city && (*/}
                                        {/*   <div className="text-danger mt-1 fz12">*/}
                                        {/*      Обязательное поле*/}
                                        {/*   </div>*/}
                                        {/*)}*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <img alt="img" className="user-image-large"
                                 src={photo ? "https://bo.akfacomfort.uz/" + photo : "https://th.bing.com/th/id/OIP.1V_ec2D7-sqwJoC5qZOmUQAAAA?pid=ImgDet&rs=1"}/>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end">
                        <Link to="/admin/master" className="mr-3">
                            <button className="btn text-white btn-bg-primary d-flex align-items-center">
                                <XCircle size={14} className="mr-2"/>
                                Отмена
                            </button>
                        </Link>
                        <button
                            className="btn text-white btn-bg-success d-flex align-items-center"
                            type="submit"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
        ;
}

export default MasterAddUpdate;
