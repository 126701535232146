import React, {useEffect, useState} from "react";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import {Edit, Layers, List, Lock, Unlock} from "react-feather";
import {DOWNLOAD, excelParams, PGET, PUT} from "../../../api/adminApi";
import {CustomSvg} from "../../../utils/CustomSvg";
import BlockUser from "./BlockUser";
import Select from "react-select";
import EditUser from "./EditUser";
import {AiOutlineBell, AiOutlineStar} from "react-icons/ai";

function User() {
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(
        +localStorage.getItem("currentPageUsers") || 0
    ); //for numeration and saving currentPage after refresh
    const [modal, setModal] = useState({show: false, item: null});
    const [modalName, setModalName] = useState({show: false, item: null});
    const [changeStatusOptions, setChangeStatusOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const statusOptions = [
        {id: 0, name: "Все"},
        {id: 1, name: "Активные"},
        {id: 2, name: "Блокированные"}
    ]
    const [statusOpt, setStatusOpt] = useState(statusOptions[0]);
    const selectStatus = (selectedOpt) => {
        if (statusOpt !== selectedOpt) {
            setStatusOpt(selectedOpt);
            setCurrentPageToZero();
        }
    };
    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPageUsers", 0);
    }

    function paginate(data) {
        setCurrentPage(data.selected);
        localStorage.setItem("currentPageUsers", data.selected); //for saving currentPage after refresh
    }

    async function getItems() {
        const data = await PGET("/services/admin/api/user-pageList", true, {
            page: currentPage,
            size: 10,
            search: value,
            active: statusOpt.id
        });
        setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
        setItems(data.data);
    }

    async function handleActivate(userId) {
        const res = await PUT("/services/admin/api/user-activate/" + userId);

        if (res) {
            getItems();
        }
    }

    function downloadExcel() {
        const excelUrl = 'https://bo.akfacomfort.uz/services/admin/api/user-report/download-all';
        const fileName = 'user-report.xlsx'
        DOWNLOAD(excelUrl, fileName);
    }

    function pointsExcel(phone) {
        const excelUrl = 'https://bo.akfacomfort.uz/services/admin/api/history/points-download-all';
        const fileName = `${phone}-points.xlsx`
        excelParams(excelUrl, fileName, true, {
            phone: phone,
        });
    }
    function requestsExcel(phone) {
        const excelUrl = 'https://bo.akfacomfort.uz/services/admin/api/request/requests-download-all';
        const fileName = `${phone}-request.xlsx`
        excelParams(excelUrl, fileName, true, {
            phone: phone,
        });
    }
    useEffect(() => {
        getItems();
    }, [currentPage, value, statusOpt]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="br-5 card" style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Пользователи</span>
                </h3>
            </div>

            <div className="px-3 pb-4 pt-2">
                <div className="mb-4 d-flex justify-content-between">
                    <div
                        className="d-flex justify-content-end w-100 mr-2"
                        style={{width: "150px"}}
                    >
                        <Select
                            className="w-30 h-100"
                            value={statusOpt}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            options={statusOptions}
                            onChange={selectStatus}
                        />
                    </div>
                    <div className="position-relative">
                        <DebounceInput
                            minLength={3}
                            debounceTimeout={400}
                            value={value}
                            onChange={handleSearchInput}
                            type="text"
                            className="input-search search-icon br-5 "
                            size={24}
                            placeholder="Поиск"
                        />
                    </div>
                    <div className="d-flex justify-content-end" style={{width: "15%"}}>
                        <button
                            onClick={downloadExcel}
                            className="mr-0 box-shadow"
                            style={{
                                backgroundColor: "#fcfcfc",
                                height: 38,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid rgb(212, 211, 214)",
                                borderRadius: 5,
                                padding: "0 10px",
                            }}
                        >
                            <CustomSvg path='/icons/duotune/files/fil017.svg' className='svg-icon-3 mr-2'/>
                            Скачать все
                        </button>
                    </div>
                </div>
                <div style={{overflow: "auto", maxHeight: '75vh'}}>
                    <table className='table bg-white p-0 table-rounded table-striped border gy-4 gs-7'>
                        <thead className='position-sticky top-0 rounded'>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                            <th className="text-center text-white" style={{
                                width: "30px",
                                backgroundColor: '#E7372C',
                                fontWeight: "700",
                                borderTopLeftRadius: '8px'
                            }}>
                                №
                            </th>
                            <th className="text-start text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>ФИО
                            </th>
                            <th className="text-start text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Номер телефона
                            </th>
                            <th className="text-start text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Время регистрации
                            </th>
                            <th className="text-start text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Регион
                            </th>
                            <th className="text-center text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Комментария
                            </th>
                            <th className="text-center text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Язык
                            </th>
                            <th className="text-center text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Общий балл
                            </th>
                            <th className="text-center text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Статус
                            </th>
                            <th className="text-center text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Изменить
                            </th>
                            <th style={{
                                backgroundColor: '#E7372C',
                                fontWeight: "700",
                                borderTopRightRadius: '8px'
                            }}></th>
                        </tr>
                        </thead>

                        <tbody style={{overflowX: "auto"}}>
                        {items?.map((item, idx) => {
                            return (
                                <tr key={item.id} className='borderBottom'>
                                    <td className="text-center">
                                        {currentPage === 0
                                            ? idx + 1
                                            : currentPage * 10 + idx + 1}
                                    </td>
                                    <td className="text-start">{item.fullName}</td>
                                    <td className="text-start">{item.login}</td>
                                    <td className="text-start">{item.createdDate}</td>
                                    <td className="text-start">
                                        <div className="flex flex-col">
                                            <div className="flex">
                                                <p className="m-0 p-0 font-bold text-#eff2f5">Регион: </p>
                                                <p className="p-0 ml-2 mb-0">{item.regionName}</p>
                                            </div>
                                            <div className="flex">
                                                <p className="m-0 p-0 font-bold text-#eff2f5">Город: </p>
                                                <p className="p-0 ml-2 mb-0">{item.cityName}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-center">{item.blockedReason}</td>
                                    <td className="text-center">{item.language}</td>
                                    <td className="text-center">
                                        {item.earnedPoint}
                                    </td>

                                    <td
                                        className='text-center'
                                    >
                                        {item.activated ? (
                                            <span className='badge badge-success fs-7 fw-bold'>{item.statusName}</span>
                                        ) : (<span className='badge badge-danger fs-7 fw-bold'>{item.statusName}</span>
                                        )}
                                    </td>
                                    <td className="text-center">
                                        <div className="d-flex justify-content-center">
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedUser(item)
                                                    setModalName({show: true, item});
                                                }}
                                                className='btn btn-icon shadow-sm btn-active-color-primary btn-sm me-1'
                                            >
                                                <Edit color={"#206123"} size={14}/>
                                            </button>
                                        </div>
                                    </td>
                                    <td className="text-center flex">
                                        <div className="d-flex justify-content-center">
                                            {item.activated ? (
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setSelectedUser(item)
                                                        setModal({show: true, item});
                                                    }}
                                                    className='btn btn-icon shadow-sm btn-active-color-primary btn-sm me-1'
                                                >
                                                    <Lock color={"#d31818"} size={14}/>
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setSelectedUser(item)
                                                        setModal({show: true, item});
                                                    }}
                                                    className='btn btn-icon shadow-sm btn-active-color-primary btn-sm me-1'
                                                >
                                                    <Unlock color={"#189ED3"} size={14}/>
                                                </button>
                                            )}
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button
                                                onClick={() => requestsExcel(item.login)}
                                                className='btn btn-icon shadow-sm btn-active-color-primary btn-sm me-1'
                                            >
                                                <List color={"#206123"} size={14}/>
                                            </button>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button
                                                onClick={() => pointsExcel(item.login)}
                                                className='btn btn-icon shadow-sm btn-active-color-primary btn-sm me-1'
                                            >
                                                <Layers color={"#206123"} size={14}/>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                {pageCount > 1 && (
                    <ReactPaginate
                        forcePage={currentPage}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={paginate}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        pageClassName={"page-item box-shadow"}
                    />
                )}
            </div>
            {modal.item && (
                <BlockUser
                    modal={modal}
                    setModal={setModal}
                    getItems={getItems}
                    statusOptions={changeStatusOptions}
                />
            )}
            {modalName.item && (
                <EditUser
                    modal={modalName}
                    setModal={setModalName}
                    getItems={getItems}
                    statusOptions={changeStatusOptions}
                />
            )}
        </div>
    );
}

export default User;
