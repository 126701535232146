import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useHistory } from "react-router-dom";
import {
   ChevronRight,
   ChevronDown,
   User,
   Edit,
   Settings,
   LogOut,
   Users,
   HelpCircle,
   ShoppingCart,
   RefreshCcw,
   Activity,
   Codesandbox,
   Disc,
   MessageSquare,
   AtSign,
   AlertOctagon,
   Folder, Gift, List,
} from "react-feather";
import { Image } from "react-bootstrap";
import {CustomSvg} from "../../utils/CustomSvg";
import {PGET} from "../../api/adminApi";

// import menu from './SidebarMenu'

function SideBar({ children }) {
   const location = useLocation();
   let history = useHistory();
   const [showSideBar, setShowSideBar] = useState(false);
   const [showSettings, setShowSettings] = useState(false);
   const [msgCount, setMsgCount] = useState([]);

   const [menu, setMenu] = useState([
      {
         icon: <Activity size={20} />,
         name: "Главная",
         path: "/admin/main",
         show: false,
         children: [],
      },
      // {
      //    icon: <Users size={20} />,
      //    name: "Мастеры",
      //    path: "/admin/master",
      //    show: false,
      //    children: [],
      // },
      {
         icon: <Users size={20} />,
         name: "Пользователи",
         path: "/admin/user",
         show: false,
         children: [],
      },
      {
         icon: <HelpCircle size={20} />,
         name: "Запросы",
         path: "/admin/requestFromMasters",
         show: false,
         children: [],
      },
      {
         icon: <List size={20} />,
         name: "Логи",
         path: "/admin/log/",
         show: false,
         children: [
            {
               icon: <ChevronRight size={16} />,
               name: "Статусы по запросам мастеров",
               path: "/admin/log/status-master",
               show: false,
               children: [],
            },
            {
               icon: <ChevronRight size={16} />,
               name: "Баллы мастеров",
               path: "/admin/log/point-master",
               show: false,
               children: [],
            },
         ],
      },
      {
         icon: <AlertOctagon size={20} />,
         name: "Отчёты",
         path: "/admin/",
         show: false,
         children: [
            {
               icon: <ChevronRight size={16} />,
               name: "По баллам всех мастеров",
               path: "/admin/report/all-master",
               show: false,
               children: [],
            },
            {
               icon: <ChevronRight size={16} />,
               name: "По баллам спящих мастеров",
               path: "/admin/report/dead-master",
               show: false,
               children: [],
            },
            {
               icon: <ChevronRight size={16} />,
               name: "По товарам",
               path: "/admin/report/report-products",
               show: false,
               children: [],
            },
            // {
            //    icon: <ChevronRight size={16} />,
            //    name: "Переиспользованные коды",
            //    path: "/admin/report/reused-codes",
            //    show: false,
            //    children: [],
            // },
         ],
      },
      {
         icon: <Codesandbox size={20} />,
         name: "Коды",
         path: "/admin/code",
         show: false,
         children: [],
      },
      // {
      //    icon: <HelpCircle size={20} />,
      //    name: "Опросник",
      //    path: "/admin/poll",
      //    show: false,
      //    children: [],
      // },
      {
         icon: <AtSign size={20} />,
         name: "Рассылки",
         path: "/admin/bulk",
         show: false,
         children: [],
      },
      {
         icon:<ShoppingCart size={20} />,
         name: "Продукты",
         path: "/admin/products",
         show: false,
         children: [],
      },
      // {
      //    icon: <MessageSquare size={20} />,
      //    name: "Сообщения",
      //    path: "/admin/messages",
      //    show: false,
      //    count: true,
      //    children: [],
      // },
      {
         icon: <Gift size={20} />,
         name: "Призы",
         path: "/admin/prize",
         show: false,
         children: [],
      },
      {
         icon: <RefreshCcw className="mr-2" size={16} />,
         name: "Обмен на призы",
         path: "/admin/exchange-prize",
         show: false,
         children: [],
      },
      // {
      //          name: "Призы",
      //          icon: <ChevronRight size={16} />,
      //          path: "/admin/prize",
      //       },
      // {
      //    icon: <RefreshCcw size={20} />,
      //    name: "Обмен",
      //    path: "/admin/request-exchange",
      //    children: [],
      //    show: false,
      // },
      // {
      //    icon: <Folder size={20} />,
      //    name: "Справочник",
      //    path: "/admin/info",
      //    show: false,
      //    children: [
      //       {
      //          name: "Менеджеры",
      //          icon: <ChevronRight size={16} />,
      //          path: "/admin/manager",
      //          show: false,
      //          children: [],
      //       },
      //       {
      //          name: "Объекты",
      //          icon: <ChevronRight size={16} />,
      //          path: "/admin/object",
      //          show: false,
      //          children: [],
      //       },
      //       {
      //          name: "Дилеры",
      //          icon: <ChevronRight size={16} />,
      //          path: "/admin/dealer",
      //          show: false,
      //          children: [],
      //       },
      //       {
      //          name: "Базары",
      //          icon: <ChevronRight size={16} />,
      //          path: "/admin/bazar",
      //       },
      //       {
      //          name: "Торговые точки",
      //          icon: <ChevronRight size={16} />,
      //          path: "/admin/salepoint",
      //       },
      //       {
      //          name: "Территории",
      //          icon: <ChevronRight size={16} />,
      //          path: "/admin/territory",
      //       },
      //       {
      //          name: "Призы",
      //          icon: <ChevronRight size={16} />,
      //          path: "/admin/prize",
      //       },
      //    ],
      // },
      {
         icon: <Settings size={20} />,
         name: "Настройки",
         path: "/admin/settings",
         show: false,
         children: [],
      },
   ]);

   function signOut() {
      localStorage.removeItem("admin_tokenTime");
      localStorage.removeItem("admin_access_token");
      localStorage.removeItem("admin");
      history.push("/");
   }

   const showItem = (index, bool) => {
      let copy = [...menu];
      let copyItem = { ...menu[index] };
      copyItem.show = bool;
      copy[index] = copyItem;
      setMenu(copy);
   };

   useEffect(() => {
      for (let i = 0; i < menu.length; i++) {
         showItem(i, false);
         if (location.pathname.includes(menu[i].path)) {
            if (menu[i].breadrcumb !== undefined && menu[i].breadrcumb.length) {
               var copy = menu;
               copy[i]["breadrcumb"][2] = location.pathname;
               setMenu([...menu, copy]);
            }
            if (menu[i].children.length > 0) {
               showItem(i, true);
            }
         }
      }
   }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div>
         <div
            className="sidebar sidebar-fixed"
            style={showSideBar ? { marginLeft: "-256px" } : {}}
         >
            <Link className="sidebar-brand pl-5" to="/">
               <Image
                  src="/img/logo/akfa.png"
                  className="bg-light pl-3"
                  alt=""
                  height={30}
               />
            </Link>
            <div className="sidebar-nav" style={{ paddingTop: "40px" }}>
               {menu.map((item, index) => {
                  return (
                     <React.Fragment key={item.name}>
                        {item.children.length ? (
                           <div
                              className={
                                 "sidebar-nav-item mx-2 mb-1 cursor " +
                                 (item.show ? "sidebar-nav-active" : "")
                              }
                           >
                              <div
                                 onClick={() => showItem(index, !item.show)}
                                 className="mx-3 px-2 py-3"
                              >
                                 <div className="d-flex justify-content-between align-items-center ">
                                    <div className="d-flex align-items-center">
                                       {item.icon}
                                       <span style={{fontSize: "15px"}} className="fz13 ml-3">{item.name}</span>
                                    </div>
                                    {item.children.length && item.show ? (
                                       <ChevronDown
                                          color={"#fcfcfc"}
                                          size={16}
                                       />
                                    ) : (
                                       <ChevronRight size={16} />
                                    )}
                                 </div>
                              </div>
                           </div>
                        ) : (
                           <NavLink
                              key={index}
                              to={item.path}
                              activeClassName="sidebar-nav-active"
                              className={"sidebar-nav-item mx-2 mb-1 "}
                           >
                              <div className="mx-3 px-2 py-3">
                                 <div className="d-flex justify-content-between align-items-center ">
                                    <div className="d-flex align-items-center">
                                       {item.icon}
                                       <span style={{fontSize: "15px"}} className="fz13 ml-3">{item.name}</span>
                                       {item.count ? <span className={`fz13 ml-2 color-blue msg-count msg-hover ${location.pathname === item.path ? "msg-active" : ''}`}>{msgCount}</span> : null}
                                    </div>
                                 </div>
                              </div>
                           </NavLink>
                        )}
                        {item.children.length > 0 && item.show ? (
                           <div className="sidebar-nav-children mx-3">
                              {item.children.map((item2) => {
                                 return (
                                    <Link key={item2.name} to={item2.path}>
                                       <div
                                          className={
                                             "d-flex justify-content-between align-items-center sidebar-nav-item" +
                                             (location.pathname ===
                                                item2.path ||
                                             location.pathname.includes(
                                                item2.path
                                             )
                                                ? "sidebar-nav-active"
                                                : "")
                                          }
                                       >
                                          <div className={`d-flex align-items-center sidebar-nav-children-item 
                                          ${location.pathname === item2.path ? "children-active" : ''}`}>
                                             {item2.icon}{" "}
                                             <span className="fz13 ml-2">
                                                {item2.name}
                                             </span>
                                          </div>
                                       </div>
                                    </Link>
                                 );
                              })}
                           </div>
                        ) : (
                           ""
                        )}
                     </React.Fragment>
                  );
               })}
            </div>
         </div>
         <div className={"wrapper  " + (showSideBar ? "ml-0" : "")}>
            <div
               className="mb-2 d-flex w-100 justify-content-between align-items-center px-3 py-3 background-white"
            >
               <div className="d-flex align-items-center">
                  <div
                      onClick={() => setShowSideBar(!showSideBar)}
                      className='pl-3 shadow-sm py-2 pr-2 cursor-pointer mb-1'>
                     <CustomSvg
                         path="/icons/duotune/general/gen059.svg"
                         className={`svg-icon-dark ms-n1 svg-icon-sm-1`}
                     />
                  </div>
                  <div>
                     {menu.map((item, index) => {
                        return (
                           <React.Fragment key={item.path + index}>
                              {location.pathname.includes(item.path) &&
                                 item.path !== location.pathname &&
                                 item.children.length === 0 && (
                                    <div
                                       className="d-flex align-items-center justify-content-center"
                                       style={{ color: "#D4D3D6" }}
                                    >
                                       {item.icon}
                                       <span className="mr-2">::</span>
                                       <Link to={item.path}>
                                          <span style={{ color: "#0BADEE" }}>
                                             {item.name}
                                          </span>
                                       </Link>
                                       {item.breadrcumb !== undefined &&
                                       location.pathname.includes(item.path) &&
                                       item.breadrcumb.length
                                          ? item.breadrcumb.map((item2) => {
                                               return (
                                                  <React.Fragment
                                                     key={
                                                        item2.path + item2.name
                                                     }
                                                  >
                                                     {location.pathname.includes(
                                                        item2.path
                                                     ) ? (
                                                        <div>
                                                           <span className="mx-2">
                                                              /
                                                           </span>{" "}
                                                           {item2.name}
                                                        </div>
                                                     ) : (
                                                        ""
                                                     )}
                                                  </React.Fragment>
                                               );
                                            })
                                          : ""}
                                    </div>
                                 )}
                           </React.Fragment>
                        );
                     })}
                  </div>
               </div>

               <div className="position-relative">
                  <div
                     onClick={() => setShowSettings(!showSettings)}
                     className="mr-2 mb-1 p-1 cursor d-flex rounded-pill bg-dark-grey"
                  >
                     <User
                        src="img/delivery-truck.png"
                        size={30}
                        fill="#fff"
                        color="#fff"
                        alt=""
                     />
                  </div>
                  {showSettings && (
                     <div
                        className="position-absolute box-shadow border br-5 bg-white"
                        style={{ right: 0, top: "50px" }}
                     >
                        <div className="d-flex align-items-center border-bottom p-2">
                           <div>
                              <img
                                 src="/img/grey.png"
                                 className="br-50 mr-2"
                                 width={36}
                                 height={36}
                                 alt=""
                              />
                           </div>
                           <div>
                              <div>
                                 <b>Администратор</b>
                              </div>
                              <div>
                                 <p className="mb-0 fz12">Администратор</p>
                              </div>
                           </div>
                        </div>
                        <div className="border-bottom">
                           <div className="d-flex align-items-center ml-2 p-2">
                              <Edit
                                 size={16}
                                 color="#9D9BA3"
                                 className="mr-2"
                              />
                              <div>Мои данные</div>
                           </div>
                           <div className="d-flex align-items-center ml-2 p-2">
                              <Settings
                                 size={16}
                                 color="#9D9BA3"
                                 className="mr-2"
                              />
                              <div>Настройки</div>
                           </div>
                        </div>
                        <div>
                           <div
                              onClick={() => signOut()}
                              className="d-flex align-items-center cursor ml-2 p-2"
                           >
                              <LogOut
                                 size={16}
                                 color="#9D9BA3"
                                 className="mr-2"
                              />
                              <div>Выйти</div>
                           </div>
                        </div>
                     </div>
                  )}
               </div>
            </div>
            <div>{children}</div>
         </div>
      </div>
   );
}

export default SideBar;

