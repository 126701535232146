import {ChartContainer} from "../../../style/style";
import {Line} from "react-chartjs-2";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {PGET} from "../../../api/adminApi";
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button className="datepicker-daily" onClick={onClick} ref={ref}>
        {value}
    </button>
));

export const LineChartMonthly = props => {
    const [items, setItems] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [labels, setLabels] = useState([]);
    const [datasets, setDatasets] = useState([]);
    async function getItems(year, month) {
        const data = await PGET(
            `${props.url}${year}/${month}`,
            true,
            {}
        );
        setItems(data.data);
        const daysInMonth = new Date(year, month, 0).getDate();
        const newLabels = Array.from({ length: daysInMonth }, (_, i) => i + 1);
        setLabels(newLabels);

        const newData = new Array(daysInMonth).fill(0);
        data.data.forEach((item) => {
            if (item.dayNumber && item.dayNumber <= daysInMonth) {
                if(props.request){
                    newData[item.dayNumber - 1] = item.requestsCount;
                }else {
                    newData[item.dayNumber - 1] = item.registrationCount;

                }
            }
        });

        const newDataset = [
            {
                data: newData,
                borderColor: '#1ce1ac',
                borderWidth: 1,
                fill: false,
            },
        ];
        setDatasets(newDataset);
    }

    const { options, height, width, id } = props;
    const data = {
        labels,
        datasets,
    };
    const maxValue = datasets[0]?.data ? Math.max(...datasets[0].data) * 2 : 0;
    const updatedOptions = {
        ...options,
        scales: {
            y: {
                min: 0,
                max: maxValue,
                ticks: {
                    precision: 0,
                },
            },
        },
    };
    useEffect(() => {
        getItems(selectedDate.getFullYear(), selectedDate.getMonth() + 1);
    }, [selectedDate]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    return (
        <div className="">
            <div className="d-flex flex-column">
                <label htmlFor="datePicker">Выберите период:</label>
                <DatePicker
                    id="datePicker"
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                    customInput={<CustomInput />}
                />
            </div>
            <ChartContainer className="parentContainer">
                <Line
                    id={id && id}
                    width={width}
                    data={data}
                    height={height}
                    options={updatedOptions}
                />
            </ChartContainer>
        </div>
    );
};

LineChartMonthly.defaultProps = {
    height: 300,
    width: null,
    options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
        },
        legend: {
            display: false,
            labels: {
                display: false,
            },
        },
    },
};

LineChartMonthly.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    layout: PropTypes.object,
    width: PropTypes.number,
    options: PropTypes.object,
    id: PropTypes.string,
};