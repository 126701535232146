import React, { useEffect, useRef, useState } from 'react'
import { ShoppingBag, Truck } from 'react-feather';
import io from 'socket.io-client'
import GoogleMapReact from 'google-map-react';

function Couriers() {
	const socketRef = useRef(null)

	const [data, setData] = useState([])
	const [bool, setBool] = useState(false)

	useEffect(() => {
		//socketRef.current = io.connect("http://192.168.68.115:9093/operator-orders");
		const baseURL = window.location.hostname === 'localhost' ?  'https://colibri24.uz/couriers-location' : 'https://' + window.location.hostname + "/couriers-location";

		socketRef.current = io.connect(baseURL);

		socketRef.current.on('couriers-location', (data) => {
			console.log('orders', data);
			// обновляем массив пользователей
			if (data.length > 0) {
				setData(data)
				setBool(true)
			}
		})

		return () => {
			//socketRef.current.close()
			socketRef.current.disconnect()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	function Marker({ item }) {
		console.log(item);
		return <div>
			<div className="pin-text">
				{ item.courierLogin }
			</div>
			<div className="pin-truck">
				<Truck />
			</div>
			<div className="pulse"></div>
		</div>
	}

	return (
		<div className="background-white my-2 mx-3 box-shadow br-5">
			<div className="border-bottom px-3 py-3 my-2 d-flex justify-content-between">
				<div className='d-flex align-items-center'>
					<ShoppingBag className='mr-2' color='#5C3EBA' /> <span className="fz20">Курьеры</span>
				</div>
			</div>

			{bool &&
				<div className='mb-5' style={{ height: '500px', width: '100%' }}>
					<GoogleMapReact bootstrapURLKeys={{ key: "AIzaSyDRnmjaTmZQmjt4sxKaNVW_DqOfp993Afc" }}
						defaultZoom={12}
						center={{ lat: 41.311081, lng: 69.240562 }}>
						{data.map((item, index) => (
							<Marker
								key={index}
								lat={item.gpsPointY}
								lng={item.gpsPointX}
								item={item}
							/>
						))
						}
					</GoogleMapReact>
				</div>
			}
		</div>
	)
}


export default Couriers