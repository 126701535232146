import React from "react";
import { Download, List} from "react-feather";
import {DOWNLOAD} from "../../../api/adminApi";
function ExportData() {
    function weekAllAgents() {
        const excelUrl = 'https://bo.airfelsales.uz/services/admin/api/contragent-report/download-weekly-all';
        const fileName = 'report-week-all.xlsx'
        DOWNLOAD(excelUrl, fileName);
    }
    function weekOwnerAgents() {
        const excelUrl = 'https://bo.airfelsales.uz/services/admin/api/contragent-report/download-weekly-owner';
        const fileName = 'report-week-owner.xlsx'
        DOWNLOAD(excelUrl, fileName);
    }
    function monthAllAgents() {
        const excelUrl = 'https://bo.airfelsales.uz/services/admin/api/contragent-report/download-monthly-all';
        const fileName = 'report-monthly-all.xlsx'
        DOWNLOAD(excelUrl, fileName);
    }
    function monthOwnerAgents() {
        const excelUrl = 'https://bo.airfelsales.uz/services/admin/api/contragent-report/download-monthly-owner';
        const fileName = 'report-monthly-owner.xlsx'
        DOWNLOAD(excelUrl, fileName);
    }
    return (
        <div style={{ background: "#eef1ff", marginTop: "-0.9rem" }}>
            <div className="fz18 border-bottom pl-3 py-3 my-2 d-flex align-items-center">
                <List className="mr-2" color="#5C3EBA" /> <span>Экспорт отчетов в Excel</span>
            </div>
            <div className="px-3 pb-4 pt-2">
                <div style={{ overflowX: "auto" }}>
                    <table className="table background-white box-shadow">
                        <thead>
                        <tr className="backgroung-grey">
                            <th style={{ width: "30px" }} className="text-center">
                                №
                            </th>
                            <th className="text-start">Наименование отчета</th>
                            <th className="text-start">Скачать</th>
                        </tr>
                        </thead>

                        <tbody style={{ overflowX: "auto" }}>
                                <tr>
                                    <td className="text-center">
                                       1
                                    </td>
                                    <td className="text-start">Еженедельные - все контрагенты</td>
                                    <td>
                                        <div className="d-flex">
                                            <button
                                                onClick={weekAllAgents}
                                                className="btn btn-table mr-0 box-shadow"
                                                style={{ backgroundColor: "#F4F4F5" }}
                                            >
                                                <Download color={"#E63950"} size={16} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center">
                                        2
                                    </td>
                                    <td className="text-start">Еженедельные - владельцы контрагенты</td>
                                    <td>
                                        <div className="d-flex">
                                            <button
                                                onClick={weekOwnerAgents}
                                                className="btn btn-table mr-0 box-shadow"
                                                style={{ backgroundColor: "#F4F4F5" }}
                                            >
                                                <Download color={"#E63950"} size={16} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center">
                                        3
                                    </td>
                                    <td className="text-start">Ежемесячные - все контрагенты</td>
                                    <td>
                                        <div className="d-flex">
                                            <button
                                                onClick={monthAllAgents}
                                                className="btn btn-table mr-0 box-shadow"
                                                style={{ backgroundColor: "#F4F4F5" }}
                                            >
                                                <Download color={"#E63950"} size={16} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center">
                                        4
                                    </td>
                                    <td className="text-start">Ежемесячные - владельцы контрагенты</td>
                                    <td>
                                        <div className="d-flex">
                                            <button
                                                onClick={monthOwnerAgents}
                                                className="btn btn-table mr-0 box-shadow"
                                                style={{ backgroundColor: "#F4F4F5" }}
                                            >
                                                <Download color={"#E63950"} size={16} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ExportData;
