import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {Save, XCircle} from "react-feather";
import {useForm} from "react-hook-form";
import {PGET, PUT} from "../../../api/adminApi";
import Select from "react-select";

export default function CodeAssign({
                                       modal: {show, masterId},
                                       setModal,
                                       getItems,
                                   }) {
    const {
        register,
        handleSubmit,
        formState: {errors},
        clearErrors,
    } = useForm();
    const [objectOptions, setObjectOptions] = useState([]);
    const [objectOpt, setObjectOpt] = useState(null);
    const [managerOptions, setManagerOptions] = useState([]);
    const [managerOpt, setManagerOpt] = useState(null);

    function selectObject(selectedOpt) {
        if (selectedOpt !== objectOpt) {
            setObjectOpt(selectedOpt);
        }
    }

    function selectManager(selectedOpt) {
        if (selectedOpt !== managerOpt) {
            setManagerOpt(selectedOpt);
        }
    }

    function onHide() {
        setModal({show: false, masterId: null});
        clearErrors();
        setManagerOpt(null);
        setObjectOpt(null);
    }

    async function assign({manager, object}) {
        const res = await PUT("/services/admin/api/codes/assign-object", {
            id: masterId,
            objectId: object.id,
            managerId: manager.id,
        });
        if (res && res.success === true) {
            getItems();
            onHide();
        }
    }

    useEffect(() => {
        async function managerHelper() {
            const res = await PGET("/services/admin/api/manager-helper");

            if (res) {
                setManagerOptions(res.data);
            }
        }

        async function objectHelper() {
            const res = await PGET("/services/admin/api/object-helper");
            if (res) {
                setObjectOptions(res.data);
            }
        }

        managerHelper();
        objectHelper();
    }, []);

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Привязать
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(assign)} autoComplete="off">
                    <div className="d-flex">
                        <div className="w-100">
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="mr-md-3 w-25">
                                        <label className="mb-0">Выберите Объект</label>
                                    </div>
                                    <Select
                                        {...register("object", {
                                            required: true,
                                            value: objectOpt,
                                        })}
                                        value={objectOpt}
                                        placeholder="Выберите Объект"
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        options={objectOptions}
                                        onChange={selectObject}
                                        className="w-75"
                                    />
                                </div>
                                {errors.object && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>

                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="mr-md-3 w-25">
                                        <label className="mb-0">Выберите Менеджер</label>
                                    </div>
                                    <Select
                                        {...register("manager", {
                                            required: true,
                                            value: managerOpt,
                                        })}
                                        value={managerOpt}
                                        placeholder="Выберите Менеджер"
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        options={managerOptions}
                                        onChange={selectManager}
                                        className="w-75"
                                    />
                                </div>
                                {errors.manager && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end gap-4">
                        <button
                            type="button"
                            onClick={onHide}
                            className="btn btn-outline-purple d-flex align-items-center"
                        >
                            <XCircle size={14} className="mr-2"/>
                            Отмена
                        </button>

                        <button
                            type="submit"
                            className="btn btn-purple d-flex align-items-center"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить{" "}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}
