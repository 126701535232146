import React, {useEffect, useState} from "react";
import {useHistory, Link, useParams} from "react-router-dom";
import {XCircle, Save} from "react-feather";
import Select from "react-select";
import {GET, POST, PUT} from "../../../api/adminApi";
import {toast} from "react-toastify";
import {Button, Upload, Image} from "antd";
import {FaUpload} from "react-icons/fa6";
import {get} from "lodash";

export default function BulkCreate() {
    const {id} = useParams();
    const history = useHistory();
    const [selectedOption, setSelectedOption] = useState(null);
    const [msg, setMsg] = useState('');
    const [title, setTitle] = useState('');
    const [region, setRegion] = useState({id: 0, name: "Все"});
    const [regions, setRegions] = useState(region[0]);
    const [data, setData] = useState({})
    const options = [
        {
            id: 1,
            label: "Все"
        },
        {
            id: 2,
            label: "Спящие"
        },
        {
            id: 3,
            label: "Имеющим запросов на приз"
        }
    ]

    async function getRegions() {
        const response = await GET("/services/admin/api/region-helper/1");
        if (response?.length > 0) {
            setRegions([region, ...response]);
        }
    }

    const handleChange = (option) => {
        setSelectedOption(option)
    }
    const handleChangeRegion = (option) => {
        setRegion(option)
    }

    function handleMessageChange(e) {
        setMsg(e.target.value);
    }

    function handleTitleChange(e) {
        setTitle(e.target.value);
    }

    function onSubmit(e) {
        e.preventDefault();

        async function create() {
            if (id) {
                const response = await PUT("/services/admin/api/bulk", {
                    id: parseInt(id),
                    messageText: msg,
                    sendTo: selectedOption.id,
                    titleText: title,
                    regionId: region.id,
                    imageUrl: data.imageUrl
                });
                if (response?.success) {
                    history.push("/admin/bulk");
                }
            } else {
                const response = await POST("/services/admin/api/bulk", {
                    titleText: title,
                    messageText: msg,
                    sendTo: selectedOption.id,
                    regionId: region.id,
                    imageUrl: data.imageUrl
                });
                if (response?.success) {
                    history.push("/admin/bulk");
                    toast.success("Успешно добавлен");
                }
            }
        }

        create()
    }

    async function getData() {
        const regionResponse = await GET("/services/admin/api/region-helper/1");
        const response = await GET("/services/admin/api/bulk/" + id);

        if (response && regionResponse) {
            const {
                titleText,
                messageText,
                sendTo,
                regionId
            } = response;
            setTitle(titleText)
            setMsg(messageText)
            setData(response)
            if (regionId === 0) {
                setRegion({id: 0, name: "Все"})
            } else {
                setRegion(
                    regionResponse.find((item) => item?.id === regionId)
                );
            }

            const selectedOptionFromResponse = options.find(option => option.id === sendTo);
            setSelectedOption(selectedOptionFromResponse);
        }
    }

    console.log(data)
    const handleFileChange = async (info) => {
        if (info.file.status === 'done') {
            setData(data => ({...data, imageUrl: info.file.response.url}));
            setData({...data, imageUrl: info.file.response.url})
        }
    };

    const uploadProps = {
        action: 'https://bo.akfacomfort.uz/services/admin/api/bulk-image-upload',
        headers: {
            authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        onChange: handleFileChange,
        multiple: false,
        name: 'image',
    };

    useEffect(() => {
        getRegions()
    }, []);

    useEffect(() => {
        if (id) {
            getRegions()
            getData();
        }
    }, []);

    return (
        <div className="mb-1 mx-3 ">
            <h3 className="mb-5 mt-5">
                Создать рассылки
            </h3>
            <form onSubmit={onSubmit} autoComplete="off">
                <div className="admin-card p-4 pt-12 d-flex flex-column justify-content-between"
                     style={{height: '70vh'}}>
                    <div className="mb-1 d-flex">
                        <div className="">
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Тема
                                </label>
                                <div>
                                    <input
                                        name="titleText"
                                        type="text"
                                        value={title}
                                        onChange={handleTitleChange}
                                        className="input-control"
                                        style={{width: "300px"}}
                                    />
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Сообщение
                                </label>
                                <div>
                           <textarea
                               value={msg}
                               name="messageText"
                               type="text"
                               className="input-control"
                               onChange={handleMessageChange}
                               style={{width: "300px"}}
                           />
                                </div>
                            </div>
                            <div
                                className="d-flex align-items-center mb-2 gap-2"
                            >
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Группа
                                </label>
                                <Select
                                    name="sendTo"
                                    className="w-300px"
                                    options={options}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.id}
                                    value={selectedOption}
                                    onChange={handleChange}
                                />
                            </div>
                            <div
                                className="d-flex align-items-center mb-2 gap-2"
                            >
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Регион
                                </label>
                                <Select
                                    name="regionId"
                                    className="w-300px"
                                    options={regions}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    value={region}
                                    onChange={handleChangeRegion}
                                />
                            </div>
                            <div
                                className="d-flex align-items-center mb-2 gap-2"
                            >
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Фото
                                </label>
                                <div className="flex flex-col">
                                    <Upload {...uploadProps}>
                                        <Button icon={<FaUpload/>}>Click to Upload</Button>
                                    </Upload>
                                    <Image
                                        className="mt-4"
                                        width={100}
                                        src={'https://bo.akfacomfort.uz' + get(data, 'imageUrl')}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end">
                        <Link to="/admin/bulk" className="mr-3">
                            <button className="btn text-white btn-bg-primary d-flex align-items-center">
                                <XCircle size={14} className="mr-2"/>
                                Отмена
                            </button>
                        </Link>
                        <button
                            className="btn text-white btn-bg-success d-flex align-items-center"
                            type="submit"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

