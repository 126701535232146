import React, {useEffect, useState} from "react";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import {HelpCircle, CheckSquare, Search} from "react-feather";
import Select from "react-select";

import {PGET} from "../../../api/adminApi";
import ChangeStatus from "./ChangeStatus";

function RequestToExchange() {
    const [items, setItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(
        +localStorage.getItem("currentPageRequestToExchange") || 0
    ); //for numeration and saving currentPage after refresh
    const [selectedUser, setSelectedUser] = useState(null);
    const [statusOptions, setStatusOptions] = useState([
        {id: 0, name: "Все"},
    ]);
    const [statusOpt, setStatusOpt] = useState(statusOptions[0]);
    const [modal, setModal] = useState({show: false, user: null});
    const [changeStatusOptions, setChangeStatusOptions] = useState([]);

    const selectStatus = (selectedOpt) => {
        if (statusOpt !== selectedOpt) {
            setStatusOpt(selectedOpt);
            setCurrentPageToZero();
        }
    };

    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPageRequestToExchange", 0);
    }

    function paginate(data) {
        setCurrentPage(data.selected); //for numeration
        localStorage.setItem("currentPageRequestToExchange", data.selected); //for saving currentPage after refresh
    }

    async function getItems() {
        const data = await PGET(
            "/services/admin/api/master-change-point-pageList",
            true,
            {
                status: statusOpt.id,
                page: currentPage,
                size: 15,
                search: value,
                region: 0,
            }
        );
        setPageCount(Math.ceil(data?.headers["x-total-count"] / 15));
        setItems(data?.data);
    }

    useEffect(() => {
        getItems();
    }, [statusOpt, currentPage, value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function getStatusHelper() {
            const data = await PGET(
                "/services/admin/api/request/status-helper"
            );
            if (data) {
                setStatusOptions([statusOptions[0], ...data.data]);
            }
        }

        getStatusHelper();

        async function getChangeStatusOptions() {
            const res = await PGET(
                "/services/admin/api/request/status-helper-point-change"
            );
            if (res) {
                setChangeStatusOptions(res.data);
            }
        }

        getChangeStatusOptions();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className="br-5 card"
            style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh', padding: '0 10px'}}
        >
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Запросы на обмен</span>
                </h3>
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', padding: '0 20px', gap: 20}}

            >
                <div
                    className="d-flex justify-content-end w-50"
                    style={{width: "150px"}}
                >
                    <Select
                        className="w-30"
                        value={statusOpt}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={statusOptions}
                        onChange={selectStatus}
                    />
                </div>
                <div className="position-relative">
                    <DebounceInput
                        minLength={3}
                        debounceTimeout={400}
                        value={value}
                        onChange={handleSearchInput}
                        type="text"
                        className="input-search search-icon br-5 w-100"
                        size={24}
                        placeholder="Поиск"
                    />
                </div>
            </div>
            <div style={{ overflow: "auto", maxHeight: '75vh' }} className='mt-5'>
                <table className='table bg-white table-rounded p-0 border gy-4 gs-7'>
                    <thead className='position-sticky top-0 rounded'>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                        <th style={{backgroundColor: '#E7372C', fontWeight: "700", borderTopLeftRadius: '8px', width: "30px"}} className="text-white text-center">№</th>
                        <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>ФИО Мастера</th>
                        <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Регион</th>
                        <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Номер телефона</th>
                        <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Дата получения</th>
                        <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Балл на обмен</th>
                        <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Причина</th>
                        <th className="text-center text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Статус</th>
                        <th className="text-center text-white" style={{backgroundColor: '#E7372C', fontWeight: "700", borderTopRightRadius: '8px'}}></th>
                    </tr>
                    </thead>

                    <tbody style={{overflowX: "auto"}}>
                    {items?.map((user, idx) => {
                        const {
                            id,
                            masterFullname,
                            masterRegion,
                            phone,
                            receivedTime,
                            requestedPoint,
                            reason,
                            statusId,
                            statusName,
                        } = user;
                        return (
                            <tr
                                onClick={() => {
                                    setSelectedUser(user);
                                }}
                                // role="button"
                                className="borderBottom"
                                style={{
                                    background: `${
                                        id === selectedUser?.id
                                            ? "white"
                                            : "white"
                                    }`,
                                }}
                                key={id}
                            >
                                <td className="text-center pointer">
                                    {currentPage === 0
                                        ? idx + 1
                                        : // : currentPage * 10 + idx + 1} // for 10 items per page
                                          // for 15 items per page
                                        currentPage * 10 +
                                        idx +
                                        1 +
                                        5 * currentPage}
                                </td>
                                <td className="text-start">{masterFullname}</td>
                                <td className="text-start">{masterRegion}</td>
                                <td className="text-start">{phone}</td>
                                <td className="text-start">
                                    {new Date(receivedTime).toLocaleString()}
                                </td>
                                <td className="text-center">
                                    {requestedPoint}
                                </td>
                                <td className="text-start">{reason}</td>
                                <td className={`text-center w-10`}>
                                    <b
                                        style={{
                                            whiteSpace: "nowrap",
                                        }}
                                        className={`p-2 badge ${
                                            statusId === 1
                                                ? "badge-warning"
                                                : statusId === 2
                                                    ? "badge-success"
                                                    : statusId === 3
                                                        ? "badge-danger"
                                                        : "badge-primary"
                                        }`}
                                    >
                                        {statusName}
                                    </b>
                                </td>
                                <td className="text-center">
                                    {statusId !== 2 && (
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setModal({show: true, user});
                                            }}
                                            className="btn btn-table shadow-sm br-5"
                                            style={{
                                                backgroundColor: "#fff",
                                            }}
                                        >
                                            <CheckSquare
                                                color={"#5C3EBA"}
                                                size={16}
                                            />
                                        </button>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>

            </div>

            {pageCount > 1 && (
                <ReactPaginate
                    forcePage={currentPage}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={paginate}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item box-shadow br-5"}
                />
            )}

            {modal.user && (
                <ChangeStatus
                    modal={modal}
                    setModal={setModal}
                    getItems={getItems}
                    statusOptions={changeStatusOptions}
                />
            )}
        </div>
    );
}

export default RequestToExchange;
