import React from 'react'
import { Circle } from 'react-feather';

function Notifications() {
  return (
    <div>
      <div className="mb-3 fz28">Уведомления</div>
      <div className="row">
        <div className="col-md-12">
          <div className="profile-card p-3 mb-3">
            <div className="d-flex mb-2">
              <div className="red-circle d-flex align-items-center mr-2">
                <Circle size={16} />
              </div>
              <div className="color-grey">03 Апр 21, 10:51 </div>
            </div>
            <div className="card-content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida sem dictum ac et, commodo lorem pulvinar. Quis pellentesque posuere in sit a amet interdum tristique nunc.
            </div>
          </div>
          <div className="profile-card p-3 mb-3">
            <div className="d-flex mb-2">
              <div className="red-circle d-flex align-items-center mr-2">
                <Circle size={16} />
              </div>
              <div className="color-grey">03 Апр 21, 10:51 </div>
            </div>
            <div className="card-content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida sem dictum ac et, commodo lorem pulvinar. Quis pellentesque posuere in sit a amet interdum tristique nunc.
            </div>
          </div>
          <div className="profile-card p-3 mb-3">
            <div className="d-flex mb-2">
              <div className="red-circle d-flex align-items-center mr-2">
                <Circle size={16} />
              </div>
              <div className="color-grey">03 Апр 21, 10:51 </div>
            </div>
            <div className="card-content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida sem dictum ac et, commodo lorem pulvinar. Quis pellentesque posuere in sit a amet interdum tristique nunc.
            </div>
          </div>
          <div className="profile-card p-3 mb-3">
            <div className="d-flex mb-2">
              <div className="grey-circle d-flex align-items-center mr-2">
                <Circle size={16} />
              </div>
              <div className="color-grey">03 Апр 21, 10:51 </div>
            </div>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida sem dictum ac et, commodo lorem pulvinar. Quis pellentesque posuere in sit a amet interdum tristique nunc.
            </div>
          </div>
          <div className="profile-card p-3 mb-3">
            <div className="d-flex mb-2">
              <div className="grey-circle d-flex align-items-center mr-2">
                <Circle size={16} />
              </div>
              <div className="color-grey">03 Апр 21, 10:51 </div>
            </div>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida sem dictum ac et, commodo lorem pulvinar. Quis pellentesque posuere in sit a amet interdum tristique nunc.
            </div>
          </div>
          <div className="profile-card p-3 mb-3">
            <div className="d-flex mb-2">
              <div className="grey-circle d-flex align-items-center mr-2">
                <Circle size={16} />
              </div>
              <div className="color-grey">03 Апр 21, 10:51 </div>
            </div>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida sem dictum ac et, commodo lorem pulvinar. Quis pellentesque posuere in sit a amet interdum tristique nunc.
            </div>
          </div> 
        </div>
      </div>
    </div>
  )
}

export default Notifications
