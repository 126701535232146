import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import ReactPaginate from "react-paginate";
import {PGET, DELETE, GET, excelParams} from "../../../api/adminApi";
import {CustomSvg} from "../../../utils/CustomSvg";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment/moment";

export default function ProductsReport() {
   const [items, setItems] = useState([]);
   const [pageCount, setPageCount] = useState(0);
   const [value, setValue] = useState("");
   const [currentPage, setCurrentPage] = useState(
       +localStorage.getItem("currentPagePrizes") || 0
   ); //for numeration and saving currentPage after refresh
   const [regions, setRegions] = useState([{ id: 0, name: "Все регионы" }]);
   const [region, setRegion] = useState(regions[0]);
   const [productFilter, setProductFilter] = useState([{id: 0, name: "Все"}]);
   const [filteredProduct, setFilteredProduct] = useState(productFilter[0]);

   // States for the second filter
   const [productFilterHelper, setProductFilterHelper] = useState([{id: 0, name: "Все"}]);
   const [filteredProductHelper, setFilteredProductHelper] = useState(productFilterHelper[0]);

   const [codesFilter, setCodesFilter] = useState([{id: '00', name: "Все"}]);
   const [codeFilter, setCodeFilter] = useState(codesFilter[0]);

   // States for the third filter
   const [segmentFilter, setSegmentFilter] = useState([{id: 0, name: "Все"}]);
   const [filteredSegment, setFilteredSegment] = useState(segmentFilter[0]);
   const [dateRange, setDateRange] = useState([
      new Date('2024-01-01'),
      new Date('2025-01-01'),
   ]);
   const [startDate, endDate] = dateRange;
   async function getRegions() {
      const response = await GET("/services/admin/api/region-helper/1");

      if (response.length > 0) {
         setRegions([regions[0], ...response]);
      }
   }
   async function getProductFilter() {
      const response = await GET("/services/admin/api/product-type-helper");
      if (response.length > 0) {
         setProductFilter([productFilter[0], ...response]);
      }
   }
   async function getCodesFilter() {
      const response = await GET("/services/admin/api/codes-helper");
      if (response.length > 0) {
         setCodesFilter([codesFilter[0], ...response]);
      }
   }
// Fetch data for the second filter
   async function getProductFilterHelper() {
      const response = await GET(`/services/admin/api/product-segment-helper`);
      if (response.length > 0) {
         setProductFilterHelper([productFilterHelper[0], ...response]);
      }
   }

// Fetch data for the third filter
   async function getSegmentFilter(type, segment) {
      if (type !== undefined && type !== null && segment !== undefined && segment !== null) {
         const response = await GET(`/services/admin/api/product-helper/${type}/${segment}`);
         if (response.length > 0) {
            setSegmentFilter([segmentFilter[0], ...response]);
         }
      }
   }
   const handleSearchInput = (e) => {
      setValue(e.target.value);
      setCurrentPageToZero();
   };
   const selectRegion = (selectedOpt) => {
      if (region !== selectedOpt) {
         setRegion(selectedOpt);
         setCurrentPageToZero();
      }
   };
   const selectCode = (selectedOpt) => {
      if (codeFilter !== selectedOpt) {
         setCodeFilter(selectedOpt);
         setCurrentPageToZero();
      }
   };
   const selectProductFilter = (selectedOpt) => {
      if (filteredProduct !== selectedOpt) {
         setFilteredProduct(selectedOpt);
         setCurrentPageToZero();
         getProductFilterHelper(selectedOpt.id);
      }
   };

   // Handle changes in the second filter
   const selectProductFilterHelper = (selectedOpt) => {
      if (filteredProductHelper !== selectedOpt) {
         setFilteredProductHelper(selectedOpt);
         setCurrentPageToZero();
         getSegmentFilter(filteredProduct.id, selectedOpt.id);
      }
   };
   const selectSegmentFilter = (selectedOpt) => {
      if (filteredSegment !== selectedOpt) {
         setFilteredSegment(selectedOpt);
         setCurrentPageToZero();
      }
   };
   function setCurrentPageToZero() {
      setCurrentPage(0);
      localStorage.setItem("currentPagePrizes", 0);
   }

   function paginate(data) {
      setCurrentPage(data.selected);
      localStorage.setItem("currentPagePrizes", data.selected); //for saving currentPage after refresh
   }

   async function getItems() {
      if (startDate && endDate) {

         const data = await PGET("/services/admin/api/master-products-sales/", true, {
            page: currentPage,
            size: 10,
            search: value,
            region: region.id,
            productType: filteredProduct.id,
            productSegment: filteredProductHelper.id,
            productId: filteredSegment.id,
            dateFrom: moment(startDate).format("YYYY-MM-DD"),
            dateTo: moment(endDate).add(1, 'day').format("YYYY-MM-DD"),
            sections: codeFilter.id
         });
         setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
         setItems(data.data);
      }

   }

   async function deleteItem(id) {
      await DELETE("/services/admin/api/product/" + id);
      getItems();
   }

   function weekOwnerAgents() {
      const excelUrl = 'https://bo.akfacomfort.uz/services/admin/api/products-report/download-all';
      const fileName = 'reportall.xlsx'
      excelParams(excelUrl, fileName, true, {
         region: region.id,
         size: 10,
         search: value,
         productType: filteredProduct.id,
         productSegment: filteredProductHelper.id,
         sections: codeFilter.id,
         dateFrom: moment(startDate).format("YYYY-MM-DD"),
         dateTo: moment(endDate).add(1, 'day').format("YYYY-MM-DD"),
      });
   }

   useEffect(() => {
      getItems();
   }, [region, currentPage, value, filteredProduct, filteredProductHelper, filteredSegment, dateRange, codeFilter]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      getRegions()
      getProductFilter()
      getProductFilterHelper()
      getSegmentFilter()
      getCodesFilter()
   }, [])

   return (
       <div
           className="br-5 card"
           style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}
       >
          <div className='card-header border-0 pt-5'>
             <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Продукты</span>
             </h3>
          </div>

          <div className="px-3 pb-4 pt-2">
             <div className="mb-4 d-flex justify-between">
                <div className="flex">
                   <div style={{width: "120px"}}>
                      <Select
                          value={region}
                          placeholder="Выберите регион"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          options={regions}
                          onChange={selectRegion}
                      />
                   </div>
                   <div style={{width: "120px", marginLeft: 10}}>
                      <Select
                          value={filteredProduct}
                          placeholder="Выберите"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          options={productFilter}
                          onChange={selectProductFilter}
                      />
                   </div>
                   <div style={{width: "120px", marginLeft: 10}}>
                      <Select
                          value={filteredProductHelper}
                          placeholder="Выберите"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          options={productFilterHelper}
                          onChange={selectProductFilterHelper}
                      />
                   </div>
                   <div style={{width: "120px", marginLeft: 10}}>
                      <Select
                          value={filteredSegment}
                          placeholder="Выберите"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          options={segmentFilter}
                          onChange={selectSegmentFilter}
                      />
                   </div>
                   <div style={{width: "120px", marginLeft: 10}}>
                      <Select
                          value={codeFilter}
                          placeholder="Выберите"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          options={codesFilter}
                          onChange={selectCode}
                      />
                   </div>
                </div>

                <div className="flex">
                   <div style={{width: 200,  marginLeft: 10}}>
                      <DatePicker
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(update) => {
                             setDateRange(update);
                          }}
                          isClearable={true}
                          className="datepicker-custom-input"
                      />
                   </div>
                   <div className="position-relative ml-2">
                      <DebounceInput
                          minLength={3}
                          debounceTimeout={400}
                          value={value}
                          onChange={handleSearchInput}
                          type="text"
                          className="input-search search-icon br-5 "
                          size={24}
                          placeholder="Поиск"
                      />
                   </div>
                   <div className="d-flex ml-2">
                      <button
                          onClick={weekOwnerAgents}
                          className="mr-0 box-shadow"
                          style={{
                             backgroundColor: "#fcfcfc",
                             height: 38,
                             display: "flex",
                             justifyContent: "center",
                             alignItems: "center",
                             border: "1px solid rgb(212, 211, 214)",
                             borderRadius: 5,
                             padding: "0 10px"
                          }}
                      >
                         <CustomSvg path='/icons/duotune/files/fil017.svg' className='svg-icon-3 mr-2'/>
                         Скачать все
                      </button>
                   </div>
                </div>
             </div>
             <div style={{overflow: "auto", maxHeight: '75vh'}}>
                <table className='table bg-white table-rounded p-0 border gy-3 gs-7'>
                   <thead className='position-sticky top-0 rounded'>
                   <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                      <th style={{
                         backgroundColor: '#E7372C',
                         fontWeight: "700",
                         borderTopLeftRadius: '8px',
                         width: "30px"
                      }} className="text-white text-center">№
                      </th>
                      <th className="text-start text-white"
                          style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Наименование
                      </th>
                      <th className="text-start text-white"
                          style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Секции в радиаторе
                      </th>
                      <th className="text-start text-white"
                          style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Кол-во секции
                      </th>
                      <th className="text-center text-white"
                          style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Кол-во продаж
                      </th>
                      <th className="text-white"
                          style={{backgroundColor: '#E7372C', fontWeight: "700", borderTopRightRadius: '8px'}}></th>
                      {/*<th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700", borderTopRightRadius: '8px'}}></th>*/}
                   </tr>
                   </thead>

                   <tbody style={{overflowX: "auto"}}>
                   {items.map((item, idx) => {
                      return (
                          <tr key={item.id} className='borderBottom'>
                             <td className="text-center">
                                {currentPage === 0
                                    ? idx + 1
                                    : currentPage * 10 + idx + 1}
                             </td>
                             <td className="text-start">{item.productName}</td>
                             <td className="text-start">{item.sectioned}</td>
                             <td className="text-start pl-5">{item.sectionCount}</td>
                             <td className="text-center">{item.soldCount}</td>
                          </tr>
                      );
                   })}
                   </tbody>
                </table>
             </div>
             {pageCount > 1 && (
                 <ReactPaginate
                     forcePage={currentPage}
                     breakLabel={"..."}
                     breakClassName={"break-me"}
                     pageCount={pageCount}
                     marginPagesDisplayed={1}
                     pageRangeDisplayed={1}
                     onPageChange={paginate}
                     containerClassName={"pagination"}
                     activeClassName={"bg-dark text-white"}
                     pageClassName={"page-item box-shadow"}
                 />
             )}
          </div>
       </div>
   );
}

