import React, { useState } from "react";
import { Save, XCircle } from "react-feather";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import {PUT} from "../../../api/adminApi";
import {target} from "react-paginate/demo/webpack.config";

export default function EditUser({
   modal: { show, item },
   setModal,
   getItems,
   statusOptions,
}) {
   const [statusOpt, setStatusOpt] = useState(null);
   const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      clearErrors,
   } = useForm();
   const [name, setName] = useState(item.fullName || '')
   async function changeStatus(data) {
      const response = await  PUT("/services/admin/api/user",
         {
            id: item.id,
            fullName: name,
         }
      );
      if (response && response.success === true) {
         getItems();
         onHide();
      }
   }

   function onHide() {
      setModal({ show: false, item: null });
      setStatusOpt(null);
      setValue("fullName", "");
      clearErrors();
   }

   return (
      <Modal
         show={show}
         size="md"
         aria-labelledby="contained-modal-title-vcenter"
         centered
         onHide={onHide}
      >
         <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
               Изменить ФИО
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <form onSubmit={handleSubmit(changeStatus)} autoComplete="off">
               <div className="d-flex">
                  <div className="w-100">
                     <div className="admin-field">
                        <div className="d-flex w-100">
                           <div className="mr-md-3 w-25">
                              <label className="mb-0">ФИО</label>
                           </div>
                           <input
                               {...register("fullName", {})}
                               value={name}
                               onChange={(e) => setName(e.target.value)}
                               type="text"
                               placeholder=""
                               className="input-control w-75"
                           />
                        </div>
                     </div>
                  </div>
               </div>
               <div className="d-flex justify-content-end">
                  <button onClick={onHide} className="btn text-white btn-bg-primary d-flex align-items-center">
                     <XCircle size={14} className="mr-2"/>
                     Отмена
                  </button>
                  <button
                      className="btn ml-2 text-white btn-bg-success d-flex align-items-center"
                      type="submit"
                  >
                     <Save size={14} className="mr-2"/>
                     Сохранить
                  </button>
               </div>
            </form>
         </Modal.Body>
      </Modal>
   );
}
