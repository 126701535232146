import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import {
    Edit3, Search, PlusCircle, User, CheckCircle, XCircle,
} from "react-feather";
import Select from "react-select";

import {PGET, PUT, GET} from "../../../api/adminApi";
import MasterDeleteModal from "./MasterDeleteModal";
import {CustomSvg} from "../../../utils/CustomSvg";
import {PageHeader} from "../../common/page-headers/page-headers";
import {Button} from "../../common/buttons/buttons";

function Master() {
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(+localStorage.getItem("currentPageMasters") || 0); //for numeration and saving currentPage after refresh
    const [regions, setRegions] = useState([{id: 0, name: "Все регионы"}]);
    const [region, setRegion] = useState(regions[0]);
    const [deleteModal, setDeleteModal] = useState({
        show: false, master: null,
    });

    const selectRegion = (selectedOpt) => {
        if (region !== selectedOpt) {
            setRegion(selectedOpt);
            setCurrentPageToZero();
        }
    };

    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPageMasters", 0);
    }

    const paginate = (data) => {
        setCurrentPage(data.selected);
        localStorage.setItem("currentPageMasters", data.selected); //for saving currentPage after refresh
    };

    async function getRegions() {
        const response = await GET("/services/admin/api/region-helper/1");

        if (response.length > 0) {
            setRegions([regions[0], ...response]);
        }
    }

    async function getItems() {
        const data = await PGET("/services/admin/api/master-pageList", true, {
            region: region.id, page: currentPage, size: 10, search: value,
        });
        setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
        setItems(data.data);
    }

    async function active(id) {
        await PUT("/services/admin/api/master-approve/" + id);
        getItems();
    }

    useEffect(() => {
        getItems();
    }, [region, currentPage, value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getRegions();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (<div
        className="br-5 card"
        style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}
    >
        <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Мастеры</span>
            </h3>
            <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to add a user'
            >
                <Link
                    to="/admin/master/create"
                    className='btn btn-sm btn-light-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_invite_friends'
                >
                    <CustomSvg path='/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                    Добавить новый
                </Link>
            </div>
        </div>
        <div className="px-3 pb-4 pt-2">
            <div className="mb-4 d-flex justify-content-between">
                <div className="d-flex gap-4">
                    <div style={{width: "150px"}}>
                        <Select
                            value={region}
                            placeholder="Выберите регион"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            options={regions}
                            onChange={selectRegion}
                        />
                    </div>
                    <div className="position-relative ">
                        <DebounceInput
                            minLength={3}
                            debounceTimeout={400}
                            value={value}
                            onChange={handleSearchInput}
                            type="text"
                            className="input-search search-icon br-5 "
                            size={24}
                            placeholder="Поиск"
                        />
                    </div>
                </div>
            </div>
            <div style={{ overflow: "auto", maxHeight: '75vh' }} className='position-relative'>
                <table className='table p-0 bg-white table-rounded border gy-4 gs-7'>
                    <thead className='position-sticky top-0 rounded'>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                        <th style={{width: "30px", backgroundColor: '#E7372C', fontWeight: "700", borderTopLeftRadius: '8px'}} className="text-center text-white">№ </th>
                        <th style={{backgroundColor: '#E7372C', fontWeight: "700"}} className="text-start text-white">ФИО</th>
                        <th style={{backgroundColor: '#E7372C', fontWeight: "700"}} className="text-start text-white">Номер телефона</th>
                        <th style={{backgroundColor: '#E7372C', fontWeight: "700"}} className="text-start text-white">Город/Район</th>
                        <th style={{backgroundColor: '#E7372C', fontWeight: "700"}} className="text-start text-white">Регион</th>
                        <th style={{backgroundColor: '#E7372C', fontWeight: "700", borderTopRightRadius: '8px'}} className="text-start text-white"></th>
                    </tr>
                    </thead>

                    <tbody style={{overflowX: "auto"}}>
                    {items.map((item, index) => {
                        return (<tr className='borderBottom' key={item.id}>
                            <td className="text-center">
                                {currentPage === 0 ? index + 1 : currentPage * 10 + index + 1}
                            </td>
                            <td className="text-center">
                                <div className="d-flex">
                                    <img alt="img" src={item.photo ? "https://bo.akfacomfort.uz/" + item.photo : "https://th.bing.com/th/id/OIP.1V_ec2D7-sqwJoC5qZOmUQAAAA?pid=ImgDet&rs=1"} className="user-image"/>
                                    <div className="d-flex flex-column align-items-start">
                                        <span className="fw-bold mb-1">{item.name}</span>
                                        <p className="fs-7 lh-1">{item.birthday}</p>
                                    </div>
                                </div>
                            </td>
                            <td className="text-start">{item.phone}</td>
                            <td className="text-start">
                                {item.cityHelperDTO.name}
                            </td>
                            <td className="text-start">
                                {item.regionHelperDTO.name}
                            </td>
                            <td>
                                <div className='d-flex justify-content-end flex-shrink-0'>
                                    <Link
                                        to={"/admin/master/update/" + item.id}
                                        href='#'
                                        className='btn btn-icon btn-active-color-primary btn-sm me-1 shadow-sm'
                                    >
                                        <CustomSvg path='/icons/duotune/art/art005.svg' className='svg-icon-3'/>
                                    </Link>

                                </div>
                            </td>
                        </tr>);
                    })}
                    </tbody>
                </table>
            </div>
            {pageCount > 1 && (<ReactPaginate
                forcePage={currentPage}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                onPageChange={paginate}
                containerClassName={"pagination"}
                activeClassName={"bg-dark text-white"}
                pageClassName={"page-item box-shadow br-5"}
            />)}
        </div>

        {deleteModal.master && (<MasterDeleteModal
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            getItems={getItems}
        />)}
    </div>);
}

export default Master;
