import React, {useEffect, useState} from "react";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import {HelpCircle, Search} from "react-feather";
import Select from "react-select";

import {PGET} from "../../../api/adminApi";
import {CustomSvg} from "../../../utils/CustomSvg";

export default function StatusMaster() {
    const [items, setItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(
        +localStorage.getItem("currentPageStatusMaster") || 0
    ); //for numeration and saving currentPage after refresh
    const [statusOptions, setStatusOptions] = useState([
        {id: 0, name: "Все"},
    ]);
    const [statusOpt, setStatusOpt] = useState(statusOptions[0]);

    const selectStatus = (selectedOpt) => {
        if (statusOpt !== selectedOpt) {
            setStatusOpt(selectedOpt);
            setCurrentPageToZero();
        }
    };

    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPageStatusMaster", 0);
    }

    function paginate(data) {
        setCurrentPage(data.selected); //for numeration
        localStorage.setItem("currentPageStatusMaster", data.selected); //for saving currentPage after refresh
    }

    async function getItems() {
        const data = await PGET(
            "/services/admin/api/history/masters-statuses-pageList",
            true,
            {
                statusId: statusOpt.id,
                page: currentPage,
                size: 15,
                search: value,
            }
        );
        setPageCount(Math.ceil(data?.headers["x-total-count"] / 15));
        setItems(data?.data);
    }

    useEffect(() => {
        getItems();
    }, [statusOpt, currentPage, value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function getStatusHelper() {
            const data = await PGET(
                "/services/admin/api/request/status-helper"
            );
            if (data) {
                setStatusOptions([statusOptions[0], ...data.data]);
            }
        }

        getStatusHelper();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className="br-5 card"
            style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}
        >
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Статусы по мастерам</span>
                </h3>
                <div
                    className="d-flex gap-4"
                >
                    <div
                        className="d-flex justify-content-end w-100"
                        style={{width: "150px"}}
                    >
                        <Select
                            className="w-100"
                            value={statusOpt}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            options={statusOptions}
                            onChange={selectStatus}
                        />
                    </div>
                    <div className="position-relative w-100">
                        <DebounceInput
                            minLength={3}
                            debounceTimeout={400}
                            value={value}
                            onChange={handleSearchInput}
                            type="text"
                            className="input-search search-icon br-5 w-100"
                            size={24}
                            placeholder="Поиск"
                        />
                    </div>
                </div>
            </div>
            <div className="m-3" style={{ overflow: "auto", maxHeight: '75vh' }}>
                <table className='table p-0 bg-white table-rounded gy-4 gs-7'>
                    <thead className='position-sticky top-0 rounded'>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                        <th className="text-center text-white" style={{backgroundColor: '#E7372C', fontWeight: "700", borderTopLeftRadius: '8px'}}>№</th>
                        <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}   >Номер запроса</th>
                        <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}   >Дата изменения</th>
                        <th className="text-center text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}  >Изменен с</th>
                        <th className="text-center text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}  >
                            {/*<CustomSvg path='/icons/duotune/arrows/arr001.svg' className='svg-icon-3'/>*/}
                        </th>
                        <th className="text-center text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}  >Изменен на</th>
                        <th className="text-center text-white" style={{backgroundColor: '#E7372C', fontWeight: "700", borderTopRightRadius: '8px'}}   >Причина</th>
                    </tr>
                    </thead>

                    <tbody>
                    {items?.map((user, idx) => {
                        const {
                            changedTime,
                            id,
                            reason,
                            requestMasterId,
                            statusChangedFrom,
                            statusChangedFromName,
                            statusChangedTo,
                            statusChangedToName,
                        } = user;
                        return (
                            <tr key={id} className='borderBottom'>
                                <td className="text-center pointer">
                                    {currentPage === 0
                                        ? idx + 1
                                        : // : currentPage * 10 + idx + 1} // for 10 items per page
                                          // for 15 items per page
                                        currentPage * 10 +
                                        idx +
                                        1 +
                                        5 * currentPage}
                                </td>
                                <td className="text-start">
                                    {requestMasterId}
                                </td>
                                <td className="text-start">
                                    {changedTime}
                                </td>
                                <td className="text-start w-10">
                                    <b
                                        style={{
                                            whiteSpace: "nowrap",
                                        }}
                                        className={`p-2 badge rounded font-weight-500 d-block ${
                                            statusChangedFrom === 1
                                                ? "badge-warning"
                                                : statusChangedFrom === 2
                                                    ? "badge-success"
                                                    : statusChangedFrom
                                                        ? "badge-danger"
                                                        : "badge-primary"
                                        }`}
                                    >
                                        {statusChangedFromName}
                                    </b>
                                </td>
                                <td className="text-center">
                                    <CustomSvg path='/icons/duotune/arrows/arr001.svg' className='svg-icon-3'/>
                                </td>
                                <td className="text-start w-10">
                                    <b
                                        style={{
                                            whiteSpace: "nowrap",
                                        }}
                                        className={`p-2 badge rounded font-weight-500 d-block ${
                                            statusChangedTo === 1
                                                ? "badge-warning"
                                                : statusChangedTo === 2
                                                    ? "badge-success"
                                                    : statusChangedTo === 3
                                                        ? "badge-danger"
                                                        : "badge-primary"
                                        }`}
                                    >
                                        {statusChangedToName}
                                    </b>
                                </td>
                                <td className="text-center">{reason}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>

            {pageCount > 1 && (
                <ReactPaginate
                    forcePage={currentPage}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={paginate}
                    containerClassName={"pagination"}
                    activeClassName={"bg-dark text-white"}
                    pageClassName={"page-item box-shadow br-5"}
                />
            )}
        </div>
    );
}
