import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { DebounceInput } from 'react-debounce-input';
import ReactPaginate from 'react-paginate';
import { Edit3, Trash2, Search, PlusCircle } from 'react-feather';

import { DELETE, PGET } from '../../../api/adminApi'

function Cuisines() {
	const [items, setItems] = useState([])
	const [pageCount, setPageCount] = useState(0)

	async function getItems() {
		const data = await PGET('/services/admin/api/cuisines-pageList', true, { page: 0, size: 20, sorting: 1 })
		setPageCount(Math.ceil(data.headers['x-total-count'] / 20))
		setItems(data.data)
	}

	async function deleteItem(id) {
		await DELETE('/services/admin/api/cuisines/' + id,)
		getItems()
	}

	async function search(e) {
		const data = await PGET('/services/admin/api/cuisines-pageList?search=' + e.target.value, true)
		setPageCount(Math.ceil(data.headers['x-total-count'] / 20))
		setItems(data.data);
	};

	async function paginate(data) {
		const response = await PGET('/services/admin/api/cuisines-pageList', true, { page: data.selected, size: 20 })
		setItems(response.data);
	};

	useEffect(() => {
		getItems()
	}, [])

	return (
		<>
			<div className="background-white my-2 mx-3 box-shadow br-5">
				<div className="fz20 border-bottom pl-3 py-3 my-2 d-flex align-items-center">
					Кухня
				</div>
				<div className='px-3 pb-4 pt-2'>
					<div className="mb-4 d-flex justify-content-between">
						<Link to='/admin/directory/cuisines/create'>
							<button className="btn btn-success d-flex align-items-center">
								<PlusCircle size={18} className='mr-1' />
								<div>Добавить новый</div>
							</button>
						</Link>
						<div className="position-relative">
							<Search size={14} color='#9D9BA3' className="input-search-icon" />
							<DebounceInput minLength={3} debounceTimeout={400} onChange={(e) => search(e)} type="text" className="input-search" size={24} placeholder='Поиск' />
						</div>
					</div>
					<div style={{ overflowX: 'auto' }}>
						<table className="table table-bordered">
							<thead>
								<tr className='backgroung-grey'>
									<th >Название </th>
									<th >Описание </th>
									<th className="text-center">Действие </th>
								</tr>
							</thead>

							<tbody style={{ overflowX: 'auto' }}>
								{
									items.map((item) => {
										return (
											<tr key={item.id}>
												<td className="vertical-middle" >{item.nameRu}</td>
												<td className="vertical-middle">{item.descriptionRu}</td>
												<td className="vertical-middle">
													<div className="d-flex justify-content-center">
														<Link to={'/admin/directory/cuisines/update/' + (item.id)}><button className="btn btn-table" style={{ backgroundColor: '#F4F4F5' }}><Edit3 color={'#189ED3'} size={16} /></button></Link>
														<button onClick={() => deleteItem(item.id)} className="btn btn-table mr-0" style={{ backgroundColor: '#F4F4F5' }}><Trash2 color={'#E63950'} size={16} /></button>
													</div>
												</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</div>
					{
						pageCount > 1 &&
						<ReactPaginate
							// breakLabel={'...'}
							// breakClassName={'break-me'}
							pageCount={pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={5}
							onPageChange={paginate}
							containerClassName={'pagination'}
							activeClassName={'active'}
							pageClassName={'page-item'}
						/>
					}
				</div>
			</div>
		</>
	)
}

export default Cuisines
