import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import {PGET, GET, DOWNLOAD} from "../../../api/userApi";
import {CustomSvg} from "../../../utils/CustomSvg";
import DatePicker from "react-datepicker";
import moment from "moment";
import {excelParams} from "../../../api/adminApi";

function UserMastersReport() {
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(
        +localStorage.getItem("currentPageMasters") || 0
    ); //for numeration and saving currentPage after refresh
    const [regions, setRegions] = useState([{id: 0, name: "Все регионы"}]);
    const [region, setRegion] = useState(regions[0]);
    const [dateRange, setDateRange] = useState([
        new Date('2024-01-01'),
        new Date('2025-01-01'),
    ]);
    const [startDate, endDate] = dateRange;
    const selectRegion = (selectedOpt) => {
        if (region !== selectedOpt) {
            setRegion(selectedOpt);
            setCurrentPageToZero();
        }
    };

    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPageMasters", 0);
    }

    const paginate = (data) => {
        setCurrentPage(data.selected);
        localStorage.setItem("currentPageMasters", data.selected); //for saving currentPage after refresh
    };

    async function getRegions() {
        const response = await GET("/services/admin/api/region-helper/1");

        if (response.length > 0) {
            setRegions([regions[0], ...response]);
        }
    }

    async function getItems() {
        if (startDate && endDate) {

            const data = await PGET(
                "/services/admin/api/master-report-pageList",
                true,
                {
                    region: region.id,
                    page: currentPage,
                    size: 10,
                    search: value,
                    dateFrom: moment(startDate).format("YYYY-MM-DD"),
                    dateTo: moment(endDate).add(1, 'day').format("YYYY-MM-DD"),
                });
            setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
            setItems(data.data);
        }
    }

    function weekOwnerAgents() {
        const excelUrl = 'https://bo.akfacomfort.uz/services/admin/api/masters-report/download-all';
        const fileName = 'reportall.xlsx'
        excelParams(excelUrl, fileName, true, {
            region: region.id,
            size: 10,
            search: value,
            dateFrom: moment(startDate).format("YYYY-MM-DD"),
            dateTo: moment(endDate).add(1, 'day').format("YYYY-MM-DD"),
        });
    }
    useEffect(() => {
        getItems();
    }, [region, currentPage, value, dateRange, startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getRegions();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className="br-5 card"
            style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}
        >
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Все мастеры</span>
                </h3>
            </div>

            <div className="px-3 pb-4 pt-2">
                <div className="mb-4 d-flex justify-content-end">
                    <div className="d-flex gap-4">
                        <div style={{width: "150px"}}>
                            <Select
                                value={region}
                                placeholder="Выберите регион"
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                options={regions}
                                onChange={selectRegion}
                            />
                        </div>
                        <div style={{width: 200}}>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                    setDateRange(update);
                                }}
                                isClearable={true}
                                className="datepicker-custom-input"
                            />
                        </div>
                        <div className="position-relative ">
                            <DebounceInput
                                minLength={3}
                                debounceTimeout={400}
                                value={value}
                                onChange={handleSearchInput}
                                type="text"
                                className="input-search search-icon br-5 "
                                size={24}
                                placeholder="Поиск"
                            />
                        </div>
                        <div className="d-flex">
                            <button
                                onClick={weekOwnerAgents}
                                className="mr-0 box-shadow"
                                style={{
                                    backgroundColor: "#fcfcfc",
                                    height: 38,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "1px solid rgb(212, 211, 214)",
                                    borderRadius: 5,
                                    padding: "0 10px"
                                }}
                            >
                                <CustomSvg path='/icons/duotune/files/fil017.svg' className='svg-icon-3 mr-2'/>
                                Скачать все
                            </button>
                        </div>
                    </div>

                </div>

                <div style={{overflow: "auto", maxHeight: '75vh'}}>
                    <table className='table p-0 bg-white table-rounded border gy-4 gs-7'>
                        <thead className='position-sticky top-0 rounded'>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                            <th className="text-center text-white" style={{
                                backgroundColor: '#E7372C',
                                fontWeight: "700",
                                width: "30px",
                                borderTopLeftRadius: '8px'
                            }}>№
                            </th>
                            <th className="text-start text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Мастер
                            </th>
                            <th className="text-start text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Регион
                            </th>
                            <th className="text-center text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Панельный
                            </th>
                            <th className="text-center text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Секционный
                            </th>
                            <th className="text-center text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Котел
                            </th>
                            <th className="text-center text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>По рефералу
                            </th>
                            <th className="text-center text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Текущий балл
                            </th>
                            <th className="text-center text-white" style={{
                                backgroundColor: '#E7372C',
                                fontWeight: "700",
                                borderTopRightRadius: '8px'
                            }}>Общие баллы
                            </th>
                        </tr>
                        </thead>

                        <tbody style={{overflowX: "auto"}}>
                        {items.map((item, index) => {
                            return (
                                <tr key={item.id} className='borderBottom'>
                                    <td className="text-center">
                                        {currentPage === 0
                                            ? index + 1
                                            : currentPage * 10 + index + 1}
                                    </td>
                                    <td className="text-start">
                                        <div className="flex flex-col">
                                            <div className="flex">
                                                <p className="m-0 p-0 font-bold text-#eff2f5">ФИО: </p>
                                                <p className="p-0 ml-2 mb-0">{item.name}</p>
                                            </div>
                                            <div className="flex">
                                                <p className="m-0 p-0 font-bold text-#eff2f5">Номер: </p>
                                                <p className="p-0 ml-2 mb-0">{item.phone}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-start">
                                        <div className="flex flex-col">
                                            <div className="flex">
                                                <p className="m-0 p-0 font-bold text-#eff2f5">Регион: </p>
                                                <p className="p-0 ml-2 mb-0">{item.regionName}</p>
                                            </div>
                                            <div className="flex">
                                                <p className="m-0 p-0 font-bold text-#eff2f5">Город: </p>
                                                <p className="p-0 ml-2 mb-0">{item.cityName}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        {item.totalPointPanel}
                                    </td>
                                    <td className="text-center">
                                        {item.totalPointSection}
                                    </td>
                                    <td className="text-center">
                                        {item.totalPointKotel}
                                    </td>
                                    <td className="text-center">
                                        {item.totalPointReferal}
                                    </td>
                                    <td className="text-center">{item.totalPoint}</td>
                                    <td className="text-center">{item.totalPointOverall}</td>
                                    {/*<td>*/}
                                    {/*   <div className="d-flex justify-content-center">*/}
                                    {/*      /!* <Link*/}
                                    {/*         to={"/admin/master/update/" + item.id}*/}
                                    {/*      > *!/*/}
                                    {/*      <button*/}
                                    {/*         className="btn btn-table box-shadow br-5"*/}
                                    {/*         style={{ backgroundColor: "#F4F4F5" }}*/}
                                    {/*      >*/}
                                    {/*         <List color={"#189ED3"} size={16} />*/}
                                    {/*      </button>*/}
                                    {/*      /!* </Link> *!/*/}
                                    {/*   </div>*/}
                                    {/*</td>*/}
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                {pageCount > 1 && (
                    <ReactPaginate
                        forcePage={currentPage}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={paginate}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        pageClassName={"page-item box-shadow br-5"}
                    />
                )}
            </div>
        </div>
    );
}

export default UserMastersReport;
