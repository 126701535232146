import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ChevronLeft, Clock, Heart, Phone, MapPin, Edit3, Bell, Settings, LogOut } from 'react-feather';

import Addresses from './Profile/Addresses';
import FavoriteRestaurants from './Profile/FavoriteRestaurants';
import HistoryOrders from './Profile/HistoryOrders';
import Notifications from './Profile/Notifications';
import ProfileSettings from './Profile/Settings';
import SignOut from './Profile/SignOut';
import Languages from './Profile/Languages';

import { GET } from '../../api/api';
import { formatPhone } from '../../helper';

function Profile() {
  let history = useHistory()
  let location = useLocation()
  const [active, setActive] = useState(0)
  const [user, setUser] = useState({

  })
  const [menu, setMenu] = useState([
    {
      component: <Addresses />,
      icon: <MapPin size={18} className="mr-2" />,
      name: 'Мои адреса',
      active: false,
      id: 1,
    },
    {
      component: <Notifications />,
      icon: <Bell size={18} className="mr-2" />,
      name: 'Уведомления',
      active: false,
      id: 2,
    },
    {
      component: <HistoryOrders />,
      icon: <Clock size={18} className="mr-2" />,
      name: 'История заказов',
      active: false,
      id: 3,
    },
    {
      component: <FavoriteRestaurants />,
      icon: <Heart size={18} className="mr-2" />,
      name: 'Любимые рестораны',
      active: false,
      id: 4,
    },
    {
      component: <Languages />,
      icon: <Settings  size={18} className="mr-2" />,
      name: 'Настройки',
      active: false,
      id: 5,
    },
    {
      component: <SignOut />,
      icon: <LogOut size={18} className="mr-2" />,
      name: 'Выход',
      active: false,
      id: 6,
    },
  ])

  async function getInfo() {
    const response = await GET('/services/web/api/get-info')
    response.login = formatPhone(response.login)
    setUser(response)
  }

  function activate(index) {
    let copy = [...menu];
    let copyItem = { ...menu[index] };
    for (let i = 0; i < copy.length; i++) {
      copy[i].active = false
    }
    copyItem.active = true;
    copy[index] = copyItem;
    setActive(copy[index].id)
    setMenu(copy);
    history.replace({ pathname: '/profile', search: '?' + index});
  }

  useEffect(() => {
    if (location.search) {
      let index = location.search.replace(/[^0-9]/g, '')
      activate(index)
    } 
    getInfo()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="bg-white">
        <div className="container">
          <div className="mx-sm-2 mx-lg-5 py-3">
            <div>
              <Link to="/">
                <div className="fz20 d-flex align-items-center mb-3">
                  <ChevronLeft />
                  Главная страница
                </div>
              </Link>
              <div className="d-flex align-items-center">
                <img src="img/profile.png" alt="" className="mr-4" />
                <div>
                  <h3 className="mb-4">{user.firstName}</h3>
                  <div className="d-flex align-items-center flex-wrap">
                    <span className="rounded-text mb-3 mr-2">
                      <div className="d-flex mr-2"><Phone size={18} color='#5C3EBA' /></div>
                      <div>{user.login}</div>
                    </span>
                    <span className="rounded-text mb-3 mr-2">
                      <div className="d-flex mr-2"><MapPin size={18} color='#5C3EBA' /></div>
                      <div>Себзор кўчаси, Тошкент</div>
                    </span>
                    <span onClick={() => setActive(0) + history.replace({ pathname: '/profile'})}
                    className={"rounded-text cursor mb-3 mr-2 " + (active === 0 ? 'bg-purple' : '')}>
                      <div className="d-flex mr-2"><Edit3 size={18} /></div>
                      <div>Редактировать профиль</div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="pb-4 section products-section" style={{ backgroundColor: "#FBFBFB" }}>
        <div className="container">
          <div className="mx-sm-2 mx-lg-5">
            <div className="d-flex justify-content-center flex-wrap pt-4 mb-3">
              {
                menu.map((item, index) => {
                  return (
                    <span key={item.id} className={"rounded-text-lg mb-3 mr-2 cursor " + (item.active && active !== 0 ? "bg-purple" : "")} onClick={() => activate(index)}>
                      {item.icon}
                      <div>{item.name}</div>
                      {item.name === 'Уведомления' &&
                        <span className="notification">3</span>
                      }
                    </span>
                  )
                })
              }
              {/* <span className="rounded-text-lg mb-3 mr-2 cursor">
                <MapPin size={18} className="mr-2" />
                <div>Мои адреса</div>
              </span> */}
            </div>
            {active ? menu.map((item, index) => {
              return (
                item.id === active ?
                  <React.Fragment key={item.id}>
                    {item.component}
                  </React.Fragment>
                  :
                  null
              )
            }) : <ProfileSettings user={user} />
            }

          </div>
        </div>
      </section>
    </>
  )
}

export default Profile
