import React from 'react'
import GoogleMapReact from 'google-map-react';

function Map({ lat, lng, onClick }) {

  // function onClick(t) {
  // 	setData({ ...data, gpsPointX: t.lat, gpsPointY: t.lng })
  // 	setValue('gpsPointX', t.lat)
  // 	setValue('gpsPointY', t.lng)
  // }

  const Marker = props => {
    return <div lat={lat} lng={lng}> <div className='pin' /> <div className='pulse' /> </div>
  }


  return (
    <GoogleMapReact bootstrapURLKeys={{ key: "AIzaSyDRnmjaTmZQmjt4sxKaNVW_DqOfp993Afc" }}
      defaultZoom={13}
      yesIWantToUseGoogleMapApiInternals
      center={{ lat: lat, lng: lng }}
      onClick={onClick}
    // onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
    >
      <Marker lat={lat} lng={lng} />
    </GoogleMapReact>
  )
}

export default Map
