import React, {useState} from "react";
import {Main} from './styled'
import {Row, Col} from 'antd';
import {Cards} from "../../common/frame/cards-frame";
import {
    ChartjsBarChartGrad,
    RegionSalesChart,
} from "../Charts/chartjs";
import Statistics from "../Charts/Statistics";
import TopMasterByProduct from "./TopMasterByProduct";
import ProductSales from "./ProductSales";
import {LineChartMonthly} from "../Charts/LineChartMonthly";
import {LineChartYear} from "../Charts/LineChartYear";
import {MasterRegionChart} from "./MasterRegionChart";
import {DeadMasterRegionChart} from "./DeadMasterRegionChart";
import {KotelDashboard} from "./KotelDashboard";
import {KotelMonthDashboard} from "./KotelMonthDashboard";

function Dashboard() {
    const [chart, setChart] = useState('day');

    const handleMonthClick = () => {
        setChart('day');
    };

    const handleYearClick = () => {
        setChart('month');
    };
    return (
        <>
            <div
                style={{background: "#eef1ff", marginTop: "-0.4rem"}}
                className="p-3"
            >
                <Statistics/>
                <Main>
                    <Row>
                        <Col md={24}>
                            <Cards title="Запросы" size="large">
                                <div className="d-flex justify-content-end">
                                    <button
                                        className="btn h-30px text-white btn-bg-success d-flex align-items-center mr-2"
                                        onClick={handleMonthClick}
                                    >
                                        День
                                    </button>
                                    <button
                                        className="btn h-30px text-white btn-bg-success d-flex align-items-center"
                                        onClick={handleYearClick}
                                    >
                                        Месяц
                                    </button>
                                </div>
                                {chart === 'day' &&
                                    <LineChartMonthly request={true}
                                                      url="/services/admin/api/dashboard-requests-daily/"/>}
                                {chart === 'month' &&
                                    <LineChartYear request={true}
                                                   url="/services/admin/api/dashboard-requests-monthly/"/>}
                            </Cards>
                        </Col>
                        <div className="wrapper-container">
                            <Cards title="Продажа по регионам разбито по типам" size="large">
                                <RegionSalesChart className="foo" style={{marginBottom: '20px'}}/>
                            </Cards>
                            <Cards title="Продажа по регионам котел" size="large">
                                <KotelDashboard className="foo" style={{marginBottom: '20px'}}/>
                            </Cards>
                        </div>
                        <div className="wrapper-container">
                            <Cards title="Продажа по регионам разбито по типам" size="large">
                                <ChartjsBarChartGrad className="foo" style={{marginBottom: '20px'}}/>
                            </Cards>
                            <Cards title="Продажа по месяцам котел" size="large">
                                <KotelMonthDashboard className="foo" style={{marginBottom: '20px'}}/>
                            </Cards>
                        </div>
                        <div className="wrapper-container">
                            <Cards title="Мастеры по регионам" size="large">
                                <MasterRegionChart className="foo" style={{marginBottom: '20px'}}/>
                            </Cards>
                            <Cards title="Спящие мастеры по регионам" size="large">
                                <DeadMasterRegionChart className="foo" style={{marginBottom: '20px'}}/>
                            </Cards>
                        </div>
                        <div className="wrapper-container-full">
                            <div className="masters-container">
                                <Cards headStyle={{fontSize: 14}} title="Топ 10 мастеров по продажам" bodypadding="0">
                                    <TopMasterByProduct/>
                                </Cards>
                            </div>
                        </div>
                        <div className="wrapper-container-full">
                            <div className="products-container">
                                <Cards headStyle={{fontSize: 14}} title="Продажа по моделям" bodypadding="0">
                                    <ProductSales/>
                                </Cards>
                            </div>
                        </div>

                        <Col md={24}>
                            <Cards title="Зарегистрированные мастера" size="large">
                                <div className="d-flex justify-content-end">
                                    <button
                                        className="btn h-30px text-white btn-bg-success d-flex align-items-center mr-2"
                                        onClick={handleMonthClick}
                                    >
                                        День
                                    </button>
                                    <button
                                        className="btn h-30px text-white btn-bg-success d-flex align-items-center"
                                        onClick={handleYearClick}
                                    >
                                        Месяц
                                    </button>
                                </div>
                                {chart === 'day' &&
                                    <LineChartMonthly request={false}
                                                      url="/services/admin/api/dashboard-masters-reg-daily/"/>}
                                {chart === 'month' &&
                                    <LineChartYear request={false}
                                                   url="/services/admin/api/dashboard-masters-reg-monthly/"/>}
                            </Cards>
                        </Col>
                    </Row>
                </Main>
            </div>
        </>
    );
}

export default Dashboard;
