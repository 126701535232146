import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { Star, Clock, Tag, Trash2 } from 'react-feather';

import { GET, DELETE } from '../../../api/adminApi';

function FavoriteRestaurants() {
  const [branchs, setbranchs] = useState([])

  async function getBranchs() {
    const response = await GET('/services/web/api/branch-favorite', true)
    setbranchs(response)
  }

  async function deleteFavorite(id) {
    await DELETE('/services/mobile/api/branch-favorite/' + id, true)
    getBranchs()
  }

  useEffect(() => {
    getBranchs()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="mb-3 fz28">Любимые рестораны</div>
      <div className="row">
        {
          branchs.map((item) => {
            return (
              <div key={item.id} className="item col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <div className="list-card h-100 overflow-hidden position-relative">
                  <div className="member-plan position-absolute">
                    <Image src={'https://colibri24.uz' + (item.brandImageUrl)} className="brand-image" alt='' />
                  </div>
                  <div onClick={() => deleteFavorite(item.id)} className="deleteIcon">
                    <Trash2 size={17} />
                  </div>

                  <div className="list-card-image text-center">
                    {item.workingStatus ?
                      <div className="status status-position">
                        Открыто
                      </div>
                      :
                      <div className="status status-danger status-position">
                        Закрыто
                      </div>
                    }
                    <Link to={'/detail' + item.id}>
                      <Image src={'https://colibri24.uz' + (item.imageUrl)} className="w-100 img-fluid item-img branch-image" alt='' />
                    </Link>
                  </div>
                  <div className="p-3 bg-white position-relative">
                    <div className="list-card-body">
                      <h6 className="mb-1 text-center"><Link to={'/detail' + item.id} className="text-purple">{item.name}</Link></h6>
                      <div className="text-gray mb-3 d-flex align-items-center justify-content-between">
                        <div className='row w-100 justify-content-center'>
                          <span className="bg-grey text-dark  px-2 py-1 d-flex align-items-center justify-content-center border-radius-15 col-xl-5 col-md-4 col-sm-4 col-xs-4 col-12 ">
                            <div className='d-flex mr-2'><Clock size={12} color={'#5C3EBA'} /></div>
                            <div className='price-text'>
                              {item.deliveryFrom + '–' + item.deliveryTo + ' min' }
                            </div>
                          </span>
                          <span className="bg-grey text-dark  px-2 py-1 d-flex align-items-center justify-content-center border-radius-15 col-xl-5 col-md-6 col-sm-5 col-xs-5 col-12">
                            <div className='d-flex mr-2'><Tag size={12} color={'#5C3EBA'} /></div>
                            <div className='price-text text-center'>от 10`000 сум</div>
                          </span>
                          <span className="bg-grey text-dark price-text  px-2 py-1 d-flex align-items-center justify-content-center border-radius-15 col-xl-2 col-md-2 col-sm-3 col-xs-3 col-12">
                            <div className="text-black-50"> <Star size={12} color='#FFA91A' fill='#FFA91A' /></div>
                            <div className="text-black-50">{item.rating}</div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
        {/* <div className="item col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12">
          <CardItem title='Bite Me Sandwiches' image='img/background.png' deleteIcon={true}
            imageClass='img-fluid item-img' time='15–25 min' brandClass='brand-border' status='открыто'
          />
        </div> */}
      </div>
    </div>
  )
}

export default FavoriteRestaurants