import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {CustomSvg} from "../../../utils/CustomSvg";
import Select from "react-select";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import MasterDeleteModal from "../Masters/MasterDeleteModal";
import {GET, PGET, PUT} from "../../../api/adminApi";
import {getDateTime} from "../../../utils/Dates";

export default function SupportMessage() {
    const history = useHistory()
    const [items, setItems] = useState([]);
    const [msgCount, setMsgCount] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(+localStorage.getItem("currentPageMsg") || 0); //for numeration and saving currentPage after refresh
    const [regions, setRegions] = useState([{id: 0, name: "Все регионы"}]);
    const [region, setRegion] = useState(regions[0]);
    const [deleteModal, setDeleteModal] = useState({
        show: false, master: null,
    });

    const selectRegion = (selectedOpt) => {
        if (region !== selectedOpt) {
            setRegion(selectedOpt);
            setCurrentPageToZero();
        }
    };

    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPageMsg", 0);
    }

    const paginate = (data) => {
        setCurrentPage(data.selected);
        localStorage.setItem("currentPageMsg", data.selected); //for saving currentPage after refresh
    };

    async function getRegions() {
        const response = await GET("/services/admin/api/region-helper/1");

        if (response.length > 0) {
            setRegions([regions[0], ...response]);
        }
    }

    async function getItems() {
        const data = await PGET("/services/admin/api/support-message-pageList", true, {
            region: region.id, page: currentPage, size: 10, search: value,
        });
        setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
        setItems(data.data);
    }
    async function getMsgCount() {
        const data = await PGET("/services/admin/api/support-unread-message-count/", true, {
            region: region.id, page: currentPage, size: 10, search: value,
        });
        // setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
        setMsgCount(data.data);
    }
    useEffect(() => {
        getItems();
        getMsgCount();
    }, [region, currentPage, value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getRegions();
    }, []);
    return (
        <div
            className="br-5 card"
            style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}
        >
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start '>
                    <span className='card-label fw-bold fs-3 mb-1'>Сообщения</span>
                    <span className="fs-3 mb-1 color-blue">{msgCount}</span>
                </h3>
            </div>
            <div className="px-3 pb-4 pt-2">
                <div style={{ overflow: "auto", maxHeight: '75vh' }}>
                    <table className='table bg-white p-0 table-rounded border gy-4 gs-7'>
                        <thead className='position-sticky top-0 rounded'>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                            <th style={{backgroundColor: '#E7372C', fontWeight: "700", borderTopLeftRadius: '8px', width: "30px"}} className="text-white text-center">№</th>
                            <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Номер</th>
                            <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Время получения</th>
                            <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Мастер</th>
                            <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700", borderTopRightRadius: '8px'}}>Номер Телефона</th>
                        </tr>
                        </thead>
                        <tbody style={{overflowX: "auto"}}>
                        {items.map((item, index) => {
                            return (
                                    <tr
                                        onClick={() => {
                                            history.push("/admin/messages/detail/" + item.id);
                                        }}
                                        className="rowHover cursor-pointer"
                                    >
                                        <td className="text-center">
                                            {currentPage === 0 ? index + 1 : currentPage * 10 + index + 1}
                                        </td>
                                        <td className="text-start">
                                            {item.id}
                                        </td>
                                        <td className="text-start">{getDateTime(item.receivedTime)}</td>
                                        <td className="text-start">
                                            {item.masterName}
                                        </td>
                                        <td className="text-start">
                                            {'+' + item.phone}
                                        </td>
                                    </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                {pageCount > 1 && (<ReactPaginate
                    forcePage={currentPage}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={paginate}
                    containerClassName={"pagination"}
                    activeClassName={"bg-dark text-white"}
                    pageClassName={"page-item box-shadow br-5"}
                />)}
            </div>

            {deleteModal.master && (<MasterDeleteModal
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                getItems={getItems}
            />)}
        </div>
    )
}