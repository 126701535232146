export function inBasket(data) {
  return {
    type: 'INBASKET',
    payload: data
  };
}
export function deleteBasket(data) {
  return {
    type: 'DELETEBASKET',
    payload: data
  };
}
export function clearBasket() {
  return {
    type: 'CLEARBASKET'
  };
}
export function increment(data) {
  return {
    type: 'INCREMENT',
    payload: data
  };
}
export function decrement(data) {
  return {
    type: 'DECREMENT',
    payload: data
  };
}