import React, {useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {XCircle, Save} from "react-feather";
import {useForm} from "react-hook-form";

import {FILE, GET, POST, PUT} from "../../../api/adminApi";

function PrizeAdd() {
    let {id} = useParams();
    const history = useHistory();
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: {errors},
    } = useForm({
        name: '',
        description: '',
        imageFileName: '',
        point: '',
    });

    const [data, setData] = useState({
        name: '',
        description: '',
        imageFileName: '',
        point: '',
    })

    async function create(data) {
        // const response = await POST("/services/admin/api/prize", {
        //     ...data,
        //     point: +data.point,
        // });
        let response

        if (id) {
            response = await PUT("/services/admin/api/prize", data)
        } else {
            response = await POST("/services/admin/api/prize", data)
        }

        if (response?.success) {
            history.push("/admin/prize");
        }
    }

    async function getProduct() {
        const response = await GET('/services/admin/api/prize/' + id, data)
        setData(response)
        for (let key in response) {
            setValue(`${key}`, response[key])
        }
    }

    async function getImageUrl(e) {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            const formData = new FormData();
            formData.append('image', image);
            await FILE('/services/admin/api/cuisines-image-upload', formData).then(response => {
                    setData({...data, imageFileName: response.url})
                    setValue('imageFileName', response.url)
                }
            ).catch(error => console.log(error))
            console.log(getValues('imageFileName'));
        }
    }

    useEffect(() => {
        if (id) {
            getProduct()
        }
    }, [])
    return (
        <div className="mb-2 mx-3 ">
            <h3 className="mb-5 mt-5">
                {id ? "Изменить" : "Добавить новый"}
            </h3>
            <div className="admin-card py-4">
                <form onSubmit={handleSubmit(create)} autoComplete="off">
                    <div className="d-flex pt-12">
                        <div className="" style={{width: "300px"}}>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3"><label className="mb-0">Фото</label>
                                    </div>
                                    <div className="position-relative">
                                        <div className="image-block" style={{
                                            width: '200px',
                                            minWidth: '100px',
                                            minHeight: '200px',
                                            border: '1px dashed #D4D3D6'
                                        }}>Перетащите сюда файлы для загрузки
                                        </div>
                                        <input onChange={(e) => getImageUrl(e)} type="file" placeholder=""
                                               style={{
                                                   width: '200px',
                                                   minWidth: '100px',
                                                   minHeight: '200px',
                                                   opacity: '0',
                                                   top: 0,
                                                   zIndex: 3
                                               }}
                                               className="position-absolute cursor border border-radius-3 box outline-none color-grey"/>
                                        {/* {errors.imageUrl?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>} */}
                                        {
                                            data.imageFileName &&
                                            <div className="position-absolute cursor  border-radius-3" style={{
                                                width: '200px',
                                                minWidth: '100px',
                                                minHeight: '200px',
                                                top: 0,
                                                zIndex: 2
                                            }}>
                                                <img className="w-100 h-100 background-white border box-shadow"
                                                     src={'http://bo.akfacomfort.uz' + (data.imageFileName)} alt=""/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="" style={{width: "500px"}}>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Название</label>
                                    </div>
                                    <input
                                        {...register("name", {required: true})}
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.name && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Балл</label>
                                    </div>
                                    <input
                                        {...register("point", {required: true})}
                                        type="number"
                                        step={"0.5"}
                                        min="1"
                                        placeholder="1.0"
                                        className="input-control"
                                    />
                                </div>
                                {errors.point && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Описание</label>
                                    </div>
                                    <textarea
                                        {...register("description", {required: true})}
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.description && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="btn-postion d-flex">
                        <Link to="/admin/prize" className="mr-3">
                            <button className="btn text-white btn-bg-primary d-flex align-items-center">
                                <XCircle size={14} className="mr-2"/>
                                Отмена
                            </button>
                        </Link>
                        <button
                            className="btn text-white btn-bg-success d-flex align-items-center"
                            type="submit"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default PrizeAdd;
