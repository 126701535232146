import {ChartContainer} from "../../../style/style";
import {Line} from "react-chartjs-2";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {PGET} from "../../../api/adminApi";
import DatePicker from "react-datepicker";

export const LineChartYear = (props) => {
    const [items, setItems] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date());
    const [chartLabels, setChartLabels] = useState([]);
    const [chartData, setChartData] = useState([]);

    async function getItems(year) {
        const data = await PGET(
            `${props.url}${year}`,
            true,
            {}
        );
        setItems(data.data);
    }

    useEffect(() => {
        getItems(selectedYear.getFullYear());
    }, [selectedYear]);

    useEffect(() => {
        if (items.length) {
            const monthNames = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];

            const labels = items.map((item) => {
                const monthIndex = item.monthNumber - 1;
                return monthNames[monthIndex];
            });
            let data
            if(props.request){
              data = items.map((item) => item.requestsCount);
            }else {
                data = items.map((item) => item.registrationCount);
            }

            setChartLabels(labels);
            setChartData(data);
        }
    }, [items]);

    const handleYearChange = (date) => {
        setSelectedYear(date);
    };

    const { options, height, layout, width, id } = props;
    const data = {
        labels: chartLabels,
        datasets: [
            {
                ...props.datasets[0],
                data: chartData,
            },
        ],
    };

    return (
        <>
            <div className="d-flex flex-column">
                <label htmlFor="yearPicker">Выберите месяц:</label>
                <DatePicker
                    className="datepicker-custom"
                    id="yearPicker"
                    selected={selectedYear}
                    onChange={handleYearChange}
                    dateFormat="yyyy"
                    showYearPicker
                />
            </div>
            <ChartContainer className="parentContainer">
                <Line
                    id={id && id}
                    width={width}
                    data={data}
                    height={height}
                    options={{
                        ...options,
                        ...layout,
                    }}
                />
            </ChartContainer>
        </>
    );
};

LineChartYear.defaultProps = {
    height: 300,
    width: null,
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
        {
            data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
            borderColor: '#1ce1ac',
            borderWidth: 1,
            fill: false,
        },
    ],

    options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
        },
        legend: {
            display: false,
            labels: {
                display: false,
            },
        },
    },
};

LineChartYear.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    layout: PropTypes.object,
    width: PropTypes.number,
    options: PropTypes.object,
    id: PropTypes.string,
};