import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import ReactPaginate from "react-paginate";
import { Edit3, Search, PlusCircle, Trash2, Trello } from "react-feather";

import { PGET, DELETE } from "../../../api/adminApi";
import {CustomSvg} from "../../../utils/CustomSvg";

function Objectt() {
   const [items, setItems] = useState([]);
   const [pageCount, setPageCount] = useState(0);
   const [value, setValue] = useState("");
   const [currentPage, setCurrentPage] = useState(
      +localStorage.getItem("currentPageObjects") || 0
   ); //for numeration and saving currentPage after refresh

   const handleSearchInput = (e) => {
      setValue(e.target.value);
      setCurrentPageToZero();
   };

   function setCurrentPageToZero() {
      setCurrentPage(0);
      localStorage.setItem("currentPageObjects", 0);
   }

   function paginate(data) {
      setCurrentPage(data.selected);
      localStorage.setItem("currentPageObjects", data.selected); //for saving currentPage after refresh
   }

   async function getItems() {
      const data = await PGET("/services/admin/api/object-pageList", true, {
         page: currentPage,
         size: 10,
         search: value,
      });
      setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
      setItems(data.data);
   }

   async function deleteItem(id) {
      await DELETE("/services/admin/api/object/" + id);
      getItems();
   }

   useEffect(() => {
      getItems();
   }, [currentPage, value]); // eslint-disable-line react-hooks/exhaustive-deps

   return (
       <div
           className="br-5 card"
           style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}
       >
          <div className='card-header border-0 pt-5'>
             <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Объекты</span>
             </h3>
             <div
                 className='card-toolbar'
                 data-bs-toggle='tooltip'
                 data-bs-placement='top'
                 data-bs-trigger='hover'
                 title='Click to add a user'
             >
                <Link
                    to="/admin/object/create"
                    className='btn btn-sm btn-light-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_invite_friends'
                >
                   <CustomSvg path='/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                   Добавить новый
                </Link>
             </div>
          </div>

          <div className="px-3 pb-4 pt-2">
             <div className="mb-4 d-flex justify-content-between">
                <div className="position-relative">
                   <DebounceInput
                       minLength={3}
                       debounceTimeout={400}
                       value={value}
                       onChange={handleSearchInput}
                       type="text"
                       className="input-search search-icon br-5 "
                       size={24}
                       placeholder="Поиск"
                   />
                </div>
             </div>
             <div style={{ overflow: "auto", maxHeight: '75vh' }}>
               <table className='table bg-white table-rounded p-0 border gy-3 gs-7'>
                  <thead className='position-sticky top-0 rounded'>
                  <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                     <th style={{backgroundColor: '#E7372C', fontWeight: "700", borderTopLeftRadius: '8px', width: "30px"}} className="text-white text-center">№</th>
                     <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Название</th>
                     <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Описание</th>
                     <th className="text-center text-white" style={{backgroundColor: '#E7372C', fontWeight: "700", borderTopRightRadius: '8px'}}></th>
                     </tr>
                  </thead>

                  <tbody style={{ overflowX: "auto" }}>
                     {items.map((item, idx) => {
                        return (
                           <tr key={item.id} className='borderBottom'>
                              <td className="text-center">
                                 {currentPage === 0
                                    ? idx + 1
                                    : currentPage * 10 + idx + 1}
                              </td>
                              <td className="text-start">{item.name}</td>
                              <td className="text-start">{item.description}</td>
                              <td>
                                 <div className="d-flex justify-content-center">
                                    <td>
                                       <div className="d-flex justify-content-center">
                                          <Link
                                              to={"/admin/object/update/" + item.id}
                                              href='#'
                                              className='btn btn-icon shadow-sm btn-active-color-primary btn-sm me-1'
                                          >
                                             <CustomSvg path='/icons/duotune/art/art005.svg' className='svg-icon-3'/>
                                          </Link>
                                          <button
                                              onClick={() => deleteItem(item.id)}
                                              className='btn btn-icon shadow-sm btn-active-color-primary btn-sm'
                                          >
                                             <CustomSvg
                                                 path='/icons/duotune/general/gen027.svg'
                                                 className='svg-icon-3'
                                             />
                                          </button>
                                       </div>
                                    </td>
                                 </div>
                              </td>
                           </tr>
                        );
                     })}
                  </tbody>
               </table>
            </div>
            {pageCount > 1 && (
               <ReactPaginate
                  forcePage={currentPage}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1}
                  onPageChange={paginate}
                  containerClassName={"pagination"}
                  activeClassName={"bg-dark text-white"}
                  pageClassName={"page-item box-shadow"}
               />
            )}
         </div>
      </div>
   );
}

export default Objectt;
