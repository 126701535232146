export function formatMoney(amount, decimalCount = 0, decimal = ".", thousands = "’") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 0 : decimalCount; // 0 was 2

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || '`' + 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + '’' : '') + '' + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands)
     + (decimalCount ? decimal + '' + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    // return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands)
    //  + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
  }
}
export function formatPhone(phoneNumber) {
  if (phoneNumber) {
    var x = phoneNumber.match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
    return  !x[2] ? x[1] : '+' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] + '-' + x[4] + '-' + x[5] : '');
  }
}