import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useForm } from "react-hook-form";
import { DebounceInput } from 'react-debounce-input';
import InputMask from 'react-input-mask';

import { Navbar, Image } from 'react-bootstrap';
import { User, Plus, Minus, Clock, Tag, Star } from 'react-feather';
import Modal from 'react-bootstrap/Modal'

import { store } from '../../store';
import { increment, decrement } from '../../store/actions/basket'
import { GUESTPOST, GUESTGET } from '../../api/api'
import { formatMoney, formatPhone } from '../../helper'

const Header = () => {
	let location = useLocation()
	let history = useHistory()
	const wrapperRef = useRef(null);
	const wrapperRef2 = useRef(null);
	const [showBasket, setShowBasket] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [showModalMessage, setShowModalMessage] = useState(false)
	const [phone, setPhone] = useState(null)
	const [activationCode, setActivationCode] = useState(null)
	const basket = useSelector(state => state.basket)
	const branch = JSON.parse(localStorage.getItem('branch'))
	const [totalAmount, setTotalAmount] = useState(0)
	const { register, handleSubmit, formState: { errors } } = useForm({
		phone: null,
		activationCode: null
	});
	const [searchResult, setSearchResult] = useState([])
	const [searchContent, setSearchContent] = useState('')
	useOutsideAlerter(wrapperRef, wrapperRef2, searchResult);

	function useOutsideAlerter(ref, ref2, searchResult) {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target) && ref2.current && !ref2.current.contains(event.target)) {
					if (searchResult.length > 0) {
						setSearchResult([]);
						setSearchContent('')
					}
				}
			}
			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref, ref2, searchResult]);
	}

	async function login(data) {
		await GUESTPOST('/services/web/api/register-client', { phone: data.phone.replace(/[^0-9]/g, '') }).then(response => {
			setPhone(data.phone.replace(/[^0-9]/g, ''))
			setShowModal(false)
			setShowModalMessage(true)
		})
	}

	async function checkOtp() {
		const response = await GUESTPOST('/services/web/api/activate-client', { phone: phone, activationCode: activationCode.replace(/[^0-9]/g, '') })
		if (response.success) {
			const response2 = await GUESTPOST('/auth/login', { username: phone, password: activationCode.replace(/[^0-9]/g, '') })
			localStorage.setItem('access_token', response2.access_token)
			localStorage.setItem("tokenTime", JSON.stringify(new Date().getTime()));
			localStorage.setItem('user', JSON.stringify(
				{
					username: phone,
					password: activationCode.replace(/[^0-9]/g, ''),
					role: 'USER'
				}
			))
			setShowModalMessage(false)
		}
	}

	async function search(e) {
		setSearchContent(e.target.value)
		if (e.target.value.length > 2) {
			const data = await GUESTGET(`services/mobile/api/branch-product-search?search=${e.target.value}`, true)
			// setPageCount(Math.ceil(data.headers['x-total-count'] / 20))
			setSearchResult(data);
		} else {
			setSearchResult([]);
		}
	};

	useEffect(() => {
		var number = 0
		for (let i = 0; i < basket.length; i++) {
			number += basket[i].price * basket[i].quantity
		}
		setTotalAmount(number)
		setSearchResult([])
		setSearchContent('')
	}, [basket, location]);

	return (
		<div style={{ zIndex: '1000' }}>
			<Navbar color="light" expand='lg' className="navbar-light osahan-nav fixed-top shadow-sm tablet-none">
				<div className="container-fluid pr-0 ">
					<Link to="/"><div className="my-3"><Image src="img/logo.png" height={40} alt='' /></div></Link>
					<Navbar.Toggle />
					<div className="d-flex align-items-center position-relative search-width">
						<div className="mr-2 p-2 rounded-pill bg-light"><Image src="./img/seo.png" height={22} alt='' /></div>
						<div className="w-100">
							<div ref={wrapperRef} className="w-100">
								<DebounceInput minLength={3} debounceTimeout={400} onChange={(e) => search(e)} value={searchContent}
									type="text" placeholder="Поиск" className="header-input" style={{ background: '#F6F6F6' }} />
							</div>


							<div ref={wrapperRef2} className={"search scroll " + (searchResult.length > 3 ? 'pb-3' : '')}>
								{searchResult.map((item) => {
									return (
										<div key={item.branchId}>
											<div className="d-flex align-items-center">
												<img src={'https://colibri24.uz' + (item.branchImageUrl)} style={{ objectFit: 'cover' }}
													className="mr-3 h-max-content w-25" alt="" />
												<div>
													<div>
														<h5>
															{item.branchName}
														</h5>
													</div>
													<div className="d-flex flex-wrap justify-content-center align-items-center">
														<div className="d-flex mb-2">
															<span className="branch-content mr-2">
																<div className="d-flex mr-1"><Clock size={12} color={'#5C3EBA'} /></div>
																<div className="price-text">
																	{item.deliveryFrom + '–' + item.deliveryTo + ' min'}
																</div>
															</span>
															<span className="branch-content mr-2">
																<div className="d-flex mr-1"><Tag size={12} color={'#5C3EBA'} /></div>
																<div className="price-text text-center text-ellipsis">от {formatMoney(item.minProductPrice)} сум</div>
															</span>
														</div>
														<div className="d-flex mb-2">
															<span className="branch-content mr-2 price-text">
																<div className="	d-flex"> <Star size={12} color='#FFA91A' fill='#FFA91A' /></div>
																<div>{item.branchRating}</div>
															</span>
															<div className="status">
																Открыто
															</div>
														</div>
													</div>
												</div>
											</div>
											<div>
												{
													item.productList.map((item2, index) => {
														return (
															<Link key={index} to={'/detail/' + item.branchId}>
																<div className="d-flex justify-content-between px-3 border-top py-2">
																	<div className="font-weight-600">
																		{item2.name}
																	</div>
																	<div className="fz16 color-purple">
																		{formatMoney(item2.price)} {item2.currencyName}
																	</div>
																</div>
															</Link>
														)
													})
												}
											</div>
										</div>
									)
								})}
							</div>
						</div>


					</div>
					{localStorage.getItem('access_token') ?
						<Link to='/profile'>
							<button className={"btn btn-purple border-radius-21 " + (basket.length > 0 ? '' : 'mr-3')}>
								<div className="d-flex align-items-center">
									<User size={14} className="mr-1" />
									<span >Пользователь</span>
								</div>
							</button>
						</Link>
						:
						<button onClick={() => setShowBasket(false) + (phone !== null ? setShowModalMessage(true) : setShowModal(true))}
							className="btn btn-purple border-radius-21" style={basket.length === 0 ? { marginRight: '20px' } : {}}>
							<div className="d-flex align-items-center">
								<User size={14} className="mr-1" />
								<span >Войти</span>
							</div>
						</button>}
					{basket.length > 0 &&
						<div className="position-relative w-20 p-0">
							<div className={"h-100 py-4 px-5 cursor " + (showBasket ? 'basket-active' : '')} onClick={() => setShowBasket(!showBasket)} style={{ background: '#F6F6F6' }} >
								<div className="d-flex justify-content-center align-items-center">
									<Image className="mr-1" src="./img/shopping-basket.png" height={24} alt='' />
									<span className='text-purple fz18'>
										Корзинка
									</span>
								</div>
							</div>
							{showBasket ?
								<div className="basket">
									<div className="w-100 background-white p-2 d-flex justify-content-between align-items-center">
										<div>
											<span className="fz14">Ваш заказ в ресторане</span> <br />
											<span className="fz18 font-weight-bold">{branch.name}</span>
										</div>
										<div>
											<img src={'https://colibri24.uz' + (branch.imageUrl)} style={{ objectFit: 'cover' }} height="50" width="50" alt="" />
										</div>
									</div>
									<div className="overflow-auto last scroll" style={{ maxHeight: '100%' }}>
										{
											basket.map((item) => {
												return (
													<div key={item.id} className="basket-menu cursor row">
														<div className="col-3 p-0 fz14">
															<img src={'https://colibri24.uz' + (item.imageUrl)} style={{ objectFit: 'contain' }} className="br-5" height="42" width="42" alt="" />
														</div>
														<div className="col-5 p-0 fz14">{item.name} </div>
														<div className="col-4 p-0 d-flex flex-column align-items-end">
															<div>{formatMoney(item.price)} сум</div>
														</div>
														<div className="offset-3 p-0 col-9">
															<div className="d-flex align-items-center mb-3">
																<div className="p-1 bg-grey-light br-5 cursor">
																	<Plus onClick={() => store.dispatch(increment(item))} color="#5C3EBA" />
																</div>
																<div className="fz20 px-2 text-dark"> {item.quantity} </div>
																<div className="p-1 bg-grey-light br-5 cursor">
																	<Minus onClick={() => store.dispatch(decrement(item))} color="#5C3EBA" />
																</div>
															</div>
														</div>
													</div>
												)
											})
										}
										<div style={{ marginTop: '9rem' }}></div>
									</div>
									<div onClick={() => setShowBasket(false) + localStorage.getItem('access_token') ? history.push('/checkout') : setShowModal(true)}
										className="px-2 position-absolute w-100 mt-3 mt-auto" style={{ bottom: 10 }}	>
										<div>
											<button className="btn btn-purple p-3 w-100">
												<div className="d-flex justify-content-between font-weight-bold">
													<div>Заказать</div>
													<div>{formatMoney(totalAmount)} сум</div>
												</div>
											</button>
										</div>
									</div>
								</div>
								: ''
							}
						</div>
					}
				</div>
			</Navbar>
			<Navbar color="light" expand='lg' className="navbar-light py-0 fixed-top osahan-nav shadow-sm mobile-header">
				<div className="container-fluid pr-0">
					<Link to="/"><div className="my-3 mr-3"><Image src="img/logo.png" height={40} alt='' /></div></Link>
					<Navbar.Toggle className="my-3" />
					<Navbar.Collapse id="navbarNavDropdown">
						<div className="d-flex justify-content-between w-100">
							<div className="d-flex align-items-center position-relative w-40">
								<div className="mr-2 p-2 rounded-pill bg-light"><Image src="./img/seo.png" height={22} alt='' /></div>
								<div ref={wrapperRef} className="w-100">
									<div className="w-100">
										<DebounceInput minLength={3} debounceTimeout={400} onChange={(e) => search(e)} value={searchContent}
											type="text" placeholder="Поиск" className="header-input" style={{ background: '#F6F6F6' }} />
									</div>


									<div className={"search scroll " + (searchResult.length > 3 ? 'pb-3' : '')}>
										{searchResult.map((item) => {
											return (
												<div key={item.branchId}>
													<div className="d-flex align-items-center">
														<img src={'https://colibri24.uz' + (item.branchImageUrl)} style={{ objectFit: 'cover' }}
															className="mr-3 h-max-content w-25" alt="" />
														<div>
															<div>
																<h5>
																	{item.branchName}
																</h5>
															</div>
															<div className="d-flex flex-wrap justify-content-center align-items-center">
																<div className="d-flex mb-2">
																	<span className="branch-content mr-2">
																		<div className="d-flex mr-1"><Clock size={12} color={'#5C3EBA'} /></div>
																		<div className="price-text">
																			{item.deliveryFrom + '–' + item.deliveryTo + ' min'}
																		</div>
																	</span>
																	<span className="branch-content mr-2">
																		<div className="d-flex mr-1"><Tag size={12} color={'#5C3EBA'} /></div>
																		<div className="price-text text-center text-ellipsis">от {formatMoney(item.minProductPrice)} сум</div>
																	</span>
																</div>
																<div className="d-flex mb-2">
																	<span className="branch-content mr-2 price-text">
																		<div className="	d-flex"> <Star size={12} color='#FFA91A' fill='#FFA91A' /></div>
																		<div>{item.branchRating}</div>
																	</span>
																	<div className="status">
																		Открыто
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div>
														{
															item.productList.map((item2, index) => {
																return (
																	<Link key={index} to={'/detail/' + item.branchId}>
																		<div className="d-flex justify-content-between px-3 border-top py-2">
																			<div className="font-weight-600">
																				{item2.name}
																			</div>
																			<div className="fz16 color-purple">
																				{formatMoney(item2.price)} {item2.currencyName}
																			</div>
																		</div>
																	</Link>
																)
															})
														}
													</div>
												</div>
											)
										})}
									</div>
								</div>
							</div>
							<div className=" d-flex align-items-center justify-content-center">
								{localStorage.getItem('access_token') ?
									<Link to='/profile'>
										<button className="btn btn-purple border-radius-21">
											<div className="d-flex align-items-center">
												<User size={14} className="mr-1" />
												<span >Пользователь</span>
											</div>
										</button>
									</Link>
									:
									<button onClick={() => setShowBasket(false) + (phone !== null ? setShowModalMessage(true) : setShowModal(true))}
										className="btn btn-purple border-radius-21" style={basket.length === 0 ? { marginRight: '20px' } : {}}>
										<div className="d-flex align-items-center">
											<User size={14} className="mr-1" />
											<span >Войти</span>
										</div>
									</button>}
							</div>
							{basket.length > 0 &&
								<div className="position-relative w-25 p-0">
									<div className={"h-100 py-4 px-5 cursor " + (showBasket ? 'basket-active' : '')} onClick={() => setShowBasket(!showBasket)} style={{ background: '#F6F6F6' }} >
										<div className="d-flex justify-content-center align-items-center">
											<Image className="mr-1" src="./img/shopping-basket.png" height={24} alt='' />
											<span className='text-purple fz18'>
												Корзинка
											</span>
										</div>
									</div>
									{showBasket ?
										<div className="basket">
											<div className="w-100 background-white p-2 d-flex justify-content-between align-items-center">
												<div>
													<span className="fz14">Ваш заказ в ресторане</span> <br />
													<span className="fz18 font-weight-bold">{branch.name}</span>
												</div>
												<div>
													<img src={'https://colibri24.uz' + (branch.imageUrl)} style={{ objectFit: 'cover' }} height="50" width="50" alt="" />
												</div>
											</div>
											<div className="overflow-auto last scroll" style={{ maxHeight: '100%' }}>
												{
													basket.map((item) => {
														return (
															<div key={item.id} className="basket-menu cursor row">
																<div className="col-3 p-0 fz14">
																	<img src={'https://colibri24.uz' + (item.imageUrl)} style={{ objectFit: 'contain' }} className="br-5" height="42" width="42" alt="" />
																</div>
																<div className="col-5 p-0 fz14">{item.name} </div>
																<div className="col-4 p-0 d-flex flex-column align-items-end">
																	<div>{formatMoney(item.price)} сум</div>
																</div>
																<div className="offset-3 p-0 col-9">
																	<div className="d-flex align-items-center mb-3">
																		<div className="p-1 bg-grey-light br-5 cursor">
																			<Plus onClick={() => store.dispatch(increment(item))} color="#5C3EBA" />
																		</div>
																		<div className="fz20 px-2 text-dark"> {item.quantity} </div>
																		<div className="p-1 bg-grey-light br-5 cursor">
																			<Minus onClick={() => store.dispatch(decrement(item))} color="#5C3EBA" />
																		</div>
																	</div>
																</div>
															</div>
														)
													})
												}
												<div style={{ marginTop: '9rem' }}></div>
											</div>
											<div onClick={() => setShowBasket(false) + localStorage.getItem('access_token') ? history.push('/checkout') : setShowModal(true)}
												className="px-2 position-absolute w-100 mt-3 mt-auto" style={{ bottom: 10 }}	>
												<div>
													<button className="btn btn-purple p-3 w-100">
														<div className="d-flex justify-content-between font-weight-bold">
															<div>Заказать</div>
															<div>{formatMoney(totalAmount)} сум</div>
														</div>
													</button>
												</div>
											</div>
										</div>
										: ''
									}
								</div>
							}
						</div>
					</Navbar.Collapse>
				</div>
			</Navbar>
			<Modal
				show={showModal}
				onHide={() => setShowModal(false)}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header className="border-none pb-0" closeButton>
				</Modal.Header>
				<Modal.Body className="px-4 pb-4 pt-0 ">
					<h2>Вход по телефону</h2>
					<form autoComplete='off' method="POST" onSubmit={handleSubmit(login)} action=''>
						<div className="position-relative">
							<div className="login-label">
								Телефон:
							</div>
							<InputMask mask='+999-99-999-99-99' {...register("phone", { required: true, minLength: 4 })}  // value={phone == null ? '' : phone} onChange={(e) => setPhone(e.target.value)}
								type="text" placeholder="+998-88-888-88-88" className="login-input" />
							{errors.phone?.type === 'required' && <div className='text-danger mt-2'>Обязательное поле</div>}
							<div className="fz14 my-3">
								Указывая номер телефона, вы принимаете условия <span className="cursor color-primary">пользовательского соглашения</span>
							</div>
							<button type='submit' className="btn btn-purple p-btn br-10 w-100 mt-3">
								Получить СМС код
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			<Modal
				show={showModalMessage}
				onHide={() => setShowModalMessage(false)}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header className="border-none pb-0" closeButton>
				</Modal.Header>
				<Modal.Body className="px-4 pb-4 pt-0 ">
					<h2>Введите СМС код</h2>
					<div className="fz12 text-center color-grey my-3">
						На номер <span className="cursor color-primary">{formatPhone(phone)} </span>
						отправлено СМС-сообщение с кодом подтверждения. Обычно оно приходит в теченение 3-х минут.
					</div>
					<div className="position-relative text-center">
						<div className="message-label">
							Код активации:
						</div>
						<InputMask mask='9 9 9 9 9 9' value={activationCode == null ? '' : activationCode} onChange={(e) => setActivationCode(e.target.value)}
							type="text" placeholder="_ _ _ _ _ _" className="message-input" />
					</div>
					<div className="position-relative">
						<button onClick={() => checkOtp()} className="btn btn-purple p-btn br-10 w-100 mt-3">
							Войти
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* <Modal
				// style='z-index:8'
				// backdrop={false}
				backdropClassName='basket-modal-backdrop'
				show={showBasket}
				// backdrop="static"
				onHide={() => setShowBasket(false)}
				contentClassName="br-30"
				className="basket-modal"
				dialogClassName="modal-90w m-0 basket-modal-position "
				aria-labelledby="example-custom-modal-styling-title"
			>
				<Modal.Header className="border-none px-4 pt-4 pb-0" closeButton />
				<Modal.Body className="border-none pt-0">
					<p>
					</p>
					<div className="container-fluid m-auto">
						<div className="row">
							<div className="col-lg-12 h-100 px-3 pb-3 bg-white  border-radius-21">
								<div className="d-flex">
									<div className="mr-2">
										<img src={'https://colibri24.uz' + (basketProduct.imageUrl)}
											alt="" className="border-radius-15 obj-fit-cover mb-4" height="300" width="375" />
										<span className="d-flex rounded-text">
											<div className="d-flex mr-2"><Star size={18} color='#FFA91A' fill='#FFA91A' /></div>
											<div className="w-fit-content">4.7  •  99 отзывов</div>
										</span>
									</div>
									<div className="w-100">
										<div className="d-flex flex-column h-100 justify-content-between">
											<div>
												<h4>{basketProduct.name}</h4>
												<p>{basketProduct.description}
												</p>
												<h4>Ингредиенты</h4>
											<div className="d-flex justify-content-between mb-3">
												<div className="d-flex align-items-center">
													<input type="checkbox" className="mr-2" />
													<span>Наименование ингредиента</span>
												</div>
												<span className="font-weight-bold">10`000 сум</span>
											</div>
											<div className="d-flex justify-content-between mb-3">
												<div className="d-flex align-items-center">
													<input type="checkbox" className="mr-2" />
													<span>Наименование ингредиента</span>
												</div>
												<span className="font-weight-bold">10`000 сум</span>
											</div>
											<div className="d-flex justify-content-between mb-3">
												<div className="d-flex align-items-center">
													<input type="checkbox" className="mr-2" />
													<span>Наименование ингредиента</span>
												</div>
												<span className="font-weight-bold">10`000 сум</span>
											</div>
											<div className="d-flex justify-content-between mb-3">
												<div className="d-flex align-items-center">
													<input type="checkbox" className="mr-2" />
													<span>Наименование ингредиента</span>
												</div>
												<span className="font-weight-bold">10`000 сум</span>
											</div>
											<div className="d-flex justify-content-between mb-3">
												<div className="d-flex align-items-center">
													<input type="checkbox" className="mr-2" />
													<span>Наименование ингредиента</span>
												</div>
												<span className="font-weight-bold">10`000 сум</span>
											</div>
												<div className="d-flex align-items-center mb-3">
													<div className="p-1 bg-grey-light br-5 cursor">
														<Plus onClick={() => store.dispatch(increment(basketProduct))} color="#5C3EBA" />
													</div>
													<div className="fz20 px-2 text-dark"> {basketProduct.quantity} </div>
													<div className="p-1 bg-grey-light br-5 cursor">
														<Minus onClick={() => store.dispatch(decrement(basketProduct))} color="#5C3EBA" />
													</div>
												</div>
											</div>
											<button className="btn btn-purple p-btn p-2 d-flex justify-content-between">
												<span className="font-weight-600">Заказать</span>
												<span>{formatMoney(basketProduct.price * basketProduct.quantity)} сум</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal> */}
		</div>

	);
}



export default Header;