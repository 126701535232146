import { toast } from 'react-toastify';
// import { showModal } from '../actions/modal'
// import { store } from '../../store';

const basketReducer = function (state = [], action) {
  switch (action.type) {
    case "INBASKET":
      if (state.length === 0) {
        localStorage.setItem('branch', JSON.stringify(action.payload.branch))
        if (!action.payload.item.quantity) {
          action.payload.item.quantity = 1
        }
        return ([...state, action.payload.item])
      } else {
        const branch = JSON.parse(localStorage.getItem('branch'))
        if (branch.id === action.payload.branch.id) {
          var found = false
          for (let i = 0; i < state.length; i++) {
            if (state[i]['id'] === action.payload.item.id) {
              action.payload.item.quantity = 1 + parseInt(action.payload.item.quantity)
              found = true;
              toast.success('Продукт добавлен в корзину')
              return state
            }
          }
          if (!found) {
            if (!action.payload.item.quantity) {
              action.payload.item.quantity = 1
            }
            toast.success('Продукт добавлен в корзину')
            return ([...state, action.payload.item])
          }
        } else {
          toast.error('Ошибка! Завершите прошлый заказ ')
          // store.dispatch(showModal());
          return state
        }
      }
    /* falls through */
    case "DELETEBASKET":
      localStorage.removeItem('branch')
      return state.slice(0, action.payload)
    /* falls through */

    case "CLEARBASKET":
      localStorage.removeItem('basket')
      return state = []
    /* falls through */

    case "INCREMENT":
      for (let i = 0; i < state.length; i++) {
        if (action.payload.id === state[i].id) {
          state[i].quantity = parseInt(state[i].quantity) + 1
        }
      }
      return JSON.parse(JSON.stringify(state))
    /* falls through */

    case "DECREMENT":
      for (let i = 0; i < state.length; i++) {
        if (action.payload.id === state[i].id) {
          if (state[i].quantity <= 1) {
            return JSON.parse(JSON.stringify(state))
            // return state.slice(0, state[i])
          }
          state[i].quantity = parseInt(action.payload.quantity) - 1
        }
      }
      return JSON.parse(JSON.stringify(state))
    /* falls through */
    default:
      return state;
    /* falls through */
  }
};

export default basketReducer