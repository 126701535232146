/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {CustomSvg} from "../../../utils/CustomSvg";

const StatisticsCard = (
    {
        className,
        color,
        svgIcon,
        iconColor,
        title,
        titleColor,
        description,
        descriptionColor,
    }) => {
    return (<div className={`card hoverable bg-white ${className}`}>
        <div className="">
            <div className='d-flex align-items-center'>
                <div style={{backgroundColor: `${color}`, borderBottomLeftRadius: '6px', borderTopLeftRadius: '6px'}} className='px-8 py-5'>
                    <CustomSvg path={svgIcon} className={`svg-icon-${iconColor} svg-icon-4x ms-n1`}/>
                </div>

                <div style={{marginLeft: "40px"}}>
                    <div className={`text-black fw-bold fs-2 mb-0 mt-0`}>{title}</div>

                    <div style={{color: `${color}`}} className={`fw-semibold`}>{description}</div>
                </div>
            </div>
        </div>
    </div>);
};

export {StatisticsCard};
