import React, {useEffect, useState} from "react";
import {PGET} from "../../../api/adminApi";
import {Bar} from "react-chartjs-2";
import PropTypes from "prop-types";

export const KotelMonthDashboard = props => {
    const {labels, options, height, layout} = props;
    const [dashboardData, setDashboardData] = useState([]);
    async function getDashboardMonthCounts() {
        const data = await PGET("/services/admin/api/dashboard-month-counts-airfel/", true, {regionId: 0});
        if (data) {
            setDashboardData(data.data?.dashboardChartByMonthCountSales);
        }
    }

    const data = {
        labels,
        datasets: [
            {
                label: "Продан",
                data: dashboardData?.map((item, i) => item.soldCount),
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
                label: "Установлен",
                data: dashboardData?.map((item, i) => item.mountedCount),
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            }
        ],
    };

    useEffect(() => {
        getDashboardMonthCounts();
    }, []);
    return (
        <div>
            <Bar data={data} height={height} options={{...options, ...layout}}/>
        </div>
    )
};
KotelMonthDashboard.defaultProps = {
    labels: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    datasets: [
        {
            data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
            backgroundColor: 'rgb(54,122,215, 0.9)',
            barPercentage: 0.6,
        },
        {
            data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
            backgroundColor: 'rgba(250,0,54,0.5)',
            barPercentage: 0.6,
        },
    ],

    options: {
        maintainAspectRatio: true,
        responsive: true,
        layout: {
            padding: {
                left: '0',
                right: 0,
                top: 0,
                bottom: '0',
            },
        },
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
            },
        },
        // scales: {
        //     yAxes: [
        //         {
        //             gridLines: {
        //                 color: '#e5e9f2',
        //             },
        //             ticks: {
        //                 beginAtZero: true,
        //                 fontSize: 10,
        //                 fontColor: '#182b49',
        //                 max: 80,
        //             },
        //         },
        //     ],
        //     xAxes: [
        //         {
        //             gridLines: {
        //                 display: false,
        //             },
        //
        //             ticks: {
        //                 beginAtZero: true,
        //                 fontSize: 11,
        //                 fontColor: '#182b49',
        //             },
        //         },
        //     ],
        // },
    },
};

KotelMonthDashboard.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.object,
    layout: PropTypes.object,
};