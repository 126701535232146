import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';

function SignOut() {
  let history = useHistory()
  useEffect(() => {
		localStorage.removeItem('tokenTime')
    localStorage.removeItem('access_token')
    localStorage.removeItem('user')
    history.push('/')
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      
    </div>
  )
}

export default SignOut
