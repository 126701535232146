import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import ReactPaginate from 'react-paginate';
import { Edit3, } from 'react-feather';
import Select from 'react-select';

import { GET, PUT, PGET } from '../../../api/adminApi'

function Telegram() {
	const [data, setData] = useState({})
	const [items, setItems] = useState([])
	const [brands, setBrands] = useState([])
	const [branchs, setBranchs] = useState([])

	const [showModal, setShowModal] = useState(false)
	const [pageCount, setPageCount] = useState(0)

	async function getItems() {
		const data = await PGET('/services/admin/api/tg-group-pageList', true, { page: 0, size: 20 })
		setPageCount(Math.ceil(data.headers['x-total-count'] / 20))
		setItems(data.data)
	}

	async function getItem(item) {
		setData(item)
		if(item.brandId) {
			getBranchs(item.brandId)
		}
		setShowModal(true)
	}

	async function paginate(data) {
		const response = await PGET('/services/admin/api/units-pageList', true, { page: data.selected, size: 20 })
		setItems(response.data);
	}

	async function getBrands() {
		const response = await GET('/services/admin/api/brand-helper', false)
		if (response.length > 0) {
			setBrands(response)
			setShowModal(false)
		}
	}

	async function editItem(e) {
		e.preventDefault();
		
		const response = await PUT('/services/admin/api/tg-group', data)
		if (response) {
			getItems()
		}
	}

	function selectBrand(selectedOption) {
		setData({ ...data, brandId: selectedOption.id })
		getBranchs(selectedOption.id)
	}

	async function getBranchs(brandId) {
		const response = await GET('/services/admin/api/branch-helper/' + brandId)
		if (response.length > 0) {
			setBranchs(response)
		} else {
			setBranchs([])
		}
	}

	useEffect(() => {
		getItems()
		getBrands()
	}, [])

	return (
		<>
			<div className="background-white my-2 mx-3 box-shadow br-5">
				<div className="fz20 border-bottom pl-3 py-3 my-2 d-flex align-items-center">
					Телеграм группы
				</div>
				<div className='px-3 pb-4 pt-2'>
					<div style={{ overflowX: 'auto' }}>
						<table className="table table-bordered">
							<thead>
								<tr className='backgroung-grey'>
									<th>Название группы</th>
									<th>Кто создал</th>
									<th>Ресторан</th>
									<th className="text-center">Действие</th>
								</tr>
							</thead>

							<tbody style={{ overflowX: 'auto' }}>
								{items.map((item) => {
									return (
										<tr key={item.id}>
											<td>{item.groupName}</td>
											<td className="vertical-middle">
												{item.inviteUserFirstName} {item.inviteUserLastName} <br />
												{item.inviteUserUserName}
											</td>
											<td className="vertical-middle">
												{item.brandName} <br />
												{item.branchName}
											</td>
											<td className="vertical-middle">
												<div className="d-flex justify-content-center">
													<button onClick={() => getItem(item)}
														className="btn btn-table" style={{ backgroundColor: '#F4F4F5' }}>
														<Edit3 color={'#189ED3'} size={16} />
													</button>
												</div>
											</td>
										</tr>
									)
								})
								}
							</tbody>
						</table>
					</div>
					{
						pageCount > 1 &&
						<ReactPaginate
							pageCount={pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={5}
							onPageChange={paginate}
							containerClassName={'pagination'}
							activeClassName={'active'}
							pageClassName={'page-item'}
						/>
					}
				</div>
			</div>

			<Modal
				show={showModal}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				onHide={() => setShowModal(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						Прикрепите ресторан
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form autoComplete='off' autoCorrect='off' onSubmit={editItem} >
						<div className='d-flex flex-column w-100'>
							<div className='p-2'>
								<div className="row mb-3">
									<div className="col-md-2">
										<label className="vertical-center text-nowrap color-grey mr-2">Бренд</label>
									</div>
									<div className="col-md-10">
										<div className="w-100">
											<Select
												placeholder="Выберите бренд"
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
												options={brands}
												value={brands.find(option => option.id === data.brandId) || ''}
												onChange={selectBrand}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-2">
										<label className="vertical-center text-nowrap color-grey mr-2">Филиал</label>
									</div>
									<div className="col-md-10">
										<div className="w-100">
											<Select
												placeholder="Выберите филиал"
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
												options={branchs}
												value={branchs.find(option => option.id === data.branchId) || ''}
												onChange={(option) => setData({ ...data, 'branchId': option.id })}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='p-2 w-100'>
							<button type='submit' className='btn btn-outline-purple w-100'>Сохранить</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default Telegram
