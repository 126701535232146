import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import moment from 'moment'
import { XCircle, Save, Calendar } from 'react-feather';

import { GET, POST, PUT } from '../../../api/adminApi'
import { showLoader, hideLoader } from '../../../store/actions/loader';
import { store } from '../../../store';

import "react-datepicker/dist/react-datepicker.css";

function CompanyAddUpdate() {
	let { id } = useParams();
	let history = useHistory();
	const [countries, setСountries] = useState([])
	const [data, setData] = useState({
		activated: true,
		gender: true,
	})
	const [pickedDate, setPickedDate] = useState(new Date());
	const { register, handleSubmit, formState: { errors }, setValue } = useForm({
		activated: true,
		birthday: "",
		companyName: "",
		countryId: 1,
		email: "",
		gender: 1,
		login: "",
		password: "",
		firstName: "",
		lastName: "",
		patronymicName: ""
	});
	const lastDateOfYear = `12/31/${new Date().getFullYear()}`;

	async function getProduct() {
		const response = await GET('/services/admin/api/company/' + id, true)
		for (let key in response) {
			setValue(`${key}`, response[key])
		}
		setData({ ...data, activated: response.activated, gender: response.gender === 0 ? true : false })
	}

	async function getCountries() {
		store.dispatch(showLoader())
		const response = await GET('/services/admin/api/country-pageList', false)
		setСountries(response)
		setValue('countryId', response[0].id)
		if (id) {
			await getProduct()
		} else {
			setValue('gender', 0)
			setValue('activated', true)
		}
		store.dispatch(hideLoader())
	}

	async function create(data) {
		var response;
		if (id) {
			response = await PUT('/services/admin/api/company', data)
		}
		else {
			response = await POST('/services/admin/api/company', data)
		}
		console.log(response);
		if (response) {
			history.push('/admin/company')
		}
	}

	const CustomInput = React.forwardRef((props, ref) => {
		return (
			<div onClick={props.onClick} ref={ref} className='position-relative cursor' >
				<input {...register("birthday", { required: true })} className="input-control" value={props.value || props.placeholder} />
				<div className='position-absolute' style={{ top: '7px', right: 10 }}>
					<Calendar size={18} />
				</div>
			</div>
		);
	});

	useEffect(() => {
		getCountries()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="mb-2 mx-3 ">
			<h3 className="mb-3 mt-1">Добавить новый</h3>
			<div className="admin-card p-4" >
				<form autoComplete='off' autoCorrect='off' onSubmit={handleSubmit(create)}>
					<div className="row">
						<div className="col-lg-4 offset-lg-2">
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Фамилия</label></div>
									<input {...register("lastName", { required: true })} type="text" placeholder="Фамилия" className="input-control" />
								</div>
								{errors.lastName?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Имя</label></div>
									<input {...register("firstName", { required: true })} type="text" placeholder="Имя " className="input-control" />
								</div>
								{errors.firstName?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Отчество</label></div>
									<input {...register("patronymicName", { required: true })} type="text" placeholder="Отчество " className="input-control" />
								</div>
								{errors.patronymicName?.type === 'required' && <div className='text-danger text-end mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3">
										<label className="mb-0">Пол</label>
									</div>
									<div className="w-100 d-flex justify-content-center">
										<input type="radio" onChange={(e) => setData({ ...data, gender: true }) + setValue('gender', 0)}
											checked={data.gender} id="man" name='gender' className="custom-rb" />
										<label className="mb-0" htmlFor="man">Мужской</label>
										<div className="mr-3"></div>
										<input type="radio" onChange={(e) => setData({ ...data, gender: false }) + setValue('gender', 1)}
											checked={!data.gender} id="women" name='gender' className="custom-rb" />
										<label className="mb-0" htmlFor="women">Женский</label>
									</div>
								</div>
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">День рождение</label></div>
									{/* <input {...register("birthday", { required: true })} type="date" className="input-control" /> */}
									<DatePicker
										customInput={<CustomInput />}
										className='input-control'
										showYearDropdown
										dateFormatCalendar="MMMM"
										yearDropdownItemNumber={60}
										scrollableYearDropdown
										maxDate={new Date(lastDateOfYear)}
										// maxDate={moment().startOf('year')}
										dateFormat="yyyy-MM-dd"
										selected={pickedDate}
										onChange={(date) => (setPickedDate(date) + setValue('birthday', moment(date).format("YYYY-MM-DD")))} />
								</div>
								{errors.birthday?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
						</div>
						<div className="col-lg-4">
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0 text-right">Имя пользователя</label></div>
									<input {...register("login", { required: true })} autoCorrect="off" type="text" placeholder="Имя пользователя " className="input-control" />
								</div>
								{errors.login?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Пароль</label></div>
									<>
										<input {...register("password", { required: true, })} type="password" placeholder="Пароль" className="input-control" />
									</>
								</div>
								{errors.password?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
								{errors.password?.type === 'maxLength' && <div className='text-danger mt-1 fz12'>Максимальное значение 3</div>}
								{/* {
									id ? <input {...register("password")} type="password" placeholder="Пароль" className="input-control" />
										:
										<>
											<input {...register("password", { required: true, maxLength: 3 })} type="password" placeholder="Пароль" className="input-control" />
											{errors.password?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
											{errors.password?.type === 'maxLength' && <div className='text-danger mt-1 fz12'>Максимальное значение 3</div>}
										</>
								} */}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0 text-right">Электронная почта</label></div>
									<input {...register("email", { required: true, pattern: /^\S+@\S+$/i })} type="text" placeholder="Электронная почта" className="input-control" />
								</div>
								{errors.email?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
								{errors.email?.type === 'pattern' && <div className='text-danger mt-1 fz12'>Не валидный email</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0 text-right">Название компании</label></div>
									<input {...register("companyName", { required: true })} type="text" placeholder="Название компании" className="input-control" />
								</div>
								{errors.companyName?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field align-items-center">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Страна</label></div>
									<select {...register("countryId", { required: true })} onChange={(e) => setValue('countryId', e.target.value)} className="border border-radius-3 box outline-none color-grey p-2" style={{ width: '100%' }} name="" id="">
										{
											countries.map((item) => {
												return (
													<option key={item.id} value={item.id}>{item.nameRu} </option>
												)
											})
										}
									</select>
								</div>
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className='admin-label'><label className="mb-0">Статус</label></div>
									<div className="w-100 d-flex justify-content-center">
										<input type="radio" onChange={(e) => setData({ ...data, activated: true }) + setValue('activated', true)}
											checked={data.activated} id="activated-1" name='activated' className="custom-rb " />
										<label className="mb-0" htmlFor="activated-1">Активный</label>
										<div className="mr-3"></div>
										<input type="radio" onChange={(e) => setData({ ...data, activated: false }) + setValue('activated', false)}
											checked={!data.activated} id="deactivated-1" name='activated' className="custom-rb" />
										<label className="mb-0" htmlFor="deactivated-1">Заблокирован</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex btn-postion">
						<Link to='/admin/company' className="mr-3"><button className="btn btn-outline-purple d-flex align-items-center"><XCircle size={14} className="mr-2" />Отмена</button></Link>
						<button type='submit' className="btn btn-purple d-flex align-items-center"><Save size={14} className="mr-2" />Сохранить </button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default CompanyAddUpdate;
