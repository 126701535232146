import moment from "moment";

export const DATETIME_FORMAT = 'DD.MM.YYYY (HH:mm)'
export const FULL_DATE = 'YYYY-MM-DD'

export function getDateTime(date = new Date()) {
    return moment(date).format(DATETIME_FORMAT)
}
export function getFullDate(date = new Date()) {
    return moment(date).format(FULL_DATE)
}