import React, {useEffect, useState} from "react";
import {useHistory, Link, useParams} from "react-router-dom";
import {XCircle, Save} from "react-feather";
import {GET, POST, PUT} from "../../../api/adminApi";
import {CustomSvg} from "../../../utils/CustomSvg";
import Select from "react-select";
import {value} from "lodash/seq";
import {toast} from "react-toastify";

export default function PollCreate() {
    const {id} = useParams();
    const history = useHistory();
    const [selectedOption, setSelectedOption] = useState(null);
    const [inputs, setInputs] = useState([{ id: 1, value: '' }]);
    const [msg, setMsg] = useState('');
    const options = [
        {
            id: 1,
            label: "Все"
        },
        {
            id: 2,
            label: "Спящие"
        }
    ]
    const handleInputChange = (id, newValue) => {
        const updatedInputs = inputs.map(input =>
            input.id === id ? { ...input, value: newValue } : input
        );
        setInputs(updatedInputs);
    };
    const addNewInput = () => {
        const newId = inputs.length > 0 ? inputs[inputs.length - 1].id + 1 : 1;
        setInputs([...inputs, { id: newId, value: '' }]);
    };
    const deleteInput = (id) => {
        const updatedInputs = inputs.filter(input => input.id !== id);
        setInputs(updatedInputs);
    };
    const handleChange = (option) => {
        setSelectedOption(option)
    }
    function handleMessageChange(e) {
        setMsg(e.target.value);
    }
    function onSubmit(e) {
        e.preventDefault();
        const optionsList = inputs.map(value => ({ optionsText: value.value }));
        async function create() {
            if (id) {
                const response = await PUT("/services/admin/api/bulk-poll", {
                    id: parseInt(id),
                    messageText: msg,
                    sendTo: selectedOption.id,
                    optionsList: optionsList
                });
                if (response?.success) {
                    history.push("/admin/poll");
                }
            } else {
                const response = await POST("/services/admin/api/bulk-poll", {
                    messageText: msg,
                    sendTo: selectedOption.id,
                    optionsList: optionsList
                });
                if (response?.success) {
                    history.push("/admin/poll");
                    toast.success("Успешно добавлен");
                }
            }
        }
        create()
    }
    async function getData() {
        const response = await GET("/services/admin/api/bulk-poll/" + id);
        if (response) {
            const {
                messageText,
                sendTo,
                optionsList,
            } = response;
            setMsg(messageText)

            const selectedOptionFromResponse = options.find(option => option.id === sendTo);
            setSelectedOption(selectedOptionFromResponse);

            const newInputs = optionsList.map((option, index) => ({
                id: index + 1,
                value: option.optionsText
            }));
            setInputs(newInputs);
        }
    }
    useEffect(() => {
        if (id) {
            getData();
        }
    }, []);
    return (
        <div className="mb-1 mx-3 ">
            <h3 className="mb-5 mt-5">
                Создать опросник
            </h3>
            <form onSubmit={onSubmit} autoComplete="off">
                <div className="admin-card p-4 pt-12 overflow-y-auto d-flex flex-column justify-content-between">
                    <div className="mb-1 d-flex">
                        <div className="">
                            <div
                                className="d-flex align-items-center mb-2 gap-2"
                            >
                                <label
                                        style={{width: "120px"}}
                                        className="color-grey text-end text-nowrap"
                                    >
                                        Группа
                                    </label>
                                <Select
                                    name="sendTo"
                                    className="w-300px"
                                    options={options}
                                    value={selectedOption}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Вопрос
                                </label>
                                <div>
                           <textarea
                               value={msg}
                               name="messageText"
                               type="text"
                               className="input-control"
                               onChange={handleMessageChange}
                               style={{width: "300px"}}
                           />
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                {inputs.map((input, index) => (
                                    <div className="d-flex gap-2 mb-2" key={input.id}>
                                        <label
                                            style={{width: "120px"}}
                                            className="color-grey text-end text-nowrap"
                                        >
                                            Ответ №{index + 1}
                                        </label>
                                        <div className="d-flex">
                                            <input
                                                type="text"
                                                className="input-control"
                                                value={input.value}
                                                onChange={e => handleInputChange(input.id, e.target.value)}
                                            />
                                            <button
                                                style={{height: 30, width: 30}}
                                                className="w-30px border-0 bg-red ml-2 text-white btn-bg-danger d-flex align-items-center justify-content-center align-self-end"
                                                onClick={() => deleteInput(input.id)}>
                                                <CustomSvg color="white" path='/icons/duotune/general/gen027.svg' className='svg-icon-white svg-icon-3'/>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                <button
                                    type={"button"}
                                    style={{height: 30, width: 30}}
                                    className="w-30px border-0 bg-green ml-2  text-white btn-bg-success d-flex align-items-center justify-content-center align-self-end"
                                    onClick={addNewInput}>
                                    +
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <Link to="/admin/poll" className="mr-3">
                            <button className="btn text-white btn-bg-primary d-flex align-items-center">
                                <XCircle size={14} className="mr-2"/>
                                Отмена
                            </button>
                        </Link>
                        <button
                            className="btn text-white btn-bg-success d-flex align-items-center"
                            type="submit"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
        ;
}

