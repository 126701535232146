import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import ReactPaginate from "react-paginate";
import {DELETE, PGET, PUT} from "../../../api/adminApi";
import {CustomSvg} from "../../../utils/CustomSvg";
import MasterDeleteModal from "../Masters/MasterDeleteModal";
import {getDateTime} from "../../../utils/Dates";

export default function Bulk() {
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(+localStorage.getItem("currentPageMasters") || 0); //for numeration and saving currentPage after refresh
    const [deleteModal, setDeleteModal] = useState({
        show: false, master: null,
    });
    const paginate = (data) => {
        setCurrentPage(data.selected);
        localStorage.setItem("currentPageMasters", data.selected); //for saving currentPage after refresh
    };

    async function getItems() {
        const data = await PGET("/services/admin/api/bulk-pageList", true, {
            region: 0, page: currentPage, size: 10
        });
        setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
        setItems(data.data);
    }
    async function deleteItem(id) {
        await DELETE("/services/admin/api/bulk/" + id);
        getItems();
    }
    async function sendItem(id) {
        await PUT("/services/admin/api/bulk-approve/" + id);
        getItems();
    }
    useEffect(() => {
        getItems();
    }, [currentPage]);
    console.log(items)
    return (<div
        className="br-5 card"
        style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}
    >
        <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Рассылки</span>
            </h3>
            <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to add a user'
            >
                <Link
                    to="/admin/bulk-create"
                    className='btn btn-sm btn-light-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_invite_friends'
                >
                    <CustomSvg path='/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                    Добавить новый
                </Link>
            </div>
        </div>
        <div className="px-3 pb-4 pt-2">
            <div style={{ overflow: "auto", maxHeight: '75vh' }}>
                <table className='table bg-white table-rounded p-0 border gy-4 gs-7'>
                    <thead className='position-sticky top-0 rounded'>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                        <th  style={{width: "30px", backgroundColor: '#E7372C', fontWeight: "700", borderTopLeftRadius: '8px'}} className="text-white text-center">№</th>
                        <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Кому</th>
                        <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Сообщения</th>
                        <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Время создания</th>
                        <th className="text-center text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Статус</th>
                        <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Время отправки</th>
                        <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700", borderTopRightRadius: '8px'}}></th>
                    </tr>
                    </thead>
                    <tbody style={{overflowX: "auto"}}>
                    {items.map((item, index) => {
                        return (<tr key={item.id} className='borderBottom'>
                            <td className="text-center">
                                {currentPage === 0 ? index + 1 : currentPage * 10 + index + 1}
                            </td>
                            <td className="text-start">
                                {item.sendTo === 1 ? "Всем" : "Спящим"}
                            </td>
                            <td className="text-start">
                                {item.messageText}
                            </td>
                            <td className="text-start">
                                {getDateTime(item.createdTime)}
                            </td>
                            <td className="text-start w-10">
                                <b
                                    style={{
                                        whiteSpace: "nowrap",
                                    }}
                                    className={`p-2 badge rounded font-weight-500 d-block ${
                                        item.status === '0'
                                            ? "badge-warning"
                                            :  item.status === '1'
                                                ? "badge-success"
                                                :  item.status === '3'
                                                    ? "badge-danger"
                                                    : "badge-primary"
                                    }`}
                                >
                                    {item.status === '0' ? 'В ожидании' : null}
                                    {item.status === '1' ? 'Отправляется' : null}
                                    {item.status === '2' ? 'Отправлен' : null}
                                </b>
                            </td>
                            <td className="text-start">
                                {item.approvedTime ? getDateTime(item.approvedTime) : null}
                            </td>
                            <td className="text-end">
                                <div className='d-flex'>
                                    <Link
                                        to={"/admin/bulk-update/" + item.id}
                                        href='#'
                                        className='btn btn-icon shadow-sm btn-active-color-primary btn-sm mr-2'
                                    >
                                        <CustomSvg path='/icons/duotune/art/art005.svg' className='svg-icon-3'/>
                                    </Link>
                                    <button
                                        onClick={() => deleteItem(item.id)}
                                        className='btn btn-icon shadow-sm btn-active-color-primary btn-sm mr-2'
                                    >
                                        <CustomSvg
                                            path='/icons/duotune/general/gen027.svg'
                                            className='svg-icon-3'
                                        />
                                    </button>
                                    <button
                                        onClick={() => sendItem(item.id)}
                                        className='btn btn-icon shadow-sm btn-active-color-primary btn-sm'
                                    >
                                        <CustomSvg
                                            path='/icons/duotune/general/gen037.svg'
                                            className='svg-icon-3'
                                        />
                                    </button>
                                </div>
                            </td>
                        </tr>);
                    })}
                    </tbody>
                </table>
            </div>
            {pageCount > 1 && (<ReactPaginate
                forcePage={currentPage}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                onPageChange={paginate}
                containerClassName={"pagination"}
                activeClassName={"bg-dark text-white"}
                pageClassName={"page-item box-shadow br-5"}
            />)}
        </div>

        {deleteModal.master && (<MasterDeleteModal
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            getItems={getItems}
        />)}
    </div>);
}

