import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom';
import { ChevronLeft, Clock, Tag, Star, ShoppingBag, Heart, Plus, Minus, Coffee, Filter } from 'react-feather';
// import { HashLink } from 'react-router-hash-link';
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify';

import { store } from '../../store';
import { inBasket, increment, decrement } from '../../store/actions/basket'
import { GUESTGET, POST } from '../../api/api'
import { formatMoney } from '../../helper'

function Detail() {
  let { id } = useParams();
  const [branch, setBranch] = useState([])
  const [firstSession, setFirstSession] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [basketProduct, setBasketProduct] = useState(false)
  const [sectionList, setSectionList] = useState([])
  const myRef = []
  const basket = useSelector(state => state.basket)
  // const [inBasket, setInBasket] = useState(false)

  async function getBranch() {
    const response = await GUESTGET('/services/web/api/branch/' + id, true)
    for (let i = 0; i < basket.length; i++) {
      for (let j = 0; j < response.sectionList.length; j++) {
        for (let k = 0; k < response.sectionList[j].productList.length; k++) {
          if (basket[i].id === response.sectionList[j].productList[k].id) {
            response.sectionList[j].productList[k].inBasket = true
            basket[i].inBasket = true
            response.sectionList[j].productList[k] = basket[i]
          }
        }
      }
    }
    for (let i = 0; i < response.sectionList.length; i++) {
      for (let j = 0; j < response.sectionList[i].productList.length; j++) {
        response.sectionList[i].productList[j].show = false
      }
    }
    setBranch(response)
    setFirstSession(false)
    setSectionList(response['sectionList'])
  }

  function getItem(item) {
    item.quantity = 1
    setBasketProduct(item)
    setShowModal(true)
  }

  function showCollapse(index, index2, bool) {
    var copy = sectionList[index].productList[index2]
    var arr = sectionList
    copy.show = bool
    arr[index].productList[index2] = copy
    setBranch({ ...branch, sectionList: arr })
  }

  function productInBasket() {
    var basketLength = JSON.parse(localStorage.getItem('state')).basket.length
    store.dispatch(inBasket({ item: basketProduct, branch: branch }))
    console.log(basketLength);
    console.log(JSON.parse(localStorage.getItem('state')).basket.length);
    setFirstSession(true)
    setShowModal(false)
  }

  async function createFavoriteBranch() {
    const response = await POST('/services/web/api/branch-favorite', {
      branchId: id,
      status: true
    })
    if (response.success) {
      toast.success('Ресторан добавлен в избранное')
    } else {

    }
  }

  const handleOnClick = (event, index) => {
    // console.log(myRef[1].offsetTop);
    window.scrollTo(0, myRef[index].offsetTop - 140);
    // console.log(event);
    // console.log(myRef[index]);
    // myRef[1].scrollIntoView(); 
  }

  useEffect(() => {
    if (firstSession) {
      getBranch()
    } else {
      var copy = branch
      for (let i = 0; i < basket.length; i++) {
        for (let j = 0; j < copy.sectionList.length; j++) {
          for (let k = 0; k < copy.sectionList[j].productList.length; k++) {
            if (basket[i].id === copy.sectionList[j].productList[k].id) {
              copy.sectionList[j].productList[k].inBasket = true
              basket[i].inBasket = true
              copy.sectionList[j].productList[k] = basket[i]
            }
          }
        }
      }
    }
  }, [basket]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="bg-white">
        <div className="container">
          {/* mx-sm-2 mx-lg-5 */}
          <div className="py-3">
            <div className="d-flex justify-content-between flex-wrap">
              <div className="d-flex flex-column justify-content-around">
                <Link to="/">
                  <div className="fz20 d-flex align-items-center mb-3">
                    <ChevronLeft />
                    Все рестораны
                  </div>
                </Link>
                <h2>{branch.name}</h2>
                <div className="d-flex flex-wrap">
                  {branch.workingStatus ?
                    <span className="rounded-text rounded-text-success mr-2 mb-2">
                      <div>Открыто</div>
                    </span>
                    :
                    <span className="rounded-text rounded-text-danger mr-2 mb-2">
                      <div>Закрыто</div>
                    </span>
                  }
                  <span className="rounded-text mr-2 mb-2">
                    <div className="d-flex mr-2"><Clock size={18} color='#5C3EBA' /></div>
                    <div>
                      {branch.deliveryFrom + '–' + branch.deliveryTo + ' min'}
                    </div>
                  </span>
                  <span className="rounded-text mr-2 mb-2">
                    <div className="d-flex mr-2"><Tag size={18} color='#5C3EBA' /></div>
                    <div>от {formatMoney(branch.minProductPrice)} сум</div>
                  </span>
                  <span className="rounded-text mr-2 mb-2">
                    <div className="d-flex mr-2"><Star size={18} color='#FFA91A' fill='#FFA91A' /></div>
                    <div>{branch.rating}  •  99 отзывов</div>
                  </span>
                  <span className="rounded-text mr-2 mb-2">
                    <div className="d-flex mr-2"><ShoppingBag size={18} color='#5C3EBA' /></div>
                    <div>О ресторане</div>
                  </span>
                  <span onClick={() => createFavoriteBranch()} className="rounded-text cursor mr-2 mb-2">
                    <Heart size={18} color='#E63950' fill={false ? '#E63950' : '#fff'} />
                  </span>
                </div>
              </div>
              <div>
                <img src={'https://colibri24.uz' + (branch.imageUrl)} style={{ height: 180, width: 360, borderRadius: 10, objectFit: 'cover' }} alt="" />
                {/* <img src="img/restaraunt.png" alt="" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="pb-4 section products-section" style={{ backgroundColor: "#FBFBFB" }}>
        <div className="container">
          <div className="sticky px-1">
            <span className="rounded-text px-2 mb-2 d-flex align-items-center justify-content-center mr-2">
              <Filter size={18} color='#262134' />
            </span>
            {
              sectionList.map((item, index) => {
                return (
                  <div onClick={(e) => handleOnClick(e, index)} key={item.id}>
                    <span key={item.id} className=" cursor rounded-text mb-2 px-2 d-flex align-items-center justify-content-center mr-2">
                      <div className="d-flex mr-2"><Coffee size={18} color='#262134' /></div>
                      <div>{item.name}</div>
                    </span>
                  </div>
                  // <HashLink key={item.id} to={`#${item.id}`}>
                  //   <span key={item.id} className=" cursor rounded-text mb-2 px-2 d-flex align-items-center justify-content-center mr-2">
                  //     <div className="d-flex mr-2"><Coffee size={18} color='#262134' /></div>
                  //     <div>{item.name}</div>
                  //   </span>
                  // </HashLink>
                )
              })
            }
          </div>
          {/* mx-sm-2 mx-lg-5 */}
          <div className="">

            {sectionList.map((item, index) => {
              return (
                <div ref={(ref) => { myRef[index] = ref }} id={item.id} key={item.id}>
                  <div className="pb-2 title d-flex align-items-center">
                    <div className="m-0 fz28">{item.name}</div>
                  </div>
                  {/* <div className="d-flex flex-wrap justify-content-xs-center">
                    {item.productList.map((item2, index2) => {
                      return (
                        <div key={item2.id} className="mb-3 detail-product">
                          <div className="list-card bg-white rounded overflow-hidden position-relative h-100"  >
                            <img src={'https://colibri24.uz' + (item2.imageUrl)} className="img-fluid detail-product-image item-img" alt=""/>
                            <div className={"p-3 position-absolute bg-white bottom-0 w-100 " + (item2.inBasket && "in-basket")}>
                              <div className="cursor" onClick={() => item2.inBasket ? '' : showCollapse(index, index2)} >
                                <div className="list-card-body">
                                  <h5 className="mb-1">{item2.name}</h5>
                                  <div className="text-gray mb-3 d-flex align-items-center justify-content-between">
                                  </div>
                                </div>
                                <div className="mb-2">
                                  {!item2.show && item2.description}
                                  <Collapse in={item2.show} >
                                    <p className="mb-2" id="example-collapse-text">
                                      {item2.description}
                                    </p>
                                  </Collapse>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <span className="text-dark price-text  py-1 d-flex align-items-center justify-content-center">
                                  <div className="text-black mr-1"> <Tag size={16} color='#D4D3D6' /></div>
                                  <div className="text-black fz18 font-weight-bold">{formatMoney(item2.price)} сум</div>
                                </span>
                                {item2.inBasket &&
                                  <div className="d-flex align-items-center">
                                    <div className="p-1 bg-grey-light br-5 cursor">
                                      <Plus onClick={() => store.dispatch(increment(item2))} color="#5C3EBA" />
                                    </div>
                                    <div className="fz20 px-2 text-dark"> {item2.quantity} </div>
                                    <div className="p-1 bg-grey-light br-5 cursor">
                                      <Minus onClick={() => store.dispatch(decrement(item2))} color="#5C3EBA" />
                                    </div>
                                  </div>
                                }
                                {
                                  (!item2.show && !item2.inBasket ?
                                    <span className="bg-grey text-dark price-text  px-2 py-1 d-flex align-items-center justify-content-center br-5">
                                      <div className="text-black-50 mr-1"> <Star size={16} color='#FFA91A' /></div>
                                      <div className="text-black-50">4.7</div>
                                    </span>
                                    :
                                    <Collapse in={item2.show} >
                                      <button className="btn btn-purple" onClick={() => store.dispatch(inBasket({ item: item2, branch: branch })) + setFirstSession(true)}>
                                        В корзину
                                      </button>
                                    </Collapse>)
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                    }
                  </div> */}
                  <div className="row">
                    {item.productList.map((item2, index2) => {
                      return (
                        <div key={item2.id} className="cursor col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-3" >
                          <div onMouseLeave={() => item2.inBasket ? '' : showCollapse(index, index2, false)} onMouseEnter={() => item2.inBasket ? '' : showCollapse(index, index2, true)}
                            className="list-card h-100 bg-white overflow-hidden detail "
                            style={{ backgroundImage: `url(https://colibri24.uz${item2.imageUrl})` }}
                            onClick={() => item2.inBasket ? '' : getItem(item2)}
                          >
                            {/* <img src={'https://colibri24.uz' + (item2.imageUrl)} className="w-100 img-fluid item-img detail-image" alt="" style={{ objectFit: 'cover' }} /> */}
                            {/* + (item2.show && 'position-absolute') */}
                            <div className={"p-3 bg-white bottom-0 w-100 " + (item2.inBasket ? "in-basket " : " ")}>
                              <div className="cursor" onClick={() => item2.inBasket ? '' : showCollapse(index, index2, !item.show)} >
                                <div className="list-card-body">
                                  <h5 className="mb-1 text-ellipsis">{item2.name}</h5>
                                  <div className="text-gray mb-3 d-flex align-items-center justify-content-between">
                                  </div>
                                </div>
                                <div className="mb-2">
                                  <div className="text-ellipsis">
                                    {item2.description}
                                  </div>

                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <span className="text-dark price-text py-1 d-flex align-items-center justify-content-center">
                                  <div className="text-black mr-1"> <Tag size={16} color='#D4D3D6' /></div>
                                  <div className="text-black fz18 font-weight-bold">{formatMoney(item2.price)} сум</div>
                                </span>
                                {item2.inBasket &&
                                  <div style={{ zIndex: '20' }} className="d-flex align-items-center">
                                    <div className="p-1 bg-grey-light br-5 cursor">
                                      <Plus onClick={(e) => e.stopPropagation() + store.dispatch(increment(item2))} color="#5C3EBA" />
                                    </div>
                                    <div className="fz20 px-2 text-dark"> {item2.quantity} </div>
                                    <div className="p-1 bg-grey-light br-5 cursor">
                                      <Minus onClick={(e) => e.stopPropagation() + store.dispatch(decrement(item2))} color="#5C3EBA" />
                                    </div>
                                  </div>
                                }
                                {
                                  (!item2.show && !item2.inBasket ?
                                    <span className="bg-grey text-dark price-text px-2 py-1 d-flex align-items-center justify-content-center br-5">
                                      <div className="text-black-50 mr-1"> <Star size={16} color='#FFA91A' /></div>
                                      <div className="text-black-50">4.7</div>
                                    </span>
                                    :
                                    item2.show ?
                                      <button className="btn px-2 py-1 btn-purple" style={{ zIndex: '20' }}
                                        onClick={(e) => e.stopPropagation() + store.dispatch(inBasket({ item: item2, branch: branch })) + setFirstSession(true)}>
                                        В корзину
                                      </button>
                                      :
                                      ""
                                  )
                                }
                              </div>
                            </div>
                            {
                              item2.show &&
                              <div className={"p-3 bg-white bottom-0 w-100 position-absolute " + (item2.inBasket ? "in-basket " : " ")}
                                style={{ zIndex: '30' }}>
                                <div className="cursor" onClick={() => item2.inBasket ? '' : showCollapse(index, index2, !item.show)} >
                                  <div className="list-card-body">
                                    <h5 className="mb-1 text-ellipsis">{item2.name}</h5>
                                    <div className="text-gray mb-3 d-flex align-items-center justify-content-between">
                                    </div>
                                  </div>
                                  <div className="mb-2">
                                    <div>
                                      <p className="mb-0">
                                        {item2.description}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <span className="text-dark price-text py-1 d-flex align-items-center justify-content-center">
                                    <div className="text-black mr-1"> <Tag size={16} color='#D4D3D6' /></div>
                                    <div className="text-black fz18 font-weight-bold">{formatMoney(item2.price)} сум</div>
                                  </span>
                                  {item2.inBasket &&
                                    <div style={{ zIndex: '20' }} className="d-flex align-items-center">
                                      <div className="p-1 bg-grey-light br-5 cursor">
                                        <Plus onClick={(e) => e.stopPropagation() + store.dispatch(increment(item2))} color="#5C3EBA" />
                                      </div>
                                      <div className="fz20 px-2 text-dark"> {item2.quantity} </div>
                                      <div className="p-1 bg-grey-light br-5 cursor">
                                        <Minus onClick={(e) => e.stopPropagation() + store.dispatch(decrement(item2))} color="#5C3EBA" />
                                      </div>
                                    </div>
                                  }
                                  {
                                    (!item2.show && !item2.inBasket ?
                                      <span className="bg-grey text-dark price-text px-2 py-1 d-flex align-items-center justify-content-center br-5">
                                        <div className="text-black-50 mr-1"> <Star size={16} color='#FFA91A' /></div>
                                        <div className="text-black-50">4.7</div>
                                      </span>
                                      :
                                      item2.show ?
                                        <button className="btn px-2 py-1 btn-purple" style={{ zIndex: '20' }}
                                          onClick={(e) => e.stopPropagation() + store.dispatch(inBasket({ item: item2, branch: branch })) + setFirstSession(true)}>
                                          В корзину
                                        </button>
                                        :
                                        ""
                                    )
                                  }
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      )
                    })
                    }
                  </div>
                </div>
              )
            })
            }
          </div>
        </div>
      </section>
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowModal(false)}
        contentClassName="br-30"
      >
        <Modal.Header className="border-none px-4 pt-4 pb-0" closeButton />
        <Modal.Body className="border-none pt-0">
          <p>
          </p>
          <div className="container-fluid m-auto">
            <div className="row">
              <div className="col-lg-12 h-100 px-3 pb-3 bg-white  border-radius-21">
                <div className="d-flex">
                  <div className="mr-4">
                    <img src={'https://colibri24.uz' + (basketProduct.imageUrl)}
                      alt="" className="border-radius-15 obj-fit-cover mb-4" height="300" width="375" />
                    <span className="d-flex rounded-text w-max-content">
                      <div className="d-flex mr-2"><Star size={18} color='#FFA91A' fill='#FFA91A' /></div>
                      <div className="w-fit-content">4.7  •  99 отзывов</div>
                    </span>
                  </div>
                  <div className="w-100">
                    <div className="d-flex flex-column h-100 justify-content-between">
                      <div>
                        <h4>{basketProduct.name}</h4>
                        <p>{basketProduct.description}
                        </p>
                        {/* <h4>Ингредиенты</h4>
											<div className="d-flex justify-content-between mb-3">
												<div className="d-flex align-items-center">
													<input type="checkbox" className="mr-2" />
													<span>Наименование ингредиента</span>
												</div>
												<span className="font-weight-bold">10`000 сум</span>
											</div>
											<div className="d-flex justify-content-between mb-3">
												<div className="d-flex align-items-center">
													<input type="checkbox" className="mr-2" />
													<span>Наименование ингредиента</span>
												</div>
												<span className="font-weight-bold">10`000 сум</span>
											</div>
											<div className="d-flex justify-content-between mb-3">
												<div className="d-flex align-items-center">
													<input type="checkbox" className="mr-2" />
													<span>Наименование ингредиента</span>
												</div>
												<span className="font-weight-bold">10`000 сум</span>
											</div>
											<div className="d-flex justify-content-between mb-3">
												<div className="d-flex align-items-center">
													<input type="checkbox" className="mr-2" />
													<span>Наименование ингредиента</span>
												</div>
												<span className="font-weight-bold">10`000 сум</span>
											</div>
											<div className="d-flex justify-content-between mb-3">
												<div className="d-flex align-items-center">
													<input type="checkbox" className="mr-2" />
													<span>Наименование ингредиента</span>
												</div>
												<span className="font-weight-bold">10`000 сум</span>
											</div> */}
                        <div className="d-flex align-items-center mb-3">
                          <div className="p-1 bg-grey-light br-5 cursor">
                            <Plus color="#5C3EBA"
                              onClick={() => setBasketProduct({ ...basketProduct, quantity: basketProduct.quantity + 1 })} />
                          </div>
                          <div className="fz20 px-2 text-dark"> {basketProduct.quantity} </div>
                          <div className="p-1 bg-grey-light br-5 cursor">
                            <Minus color="#5C3EBA"
                              onClick={() => basketProduct.quantity !== 1 ? setBasketProduct({ ...basketProduct, quantity: basketProduct.quantity - 1 }) : ''} />
                          </div>
                        </div>
                      </div>
                      <button onClick={() => productInBasket()} className="btn btn-purple p-btn p-2 d-flex justify-content-between br-10">
                        <span className="font-weight-600">Добавить</span>
                        <span>{formatMoney(basketProduct.price * basketProduct.quantity)} сум</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Detail
