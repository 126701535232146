import {HashRouter as Router, Switch, Route} from "react-router-dom";
import {useSelector} from "react-redux";
import {ToastContainer} from "react-toastify";
import Modal from "react-bootstrap/Modal";

// Components

// Front
// import Index from "./components/front/Index";
import Header from "./components/front/Header";
import Footer from "./components/front/Footer";
import Checkout from "./components/front/Checkout";
import Detail from "./components/front/Detail";
import Basket from "./components/front/Basket";
import Profile from "./components/front/Profile";

// Admin
import SideBar from "./components/admin/SideBar";
import AdminLogin from "./components/admin/Login";
import Dashboard from "./components/admin/Dashboard/Dashboard";

import Contragent from "./components/admin/Contragents/Contragent";
import ContragentAddUpdate from "./components/admin/Contragents/ContragentAddUpdate";

import Master from "./components/admin/Masters/Master";
import MasterAddUpdate from "./components/admin/Masters/MasterAddUpdate";

import RequestFromSellers from "./components/admin/RequestSales/RequestFromSellers";

import RequestFromMasters from "./components/admin/RequestMasters/RequestFromMasters";

import StatusSeller from "./components/admin/Logs/StatusSeller";
import StatusMaster from "./components/admin/Logs/StatusMaster";
import PointSeller from "./components/admin/Logs/PointSeller";
import PointMaster from "./components/admin/Logs/PointMaster";

import User from "./components/admin/Users/User";
import UserAddUpdate from "./components/admin/Users/UserAddUpdate";

import ContragentsReport from "./components/admin/Reports/ContragentsReport";
import MastersReport from "./components/admin/Reports/MastersReport";

import Code from "./components/admin/Codes/Code";

import RequestToExchange from "./components/admin/requestsToExchange/RequestToExchange";

import Manager from "./components/admin/Managers/Manager";
import ManagerAddUpdate from "./components/admin/Managers/ManagerAddUpdate";

import Object from "./components/admin/Objects/Object";
import ObjectAddUpdate from "./components/admin/Objects/ObjectAddUpdate";

import Dealer from "./components/admin/Dealers/Dealer";
import DealerAddUpdate from "./components/admin/Dealers/DealerAddUpdate";

import Bazar from "./components/admin/Bazars/Bazar";
import BazarAddUpdate from "./components/admin/Bazars/BazarAddUpdate";

import SalePoint from "./components/admin/SalePoints/SalePoint";
import SalePointAddUpdate from "./components/admin/SalePoints/SalePointAddUpdate";

import Territory from "./components/admin/Territories/Territory";

import Prize from "./components/admin/Prizes/Prize";
import PrizeAdd from "./components/admin/Prizes/PrizeAddUpdate";

import Products from "./components/admin/Product/Products";
import ProductAddUpdate from "./components/admin/Product/ProductAddUpdate";

import Orders from "./components/admin/Order/Orders";

import Operators from "./components/admin/Operator/Operators";
import OperatorsAddUpdate from "./components/admin/Operator/OperatorsAddUpdate";

import Couriers from "./components/admin/Courier/Couriers";
import CouriersAddUpdate from "./components/admin/Courier/CouriersAddUpdate";

import Cuisines from "./components/admin/Cuisines/Cuisines";
import CuisinesAddUpdate from "./components/admin/Cuisines/CuisinesAddUpdate";

import Categories from "./components/admin/Categories/Categories";
import CategoriesAddUpdate from "./components/admin/Categories/CategoriesAddUpdate";

import Company from "./components/admin/Company/Company";
import CompanyAddUpdate from "./components/admin/Company/CompanyAddUpdate";

import Branch from "./components/admin/Branch/Branchs";
import BranchAddUpdate from "./components/admin/Branch/BranchAddUpdate";

import Telegram from "./components/admin/Telegram/Telegram";

import Unit from "./components/admin/Unit/Unit";

import Currency from "./components/admin/Currency/Currency";

import BrandType from "./components/admin/BrandType/BrandType";

// OPERATOR

import OperatorSideBar from "./components/operator/SideBar";

import OperatorOrders from "./components/operator/Orders/Orders";
import OperatorCouriers from "./components/operator/Couriers/Couriers";

// CSS
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-select2-wrapper/css/select2.css";
import "./css/bootstrap.min.css";
import "./css/bootstrap.css";
import "./css/App.css";

import {
    PrivateRoute,
    AdminPrivateRoute,
    UserPrivateRoute,
} from "./private";
import {store} from "./store";
import ExportData from "./components/admin/Reports/ExportData";
import Product from "./components/admin/Products/Product";
import Test from "./components/admin/Test";
import NewProductAddUpdate from "./components/admin/Products/NewProductAddUpdate";
import SupportMessage from "./components/admin/Message/SupportMessage";
import SupportMessageDetail from "./components/admin/Message/SupportMessageDetail";
import SettingsForm from "./components/admin/Settings/SettingsForm";
import AlreadyUsedCodes from "./components/admin/AlreadyUsedCodes/AlreadyUsedCodes";
import DeadMasterReport from "./components/admin/Reports/DeadMasterReport";
import Poll from "./components/admin/Poll/Poll";
import PollCreate from "./components/admin/Poll/PollCreate";
import Bulk from "./components/admin/Bulk/Bulk";
import BulkCreate from "./components/admin/Bulk/BulkCreate";
import ExchangePrize from "./components/admin/ExchangePrize/ExchangePrize";
import ProductsReport from "./components/admin/Reports/ProductsReport";
import UserPointSeller from "./components/operator/UserLogs/UserPointSeller";
import UserPointMaster from "./components/operator/UserLogs/UserPointMaster";
import UserStatusMaster from "./components/operator/UserLogs/UserStatusMaster";
import UserRequestFromMasters from "./components/operator/UserRequestMasters/UserRequestFromMasters";
import UserMastersReport from "./components/operator/UserReports/UserMastersReport";
import UserProductsReport from "./components/operator/UserReports/UserProductsReport";
import UserDeadMasterReport from "./components/operator/UserReports/UserDeadMasterReport";

function App() {
    const loader = useSelector((state) => state.loader);
    const modal = useSelector((state) => state.modal);
    return (
        <>
            <ToastContainer
                closeOnClick={false}
                hideProgressBar={false}
                newestOnTop={false}
            />
            {loader && (
                <div id="loading-bg">
                    <div className="loading">
                        <div className="spinner-border text-purple"></div>
                    </div>
                </div>
            )}
            {modal && (
                <Modal
                    hide={() => store.dispa}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Очистить корзину?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            В нашей корзине есть блюда из другого ресторана. Они будут
                            удалены для добавления новых.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <btn className="btn btn-purple">Отменить</btn>
                            <btn className="btn btn-purple">Продолжить</btn>
                        </div>
                    </Modal.Footer>
                </Modal>
            )}
            <Router>
                <Switch>
                    {/* ADMIN */}

                    <Route path="/admin/login" component={AdminLogin}/>

                    <Route path="/admin/:path?" exact>
                        <SideBar>
                            <Switch>
                                <AdminPrivateRoute
                                    path="/admin/main"
                                    exact
                                    component={Dashboard}
                                />

                                <AdminPrivateRoute
                                    path="/admin/bulk"
                                    exact
                                    component={Bulk}
                                />
                                <AdminPrivateRoute
                                    path="/admin/bulk-create"
                                    exact
                                    component={BulkCreate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/poll"
                                    exact
                                    component={Poll}
                                />
                                <AdminPrivateRoute
                                    path="/admin/poll-create"
                                    exact
                                    component={PollCreate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/settings"
                                    exact
                                    component={SettingsForm}
                                />
                                <AdminPrivateRoute
                                    path="/admin/used-codes"
                                    exact
                                    component={AlreadyUsedCodes}
                                />
                                <AdminPrivateRoute
                                    path="/admin/test"
                                    exact
                                    component={Test}
                                />
                                <AdminPrivateRoute
                                    path="/admin/messages"
                                    exact
                                    component={SupportMessage}
                                />
                                <AdminPrivateRoute
                                    path="/admin/products"
                                    exact
                                    component={Product}
                                />

                                <AdminPrivateRoute
                                    path="/admin/main"
                                    exact
                                    component={Dashboard}
                                />
                                <AdminPrivateRoute
                                    path="/admin/contragent"
                                    exact
                                    component={Contragent}
                                />
                                <AdminPrivateRoute
                                    path="/admin/exchange-prize"
                                    exact
                                    component={ExchangePrize}
                                />
                                <AdminPrivateRoute
                                    path="/admin/master"
                                    exact
                                    component={Master}
                                />
                                <AdminPrivateRoute
                                    path="/admin/requestFromSellers"
                                    exact
                                    component={RequestFromSellers}
                                />
                                <AdminPrivateRoute
                                    path="/admin/requestFromMasters"
                                    exact
                                    component={RequestFromMasters}
                                />
                                <AdminPrivateRoute
                                    path="/admin/user"
                                    exact
                                    component={User}
                                />
                                <AdminPrivateRoute
                                    path="/admin/report-contragent"
                                    exact
                                    component={ContragentsReport}
                                />

                                <AdminPrivateRoute
                                    path="/admin/report-master"
                                    exact
                                    component={MastersReport}
                                />
                                <AdminPrivateRoute
                                    path="/admin/export-data"
                                    exact
                                    component={ExportData}
                                />
                                <AdminPrivateRoute
                                    path="/admin/code"
                                    exact
                                    component={Code}
                                />
                                <AdminPrivateRoute
                                    path="/admin/request-exchange"
                                    exact
                                    component={RequestToExchange}
                                />
                                <AdminPrivateRoute
                                    path="/admin/manager"
                                    exact
                                    component={Manager}
                                />
                                <AdminPrivateRoute
                                    path="/admin/object"
                                    exact
                                    component={Object}
                                />
                                <AdminPrivateRoute
                                    path="/admin/dealer"
                                    exact
                                    component={Dealer}
                                />
                                <AdminPrivateRoute
                                    path="/admin/bazar"
                                    exact
                                    component={Bazar}
                                />
                                <AdminPrivateRoute
                                    path="/admin/salepoint"
                                    exact
                                    component={SalePoint}
                                />
                                <AdminPrivateRoute
                                    path="/admin/territory"
                                    exact
                                    component={Territory}
                                />
                                <AdminPrivateRoute
                                    path="/admin/prize"
                                    exact
                                    component={Prize}
                                />
                                {/*  */}
                                <AdminPrivateRoute
                                    path="/admin/branch"
                                    exact
                                    component={Branch}
                                />
                                <AdminPrivateRoute
                                    path="/admin/product"
                                    exact
                                    component={Products}
                                />
                                <AdminPrivateRoute
                                    path="/admin/orders"
                                    exact
                                    component={Orders}
                                />
                                <AdminPrivateRoute
                                    path="/admin/operators"
                                    exact
                                    component={Operators}
                                />
                                <AdminPrivateRoute
                                    path="/admin/couriers"
                                    exact
                                    component={Couriers}
                                />
                                <AdminPrivateRoute
                                    path="/admin/telegram"
                                    exact
                                    component={Telegram}
                                />
                                <AdminPrivateRoute
                                    path="/admin/company"
                                    exact
                                    component={Company}
                                />
                            </Switch>
                        </SideBar>
                    </Route>
                    <Route path="/admin/:path/:path" exact>
                        <SideBar>
                            <Switch>
                                {/*  */}
                                <AdminPrivateRoute
                                    path="/admin/poll-update/:id"
                                    exact
                                    component={PollCreate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/bulk-update/:id"
                                    exact
                                    component={BulkCreate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/report/all-master"
                                    exact
                                    component={MastersReport}
                                />
                                <AdminPrivateRoute
                                    path="/admin/report/report-products"
                                    exact
                                    component={ProductsReport}
                                />
                                <AdminPrivateRoute
                                    path="/admin/report/dead-master"
                                    exact
                                    component={DeadMasterReport}
                                />
                                <AdminPrivateRoute
                                    path="/admin/report/reused-codes"
                                    exact
                                    component={AlreadyUsedCodes}
                                />
                                <AdminPrivateRoute
                                    path="/admin/products/create"
                                    exact
                                    component={NewProductAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/log/status-sale"
                                    exact
                                    component={StatusSeller}
                                />
                                <AdminPrivateRoute
                                    path="/admin/log/status-master"
                                    exact
                                    component={StatusMaster}
                                />
                                <AdminPrivateRoute
                                    path="/admin/log/point-sale"
                                    exact
                                    component={PointSeller}
                                />
                                <AdminPrivateRoute
                                    path="/admin/log/point-master"
                                    exact
                                    component={PointMaster}
                                />
                                {/*  */}
                                <AdminPrivateRoute
                                    path="/admin/contragent/create"
                                    component={ContragentAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/master/create"
                                    component={MasterAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/user/create"
                                    component={UserAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/manager/create"
                                    component={ManagerAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/object/create"
                                    component={ObjectAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/dealer/create"
                                    component={DealerAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/bazar/create"
                                    component={BazarAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/salepoint/create"
                                    component={SalePointAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/prize/create"
                                    component={PrizeAdd}
                                />
                                {/*  */}
                                <AdminPrivateRoute
                                    path="/admin/branch/create"
                                    component={BranchAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/product/create"
                                    component={ProductAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/operators/create"
                                    component={OperatorsAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/couriers/create"
                                    component={CouriersAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/company/create"
                                    component={CompanyAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/directory/cuisines"
                                    component={Cuisines}
                                />
                                <AdminPrivateRoute
                                    path="/admin/directory/categories"
                                    component={Categories}
                                />
                                <AdminPrivateRoute
                                    path="/admin/directory/unit"
                                    component={Unit}
                                />
                                <AdminPrivateRoute
                                    path="/admin/directory/currency"
                                    component={Currency}
                                />
                                <AdminPrivateRoute
                                    path="/admin/directory/brands-type"
                                    component={BrandType}
                                />
                            </Switch>
                        </SideBar>
                    </Route>
                    <Route path="/admin/:path/:path/:path?" exact>
                        <SideBar>
                            <Switch>
                                <AdminPrivateRoute
                                    path="/admin/products/update/:id"
                                    exact
                                    component={NewProductAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/messages/detail/:id"
                                    component={SupportMessageDetail}
                                />
                                <AdminPrivateRoute
                                    path="/admin/contragent/update/:id"
                                    component={ContragentAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/master/update/:id"
                                    component={MasterAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/user/update/:id"
                                    component={UserAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/manager/update/:id"
                                    component={ManagerAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/object/update/:id"
                                    component={ObjectAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/dealer/update/:id"
                                    component={DealerAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/bazar/update/:id"
                                    component={BazarAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/salepoint/update/:id"
                                    component={SalePointAddUpdate}
                                />
                                {/*  */}
                                <AdminPrivateRoute
                                    path="/admin/branch/update/:id"
                                    component={BranchAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/product/update/:id"
                                    component={ProductAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/operators/update/:id"
                                    component={OperatorsAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/couriers/update/:id"
                                    component={CouriersAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/company/update/:id"
                                    component={CompanyAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/directory/cuisines/create"
                                    component={CuisinesAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/directory/categories/create"
                                    component={CategoriesAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/prize/update/:id"
                                    component={PrizeAdd}
                                />
                            </Switch>
                        </SideBar>
                    </Route>

                    <Route path="/admin/:path/:path/:path/:id" exact>
                        <SideBar>
                            <Switch>
                                <AdminPrivateRoute
                                    path="/admin/directory/cuisines/update/:id"
                                    component={CuisinesAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/directory/categories/update/:id"
                                    component={CategoriesAddUpdate}
                                />
                            </Switch>
                        </SideBar>
                    </Route>

                    {/* OPERATOR */}

                    <Route path="/user/:path?" exact>
                        <OperatorSideBar>
                            <Switch>
                                <UserPrivateRoute
                                    path="/user/main"
                                    exact
                                    component={Dashboard}
                                />
                                <UserPrivateRoute
                                    path="/user/requestFromMasters"
                                    exact
                                    component={UserRequestFromMasters}
                                />
                            </Switch>
                        </OperatorSideBar>
                    </Route>
                    <Route path="/user/:path/:path?" exact>
                        <OperatorSideBar>
                            <Switch>
                                <UserPrivateRoute
                                    path="/user/log/status-master"
                                    exact
                                    component={UserStatusMaster}
                                />
                                <UserPrivateRoute
                                    path="/user/log/point-master"
                                    exact
                                    component={UserPointMaster}
                                />
                                <UserPrivateRoute
                                    path="/user/report/all-master"
                                    exact
                                    component={UserMastersReport}
                                />
                                <UserPrivateRoute
                                    path="/user/report/report-products"
                                    exact
                                    component={UserProductsReport}
                                />
                                <UserPrivateRoute
                                    path="/user/report/dead-master"
                                    exact
                                    component={UserDeadMasterReport}
                                />
                            </Switch>
                        </OperatorSideBar>
                    </Route>
                    {/* WEB */}

                    <Route path="/:path?" exact>
                        <PrivateRoute path="/checkout" component={Checkout}/>
                        <Route path="/" exact component={AdminLogin}/>
                        <Route path="/basket" exact component={Basket}/>
                        <PrivateRoute path="/profile" exact component={Profile}/>
                    </Route>

                    <Route path="/:path/:path?" exact>
                        <div className="d-flex flex-column min-vh-100">
                            <Header/>
                            <div style={{marginTop: "75px"}}>
                                <Route path="/detail/:id" exact component={Detail}/>
                            </div>

                            <div className="mt-auto">
                                <Footer/>
                            </div>
                        </div>
                    </Route>
                </Switch>
            </Router>
        </>
    );
}

export default App;
