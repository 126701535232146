import React, {useEffect, useState} from 'react'
import {PGET, POST, PUT} from "../../../api/adminApi";
import {toast} from "react-toastify";
import {Save} from "react-feather";
import {useHistory} from "react-router-dom";

export default function SettingsForm() {
    const [msg, setMsg] = useState()
    const [deadMaster, setDeadMaster] = useState()
    const history = useHistory();
    async function getItems() {
        const data = await PGET("/services/admin/api/birthday", true, );
        const deadMasters = await PGET("/services/admin/api/dead-master-days", true, );
        setMsg(data.data);
        setDeadMaster(deadMasters.data)
    }
    console.log(msg) //messageText
    console.log(deadMaster) //daysInterval
    function handleMessageChange(e) {
        setMsg({ messageText: e.target.value });
    }
    function handleDaysIntervalChange(e) {
        setDeadMaster({ daysInterval: parseInt(e.target.value) });
    }
    function onSubmitMessage(e) {
        e.preventDefault();
        async function createBirthdayMessage() {
            try {
                const response = await PUT("/services/admin/api/birthday", {
                    messageText: msg.messageText
                });
                if (response.data.success) {
                    history.push("/admin/settings");
                }
            } catch (error) {
                console.error("Error updating birthday message:", error);
            }
        }
        createBirthdayMessage();
    }

    function onSubmitDeadMaster(e) {
        e.preventDefault();
        async function createDeadMasterDays() {
            try {
                const response = await PUT("/services/admin/api/dead-master-days", {
                    daysInterval: deadMaster.daysInterval
                });

                if (response.data.success) {
                    history.push("/admin/settings");
                }
            } catch (error) {
                console.error("Error updating dead master days:", error);
            }
        }
        createDeadMasterDays();
    }

    useEffect(() => {
        getItems()
    },[])

    return (
        <div className='p-5'>
            <h3 className='card-title align-items-start flex-column mb-5 ml-3'>
                <span className='card-label fw-bold fs-3 mb-1'>Настройки</span>
            </h3>
            <div className="admin-card p-4 overflow-y-auto">
                <form onSubmit={onSubmitMessage}>
                    <div className="d-flex flex-column mb-2 gap-2">
                        <label
                            style={{width: "120px", fontSize: 16}}
                            className="color-grey text-end text-nowrap"
                        >
                            Текст поздравления
                        </label>
                        <div>
                                <textarea
                                    name="messageText"
                                    type="text"
                                    className="input-control"
                                    style={{width: "500px"}}
                                    value={msg ? msg.messageText : ""}
                                    onChange={handleMessageChange}
                                />
                        </div>
                    </div>
                    <button
                        className="btn text-white btn-bg-success d-flex align-items-center"
                        type="submit"
                    >
                        <Save size={14} className="mr-2"/>
                        Сохранить
                    </button>
                </form>
                <form onSubmit={onSubmitDeadMaster}>
                    <div className="d-flex flex-column mb-4 gap-2 mt-5">
                        <label
                            style={{width: "120px", fontSize: 16}}
                            className="color-grey text-end text-nowrap"
                        >
                            Интервал спящие мастеры
                        </label>
                        <div>
                            <input
                                name="daysInterval"
                                type="number"
                                className="input-control"
                                style={{width: "300px"}}
                                value={deadMaster ? deadMaster.daysInterval : ""}
                                onChange={handleDaysIntervalChange}
                            />
                        </div>
                    </div>
                    <button
                        className="btn text-white btn-bg-success d-flex align-items-center"
                        type="submit"
                    >
                        <Save size={12} className="mr-2"/>
                        Сохранить
                    </button>
                </form>
            </div>
        </div>
    )
}