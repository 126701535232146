import React, {useEffect, useState} from "react";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import Select from "react-select";

import {DOWNLOAD, excelParams, GET, PGET} from "../../../api/adminApi";
import CodeAssign from "./CodeAssign";
import {getDateTime} from "../../../utils/Dates";
import {CustomSvg} from "../../../utils/CustomSvg";

function Code() {
    const [items, setItems] = useState(null);
// States for the first filter
    const [productFilter, setProductFilter] = useState([{id: 0, name: "Все"}]);
    const [filteredProduct, setFilteredProduct] = useState(productFilter[0]);

    // States for the second filter
    const [productFilterHelper, setProductFilterHelper] = useState([{id: 0, name: "Все"}]);
    const [filteredProductHelper, setFilteredProductHelper] = useState(productFilterHelper[0]);

    // States for the third filter
    const [segmentFilter, setSegmentFilter] = useState([{id: 0, name: "Все"}]);
    const [filteredSegment, setFilteredSegment] = useState(segmentFilter[0]);

    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(
        +localStorage.getItem("currentPageCodes") || 0
    ); //for numeration and saving currentPage after refresh
    const [soldOptions] = useState([
        {value: 0, label: "Все"},
        {value: 1, label: "Продан"},
        {value: 2, label: "Не продан"},
    ]);
    const [soldValue, setSoldValue] = useState(soldOptions[0]);
    const [mountedOptions] = useState([
        {value: 0, label: "Все"},
        {value: 1, label: "Установлен"},
        {value: 2, label: "Не установлен"},
    ]);
    const [mountedValue, setMountedValue] = useState(mountedOptions[0]);
    const [assignedOptions] = useState([
        {value: 0, label: "Все"},
        {value: 1, label: "Привязан"},
        {value: 2, label: "Не привязан"},
    ]);
    const [assignedValue, setAssignedValue] = useState(assignedOptions[0]);
    const [modal, setModal] = useState({show: false, masterId: null});

    async function getProductFilter() {
        const response = await GET("/services/admin/api/product-type-helper");
        if (response.length > 0) {
            setProductFilter([productFilter[0], ...response]);
        }
    }

// Fetch data for the second filter
    async function getProductFilterHelper() {
        const response = await GET(`/services/admin/api/product-segment-helper`);
        if (response.length > 0) {
            setProductFilterHelper([productFilterHelper[0], ...response]);
        }
    }

// Fetch data for the third filter
    async function getSegmentFilter(type, segment) {
        if (type !== undefined && type !== null && segment !== undefined && segment !== null) {
            const response = await GET(`/services/admin/api/product-helper/${type}/${segment}`);
            if (response.length > 0) {
                setSegmentFilter([segmentFilter[0], ...response]);
            }
        }
    }

    const selectSoldFilter = (selectedOpt) => {
        if (selectedOpt !== soldValue) {
            setSoldValue(selectedOpt);
            setCurrentPageToZero();
        }
    };
    const selectProductFilter = (selectedOpt) => {
        if (filteredProduct !== selectedOpt) {
            setFilteredProduct(selectedOpt);
            setCurrentPageToZero();
            getProductFilterHelper(selectedOpt.id);
        }
    };

    // Handle changes in the second filter
    const selectProductFilterHelper = (selectedOpt) => {
        if (filteredProductHelper !== selectedOpt) {
            setFilteredProductHelper(selectedOpt);
            setCurrentPageToZero();
            getSegmentFilter(filteredProduct.id, selectedOpt.id);
        }
    };
    const selectSegmentFilter = (selectedOpt) => {
        if (filteredSegment !== selectedOpt) {
            setFilteredSegment(selectedOpt);
            setCurrentPageToZero();
        }
    };

    const selectMountedFilter = (selectedOpt) => {
        if (selectedOpt !== mountedValue) {
            setMountedValue(selectedOpt);
            setCurrentPageToZero();
        }
    };
    const selectAssignedFilter = (selectedOpt) => {
        if (selectedOpt !== assignedValue) {
            setAssignedValue(selectedOpt);
            setCurrentPageToZero();
        }
    };

    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPageCodes", 0);
    }

    function paginate(data) {
        setCurrentPage(data.selected);
        localStorage.setItem("currentPageCodes", data.selected); //for saving currentPage after refresh
    }

    function weekOwnerAgents() {
        const excelUrl = 'https://bo.akfacomfort.uz/services/admin/api/sales-report/download-all';
        const fileName = 'reportall.xlsx'
        excelParams(excelUrl, fileName, true, {
            sold: soldValue.value,
            mounted: mountedValue.value,
            assigned: assignedValue.value,
            page: currentPage,
            search: value,
        });
    }
    async function getItems() {
        const data = await PGET("/services/admin/api/codes-pageList", true, {
            sold: soldValue.value,
            type: filteredProduct.id,
            segment: filteredProductHelper.id,
            // mounted: mountedValue.value,
            assigned: assignedValue.value,
            page: currentPage,
            size: 10,
            search: value,
            productId: filteredSegment.id
        });
        setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
        setItems(data.data);
    }

    useEffect(() => {
        getItems();
    }, [soldValue, mountedValue, assignedValue, currentPage, value, filteredProduct, filteredSegment, filteredProductHelper]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getProductFilter();
        getProductFilterHelper();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className="br-5 card"
             style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Коды</span>
                </h3>
            </div>

            <div className="px-3 pb-4 pt-2">
                <div className="mb-4 d-flex justify-content-between">
                    <div className="d-flex gap-4">
                        <div style={{width: "150px"}}>
                            <Select
                                value={soldValue}
                                placeholder="Выберите"
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                options={soldOptions}
                                onChange={selectSoldFilter}
                            />
                        </div>
                        <div style={{width: "150px"}}>
                            <Select
                                value={filteredProduct}
                                placeholder="Выберите"
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                options={productFilter}
                                onChange={selectProductFilter}
                            />
                        </div>
                        <div style={{width: "150px"}}>
                            <Select
                                value={filteredProductHelper}
                                placeholder="Выберите"
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                options={productFilterHelper}
                                onChange={selectProductFilterHelper}
                            />
                        </div>
                        <div style={{width: "150px"}}>
                            <Select
                                value={filteredSegment}
                                placeholder="Выберите"
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                options={segmentFilter}
                                onChange={selectSegmentFilter}
                            />
                        </div>
                        <div className="position-relative">
                            <DebounceInput
                                minLength={3}
                                debounceTimeout={400}
                                value={value}
                                onChange={handleSearchInput}
                                type="text"
                                className="input-search search-icon br-5 "
                                size={24}
                                placeholder="Поиск"
                            />
                        </div>
                    </div>
                    <div className="d-flex">
                        <button
                            onClick={weekOwnerAgents}
                            className="mr-0 box-shadow"
                            style={{
                                backgroundColor: "#fcfcfc",
                                height: 38,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid rgb(212, 211, 214)",
                                borderRadius: 5,
                                padding: "0 10px"
                            }}
                        >
                            <CustomSvg path='/icons/duotune/files/fil017.svg' className='svg-icon-3 mr-2'/>
                            Скачать все
                        </button>
                    </div>
                </div>
                <div style={{overflow: "auto", maxHeight: '75vh'}}>
                    <table className='table bg-white table-rounded p-0 border gy-4 gs-7'>
                        <thead className='position-sticky top-0 rounded'>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                            <th className="text-center text-white" style={{
                                width: "30px",
                                backgroundColor: '#E7372C',
                                fontWeight: "700",
                                borderTopLeftRadius: '8px'
                            }}>№
                            </th>
                            <th className="text-center text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Скретч код
                            </th>
                            <th className="text-center text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Наименование продукта
                            </th>
                            <th className="text-start text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Продан
                            </th>
                            <th className="text-start text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Время продажи
                            </th>
                            <th className="text-start text-white"
                                style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Мастер
                            </th>
                            <th className="text-start text-white" style={{
                                backgroundColor: '#E7372C',
                                fontWeight: "700",
                                borderTopRightRadius: '8px'
                            }}>Тел. номер
                            </th>
                        </tr>
                        </thead>
                        <tbody style={{overflowX: "auto"}}>
                        {items?.map((item, idx) => {
                            return (
                                <tr key={item.id} className='borderBottom'>
                                    <td className="text-center">
                                        {currentPage === 0
                                            ? idx + 1
                                            : currentPage * 10 + idx + 1}
                                    </td>
                                    <td className="text-center">
                                        {item.serialNumber}
                                    </td>
                                    <td className="text-center">{item.productName}</td>
                                    <td className="text-start">
                                        {item.sold ? (
                                            <span className='badge badge-success fs-7 fw-bold'>Да</span>) : (
                                            <span className='badge badge-danger fs-7 fw-bold'>Нет</span>
                                        )}
                                    </td>
                                    <td className="text-start">{item.soldTime ? (getDateTime(item.soldTime)) : null}</td>
                                    <td className="text-start">{item.masterName}</td>
                                    <td className="text-start">{item.masterPhone}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                {pageCount > 1 && (
                    <ReactPaginate
                        forcePage={currentPage}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={paginate}
                        containerClassName={"pagination"}
                        activeClassName={"bg-dark text-white"}
                        pageClassName={"page-item box-shadow"}
                    />
                )}
            </div>

            <CodeAssign modal={modal} setModal={setModal} getItems={getItems}/>
        </div>
    );
}

export default Code;
