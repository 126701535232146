import React, {useEffect, useState} from "react";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import {HelpCircle, Search, Gift, Bell} from "react-feather";
import Select from "react-select";
import {DOWNLOAD, PGET} from "../../../api/adminApi";
import ChangeStatusPrize from "./ChangeStatusPrize";
import {CustomSvg} from "../../../utils/CustomSvg";
import PushModal from "./PushModal";
import {FaHandHoldingDollar} from "react-icons/fa6";
import GiveGift from "./GiveGift";

function ExchangePrize() {
    const [items, setItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(
        +localStorage.getItem("exchangePageToPrize") || 0
    ); //for numeration and saving currentPage after refresh
    const [selectedUser, setSelectedUser] = useState(null);
    const [statusOptions, setStatusOptions] = useState([
        {id: 0, name: "Все"},
    ]);
    const [statusOpt, setStatusOpt] = useState(statusOptions[0]);
    const [modal, setModal] = useState({show: false, user: null});
    const [giftModal, setGiftModal] = useState({show: false, user: null});
    const [pushModal, setPushModal] = useState({show: false, user: null});
    const [changeStatusOptions, setChangeStatusOptions] = useState([]);

    const selectStatus = (selectedOpt) => {
        if (statusOpt !== selectedOpt) {
            setStatusOpt(selectedOpt);
            setCurrentPageToZero();
        }
    };

    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("exchangePageToPrize", 0);
    }

    function paginate(data) {
        setCurrentPage(data.selected); //for numeration
        localStorage.setItem("exchangePageToPrize", data.selected); //for saving currentPage after refresh
    }

    async function getItems() {
        const data = await PGET(
            "/services/admin/api/master-change-point-pageList",
            true,
            {
                status: statusOpt.id,
                page: currentPage,
                size: 15,
                search: value,
                region: 0,
            }
        );
        setPageCount(Math.ceil(data?.headers["x-total-count"] / 15));
        setItems(data?.data);
    }

    useEffect(() => {
        getItems();
    }, [statusOpt, currentPage, value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function getStatusHelper() {
            const data = await PGET(
                "/services/admin/api/request/status-helper"
            );
            if (data) {
                setStatusOptions([statusOptions[0], ...data.data]);
            }
        }

        getStatusHelper();

        async function getChangeStatusOptions() {
            const res = await PGET(
                "/services/admin/api/request/status-helper-point-change"
            );
            if (res) {
                setChangeStatusOptions(res.data);
            }
        }

        getChangeStatusOptions();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function weekOwnerAgents() {
        const excelUrl = 'https://bo.akfacomfort.uz/services/admin/api/request-change-point-report/download-all';
        const fileName = 'request-change-point-report.xlsx'
        DOWNLOAD(excelUrl, fileName);
    }


    return (
        <div
            className="p-3"
            style={{background: "#eef1ff", marginTop: "-0.4rem"}}
        >
            <div className="fz18 p-3 pl-3 d-flex align-items-center">
                <HelpCircle className="mr-2" color="#5C3EBA"/>{" "}
                <span>Обмен на призы</span>
            </div>

            <div
                className="gap-3 mb-3 flex">
                <div
                    className="d-flex justify-content-end w-100"
                    style={{width: "150px"}}
                >
                    <Select
                        className="w-20"
                        value={statusOpt}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={statusOptions}
                        onChange={selectStatus}
                    />
                </div>

                <div className="position-relative w-20">
                    <Search
                        size={14}
                        color="#9D9BA3"
                        className="input-search-icon"
                    />
                    <DebounceInput
                        minLength={3}
                        debounceTimeout={400}
                        value={value}
                        onChange={handleSearchInput}
                        type="text"
                        className="input-search box-shadow br-5 w-100"
                        size={24}
                        placeholder="Поиск"
                    />
                </div>
                <div className="d-flex justify-content-end" style={{width: "15%"}}>
                    <button
                        onClick={weekOwnerAgents}
                        className="mr-0 box-shadow"
                        style={{
                            backgroundColor: "#fcfcfc",
                            height: 38,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid rgb(212, 211, 214)",
                            borderRadius: 5,
                            padding: "0 10px",
                        }}
                    >
                        <CustomSvg path='/icons/duotune/files/fil017.svg' className='svg-icon-3 mr-2'/>
                        Скачать все
                    </button>
                </div>
            </div>

            <table className='table bg-white table-rounded p-0 border gy-4 gs-7'>
                <thead className='position-sticky top-0 rounded'>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                    <th className="text-center  text-white" style={{
                        width: "30px",
                        fontSize: 12,
                        backgroundColor: '#E7372C',
                        fontWeight: "700",
                        borderTopLeftRadius: '8px'
                    }}>№
                    </th>
                    <th className="text-center text-white"
                        style={{fontSize: 12, backgroundColor: '#E7372C', fontWeight: "700"}}>Мастер
                    </th>
                    <th className="text-center text-white"
                        style={{fontSize: 12, backgroundColor: '#E7372C', fontWeight: "700"}}>Дата получения
                    </th>
                    <th className="text-start text-white"
                        style={{width: "100px", fontSize: 12, backgroundColor: '#E7372C', fontWeight: "700"}}>Общий балл
                    </th>
                    <th className="text-start text-white"
                        style={{fontSize: 12, backgroundColor: '#E7372C', fontWeight: "700"}}>Приз
                    </th>
                    <th className="text-center text-white"
                        style={{fontSize: 12, backgroundColor: '#E7372C', fontWeight: "700"}}>Панель
                    </th>
                    <th className="text-center text-white"
                        style={{fontSize: 12, backgroundColor: '#E7372C', fontWeight: "700"}}>Секция
                    </th>
                    <th className="text-center text-white"
                        style={{fontSize: 12, backgroundColor: '#E7372C', fontWeight: "700"}}>Реферал
                    </th>
                    <th className="text-center text-white"
                        style={{fontSize: 12, backgroundColor: '#E7372C', fontWeight: "700"}}>Котел
                    </th>
                    <th className="text-center text-white"
                        style={{fontSize: 12, backgroundColor: '#E7372C', fontWeight: "700"}}>Статус котел
                    </th>
                    <th className="text-center text-white"
                        style={{fontSize: 12, backgroundColor: '#E7372C', fontWeight: "700"}}>Причина
                    </th>
                    <th className="text-center text-white"
                        style={{fontSize: 12, backgroundColor: '#E7372C', fontWeight: "700"}}>Статус
                    </th>
                    <th className="text-center text-white"
                        style={{fontSize: 12, backgroundColor: '#E7372C', fontWeight: "700"}}>Отдать приз
                    </th>
                    <th className="text-center text-white"
                        style={{fontSize: 12, backgroundColor: '#E7372C', fontWeight: "700"}}>Push
                    </th>
                    <th className="text-start text-white" style={{
                        fontSize: 12,
                        backgroundColor: '#E7372C',
                        fontWeight: "700",
                        borderTopRightRadius: '8px'
                    }}></th>
                </tr>
                </thead>

                <tbody style={{overflowX: "auto"}}>
                {items?.map((user, idx) => {
                    const {
                        id,
                        masterFullname,
                        masterRegion,
                        phone,
                        receivedTime,
                        requestedPoint,
                        reason,
                        pointOrPrize,
                        masterTotalPoint,
                        prizeName,
                        statusId,
                        statusName,
                        requestedPointPanel,
                        requestedPointSection,
                        requestedPointReferal,
                        requestedPointKotel,
                        statusNameAirfel,
                        kotelEnvolved,
                        statusIdAirfel
                    } = user;
                    return (
                        <tr
                            onClick={() => {
                                setSelectedUser(user);
                            }}
                            role="button"
                            className={
                                id === selectedUser?.id ? "box-shadow" : ""
                            }
                            style={{
                                background: `${
                                    id === selectedUser?.id
                                        ? "#eef1ff"
                                        : "white"
                                }`,
                            }}
                            key={id}
                        >
                            <td className="text-center pointer">
                                {currentPage === 0
                                    ? idx + 1
                                    : // : currentPage * 10 + idx + 1} // for 10 items per page
                                      // for 15 items per page
                                    currentPage * 10 +
                                    idx +
                                    1 +
                                    5 * currentPage}
                            </td>
                            <td className="text-start">
                                <div className='d-flex flex-column'>
                                    <span style={{fontSize: 12}}>{`ФИО: ${masterFullname || ''}`}</span>
                                    <span style={{fontSize: 12}}>{`Номер: ${phone || ''}`}</span>
                                    <span style={{fontSize: 12}}>{`Регион: ${masterRegion || ''}`}</span>
                                </div>
                            </td>
                            <td className="text-start">
                                {new Date(receivedTime).toLocaleString()}
                            </td>
                            <td className="text-center">{masterTotalPoint}</td>
                            <td className="text-start">
                                <div className='d-flex flex-column'>
                                    <span style={{fontSize: 12}}>{`Н-ие: ${prizeName || ''}`}</span>
                                    <span style={{fontSize: 12}}>{`Цена: ${requestedPoint || ''}`}</span>
                                </div>
                            </td>
                            <td className="text-center">{requestedPointPanel}</td>
                            <td className="text-center">{requestedPointSection}</td>
                            <td className="text-center">{requestedPointReferal}</td>
                            <td className="text-center">{kotelEnvolved ? (requestedPointKotel) : ("-")}</td>
                            <td className="text-center">
                                {kotelEnvolved ? (
                                        <b
                                            style={{
                                                whiteSpace: "nowrap",
                                            }}
                                            className={`p-1 text-white text-center rounded font-weight-500 d-block ${
                                                statusIdAirfel === 1
                                                    ? "bg-warning text-black"
                                                    : statusIdAirfel === 2
                                                        ? "bg-success"
                                                        : statusIdAirfel === 3
                                                            ? "bg-danger"
                                                            : "bg-primary"
                                            }`}
                                        >
                                            {statusNameAirfel}
                                        </b>
                                    ) :
                                    (
                                        <>-</>
                                    )
                                }
                            </td>
                            <td className="text-center">{reason}</td>
                            <td className={`text-start w-10`}>
                                <b
                                    style={{
                                        whiteSpace: "nowrap",
                                    }}
                                    className={`p-1 text-white text-center rounded font-weight-500 d-block ${
                                        statusId === 1
                                            ? "bg-warning text-black"
                                            : statusId === 2
                                                ? "bg-success"
                                                : statusId === 3
                                                    ? "bg-danger"
                                                    : "bg-primary"
                                    }`}
                                >
                                    {statusName}
                                </b>
                            </td>
                            <td className="text-center">
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setGiftModal({show: true, user});
                                    }}
                                    disabled={statusId !== 2}
                                    className={`${statusId !== 2 && 'disabled not-allowed'} btn btn-table box-shadow br-5`}
                                    style={{
                                        backgroundColor: "#F4F4F5",
                                    }}
                                >
                                    <FaHandHoldingDollar
                                        color={"#d94843"}
                                        size={16}
                                    />
                                </button>
                            </td>
                            <td className="text-center">
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setPushModal({show: true, user});
                                    }}
                                    disabled={statusId !== 2}
                                    className={`${statusId !== 2 && 'disabled not-allowed'} btn btn-table box-shadow br-5`}
                                    style={{
                                        backgroundColor: "#F4F4F5",
                                    }}
                                >
                                    <Bell
                                        color={"#d94843"}
                                        size={16}
                                    />
                                </button>
                            </td>
                            <td className="text-center">
                                {(!kotelEnvolved || (kotelEnvolved && statusIdAirfel === 2) || (kotelEnvolved && statusIdAirfel === 3)) && (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setModal({show: true, user});
                                        }}
                                        className="btn btn-table box-shadow br-5"
                                        style={{
                                            backgroundColor: "#F4F4F5",
                                        }}
                                    >
                                        <Gift
                                            color={"#d94843"}
                                            size={16}
                                        />
                                    </button>
                                )}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>

            {pageCount > 1 && (
                <ReactPaginate
                    forcePage={currentPage}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={paginate}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item box-shadow br-5"}
                />
            )}
            {/*master-change-point-status*/}
            {modal.user && (
                <ChangeStatusPrize
                    modal={modal}
                    setModal={setModal}
                    getItems={getItems}
                    statusOptions={changeStatusOptions}
                    setStatusOptions={setChangeStatusOptions}
                />
            )}
            {giftModal.user && (
                <GiveGift
                    modal={giftModal}
                    setModal={setGiftModal}
                    getItems={getItems}
                    statusOptions={changeStatusOptions}
                    setStatusOptions={setChangeStatusOptions}
                />
            )}
            {pushModal.user && (
                <PushModal
                    modal={pushModal}
                    setModal={setPushModal}
                    getItems={getItems}
                    statusOptions={changeStatusOptions}
                    setStatusOptions={setChangeStatusOptions}
                />
            )}
        </div>
    );
}

export default ExchangePrize;
