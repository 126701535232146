import React from 'react';
import {Link} from 'react-router-dom';
import {Container,Image} from 'react-bootstrap'

class Footer extends React.Component {

	render() {
    	return (
    		<>
		      <footer className="py-4 tablet-none" style={{backgroundColor: '#262134'}}>
		         <Container>
							 <div className="d-flex justify-content-between">
								 <Image src="img/footer-logo.png" height={24} alt='' />
							 		<div className="d-flex ">
										 <div className="d-flex flex-column mr-5">
											<span className="fz12 mb-2" style={{color: 'rgba(255, 255, 255, 0.35)'}}>Присоединяйтесь к нам </span>
											<div className="d-flex">
												<Image src="img/Facebook.png" height={24} alt='' className="mr-3" />
												<Image src="img/Instagram.png" height={24} alt='' className="mr-3" />
												<Image src="img/Youtube.png" height={24} alt='' />
											</div>
										 </div>
										 <div className="d-flex flex-column">
										 	<span className="fz12 mb-2" style={{color: 'rgba(255, 255, 255, 0.35)'}}>Заказывайте с телефона  </span>
											 <div>
											 	<Image src="img/app-store.png" height={24} alt='' className="mr-3" />
										 		<Image src="img/google-play.png" height={24} alt='' className="mr-3" />
											 </div>
										 </div>
									 </div>
							 </div>
							 <div className="d-flex flex-wrap">
								 <Link to='/' className="text-white mr-3">Для ресторанов</Link>
									<Link to='/' className="text-white mr-3">Курьерам</Link>
									<Link to='/' className="text-white mr-3">Пресс-центр</Link>
									<Link to='/' className="text-white mr-3">Условия акций</Link>
									<Link to='/' className="text-white">Контакты</Link>
							 </div>
							 <div className="d-flex justify-content-end align-items-cente pb-3" style={{borderBottom: '1px solid rgba(255, 255, 255, 0.35)'}}><Image src="img/phone.png" height={24} alt='' className="mr-1" /> <span className="fz18 font-weight-bold text-white">(+998) 88-888-88-88</span></div>
							<div className="mt-3" style={{color: 'rgba(255, 255, 255, 0.65)', fontWeight: 500}}>© 2021, Calibri</div>
						 </Container>
		      </footer>
					<footer className="py-4 mobile-header" style={{backgroundColor: '#262134'}}>
						<Container>
							 <div className="d-flex flex-wrap justify-content-between">
								 <Image src="img/footer-logo.png" height={24} alt='' className="mb-2" />
							 		<div className="d-flex justify-content-between">
										 <div className="d-flex flex-column mr-5">
											<span className="fz12 mb-2" style={{color: 'rgba(255, 255, 255, 0.35)'}}>Присоединяйтесь к нам </span>
											<div className="d-flex">
												<Image src="img/Facebook.png" height={24} alt='' className="mr-3" />
												<Image src="img/Instagram.png" height={24} alt='' className="mr-3" />
												<Image src="img/Youtube.png" height={24} alt='' />
											</div>
										 </div>
										 <div className="d-flex flex-column">
										 	<span className="fz12 mb-2" style={{color: 'rgba(255, 255, 255, 0.35)'}}>Заказывайте с телефона  </span>
											 <div>
											 	<Image src="img/app-store.png" height={24} alt='store' className="mr-3" />
										 		<Image src="img/google-play.png" height={24} alt='store' className="mr-3" />
											 </div>
										 </div>
									 </div>
							 </div>
							 <div className="my-3 d-flex flex-wrap">
									<Link to='/' className="text-white mr-3">Для ресторанов</Link>
									<Link to='/' className="text-white mr-3">Курьерам</Link>
									<Link to='/' className="text-white mr-3">Пресс-центр</Link>
									<Link to='/' className="text-white mr-3">Условия акций</Link>
									<Link to='/' className="text-white">Контакты</Link>
							 </div>
							 <div className="d-flex justify-content-end align-items-cente pb-3" style={{borderBottom: '1px solid rgba(255, 255, 255, 0.35)'}}><Image src="img/phone.png" height={24} alt='phone' className="mr-1" /> <span className="fz18 font-weight-bold text-white">(+998) 88-888-88-88</span></div>
							<div className="mt-3" style={{color: 'rgba(255, 255, 255, 0.65)', fontWeight: 500}}>© 2021, Calibri</div>
						 </Container>
					</footer>
		    </>
    	);
    }
}

export default Footer;