import React, { useState } from 'react'

function Languages() {
  const [data, setData] = useState({ gender: true, imageUrl: null })

  return (
    <div>
      <div className="pb-2 d-flex justify-content-center align-items-center">
        <div className="m-0 fz28">Язык</div>
      </div>
      <div className="col-lg-4 col-md-4 col-sm-12 offset-lg-4 offset-md-2 bg-white p-5">
        <div className="mb-3">
          <input type="radio" onChange={() => setData({ ...data, gender: true })}
            checked={data.gender} id="uz" name='gender' className="custom-rb custom-rb-purple" />
          <label className="mb-0" htmlFor="uz">Узбекский</label>
        </div>
        <div className="mb-3">
          <input type="radio" onChange={() => setData({ ...data, gender: true })}
            checked={data.gender} id="rus" name='gender' className="custom-rb custom-rb-purple" />
          <label className="mb-0" htmlFor="rus">Русский</label>
        </div>
        <div className="mb-3">
          <input type="radio" onChange={() => setData({ ...data, gender: true })}
            checked={data.gender} id="en" name='gender' className="custom-rb custom-rb-purple" />
          <label className="mb-0" htmlFor="en">Английский</label>
        </div>
      </div>
    </div>
  )
}

export default Languages
