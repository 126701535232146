import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import InputMask from 'react-input-mask';
import moment from 'moment'
import { XCircle, Save, Calendar } from 'react-feather';

import { GET, POST, PUT } from '../../../api/adminApi'
import { showLoader, hideLoader } from '../../../store/actions/loader';
import { store } from '../../../store';


import "react-datepicker/dist/react-datepicker.css";


function OperatorsAddUpdate() {
	let { id } = useParams();
	let history = useHistory();
	const [pickedDate, setPickedDate] = useState(new Date());
	const { register, handleSubmit, formState: { errors }, setValue } = useForm({
		activated: false,
		address: "",
		birthDate: "",
		email: "",
		gender: 0,
		login: "",
		password: "",
		firstName: "",
		lastName: "",
		homePhone: "",
		mobilePhone: "",
	});
	const [data, setData] = useState({
		gender: true,
		activated: true
	})
	const lastDateOfYear = `12/31/${new Date().getFullYear()}`;

	async function getProduct() {
		store.dispatch(showLoader())
		const response = await GET('/services/admin/api/operators/' + id, true)
		for (let key in response) {
			setValue(`${key}`, response[key])
		}
		setData({ ...data, activated: response.activated, gender: response.gender === 0 ? true : false })
		store.dispatch(hideLoader())
	}

	async function create(data) {
		data.mobilePhone = data.mobilePhone.replace(/[^0-9]/g, '')
		data.homePhone = data.homePhone.replace(/[^0-9]/g, '')
		var response;
		if (id) {
			response = await PUT('/services/admin/api/operators', data)
		}
		else {
			response = await POST('/services/admin/api/operators', data)
		}
		if (response) {
			history.push('/admin/operators')
		}
	}

	useEffect(() => {
		if (id) {
			getProduct()
		} else {
			setValue('gender', 0)
			setValue('activated', true)
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const CustomInput = React.forwardRef((props, ref) => {
		return (
			<div onClick={props.onClick} ref={ref} className='position-relative cursor' >
				<input {...register("birthDate", { required: true })} className="input-control" value={props.value || props.placeholder} />
				<div className='position-absolute' style={{ top: '7px', right: 10 }}>
					<Calendar size={18} />
				</div>
			</div>
		);
	});

	return (
		<div className="mb-2 mx-3 ">
			<h3 className="mb-3 mt-1">Добавить новый</h3>
			<div className="admin-card p-4" >
				<form autoComplete='off' autoCorrect='off' onSubmit={handleSubmit(create)}>
					<div className="row">
						<div className="col-md-4 mb-3">
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Имя</label></div>
									<input {...register("firstName", { required: true })} type="text" placeholder="Имя" className="input-control" />
								</div>
								{errors.firstName?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Фамилия</label></div>
									<input {...register("lastName", { required: true })} type="text" placeholder="Фамилия" className="input-control" />
								</div>
								{errors.lastName?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Адрес</label></div>
									<input {...register("address", { required: true })} type="text" placeholder="Адрес" className="input-control" />
								</div>
								{errors.address?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>

							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0 text-right">Мобильный телефон</label></div>
									<InputMask mask='+999-99-999-99-99' {...register("mobilePhone", { required: true })}
										type="text" placeholder="+999-99-999-99-99" className="input-control" />
								</div>
								{errors.mobilePhone?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>

							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0 text-right">Домашний телефон</label></div>
									<InputMask mask='+999-99-999-99-99' {...register("homePhone", { required: true })}
										type="text" placeholder="+999-99-999-99-99" className="input-control" />
								</div>
								{errors.homePhone?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>

							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3">
										<label className="mb-0">Пол</label>
									</div>
									<div className="w-100 d-flex justify-content-center">
										<input type="radio" onChange={(e) => setData({ ...data, gender: true }) + setValue('gender', 0)}
											checked={data.gender} id="man" name='gender' className="custom-rb" />
										<label className="mb-0" htmlFor="man">Мужской</label>
										<div className="mr-3"></div>
										<input type="radio" onChange={(e) => setData({ ...data, gender: false }) + setValue('gender', 1)}
											checked={!data.gender} id="women" name='gender' className="custom-rb" />
										<label className="mb-0" htmlFor="women">Женский</label>
									</div>
								</div>
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">День рождение</label></div>
									{/* <input {...register("birthday", { required: true })} type="date" className="input-control" /> */}
									<DatePicker
										customInput={<CustomInput />}
										className='input-control'
										showYearDropdown
										dateFormatCalendar="MMMM"
										yearDropdownItemNumber={60}
										scrollableYearDropdown
										maxDate={new Date(lastDateOfYear)}
										// maxDate={moment().startOf('year')}
										dateFormat="yyyy-MM-dd"
										{...register("birthDate", { required: true })}
										selected={pickedDate}
										onChange={(date) => (setPickedDate(date) + setValue('birthDate', moment(date).format("YYYY-MM-DD")))} />
								</div>
								{errors.birthday?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
						</div>
						<div className="col-md-4">
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0 text-right">Имя пользователя</label></div>
									<input {...register("login", { required: true })} type="text" autoComplete="off" placeholder="Имя пользователя" className="input-control" />
								</div>
								{errors.login?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Пароль</label></div>
									{
										id ? <input {...register("password")} type="password" autoComplete="off" placeholder="Пароль" className="input-control" />
											:
											<>
												<input {...register("password", { required: true })} type="password" autoComplete="off" placeholder="Пароль" className="input-control" />
											</>
									}
								</div>
								{errors.password?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0 text-right">Электронная почта</label></div>
									<input {...register("email", { required: true, pattern: /^\S+@\S+$/i })} type="text" placeholder="Электронная почта  " className="input-control" />
								</div>
								{errors.email?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
								{errors.email?.type === 'pattern' && <div className='text-danger mt-1 fz12'>Не валидный email</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className='admin-label'><label className="mb-0">Статус</label></div>
									<div className="w-100 d-flex justify-content-center">
										<input type="radio" onChange={(e) => setData({ ...data, activated: true }) + setValue('activated', true)}
											checked={data.activated} id="activated-1" name='activated' className="custom-rb" />
										<label className="mb-0" htmlFor="activated-1">Активный</label>
										<div className="mr-3"></div>
										<input type="radio" onChange={(e) => setData({ ...data, activated: false }) + setValue('activated', false)}
											checked={!data.activated} id="deactivated-1" name='activated' className="custom-rb" />
										<label className="mb-0" htmlFor="deactivated-1">Заблокирован</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex btn-postion">
						<Link to='/admin/operators' className="mr-3"><button className="btn btn-outline-purple d-flex align-items-center"><XCircle size={14} className="mr-2" />Отмена</button></Link>
						<button type='submit' className="btn btn-purple d-flex align-items-center"><Save size={14} className="mr-2" />Сохранить </button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default OperatorsAddUpdate;
