import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { XCircle, Save } from 'react-feather';

import { GET, POST, PUT, FILE } from '../../../api/adminApi'
import { showLoader, hideLoader } from '../../../store/actions/loader';
import { store } from '../../../store';

import "react-datepicker/dist/react-datepicker.css";

const animatedComponents = makeAnimated();

function ProductAddUpdate() {
	let { id } = useParams();
	let history = useHistory();
	const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
		categoriesId: 0,
		description: "",
		galleryList: [],
		id: 0,
		imageUrl: "",
		inStock: true,
		ingredients: "",
		name: "",
		cuisinesList: [],
		productBranchList: [
			{
				branchId: 0,
				branchName: "",
				currencyId: 0,
				currencyName: "",
				id: 0,
				price: 0,
				productId: 0,
				productName: ""
			}
		],
		shortName: ""
	})
	const [categories, setCategories] = useState([])
	const [brands, setBrands] = useState([])
	const [currency, setCurrency] = useState([])
	const [galleryList, setGalleryList] = useState([])
	const [data, setData] = useState({ imageUrl: '', inStock: true })
	const [productBranch, setProductBranch] = useState([])
	//const [productBranchList, setProductBranchList] = useState([])

	const [brand, setBrand] = useState([])
	const [cuisines, setCuisines] = useState([])
	const [branchs, setBranchs] = useState([])
	const [valuesCuisines, setValuesCuisines] = useState([])
	const [valuesBranchs, setValuesBranchs] = useState([])

	async function getProduct() {
		const response = await GET('/services/admin/api/product/' + id, false)
		for (let key in response) {
			setValue(`${key}`, response[key])
		}
		setData({ ...data, imageUrl: response.imageUrl, inStock: response.inStock })
		setGalleryList(response.galleryList)
		setProductBranch(response.productBranchList)
		const brandById = await GET('/services/admin/api/brand/' + response.brandId, false)
		setBrand(brandById)
		const cuisinesHelper = await GET('/services/admin/api/cuisines-helper', false)
		const arr = []
		for (let i = 0; i < cuisinesHelper.length; i++) {
			for (let j = 0; j < response.cuisinesList.length; j++) {
				if (cuisinesHelper[i].id === response.cuisinesList[j]) {
					arr.push({
						id: cuisinesHelper[i].id,
						name: cuisinesHelper[i].name,
					});
				}
			}
		}
		setValuesCuisines(arr)
		const branchs = []
		for (let i = 0; i < response.productBranchList.length; i++) {
			branchs.push({
				id: response.productBranchList[i].branchId,
				name: response.productBranchList[i].branchName,
				price: response.productBranchList[i].price,
				currencyId: response.productBranchList[i].currencyId,
				currencyName: response.productBranchList[i].currencyName,
				productId: response.productBranchList[i].productId,
				productName: response.productBranchList[i].productName

			});
		}
		setValuesBranchs(branchs)
	}

	async function create(data) {
		for (let i = 0; i < productBranch.length; i++) {
			productBranch[i].productName = getValues('name')
			productBranch[i].currencyId = 1
		}
		var body = {
			...data,
			productBranchList: productBranch,
			galleryList: galleryList
		}
		var response;
		if (id) {
			response = await PUT('/services/admin/api/product', body)
		}
		else {
			response = await POST('/services/admin/api/product', body)
		}
		if (response) {
			history.push('/admin/product')
		}
	}

	function setPrice(e, index) {
		var copy = productBranch
		copy[index].price = e.target.value
		setProductBranch(copy)
	}

	function setCurrencyId(e, index) {
		var copy = productBranch
		copy[index].currencyId = currency[e.target.value].id
		copy[index].currencyName = currency[e.target.value].shortName
		setProductBranch(copy)
	}

	function handleChange(selectedOption) {
		console.log(selectedOption);
		const arr = []
		for (let i = 0; i < selectedOption.length; i++) {
			arr.push({
				branchId: selectedOption[i].id,
				branchName: selectedOption[i].name,
				price: selectedOption[i].price,
				currencyId: selectedOption[i].currencyId,
				currencyName: selectedOption[i].currencyName,
			});
		}
		setValuesBranchs(selectedOption)
		setProductBranch(arr)
	}

	function selectBrand(selectedOption) {
		setBrand(selectedOption)
		getBranch(selectedOption.id)
		setValue('brandId', selectedOption.id)
	}

	function selectCousines(selectedOption) {
		var arr = []
		for (let i = 0; i < selectedOption.length; i++) {
			arr.push(selectedOption[i]['id'])
		}
		setValuesCuisines(selectedOption)
		setValue('cuisinesList', arr)
	}

	async function getImageUrl(e, mainPhoto = false) {
		if (e.target.files[0]) {
			const image = e.target.files[0];
			const formData = new FormData();
			formData.append('image', image);
			var response;
			if (mainPhoto) {
				response = await FILE("/services/admin/api/product-image-resize-upload", formData);
				setData({ ...data, imageUrl: response.url })
				setValue('imageThumbUrl', response.urlTh)
			} else {
				response = await FILE("/services/admin/api/product-image-upload", formData);
				setGalleryList([...galleryList, response.url])
			}
			setValue('imageUrl', response.url)
		
		}
	}

	async function getBranch(id) {
		const response = await GET('/services/admin/api/branch-helper/' + id, true)
		setBranchs(response)
	}

	async function getCategories() {
		const response = await GET('/services/admin/api/categories-helper', false)
		if (response.length > 0) {
			setCategories(response)
			setValue('categoriesId', response[0].id)
		}
	}

	async function getBrands() {
		const response = await GET('/services/admin/api/brand-helper', false)
		if (response.length > 0) {
			setBrands(response)
			getBranch(response[0].id)
			setValue('brandId', response[0].id)
		}
	}

	async function getCurrency() {
		const response = await GET('/services/admin/api/currency-helper', false)
		if (response.length > 0) {
			setCurrency(response.reverse())
		}
	}

	async function getCuisines() {
		const response = await GET('/services/admin/api/cuisines-helper', false)
		if (response.length > 0) {
			setCuisines(response)
		}
	}

	// function deleteImage(index) {
	// 	var copy = galleryList
	// 	copy[index] = ''
	// 	console.log(copy[index]);
	// 	// copy.splice(index, 1)
	// 	setGalleryList(copy)
	// 	console.log(galleryList);
	// }

	async function getData() {
		store.dispatch(showLoader())
		await getCategories()
		await getCurrency()
		await getBrands()
		await getCuisines()
		if (id) {
			await getProduct()
		}
		store.dispatch(hideLoader())
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="mb-2 mx-3">
			<h3 className='mb-3 mt-1'>Добавить новый</h3>
			<div className="admin-card p-4" >
				<form onSubmit={handleSubmit(create)} autoComplete='off' action=''>
					<div className="row">
						<div className="col-md-4 mb-3">
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0 text-right">Название продукта</label></div>
									<input {...register("name", { required: true })} type="text" placeholder="Название продукта  " className="input-control" />
								</div>
								{errors.name?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0 text-right">Краткое название продукта</label></div>
									<input {...register("shortName", { required: true })} type="text" placeholder="Краткое название продукта" className="input-control" />
								</div>
								{errors.shortName?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0 text-right">Категория продукта</label></div>
									<select className="admin-select" {...register("categoriesId", { required: true })} onChange={(e) => setValue('categoriesId', e.target.value)} >
										{categories.map((item) => {
											return (
												<option key={item.id} value={item.id}>{item.name} </option>
											)
										})
										}
									</select>
								</div>
								{errors.categoriesId?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Ингредиенты</label></div>
									<textarea {...register("ingredients", { required: true })} style={{ resize: 'none' }} className='input-control w-100' cols="10" />
								</div>
								{errors.ingredients?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Описание</label></div>
									<textarea {...register("description", { required: true })} style={{ resize: 'none' }} className='input-control w-100' cols="10" />
								</div>
								{errors.description?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">В наличии </label></div>

									<div className="w-100 d-flex justify-content-center">
										<input type="radio" onChange={(e) => setData({ ...data, inStock: true }) + setValue('inStock', true)}
											checked={data.inStock} id="activated-1" name='activated' className="custom-rb" />
										<label className="mb-0" htmlFor="activated-1">Есть</label>
										<div className="mr-3"></div>
										<input type="radio" onChange={(e) => setData({ ...data, inStock: false }) + setValue('inStock', false)}
											checked={!data.inStock} id="deactivated-1" name='activated' className="custom-rb" />
										<label className="mb-0" htmlFor="deactivated-1">Нет</label>

									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4 mb-3">
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3">Бренды</div>
									<Select
										defaultValue={brands[0]}
										value={brand}
										placeholder="Выберите бренд"
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										options={brands}
										className="basic-multi-select w-100"
										classNamePrefix="Выбирать"
										onChange={selectBrand}
									/>
								</div>
								{errors.cuisinesIdList?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3">Кухни</div>
									<Select
										defaultValue={cuisines[0]}
										value={valuesCuisines}
										placeholder="Выберите кухни"
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										components={animatedComponents}
										isMulti
										name="brandsTypes"
										options={cuisines}
										className="basic-multi-select w-100"
										classNamePrefix="Выбирать"
										onChange={selectCousines}
									/>
								</div>
								{errors.cuisinesIdList?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3">Филиалы</div>
									<Select
										defaultValue={branchs[0]}
										value={valuesBranchs}
										placeholder="Выберите филиал"
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										components={animatedComponents}
										isMulti
										name="branchs"
										options={branchs}
										className="basic-multi-select w-100"
										classNamePrefix="Выбирать"
										onChange={handleChange}
									/>
								</div>
								{errors.cuisinesIdList?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							{productBranch.length > 0 &&
								<div className="w-100">
									{productBranch.map((item, index) => {
										return (
											<div key={item.branchId} className="admin-field">
												<div className="d-flex w-100">
													<div className="admin-label mr-md-3">{item.branchName}</div>
													<input onChange={(e) => setPrice(e, index)} type="number" className="input-control" placeholder={item.price} />
													<select onChange={(e) => setCurrencyId(e, index)}
														className="border border-radius-3 box outline-none color-grey py-2 position-absolute"
														style={{ right: '14px', width: '50px', background: '#F4F4F5', zIndex: 0 }}>
														{currency.map((item, index) => {
															return (<option key={item.id} value={index}>{item.shortName}</option>)
														})
														}
													</select>
												</div>
											</div>
										)
									})
									}
								</div>
							}
						</div>
						<div className="col-md-4 mb-3 d-flex flex-column align-items-start color-grey">
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="row w-100 d-flex align-items-center mb-3">
										<div className='col-md-12 col-sm-4 col-2 d-flex align-items-center mb-2'><label className="mb-0">Основное фото продукта</label></div>
										<div className="position-relative d-flex mb-3">
											<div className="image-block"
												style={{ width: '170px', height: '135px' }}>
												Перетащите сюда файлы для загрузки
											</div>
											<input onChange={(e) => getImageUrl(e, true)} type="file" style={{ width: '170px', height: '135px' }} className="image-input" />
											{data.imageUrl &&
												<div className="position-absolute border box-shadow cursor border-radius-3" style={{ width: '170px', height: '135px', top: 0, zIndex: 2 }}>
													<img src={'https://colibri24.uz' + (data.imageUrl)} style={{ width: '170px', height: '135px' }} alt="" />
												</div>
											}
										</div>
										<div className='col-md-12 col-sm-4 col-2 d-flex mb-2'><label className="mb-0">Другие фото</label></div>
										<div className='row d-flex justify-content-start'>
											{galleryList.map((item, index) => {
												return (
													<div key={item} className="col-md-12">
														<div className="position-relative mr-2 mb-3">
															<div className="cursor border box-shadow" style={{ width: '170px', height: '135px' }}>
																<img src={'https://colibri24.uz' + (item)} key={item} style={{ width: '170px', height: '135px' }} alt="" />
															</div>
														</div>
													</div>
												)
											})
											}
											<div className="col-md-12">
												<div className="position-relative">
													<div className="image-block" style={{ width: '170px', height: '135px' }}>Перетащите сюда файлы для загрузки</div>
													<input onChange={(e) => getImageUrl(e)} type="file" style={{ width: '170px', height: '135px', }} className="image-input" />
													{/* {errors.imageUrl?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>} */}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="btn-postion d-flex">
						<Link to='/admin/product' className="mr-3"><button className="btn btn-outline-purple d-flex align-items-center"><XCircle size={14} className="mr-2" />Отмена</button></Link>
						<button type='submit' className="btn btn-purple d-flex align-items-center"><Save size={14} className="mr-2" />Сохранить</button>
					</div>
				</form>
			</div>
		</div>
	);
}


export default ProductAddUpdate;