import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react';
import { Trash } from 'react-feather';
// import Geocode from "react-geocode";

import { GET, POST, DELETE, PUT } from '../../../api/adminApi';


function Addresses() {
  const [active, setActive] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [addresses, setAddresses] = useState([])
  const [temporaryPosition, setTemporaryPosition] = useState({
    lat: 41.311081,
    lng: 69.240562
  })
  const [data, setData] = useState({
    apartment: '',
    entrance: '',
    floor: '',
    referencePoint: ''
  })

  function onClick(t) {
    if (!showModal) {
      setShowModal(true)
      setTemporaryPosition({ ...temporaryPosition, lat: t.lat, lng: t.lng })
    }
  }
  async function savePosition() {
    var method = "create"
    var index;
    for (let i = 0; i < addresses.length; i++) {
      if (active + 1 === addresses[i].locationType) {
        method = "update"
        index = i
      }
    }
    if (method === "update") {
      await PUT('/services/web/api/user-addresses', {
        id: addresses[index].id,
        gpsPointX: temporaryPosition.lat,
        gpsPointY: temporaryPosition.lng,
        locationName: addresses[index].locationName,
        locationType: addresses[index].locationType,
        apartment: data.apartment,
        entrance: data.entrance,
        floor: data.floor,
        referencePoint: data.referencePoint
      }, true)
    } else {
      await POST('/services/web/api/user-addresses', {
        gpsPointX: temporaryPosition.lat,
        gpsPointY: temporaryPosition.lng,
        locationName: active === 0 ? "Дом" : active === 1 ? "Работа" : "Другое",
        locationType: active + 1,
        apartment: data.apartment,
        entrance: data.entrance,
        floor: data.floor,
        referencePoint: data.referencePoint
      }, true)
    }

    await getAddresses()
    setShowModal(false)
  }

  function changePosition(index) {
    var copy = addresses
    for (let i = 0; i < copy.length; i++) {
      copy[i].active = false
    }
    copy[index].active = true
    setTemporaryPosition({
      ...temporaryPosition,
      lat: copy[index].gpsPointX, lng: copy[index].gpsPointY
    })
  }

  async function getAddresses() {
    const response = await GET(`/services/web/api/user-addresses-pageList?size=6 `)
    if (response.length) {
      for (let i = 0; i < response.length; i++) {
        response[i].active = false
      }
      response[0].active = true
      setAddresses(response)
      setTemporaryPosition({
        ...temporaryPosition,
        lat: response[0].gpsPointX, lng: response[0].gpsPointY
      })
    }
  }

  async function deleteAddress(id) {
    await DELETE('/services/web/api/user-addresses/' + id)
    getAddresses()
  }

  useEffect(() => {
    // Geocode.setApiKey("AIzaSyDAbYk6feEFJSoJBLASXdK7kdvzEaq0A08");
    // Geocode.setLanguage("ru");
    // Geocode.setRegion("uz");
    // Geocode.setLocationType("ROOFTOP");
    // Geocode.fromLatLng("48.8583701", "2.2922926").then( 
    //   (response) => {
    //     const address = response.results[0].formatted_address;
    //     console.log(address);
    //   },
    //   (error) => {
    //     console.error(error);
    //   }
    // );
    getAddresses()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const Home = props => {
    return (
      <div lat={props.lat} lng={props.lng}>
        <div className="profile-pin" >
          <img src="img/house.png" alt="" height={24} width={24} className="pin-image" />
        </div>
      </div>
    )
  }

  const Work = props => {
    return (
      <div lat={props.lat} lng={props.lng}>
        <div className="profile-pin" >
          <img src="img/building.png" alt="" height={24} width={24} className="pin-image" />
        </div>
      </div>
    )
  }

  const Other = props => {
    return (
      <div lat={props.lat} lng={props.lng}>
        <div className="profile-pin" >
          <img src="img/placeholder.png" alt="" height={24} width={24} className="pin-image" />
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="mb-3 fz28">Мои адреса</div>
      <div className="row">
        <div className="col-md-4">
          {
            addresses.map((item, index) => {
              return (
                <div key={item.id} className={"address-btn cursor " + (item.active ? "address-btn-active" : "")} onClick={() => changePosition(index)}>
                  <img src={item.locationType === 1 ? "img/house.png" : item.locationType === 2 ? "img/building.png" : "img/placeholder.png"}
                    alt="" className="h-fit-content mr-2" />
                  <div className="deleteIcon" onClick={() => deleteAddress(item.id)}>
                    <Trash size={17} />
                  </div>
                  <div className="d-flex flex-column">
                    <div className="fz18"><b>{item.locationName}</b></div>
                    <div>Себзор кўчаси, Тошкент, Узбекистан</div>
                  </div>
                </div>
              )
            })
          }
          {/* <div className="address-btn">
            <img src="img/building.png" alt="" className="h-fit-content mr-2" />
            <div className="d-flex flex-column">
              <div className="fz18 color-purple"><b>Работа</b></div>
              <div className="color-grey">улица Массив-Алмазар школа 90, Tashkent, Узбекистан</div>
            </div>
          </div> */}
          <div className="text-center color-grey">
            Для добавления нового адреса укажите место на карте
          </div>
        </div>
        <div className="col-md-8 position-relative" style={{ height: '570px' }}>
          {
            showModal &&
            <div className="address-modal">
              <div className="mb-3"> Себзор кўчаси, Тошкент, Узбекистан </div>
              <div className="d-flex justify-content-center mb-3">
                <div className={"rounded-text cursor px-3 mr-3 " + (active === 0 && "bg-purple")} onClick={() => setActive(0)}>
                  <img src="img/house.png" alt="" height={16} width={16} className="mr-2" />
                  Дом
                </div>
                <div className={"rounded-text cursor px-3 mr-3 " + (active === 1 && "bg-purple")} onClick={() => setActive(1)}>
                  <img src="img/building.png" alt="" height={16} width={16} className="mr-2" />
                  Работа
                </div>
                <div className={"rounded-text cursor px-3 mr-3 " + (active === 2 && "bg-purple")} onClick={() => setActive(2)}>
                  <img src="img/placeholder.png" alt="" height={16} width={16} className="mr-2" />
                  Другое
                </div>
              </div>
              <div>
                <div className="address-field w-75 mx-auto">
                  <input type="mail" className="address-input"
                    value={data.referencePoint} onChange={(e) => setData({ ...data, referencePoint: e.target.value })} />
                  <label className="profile-label">Ориентир</label>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="address-field w-max-content">
                    <input type="mail" className="address-input"
                      value={data.entrance} onChange={(e) => setData({ ...data, entrance: e.target.value })} />
                    <label className="address-label">Подъезд</label>
                  </div>
                  <div className="address-field w-max-content">
                    <input type="mail" className="address-input"
                      value={data.floor} onChange={(e) => setData({ ...data, floor: e.target.value })} />
                    <label className="address-label">Этаж</label>
                  </div>
                  <div className="address-field w-max-content">
                    <input type="mail" className="address-input"
                      value={data.apartment} onChange={(e) => setData({ ...data, apartment: e.target.value })} />
                    <label className="address-label">Квартира</label>
                  </div>
                </div>
              </div>
              <div className="d-flex mb-4">
                <input type="radio" id="address" className="custom-rb" />
                <label className="mb-0" htmlFor="address">Cделать адрес по умолчанию </label>
              </div>
              <div className="d-flex justify-content-around">
                <button className="btn btn-grey b-r-50" onClick={() => setShowModal(false)}>
                  Отменить
                </button>
                <button className="btn btn-purple b-r-50"
                  onClick={() => savePosition()}>
                  Сохранить
                </button>
              </div>
            </div>
          }
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyDRnmjaTmZQmjt4sxKaNVW_DqOfp993Afc" }}
            defaultZoom={13}
            yesIWantToUseGoogleMapApiInternals
            center={{ lat: temporaryPosition.lat, lng: temporaryPosition.lng }}
            onClick={onClick}
            options={{ disableDefaultUI: true }}
          // onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
          >
            {
              addresses.map((item) => {
                console.log();
                return (
                  item.locationType === 1 ?
                    <Home key={item.id} lat={item.gpsPointX} lng={item.gpsPointY} />
                    : item.locationType === 2 ?
                      <Work key={item.id} lat={item.gpsPointX} lng={item.gpsPointY} />
                      :
                      <Other key={item.id} lat={item.gpsPointX} lng={item.gpsPointY} />
                )
              })
            }
          </GoogleMapReact>

        </div>
      </div>
    </div>
  )

}

export default Addresses
