import React, {useEffect, useState} from "react";
import {PGET} from "../../../api/adminApi";
import DatePicker from "react-datepicker";
import moment from "moment/moment";

export default function ProductSales() {
    const [items, setItems] = useState([]);
    const [dateRange, setDateRange] = useState([
        new Date('2024-01-01'),
        new Date('2025-01-01'),
    ]);
    const [startDate, endDate] = dateRange;
    async function getItems() {
        if (startDate && endDate) {
            const data = await PGET("/services/admin/api/dashboard-products-sales/", true, {
                dateFrom: moment(startDate).format("YYYY-MM-DD"),
                dateTo: moment(endDate).add(1, 'day').format("YYYY-MM-DD"),
            });
            if (data) {
                setItems(data.data);
            }
        }
    }
    useEffect(() => {
        getItems()
    },[startDate, endDate])
    return (
        <div className="pb-4" style={{minHeight: 400}}>
            <div style={{width: 200, position: "absolute", right: 10, top: 20}}>
                <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                    }}
                    isClearable={true}
                    className="datepicker-custom-input"
                />
            </div>
            <div style={{overflowX: "auto"}}>
                <table className='table bg-white table-rounded table-striped' style={{fontSize: 12}}>
                    <thead>
                    <tr className='fw-bold text-gray-800 border-bottom-2 border-gray-200'>
                        <th style={{width: "30px"}} className="text-center">
                            №
                        </th>
                        <th className="text-start">Название продукта</th>
                        <th className="text-center">Кол-во продаж</th>
                        <th className="text-center">Кол-во ребер</th>
                    </tr>
                    </thead>

                    <tbody style={{overflowX: "auto"}}>
                    {items.map((item, index) => {
                        return (<tr key={item.id}>
                            <td className="text-center">
                                {index + 1}
                            </td>
                            <td className="text-start">
                                {item.productName}
                            </td>
                            <td className="text-center">
                                {item.soldCount}
                            </td>
                            <td className="text-center">
                                {item.sectionCount}
                            </td>

                        </tr>);
                    })}
                    </tbody>
                </table>
            </div>
        </div>)
}

