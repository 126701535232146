import React, {useEffect, useState} from 'react';
import {Bar, Line, Pie, Doughnut} from 'react-chartjs-2';
import PropTypes from 'prop-types';
import {ChartContainer} from "../../../style/style";
import {customTooltips} from "../../../utils/utilities";
import useChartData from "../../../hooks/useChartData";
import {GET, PGET} from "../../../api/adminApi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import moment from "moment";


const ChartjsBarChart = props => {
    const [items, setItems] = useState([])

    async function getItems() {
        const data = await PGET("/services/admin/api/dashboard-month-counts/", true, {});
        setItems(data.data);
    }

    const dataValues = items.map(item => item.soldCount);
    const maxValue = Math.max(...dataValues);

    const datasets = [
        {
            data: dataValues,
            backgroundColor: 'rgb(54,122,215, 0.9)',
            barPercentage: 0.6,
        },
    ];
    useEffect(() => {
        getItems()
    }, [])
    const {labels, options, height} = props;
    const data = {
        datasets,
        labels,
    };
    options.scales.yAxes[0].ticks.max = maxValue * 1.5;

    return <Bar data={data} height={height} options={options}/>;
};

ChartjsBarChart.defaultProps = {
    height: 150,
    labels: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],

    options: {
        maintainAspectRatio: true,
        responsive: true,
        legend: {
            display: false,
            labels: {
                display: false,
            },
        },
        scales: {
            yAxes: [
                {
                    gridLines: {
                        color: '#e5e9f2',
                    },
                    ticks: {
                        beginAtZero: true,
                        fontSize: 10,
                        fontColor: '#182b49',
                    },
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        fontSize: 11,
                        fontColor: '#182b49',
                    },
                },
            ],
        },
    },
};

ChartjsBarChart.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string),
    height: PropTypes.number,
    datasets: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.object,
};

const ChartjsStackedChart = props => {
    const {labels, datasets, options, height} = props;
    const data = {
        datasets,
        labels,
    };
    return <Bar data={data} height={height} options={options}/>;
};

ChartjsStackedChart.defaultProps = {
    height: 200,
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

    datasets: [
        {
            data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
            backgroundColor: 'rgb(54,122,215, 0.9)',
            barPercentage: 0.6,
        },
        {
            data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
            backgroundColor: 'rgba(250,0,54,0.5)',
            barPercentage: 0.6,
        },
    ],

    options: {
        maintainAspectRatio: true,
        responsive: true,
        legend: {
            display: false,
            labels: {
                display: false,
            },
        },
        scales: {
            yAxes: [
                {
                    stacked: true,
                    gridLines: {
                        color: '#e5e9f2',
                    },
                    ticks: {
                        beginAtZero: true,
                        fontSize: 10,
                        fontColor: '#182b49',
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                    gridLines: {
                        display: false,
                    },

                    ticks: {
                        beginAtZero: true,
                        fontSize: 11,
                        fontColor: '#182b49',
                    },
                },
            ],
        },
    },
};

ChartjsStackedChart.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.object,
};

const ChartjsLineChart = props => {
    const {labels, datasets, options, height, layout, width, id} = props;
    const data = {
        labels,
        datasets,
    };
    return (
        <ChartContainer className="parentContainer">
            <Line
                id={id && id}
                width={width}
                data={data}
                height={height}
                options={{
                    ...options,
                    ...layout,
                }}
            />
        </ChartContainer>
    );
};

ChartjsLineChart.defaultProps = {
    height: 479,
    width: null,
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
        {
            data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
            borderColor: 'rgb(54,122,215, 0.9)',
            borderWidth: 1,
            fill: false,
        },
        {
            data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
            borderColor: 'rgba(250,0,54,0.5)',
            borderWidth: 1,
            fill: false,
        },
    ],

    options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: '-10',
                right: 0,
                top: 0,
                bottom: '-10',
            },
        },
        legend: {
            display: false,
            labels: {
                display: false,
            },
        },
    },
};

ChartjsLineChart.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    layout: PropTypes.object,
    width: PropTypes.number,
    options: PropTypes.object,
    id: PropTypes.string,
};

const ChartjsAreaChart = props => {
    const {labels, datasets, options, height, layout, id} = props;

    const data = {
        labels,
        datasets,
    };
    return (
        <div>
            <ChartContainer className="parentContainer">
                <Line
                    id={id}
                    data={data}
                    height={height}
                    options={{
                        tooltips: {
                            mode: 'nearest',
                            intersect: false,
                            enabled: false,
                            custom: customTooltips,
                            callbacks: {
                                labelColor() {
                                    return {
                                        backgroundColor: datasets.map(item => item.borderColor),
                                        borderColor: 'transparent',
                                    };
                                },
                            },
                        },
                        ...options,
                        ...layout,
                    }}
                />
            </ChartContainer>
        </div>
    );
};

ChartjsAreaChart.defaultProps = {
    height: 250,
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
        {
            data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
            borderColor: 'rgb(54,122,215, 0.9)',
            borderWidth: 1,
            fill: true,
            backgroundColor: 'rgb(54,122,215, 0.9)50',
            pointHoverBorderColor: 'transparent',
        },
        {
            data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
            borderColor: 'rgba(250,0,54,0.5)',
            borderWidth: 1,
            fill: true,
            backgroundColor: 'rgba(255, 99, 132, 0.1)',
            pointHoverBorderColor: 'transparent',
        },
    ],
    options: {
        maintainAspectRatio: true,
        hover: {
            mode: 'nearest',
            intersect: false,
        },

        layout: {
            padding: {
                left: -10,
                right: 0,
                top: 2,
                bottom: -10,
            },
        },
        legend: {
            display: false,
            labels: {
                display: false,
            },
        },
        elements: {
            point: {
                radius: 0,
            },
        },
        scales: {
            yAxes: [
                {
                    stacked: true,
                    gridLines: {
                        display: false,
                        color: '#e5e9f2',
                    },
                    ticks: {
                        beginAtZero: true,
                        fontSize: 10,
                        display: false,
                        stepSize: 20,
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                    gridLines: {
                        display: false,
                    },

                    ticks: {
                        beginAtZero: true,
                        fontSize: 11,
                        display: false,
                    },
                },
            ],
        },
    },
};

ChartjsAreaChart.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    layout: PropTypes.object,
    options: PropTypes.object,
    id: PropTypes.string,
};

const ChartjsBarChartTransparent = props => {
    const [items, setItems] = useState([])

    async function getItems() {
        const data = await PGET("/services/admin/api/dashboard-quarter-counts/", true, {});
        setItems(data.data);
    }

    const dataValuesPanel = items.map(item => item.soldCountPanel);
    const dataValuesSection = items.map(item => item.soldCountSection);
    // const maxValue = Math.max(...dataValues);
    const datasets = [
        {
            label: "Панельный",
            data: dataValuesPanel,
            backgroundColor: 'rgb(54,122,215, 0.9)',
            barPercentage: 0.6,
        },
        {
            label: "Секционный",
            data: dataValuesSection,
            backgroundColor: 'rgba(250,0,54,0.5)',
            barPercentage: 0.6,
        },
    ];
    useEffect(() => {
        getItems()
    }, [])
    const {labels, options, height, layout} = props;

    const data = {
        labels,
        datasets,
    };
    // options.scales.yAxes[0].ticks.max = maxValue * 1.5;

    return (
        <ChartContainer className="parentContainer">
            <Bar
                data={data}
                height={window.innerWidth <= 575 ? 230 : height}
                options={{
                    ...options,
                    ...layout,
                    tooltips: {
                        mode: 'label',
                        intersect: false,
                        position: 'average',
                        enabled: false,
                        custom: customTooltips,
                    },
                }}
            />
        </ChartContainer>
    );
};

ChartjsBarChartTransparent.defaultProps = {
    height: 150,
    labels: ['1ый Квартал', '2ой Квартал', '3ий Квартал', '4ый Квартал'],
    options: {
        maintainAspectRatio: true,
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
            },
        },
        layout: {
            padding: {
                left: '0',
                right: 0,
                top: 0,
                bottom: '0',
            },
        },
        scales: {
            yAxes: [
                {
                    gridLines: {
                        color: '#e5e9f2',
                    },
                    ticks: {
                        beginAtZero: true,
                        fontSize: 13,
                        fontColor: '#182b49',
                        stepSize: 5,
                        callback(value) {
                            return `${value}`;
                        },
                    },
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        fontSize: 13,
                        fontColor: '#182b49',
                    },
                },
            ],
        },
    },
};

ChartjsBarChartTransparent.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.object,
    layout: PropTypes.object,
};
const AllChartjsBarChartTransparent = props => {
    const {labels, options, height, layout} = props;
    const [items, setItems] = useState([])

    async function getItems() {
        const data = await PGET("/services/admin/api/dashboard-quarter-counts/", true, {});
        if (data) {
            setItems(data.data);
        }
    }

    const panelData = items?.map((item, i) => item.soldCountPanel)
    const sectionData = items?.map((item, i) => item.soldCountSection)
    const sumData = panelData.map((panelCount, i) => panelCount + sectionData[i]);
    const data = {
        labels,
        datasets: [
            {
                data: sumData,
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
        ],
    };
    useEffect(() => {
        getItems()
    }, [])

    // options.scales.yAxes[0].ticks.max = maxValue * 1.5;

    return (
        <ChartContainer className="parentContainer">
            <Bar
                data={data}
                height={window.innerWidth <= 575 ? 230 : height}
                options={{
                    ...options,
                    ...layout,
                    tooltips: {
                        mode: 'label',
                        intersect: false,
                        position: 'average',
                        enabled: false,
                        custom: customTooltips,
                    },
                }}
            />
        </ChartContainer>
    );
};

AllChartjsBarChartTransparent.defaultProps = {
    height: 150,
    labels: ['1ый Квартал', '2ой Квартал', '3ий Квартал', '4ый Квартал'],
    options: {
        maintainAspectRatio: true,
        responsive: true,
        legend: {
            display: false
        },
        layout: {
            padding: {
                left: '0',
                right: 0,
                top: 0,
                bottom: '0',
            },
        },
        scales: {
            yAxes: [
                {
                    gridLines: {
                        color: '#e5e9f2',
                    },
                    ticks: {
                        beginAtZero: true,
                        fontSize: 13,
                        fontColor: '#182b49',
                        stepSize: 5,
                        callback(value) {
                            return `${value}`;
                        },
                    },
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        fontSize: 13,
                        fontColor: '#182b49',
                    },
                },
            ],
        },
    },
};

AllChartjsBarChartTransparent.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.object,
    layout: PropTypes.object,
};
const AllChartjsBarChartGrad = props => {
    const {labels, options, height, layout} = props;
    const [dashboardData, setDashboardData] = useState([]);

    async function getDashboardMonthCounts() {
        const data = await PGET("/services/admin/api/dashboard-month-counts/");
        if (data) {
            setDashboardData(data.data);
        }
    }

    const panelData = dashboardData?.map((item, i) => item.soldCountPanel)
    const sectionData = dashboardData?.map((item, i) => item.soldCountSection)
    const sumData = panelData.map((panelCount, i) => panelCount + sectionData[i]);
    const data = {
        labels,
        datasets: [
            {
                data: sumData,
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
        ],
    };
    useEffect(() => {
        getDashboardMonthCounts();
    }, []);
    return <Bar data={data} height={height} options={{...options, ...layout}}/>;
};

AllChartjsBarChartGrad.defaultProps = {
    labels: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    datasets: [
        {
            data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
            backgroundColor: 'rgb(54,122,215, 0.9)',
            barPercentage: 0.6,
        },
        {
            data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
            backgroundColor: 'rgba(250,0,54,0.5)',
            barPercentage: 0.6,
        },
    ],

    options: {
        maintainAspectRatio: true,
        responsive: true,
        layout: {
            padding: {
                left: '0',
                right: 0,
                top: 0,
                bottom: '0',
            },
        },
        legend: {
            display: false,
        },
        scales: {
            yAxes: [
                {
                    gridLines: {
                        color: '#e5e9f2',
                    },
                    ticks: {
                        beginAtZero: true,
                        fontSize: 10,
                        fontColor: '#182b49',
                        max: 80,
                    },
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },

                    ticks: {
                        beginAtZero: true,
                        fontSize: 11,
                        fontColor: '#182b49',
                    },
                },
            ],
        },
    },
};

AllChartjsBarChartGrad.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.object,
    layout: PropTypes.object,
};
const ChartjsBarChartGrad = props => {
    const {labels, options, height, layout} = props;
    const [dashboardData, setDashboardData] = useState([]);
    const [region, setRegion] = useState({id: 0, name: "Все"});
    const [regions, setRegions] = useState(region[0]);

    async function getRegions() {
        const response = await GET("/services/admin/api/region-helper/1");
        if (response?.length > 0) {
            setRegions([region, ...response]);
        }
    }

    const handleChangeRegion = (option) => {
        setRegion(option)
    }

    async function getDashboardMonthCounts() {
        const data = await PGET("/services/admin/api/dashboard-month-counts/", true, {regionId: region.id});
        if (data) {
            setDashboardData(data.data);
        }
    }

    const data = {
        labels,
        datasets: [
            {
                label: "Панельный",
                data: dashboardData?.map((item, i) => item.soldCountPanel),
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
                label: "Секционный",
                data: dashboardData?.map((item, i) => item.soldCountSection),
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
        ],
    };
    useEffect(() => {
        getRegions();
    }, []);

    useEffect(() => {
        getDashboardMonthCounts();
    }, [region]);
    return (
        <div>
            <div style={{width: 200, position: "absolute", right: 10, top: 15}}>
                <Select
                    name="regionId"
                    className="custom-react-select"
                    options={regions}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={region}
                    onChange={handleChangeRegion}
                />
            </div>
            <Bar data={data} height={height} options={{...options, ...layout}}/>
        </div>
    )
};
ChartjsBarChartGrad.defaultProps = {
    labels: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    datasets: [
        {
            data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
            backgroundColor: 'rgb(54,122,215, 0.9)',
            barPercentage: 0.6,
        },
        {
            data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
            backgroundColor: 'rgba(250,0,54,0.5)',
            barPercentage: 0.6,
        },
    ],

    options: {
        maintainAspectRatio: true,
        responsive: true,
        layout: {
            padding: {
                left: '0',
                right: 0,
                top: 0,
                bottom: '0',
            },
        },
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
            },
        },
    },
};

ChartjsBarChartGrad.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.object,
    layout: PropTypes.object,
};
const RegionSalesChart = props => {
    const {options, height, layout} = props;
    const [dashboardData, setDashboardData] = useState([]);
    const [dateRange, setDateRange] = useState([
        new Date('2024-01-01'),
        new Date('2025-01-01'),
    ]);
    const [startDate, endDate] = dateRange;
    async function getDashboardMonthCounts() {
        if (startDate && endDate) {
            const data = await PGET("/services/admin/api/dashboard-region-counts/", true, {
                dateFrom: moment(startDate).format("YYYY-MM-DD"),
                dateTo: moment(endDate).add(1, 'day').format("YYYY-MM-DD"),
            });
            if (data) {
                setDashboardData(data.data);
            }
        }
    }

    const data = {
        labels: dashboardData?.map((item, i) => item.regionName),
        datasets: [
            {
                label: "Панельный",
                data: dashboardData?.map((item, i) => item.salesCountPanel),
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
                label: "Секционный",
                data: dashboardData?.map((item, i) => item.salesCountSection),
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            }
        ],
    };
    useEffect(() => {
        getDashboardMonthCounts();
    }, [startDate, endDate]);

    return (
        <div style={{display: ''}}>
            <div style={{width: 200, position: "absolute", right: 10, top: 20}}>
                <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                    }}
                    isClearable={true}
                    className="datepicker-custom-input"
                />
            </div>
            <Bar data={data} height={height} options={{...options, ...layout}}/>
        </div>
    )

};

RegionSalesChart.defaultProps = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
        {
            data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
            backgroundColor: 'rgb(54,122,215, 0.9)',
            barPercentage: 0.6,
        },
        {
            data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
            backgroundColor: 'rgba(250,0,54,0.5)',
            barPercentage: 0.6,
        },
    ],

    options: {
        maintainAspectRatio: true,
        responsive: true,
        layout: {
            padding: {
                left: '0',
                right: 0,
                top: 0,
                bottom: '0',
            },
        },
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
            },
        },
        // scales: {
        //     yAxes: [
        //         {
        //             gridLines: {
        //                 color: '#e5e9f2',
        //             },
        //             ticks: {
        //                 beginAtZero: true,
        //                 fontSize: 10,
        //                 fontColor: '#182b49',
        //                 max: 80,
        //             },
        //         },
        //     ],
        //     xAxes: [
        //         {
        //             gridLines: {
        //                 display: false,
        //             },
        //
        //             ticks: {
        //                 beginAtZero: true,
        //                 fontSize: 11,
        //                 fontColor: '#182b49',
        //             },
        //         },
        //     ],
        // },
    },
};

RegionSalesChart.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.object,
    layout: PropTypes.object,
};
const AllRegionSalesChart = props => {
    const {options, height, layout} = props;
    const [dashboardData, setDashboardData] = useState([]);

    async function getDashboardMonthCounts() {
        const data = await PGET("/services/admin/api/dashboard-region-counts/");
        if (data) {
            setDashboardData(data.data);
        }
    }

    const panelData = dashboardData?.map((item, i) => item.salesCountPanel)
    const sectionData = dashboardData?.map((item, i) => item.salesCountSection)
    const sumData = panelData.map((panelCount, i) => panelCount + sectionData[i]);
    const data = {
        labels: dashboardData?.map((item, i) => item.regionName),
        datasets: [
            {
                data: sumData,
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
        ],
    };
    useEffect(() => {
        getDashboardMonthCounts();
    }, []);
    return <Bar data={data} height={height} options={{...options, ...layout}}/>;
};

AllRegionSalesChart.defaultProps = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
        {
            data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
            backgroundColor: 'rgb(54,122,215, 0.9)',
            barPercentage: 0.6,
        },
        {
            data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
            backgroundColor: 'rgba(250,0,54,0.5)',
            barPercentage: 0.6,
        },
    ],

    options: {
        maintainAspectRatio: true,
        responsive: true,
        layout: {
            padding: {
                left: '0',
                right: 0,
                top: 0,
                bottom: '0',
            },
        },
        legend: {
            display: false,
        },
        scales: {
            yAxes: [
                {
                    gridLines: {
                        color: '#e5e9f2',
                    },
                    ticks: {
                        beginAtZero: true,
                        fontSize: 10,
                        fontColor: '#182b49',
                        max: 80,
                    },
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },

                    ticks: {
                        beginAtZero: true,
                        fontSize: 11,
                        fontColor: '#182b49',
                    },
                },
            ],
        },
    },
};

AllRegionSalesChart.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.object,
    layout: PropTypes.object,
};
const ChartjsPieChart = props => {
    const [items, setItems] = useState([])

    async function getItems() {
        const data = await PGET("/services/admin/api/dashboard-quarter-counts/", true, {});
        setItems(data.data);
    }

    const dataValues = items.map(item => item.soldCount);

    const datasets = [
        {
            data: dataValues,
            backgroundColor: 'rgb(54,122,215, 0.9)',
            barPercentage: 0.6,
        },
    ];
    useEffect(() => {
        getItems()
    }, [])
    const {labels, options, height} = props;
    const data = {
        labels,
        datasets,
    };
    return <Pie data={data} height={height} options={options}/>;
};

ChartjsPieChart.defaultProps = {
    height: 200,
    labels: ['1ый Квартал', '2ой Квартал', '3ий Квартал', '4ый Квартал'],
    datasets: [
        {
            data: [20, 20, 30, 5],
            backgroundColor: ['#007bff', '#00cccc', '#cbe0e3', '#74de00'],
        },
    ],

    options: {
        maintainAspectRatio: true,
        responsive: true,
        legend: {
            display: false,
        },
        animation: {
            animateScale: true,
            animateRotate: true,
        },
    },
};

ChartjsPieChart.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.object,
};
/**
 *
 *  {const labels = chart.data.labels.reduce((prev, curent, i) => {
 return `${prev}<li><span class="doughnutLabelColor" style="background-color:${chart.data.datasets[0].backgroundColor[i]}"></span><span class="doughnutLabe">${curent}</span></li>`;
 }, '');
 const generatedLegend = `<ul class="${chart.id}-legend">${labels}</ul>`;

 return generatedLegend;} props
 */

const ChartjsDonutChart = props => {
    const {labels, datasets, options, height} = props;
    const {ref} = useChartData();
    const data = {
        labels,
        datasets,
    };

    return (
        <div style={{position: 'relative'}}>
            <p>
                <span>{datasets[0].data.reduce((a, b) => a + b, 0)}</span>
                Total visitors
            </p>
            <Doughnut ref={ref} data={data} height={height} options={options}/>
        </div>
    );
};

ChartjsDonutChart.defaultProps = {
    height: 200,
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
    datasets: [
        {
            data: [20, 20, 30, 5, 25],
            backgroundColor: ['#560bd0', '#007bff', '#00cccc', '#cbe0e3', '#74de00'],
        },
    ],

    options: {
        cutoutPercentage: 70,
        maintainAspectRatio: true,
        responsive: true,
        legend: {
            display: false,
            position: 'bottom',
        },
        animation: {
            animateScale: true,
            animateRotate: true,
        },
    },
};

ChartjsDonutChart.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.object,
};

const ChartjsDonutChart2 = props => {
    const {labels, datasets, options, height} = props;
    const {ref} = useChartData();
    const dataInfo = {
        labels,
        datasets,
    };

    return (
        <div>
            <Doughnut ref={ref} data={dataInfo} height={height} options={options} width={200}/>

            <div className="align-center-v justify-content-between rd-labels">
                <div className="revenue-chat-label">
                    {labels.map((label, key) => {
                        return (
                            <div key={key + 1} className="chart-label">
                                <span className={`label-dot dot-${label}`}/>
                                {label}
                            </div>
                        );
                    })}
                </div>
                <div className="revenue-chart-data">
                    {datasets.map((item, key) => {
                        const {data} = item;
                        return (
                            <div key={key + 1}>
                                {data.map(value => {
                                    return (
                                        <p key={value}>
                                            <strong>${value}</strong>
                                        </p>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
                <div className="revenue-chat-percentage">
                    <span>45%</span>
                    <span>45%</span>
                    <span>45%</span>
                </div>
            </div>
        </div>
    );
};

ChartjsDonutChart2.defaultProps = {
    height: 220,
    // width: 220,
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
    datasets: [
        {
            data: [20, 20, 30, 5, 25],
            backgroundColor: ['#560bd0', '#007bff', '#00cccc', '#cbe0e3', '#74de00'],
        },
    ],

    options: {
        cutoutPercentage: 60,
        maintainAspectRatio: false,
        responsive: false,
        legend: {
            display: false,
            position: 'bottom',
        },
        animation: {
            animateScale: true,
            animateRotate: true,
        },
    },
};

ChartjsDonutChart2.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.object,
};

const ChartjsDonut = props => {
    const {labels, datasets, options, height} = props;
    const {ref} = useChartData();
    const dataInfo = {
        labels,
        datasets,
    };

    return <Doughnut ref={ref} data={dataInfo} height={height} options={options} width={200}/>;
};

ChartjsDonut.defaultProps = {
    height: 220,
    // width: 220,
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
    datasets: [
        {
            data: [20, 20, 30, 5, 25],
            backgroundColor: ['#560bd0', '#007bff', '#00cccc', '#cbe0e3', '#74de00'],
        },
    ],

    options: {
        cutoutPercentage: 60,
        maintainAspectRatio: false,
        responsive: false,
        legend: {
            display: false,
            position: 'bottom',
        },
        animation: {
            animateScale: true,
            animateRotate: true,
        },
    },
};

ChartjsDonut.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.array,
    datasets: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.object,
};

export {
    AllChartjsBarChartTransparent,
    AllChartjsBarChartGrad,
    AllRegionSalesChart,
    RegionSalesChart,
    ChartjsDonutChart,
    ChartjsDonutChart2,
    ChartjsPieChart,
    ChartjsBarChartGrad,
    ChartjsBarChartTransparent,
    ChartjsAreaChart,
    ChartjsLineChart,
    ChartjsStackedChart,
    ChartjsBarChart,
    ChartjsDonut,
};
