import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import ReactPaginate from "react-paginate";
import { Search, UserCheck, List } from "react-feather";
import Select from "react-select";
import { Popover } from "antd";

import { PGET, GET } from "../../../api/adminApi";

function ContragentsReport() {
   const [items, setItems] = useState([]);
   const [pageCount, setPageCount] = useState(0);
   const [value, setValue] = useState("");
   const [currentPage, setCurrentPage] = useState(
      +localStorage.getItem("currentPageContragents") || 0
   ); //for numeration and saving currentPage after refresh
   const [regions, setRegions] = useState([{ id: 0, name: "Все регионы" }]);
   const [region, setRegion] = useState(regions[0]);
   const [cities, setCities] = useState([
      { id: 0, name: "Все города / районы" },
   ]);
   const [city, setCity] = useState(cities[0]);

   const selectRegion = (selectedOpt) => {
      if (selectedOpt !== region) {
         setRegion(selectedOpt);
         getCities(selectedOpt.id);
         setCity(cities[0]);
         setCurrentPageToZero();
      }
   };

   const selectCity = (selectedOpt) => {
      if (selectedOpt !== city) {
         setCity(selectedOpt);
         setCurrentPageToZero();
      }
   };

   const handleSearchInput = (e) => {
      setValue(e.target.value);
      setCurrentPageToZero();
   };

   function setCurrentPageToZero() {
      setCurrentPage(0);
      localStorage.setItem("currentPageContragents", 0);
   }

   function paginate(data) {
      setCurrentPage(data.selected);
      localStorage.setItem("currentPageContragents", data.selected); //for saving currentPage after refresh
   }

   async function getRegions() {
      const response = await GET("/services/admin/api/region-helper/1");

      if (response?.length > 0) {
         setRegions([regions[0], ...response]);
      }
   }

   async function getCities(id) {
      const response = await GET(`/services/admin/api/city-helper/${id || 0}`);

      if (response.length > 0) {
         setCities([cities[0], ...response]);
      }
   }

   async function getItems() {
      const data = await PGET(
         "/services/admin/api/contragent-report-pageList",
         true,
         {
            region: region.id,
            city: city.id,
            page: currentPage,
            size: 10,
            search: value,
         }
      );
      setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
      setItems(data.data);
   }

   useEffect(() => {
      getItems();
   }, [region, city, currentPage, value]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      getRegions();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div
         className="box-shadow br-5"
         style={{ background: "#eef1ff", marginTop: "-0.9rem" }}
      >
         <div className="fz18 border-bottom pl-3 py-3 my-2 d-flex align-items-center">
            <UserCheck className="mr-2" color="#5C3EBA" />{" "}
            <span>Контрагенты</span>
         </div>

         <div className="px-3 pb-4 pt-2">
            <div className="mb-4 d-flex justify-content-end">
               <div className="d-flex gap-4">
                  <div style={{ width: "150px" }}>
                     <Select
                        value={region}
                        placeholder="Выберите регион"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={regions}
                        onChange={selectRegion}
                     />
                  </div>

                  <div style={{ width: "150px" }}>
                     <Select
                        value={city}
                        placeholder="Выберите город / район"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={cities}
                        onChange={selectCity}
                     />
                  </div>

                  <div className="position-relative ">
                     <Search
                        size={14}
                        color="#9D9BA3"
                        className="input-search-icon"
                     />
                     <DebounceInput
                        minLength={3}
                        debounceTimeout={400}
                        value={value}
                        onChange={handleSearchInput}
                        type="text"
                        className="input-search box-shadow br-5"
                        size={24}
                        placeholder="Поиск"
                     />
                  </div>
               </div>
            </div>

            <div style={{ overflowX: "auto" }}>
               <table className="table background-white rounded box-shadow br-5">
                  <thead>
                     <tr className="backgroung-grey">
                        <th style={{ width: "30px" }} className="text-center">
                           №
                        </th>
                        <th className="text-start">ФИО</th>
                        <th className="text-start">Номер телефона</th>
                        <th className="text-start">Город/Район</th>
                        <th className="text-start">Регион</th>
                        <th className="text-start">Smartup имя</th>
                        <th className="text-center">Общие баллы</th>
                        <th className="text-center">Установленные</th>
                     </tr>
                  </thead>

                  <tbody style={{ overflowX: "auto" }}>
                     {items.map((item, index) => {
                        return (
                           <tr key={item.id}>
                              <td className="text-center">
                                 {currentPage === 0
                                    ? index + 1
                                    : currentPage * 10 + index + 1}
                              </td>
                              <td className="text-start">
                                 <Popover
                                    content={
                                       <div>
                                          <p>
                                             <b>Торговая точка: </b>
                                             {item.salePointHelperDTO.name}
                                          </p>
                                          <p>
                                             <b>Базар: </b>
                                             {item.bazarHelperDTO.name}
                                          </p>
                                       </div>
                                    }
                                 >
                                    {item.name}
                                 </Popover>
                              </td>
                              <td className="text-start">{item.phone}</td>
                              <td className="text-start">
                                 {item.cityHelperDTO.name}
                              </td>
                              <td className="text-start">
                                 {item.regionHelperDTO.name}
                              </td>
                              <td className="text-start">{item.smartupName}</td>
                              <td className="text-center">{item.totalPoint}</td>
                              <td className="text-center">
                                 {item.mountedAmountOfSold}
                              </td>
                              <td>
                                 <div className="d-flex justify-content-center">
                                    {/* <Link
                                       to={
                                          "/admin/contragent/update/" + item.id
                                       }
                                    > */}
                                    <button
                                       className="btn btn-table box-shadow br-5"
                                       style={{ backgroundColor: "#F4F4F5" }}
                                    >
                                       <List color={"#189ED3"} size={16} />
                                    </button>
                                    {/* </Link> */}
                                 </div>
                              </td>
                           </tr>
                        );
                     })}
                  </tbody>
               </table>
            </div>
            {pageCount > 1 && (
               <ReactPaginate
                  forcePage={currentPage}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1}
                  onPageChange={paginate}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageClassName={"page-item box-shadow br-5"}
               />
            )}
         </div>
      </div>
   );
}

export default ContragentsReport;
