import React, {useEffect, useState} from "react";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import {Search, HelpCircle, CheckSquare} from "react-feather";
import {toast} from "react-toastify";
import Select from "react-select";

import Logo from "../../../assets/logo2.png";
import {PGET, POST} from "../../../api/adminApi";
import AlreadyUsedStatus from "./AlreadyUsedStatus";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import {CustomSvg} from "../../../utils/CustomSvg";

function AlreadyUsedCodes() {
    const [items, setItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(
        +localStorage.getItem("currentPageRequestFromMasters") || 0
    ); //for numeration and saving currentPage after refresh
    const [selectedUser, setSelectedUser] = useState(null);
    const [code, setCode] = useState(""); // checkCode input
    const [selectImg, setSelectImg] = useState(true);
    const [statusOptions, setStatusOptions] = useState([{id: 0, name: "Все"}]);
    const [statusOpt, setStatusOpt] = useState(statusOptions[0]);
    const [modal, setModal] = useState({show: false, user: null});
    const [changeStatusOptions, setChangeStatusOptions] = useState([]);

    const selectStatus = (selectedOpt) => {
        if (statusOpt !== selectedOpt) {
            setStatusOpt(selectedOpt);
            setCurrentPageToZero();
        }
    };

    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPageRequestFromMasters", 0);
    }

    function paginate(data) {
        setCurrentPage(data.selected); //for numeration
        localStorage.setItem("currentPageRequestFromMasters", data.selected); //for saving currentPage after refresh
    }

    async function getItems() {
        const data = await PGET(
            "/services/admin/api/report/already-used-masters-pageList",
            true,
            {statusId: statusOpt.id, page: currentPage, size: 15, search: value}
        );
        setPageCount(Math.ceil(data?.headers["x-total-count"] / 15));
        setItems(data?.data);
        setCode("");
    }

    async function handleCheckCodeInput(e) {
        e.preventDefault();
        const response = await POST(
            "/services/admin/api/request/masters/checkCode",
            {code, requestCode: selectedUser.id}
        );
        toast.info(response?.message);
        getItems();
    }

    useEffect(() => {
        getItems();
    }, [statusOpt, currentPage, value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSelectedUser(
            (prev) =>
                items &&
                (prev
                    ? items.find((item) => item.id === prev.id) === undefined
                        ? items[0]
                        : items.find((item) => item.id === prev.id)
                    : items[0])
        );
    }, [items]);

    useEffect(() => {
        async function getStatusHelper() {
            const data = await PGET("/services/admin/api/request/status-helper");
            if (data) {
                setStatusOptions([statusOptions[0], ...data.data]);
            }
        }

        getStatusHelper();

        async function getChangeStatusOptions() {
            const res = await PGET(
                "/services/admin/api/request/status-helper-change"
            );
            if (res) {
                setChangeStatusOptions(res.data);
            }
        }

        getChangeStatusOptions();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="br-5 card" style={{background: "#eef1ff", marginTop: "-0.4rem", height: '100vh'}}>
            <div style={{paddingLeft: "40px"}} className="fz18 d-flex align-items-center pt-7">
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Переиспользованные коды</span>
                </h3>
            </div>

            <div
                className="m-3 gap-3"
                style={{display: "grid", gridTemplateColumns: "auto 33%"}}
            >
                <div
                    className="d-flex justify-content-end w-100"
                    style={{width: "150px"}}
                >
                </div>

                <div className="position-relative w-100">
                    <DebounceInput
                        minLength={3}
                        debounceTimeout={400}
                        value={value}
                        onChange={handleSearchInput}
                        type="text"
                        className="input-search search-icon br-5 w-100"
                        size={24}
                        placeholder="Поиск"
                    />
                </div>

                <div>
                    <div style={{ overflow: "auto", maxHeight: '75vh' }}>
                        <table
                            className="table table-rounded p-0 bg-white border gy-5 gs-7 background-white rounded box-shadow br-5 align-self-start">
                            <thead className='position-sticky top-0 rounded'>
                            <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                                <th className="text-center text-white" style={{backgroundColor: '#E7372C', fontWeight: "700", borderTopLeftRadius: '8px'}}>№</th>
                                <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Время получения</th>
                                <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Номер запроса</th>
                                <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Мастер</th>
                                <th className="text-center text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Статус</th>
                                <th className="text-center text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Балл</th>
                                <th className="text-center text-white" style={{backgroundColor: '#E7372C', fontWeight: "700", borderTopRightRadius: '8px'}}>Полученный код</th>
                            </tr>
                            </thead>

                            <tbody style={{overflowX: "auto"}}>
                            {items?.map((user, idx) => {
                                const {
                                    id,
                                    receivedDate,
                                    phone,
                                    requestStatus,
                                    requestStatusId,
                                    earnedPoint,
                                    receivedCode,
                                    photo,
                                    masterName,
                                } = user;
                                return (
                                    <tr
                                        onClick={() => {
                                            setSelectedUser(user);
                                        }}
                                        role="button"
                                        className={
                                            id === selectedUser?.id ? "box-shadow rowHover" : "rowHover"
                                        }
                                        style={{
                                            background: `${
                                                id === selectedUser?.id ? "rgba(231, 55, 44, 0.15)" : "white"
                                            }`,
                                        }}
                                        key={id}
                                    >
                                        <td className="text-center pointer">
                                            {currentPage === 0
                                                ? idx + 1
                                                : // : currentPage * 10 + idx + 1} // for 10 items per page
                                                  // for 15 items per page
                                                currentPage * 10 + idx + 1 + 5 * currentPage}
                                        </td>
                                        <td className="text-start">
                                            {new Date(receivedDate).toLocaleString()}
                                        </td>
                                        <td className="text-start">{id}</td>
                                        <td className="text-start">
                                            <div className="d-flex">
                                                <img alt="img" src={photo ? "https://bo.akfacomfort.uz/" + photo : "https://th.bing.com/th/id/OIP.1V_ec2D7-sqwJoC5qZOmUQAAAA?pid=ImgDet&rs=1"} className="user-image"/>
                                                <div className="d-flex flex-column">
                                                    <span className="fw-bold mb-1">{masterName}</span>
                                                    <p className="fs-7 lh-1">{'+' + phone}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={`text-start w-10`}>
                                            <b
                                                style={{
                                                    whiteSpace: "nowrap",
                                                }}
                                                className={`badge ${
                                                    requestStatusId === 1
                                                        ? "badge-warning"
                                                        : requestStatusId === 2
                                                            ? "badge-success"
                                                            : requestStatusId === 3
                                                                ? "badge-danger"
                                                                : "badge-primary"
                                                }`}
                                            >
                                                {requestStatus}
                                            </b>
                                        </td>
                                        <td className="text-center">{earnedPoint}</td>
                                        <td className="text-center">{receivedCode}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-5'>
                        {pageCount > 1 && (
                            <ReactPaginate
                                forcePage={currentPage}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={paginate}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                pageClassName={"page-item box-shadow br-5"}
                            />
                        )}
                    </div>
                </div>

                <div className="h-100">
                    <table
                        className="p-0 table table-rounded bg-white border gy-3 gs-7 background-white rounded box-shadow br-5">
                        <tbody>
                        <tr style={{borderBottom: "rgba(0, 0, 0, 0.15) 1px solid"}}>
                            <th>Мастер:</th>
                            <td className="text-end">{selectedUser?.masterName}</td>
                        </tr>
                        <tr style={{borderBottom: "rgba(0, 0, 0, 0.15) 1px solid"}}>
                            <th>Регион:</th>
                            <td className="text-end">
                                {selectedUser?.regionHelperDTO?.name}
                            </td>
                        </tr>
                        <tr>
                            <th>Город/Район:</th>
                            <td className="text-end">
                                {selectedUser?.cityHelperDTO?.name}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {selectedUser?.requestStatusId === 4 && (
                        <table className="table bg-white table-rounded p-0 border gy-3 gs-7">
                            <tbody>
                            <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                                <th>Продан:</th>
                                <td className="text-end">
                                    {selectedUser?.soldMasterName}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    )}
                    <div
                        className="rounded box-shadow br-5 background-white d-flex items-center justify-content-center">
                        <TransformWrapper>
                            <TransformComponent>
                                <img
                                    style={{
                                        width: "100%",
                                        maxHeight: "550px",
                                        minHeight: "330px",
                                    }}
                                    src={`${
                                        selectedUser?.id
                                            ? "https://bo.akfacomfort.uz/" +
                                            (selectImg
                                                ? selectedUser.filePathCode
                                                : selectedUser.filePathScratchCode)
                                            : Logo
                                    }`}
                                    alt="img"
                                />
                            </TransformComponent>
                        </TransformWrapper>
                    </div>
                </div>
            </div>

            {modal.user && (
                <AlreadyUsedStatus
                    modal={modal}
                    setModal={setModal}
                    getItems={getItems}
                    statusOptions={changeStatusOptions}
                />
            )}
        </div>
    );
}

export default AlreadyUsedCodes;
