import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { Plus, Minus, Check } from 'react-feather';

import { store } from '../../store';
import { increment, decrement, clearBasket } from '../../store/actions/basket'
import { POST, GET } from '../../api/api'
import { formatMoney } from '../../helper'

const Checkout = () => {
	const basket = useSelector(state => state.basket)
	let history = useHistory()
	const branch = JSON.parse(localStorage.getItem('branch'))
	const [addresses, setAddresses] = useState([])
	const [firstSession, setFirstSession] = useState(true)
	const [data, setData] = useState({
		branchId: JSON.parse(localStorage.getItem('branch')).id,
		comment: "",
		gpsPointX: 0,
		gpsPointY: 0,
		orderPrice: 0,
		paymentType: 1,
		productList: [],
		userAddressesId: 0
	})

	async function createOrder() {
		if (addresses.length === 0) {
			toast.error('Ошибка: Создайте адрес для доставки')
			return
		}
		await POST('/services/web/api/orders', data).then(response => {
			if (response.success) {
				store.dispatch(clearBasket())
				history.push({
					pathname: '/profile',
					search: "2",
				})
			}
		})

	}

	async function getAddresses() {
		const response = await GET(`/services/web/api/user-addresses-helper`)
		if (response.length > 0) {
			setData({ ...data, userAddressesId: response[0].id })
			setAddresses(response)
		}
	}

	async function getData() {
		if (basket.length === 0) {
			history.push('/')
		}
		await getAddresses()
		var arr = []
		var totalAmount = 0
		for (let i = 0; i < basket.length; i++) {
			arr.push({
				comment: basket[i].description,
				productId: basket[i].id,
				productImageUrl: basket[i].imageUrl,
				productName: basket[i].name,
				productPrice: basket[i].price,
				quantity: basket[i].quantity
			})
			totalAmount += basket[i].price * basket[i].quantity
		}
		setData({ ...data, orderPrice: totalAmount, productList: arr })
		setFirstSession(false)
	}

	useEffect(() => {
		if (firstSession) {
			getData()
		} else {
			var totalAmount = 0
			for (let i = 0; i < basket.length; i++) {
				totalAmount += basket[i].price * basket[i].quantity
			}
			setData({ ...data, orderPrice: totalAmount })
		}

	}, [basket]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div >

			<div className="container">
				<h2>Оформление заказа</h2>
				<div className="d-flex">
					<div className="col-md-8 col-sm-12 h-max-content background-white px-4 my-3 mr-4" >
						<div className="w-75 mx-auto my-4 px-3">
							<div className="border-bottom fz24 font-weight-500 pb-2">Доставка</div>
							<label className='mt-3 fz12 color-purple font-weight-600' color={{ color: '#808285' }}>Телефон</label>
							<div>
								<input type="text" className="w-100 p-2 mb-2" placeholder='(+998) 88-888-88-88' style={{ border: '2px solid #5C3EBA', borderRadius: '10px', outline: 0 }} />
							</div>
							<div>
								<label className='mb-2 fz12 color-grey font-weight-500' color={{ color: '#808285' }}>Адрес доставки</label>
								{
									addresses.length > 0 ?
										<div className="admin-field">
											<div className="d-flex w-100">
											<select className="admin-select" value={data.userAddressesId} onChange={(e) => setData({ ...data, userAddressesId: e.target.value })}>
												{addresses.map((item) => {
													return (
														<option key={item.id} value={item.id}>{item.name} </option>
													)
												})
												}
											</select>
											</div>
										</div>
										:
										<div className="mb-2">
											<Link to="/profile?0">
												<div className="color-primary">Добавить адрес</div>
											</Link>
										</div>
								}
							</div>
							{/* <div className="mb-3">
								<label className='mb-2 fz12 color-grey font-weight-500' color={{ color: '#808285' }}>Адрес доставки</label>
								<input type="text" className="checkout-input w-100 p-3 mb-2" placeholder='Lorem ipsum dolor sit amet, consectetur adipiscing elit. ' />
							</div>
							<div className='d-flex justify-content-between'>
								<input type="text" className="checkout-input p-3 mr-2 mb-2" placeholder='Подъезд' />
								<input type="text" className="checkout-input p-3 mr-2 mb-2" placeholder='Этаж' />
								<input type="text" className="checkout-input p-3 mb-2" placeholder='Квартира' />
							</div> */}
							<label className='mb-2 fz12 color-grey font-weight-500' color={{ color: '#808285' }}>Комментарий</label>
							<div>
								<input type="text" onChange={(e) => setData({ ...data, comment: e.target.value })}
									className="checkout-input w-100 p-3 mb-2" placeholder='Напишите, как вас найти или пожелания для блюд... ' />
							</div>
							<div className="border-bottom fz20 font-weight-500 pb-2 mt-4">Оплата</div>
							<div className="d-flex justify-content-between my-3">
								<div onClick={() => setData({ ...data, paymentType: 1 })} className={"checkout-payment " + (data.paymentType === 1 && 'checkout-payment-acitve')} >
									{data.paymentType === 1 &&
										<Check color="#fff" size={18} />
									}
									<img src="img/payme.png" width="130" style={{ objectFit: 'none' }} alt="" />
								</div>
								<div onClick={() => setData({ ...data, paymentType: 2 })} className={"checkout-payment " + (data.paymentType === 2 && 'checkout-payment-acitve')} >
									{data.paymentType === 2 &&
										<Check color="#fff" size={18} />
									}
									<img src="img/click.png" width="130" alt="" />
								</div>
								<div onClick={() => setData({ ...data, paymentType: 3 })} className={"checkout-payment " + (data.paymentType === 3 && 'checkout-payment-acitve')} >
									{data.paymentType === 3 &&
										<Check color="#fff" size={18} />
									}
									<img src="img/stripe.png" width="130" style={{ objectFit: 'contain' }} alt="" />
								</div>
							</div>
							<div>
								<button className="btn btn-purple w-100" onClick={() => createOrder()} style={{ borderRadius: '50px' }}>Заказать</button>
							</div>
						</div>
					</div>
					<div className="col-md-4 h-max-content background-white br-10 my-3">
						<div className="w-100 p-3 d-flex justify-content-between align-items-center">
							<div>
								<span className="fz14">Ваш заказ в ресторане</span> <br />
								<span className="fz18 font-weight-bold">{branch.name}</span>
							</div>
							<div>
								<img src={'https://colibri24.uz' + (branch.imageUrl)} style={{ objectFit: 'cover' }} height="50" width="50" alt="" />
							</div>
						</div>
						<div>
							<div className="overflow-auto scroll" style={{ maxHeight: '443px' }}>
								{
									basket.map((item) => {
										return (
											<div key={item.id} className="basket-menu mt-0 row">
												<div className="col-3 p-0 fz14">
													<img src={'https://colibri24.uz' + (item.imageUrl)} className="br-5 obj-fit-cover" height="42" width="42" alt="" />
												</div>
												<div className="col-5 p-0 fz14">{item.name} </div>
												<div className="col-4 p-0 d-flex flex-column align-items-end">
													<div>{formatMoney(item.price)} сум</div>
												</div>
												<div className="offset-3 p-0 col-9">
													<div className="d-flex align-items-center mb-3">
														<div className="p-1 bg-grey-light br-5 cursor">
															<Plus onClick={() => store.dispatch(increment(item))} color="#5C3EBA" />
														</div>
														<div className="fz20 px-2 text-dark"> {item.quantity} </div>
														<div className="p-1 bg-grey-light br-5 cursor">
															<Minus onClick={() => store.dispatch(decrement(item))} color="#5C3EBA" />
														</div>
													</div>
												</div>
											</div>
										)
									})
								}
							</div>
							<div className="mt-3 mx-2">
								<div className="d-flex justify-content-between mb-2">
									<div>Доставка</div>
									<div className="font-weight-bold ">0 сум</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<div>Сервисный сбор</div>
									<div className="font-weight-bold">0 сум</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<div>
										<h3>Итого</h3>
									</div>
									<div className="font-weight-bold"><h3>{formatMoney(data.orderPrice)} сум</h3></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	);

}
export default Checkout;