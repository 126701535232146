import React, {useEffect, useState} from "react";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import {PGET} from "../../../api/adminApi";
import {FaArrowRight, FaLongArrowAltRight} from "react-icons/fa";

export default function PointMaster() {
    const [items, setItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(
        +localStorage.getItem("currentPagePointMaster") || 0
    )
    //for numeration and saving currentPage after refresh
    const [statusOptions, setStatusOptions] = useState([{id: 0, name: "Все"}]);
    const [statusOpt, setStatusOpt] = useState(statusOptions[0]);

    const selectStatus = (selectedOpt) => {
        if (statusOpt !== selectedOpt) {
            setStatusOpt(selectedOpt);
            setCurrentPageToZero();
        }
    };

    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPagePointMaster", 0);
    }

    function paginate(data) {
        setCurrentPage(data.selected); //for numeration
        localStorage.setItem("currentPagePointMaster", data.selected); //for saving currentPage after refresh
    }

    async function getItems() {
        const data = await PGET(
            "/services/admin/api/history/masters-points-pageList",
            true,
            {statusId: statusOpt.id, page: currentPage, size: 15, search: value}
        );
        setPageCount(Math.ceil(data?.headers["x-total-count"] / 15));
        setItems(data?.data);
    }

    useEffect(() => {
        getItems();
    }, [statusOpt, currentPage, value]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function getStatusHelper() {
            const data = await PGET("/services/admin/api/request/status-helper");
            if (data) {
                setStatusOptions([statusOptions[0], ...data.data]);
            }
        }

        getStatusHelper();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className="br-5 card"
            style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}
        >
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Баллы мастеров</span>
                </h3>
                <div
                    className="d-flex gap-4"
                >
                    <div
                        className="d-flex justify-content-end w-100"
                        style={{width: "150px"}}
                    >
                        <Select
                            className="w-100"
                            value={statusOpt}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            options={statusOptions}
                            onChange={selectStatus}
                        />
                    </div>
                    <div className="position-relative w-100">
                        <DebounceInput
                            minLength={3}
                            debounceTimeout={400}
                            value={value}
                            onChange={handleSearchInput}
                            type="text"
                            className="input-search search-icon br-5 w-100"
                            size={24}
                            placeholder="Поиск"
                        />
                    </div>
                </div>
            </div>
            <div className="m-3" style={{overflow: "auto", maxHeight: '75vh'}}>
                <table className='table bg-white p-0 table-rounded border gy-4 gs-7'>
                    <thead className='position-sticky top-0 rounded'>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                        <th className="text-center text-white"
                            style={{
                                backgroundColor: '#E7372C',
                                fontWeight: "700",
                                borderTopLeftRadius: '8px',
                                fontSize: 10
                            }}>№
                        </th>
                        <th className="text-start text-white"
                            style={{backgroundColor: '#E7372C', fontWeight: "700", fontSize: 12}}>Запрос:
                        </th>
                        <th className="text-start text-white"
                            style={{backgroundColor: '#E7372C', fontWeight: "700", fontSize: 12}}>Причина
                        </th>
                        <th className="text-start text-white"
                            style={{backgroundColor: '#E7372C', fontWeight: "700", fontSize: 12}}>Мастер
                        </th>

                        <th className="text-center text-white"
                            style={{backgroundColor: '#E7372C', fontWeight: "700", fontSize: 12}}>Панельный
                        </th>
                        <th className="text-center text-white"
                            style={{backgroundColor: '#E7372C', fontWeight: "700", fontSize: 12}}>Секционный
                        </th>
                        {/*<th className="text-center text-white"*/}
                        {/*    style={{backgroundColor: '#E7372C', fontWeight: "700", fontSize: 12}}>Котел*/}
                        {/*</th>*/}
                        <th className="text-center text-white"
                            style={{backgroundColor: '#E7372C', fontWeight: "700", fontSize: 12}}>Реферал
                        </th>
                        <th className="text-center text-white"
                            style={{backgroundColor: '#E7372C', fontWeight: "700", fontSize: 12, borderTopRightRadius: '8px',}}>Общий балл
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    {items?.map((user, idx) => {
                        const {
                            id,
                            requestMastersId,
                            changedTime,
                            pointFrom,
                            pointTo,
                            reason,
                            masterName,
                            masterPhone,
                            pointToSection,
                            pointToReferal,
                            pointToPanel,
                            pointToKotel,
                            pointFromSection,
                            pointFromReferal,
                            pointFromPanel,
                            pointFromKotel
                        } = user;
                        return (
                            <tr key={id} className='borderBottom'>
                                <td className="text-center pointer">
                                    {currentPage === 0
                                        ? idx + 1
                                        : // : currentPage * 10 + idx + 1} // for 10 items per page
                                          // for 15 items per page
                                        currentPage * 10 + idx + 1 + 5 * currentPage}
                                </td>
                                <td className="text-start">
                                    <div className="flex flex-col">
                                        <div className="flex">
                                            <p className="m-0 p-0 font-bold text-#eff2f5">Номер запроса: </p>
                                            <p className="p-0 ml-2 mb-0">{requestMastersId}</p>
                                        </div>
                                        <div className="flex">
                                            <p className="m-0 p-0 font-bold text-#eff2f5">Время получения: </p>
                                            <p className="p-0 ml-2 mb-0">{changedTime}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className="text-start">{reason}</td>
                                <td className="text-start">
                                    <div className="flex flex-col">
                                        <div className="flex">
                                            <p className="m-0 p-0 font-bold text-#eff2f5">ФИО: </p>
                                            <p className="p-0 ml-2 mb-0">{masterName}</p>
                                        </div>
                                        <div className="flex">
                                            <p className="m-0 p-0 font-bold text-#eff2f5">Номер: </p>
                                            <p className="p-0 ml-2 mb-0">{masterPhone}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className="text-center" style={{borderLeft: '0.5px solid #eff2f5'}}>
                                    <div className="flex items-center justify-center">
                                        <p className="p-0 mr-2 mb-0">{pointFromPanel}</p>
                                        <FaArrowRight size="8px" color="#c7c8c9"/>
                                        <p className="p-0 ml-2 mb-0">{pointToPanel}</p>
                                    </div>
                                </td>
                                <td className="text-center" style={{borderLeft: '0.5px solid #eff2f5'}}>
                                    <div className="flex items-center justify-center">
                                        <p className="p-0 mr-2 mb-0">{pointFromSection}</p>
                                   <FaArrowRight size="8px" color="#c7c8c9"/>
                                        <p className="p-0 ml-2 mb-0">{pointToSection}</p>
                                    </div>
                                </td>
                                {/*<td className="text-center" style={{borderLeft: '0.5px solid #eff2f5'}}>*/}
                                {/*    <div className="flex items-center justify-center">*/}
                                {/*        <p className="p-0 mr-2 mb-0">{pointFromKotel}</p>*/}
                                {/*   <FaArrowRight size="8px" color="#c7c8c9"/>*/}
                                {/*        <p className="p-0 ml-2 mb-0">{pointToKotel}</p>*/}
                                {/*    </div>*/}
                                {/*</td>*/}
                                <td className="text-center" style={{borderLeft: '0.5px solid #eff2f5'}}>
                                    <div className="flex items-center justify-center">
                                        <p className="p-0 mr-2 mb-0">{pointFromReferal}</p>
                                   <FaArrowRight size="8px" color="#c7c8c9"/>
                                        <p className="p-0 ml-2 mb-0">{pointToReferal}</p>
                                    </div>
                                </td>
                                <td className="text-center" style={{borderLeft: '0.5px solid #eff2f5', borderRight: '1px solid #eff2f5'}}>
                                    <div className="flex items-center justify-center">
                                        <p className="p-0 mr-2 mb-0">{pointFrom}</p>
                                   <FaArrowRight size="8px" color="#c7c8c9"/>
                                        <p className="p-0 ml-2 mb-0">{pointTo}</p>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>

            {pageCount > 1 && (
                <ReactPaginate
                    forcePage={currentPage}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={paginate}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item box-shadow br-5"}
                />
            )}
        </div>
    );
}
