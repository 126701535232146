import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { DebounceInput } from 'react-debounce-input';
import ReactPaginate from 'react-paginate';
import { CheckCircle, Edit3, Search, XCircle, Briefcase, PlusCircle } from 'react-feather';

import { PUT, PGET } from '../../../api/adminApi'

function Company() {
	const [items, setItems] = useState([])
	const [pageCount, setPageCount] = useState(0)

	async function getItems() {
		const data = await PGET('/services/admin/api/company-pageList', true, { page: 0, size: 20 })
		setPageCount(Math.ceil(data.headers['x-total-count'] / 20))
		setItems(data.data);
	}

	async function active(item) {
		item.activated = !item.activated
		await PUT('/services/admin/api/company-activate', item)
		getItems()
	}

	async function search(e) {
		const data = await PGET('/services/admin/api/company-pageList?search=' + e.target.value, true)
		setPageCount(Math.ceil(data.headers['x-total-count'] / 20))
		setItems(data.data);
	};

	async function paginate(data) {
		const response = await PGET('/services/admin/api/company-pageList', true, { page: data.selected, size: 20 })
		setItems(response.data);
	};

	useEffect(() => {
		getItems()
	}, [])

	return (
		<div className="background-white my-2 mx-3 box-shadow br-5">
			<div className="fz20 border-bottom pl-3 py-3 my-2 d-flex align-items-center">
				<Briefcase className='mr-2' color='#5C3EBA' /> <span>Владелецы бизнеса</span>
			</div>
			<div className='px-3 pb-4 pt-2'>
				<div className="mb-4 d-flex justify-content-between">
					<Link to='/admin/company/create'>
						<button className="btn btn-success d-flex align-items-center">
							<PlusCircle size={18} className='mr-1' />
							<div>Добавить новый</div>
						</button>
					</Link>
					<div className="position-relative">
						<Search size={14} color='#9D9BA3' className="input-search-icon" />
						<DebounceInput minLength={3} debounceTimeout={400} onChange={(e) => search(e)} type="text" className="input-search" size={24} placeholder='Поиск' />
					</div>
				</div>
				<div style={{ overflowX: 'auto' }}>
					<table className="table table-bordered">
						<thead>
							<tr className='backgroung-grey'>
								<th>
									Фамилия
								</th>
								<th className="text-center">Имя</th>
								<th className="text-center">Отчество</th>
								<th className="text-center">Пол</th>
								<th className="text-center">Имя пользователя</th>
								<th className="text-center">День рождение</th>
								<th className="text-center">Электронная почта</th>
								<th className="text-center">Название компании</th>
								<th className="text-center">Страна</th>
								<th className="text-center">Статус</th>
								<th className="text-center">Действия</th>
							</tr>
						</thead>

						<tbody style={{ overflowX: 'auto' }}>
							{
								items.map((item) => {
									return (
										<tr key={item.id}>
											<td className="text-center">{item.lastName}</td>
											<td className="text-center">{item.firstName}</td>
											<td className="text-center">{item.patronymicName}</td>
											<td className="text-center">{item.gender === 0 ? 'Мужской' : 'Женский'}</td>
											<td className="text-center">{item.login}</td>
											<td className="text-center">{item.birthday}</td>
											<td className="text-center">{item.email}</td>
											<td className="text-center">{item.companyName}</td>
											<td className="text-center">{item.countryName}</td>
											<td className="text-center">
												{item.activated ?
													<button className="btn p-2 br-5 text-white d-flex align-items-center justify-content-center m-auto" style={{ backgroundColor: '#189ED3' }}><CheckCircle size={16} /></button>
													:
													<button className="btn p-2 br-5 text-white d-flex align-items-center justify-content-center m-auto" style={{ backgroundColor: '#E63950' }}><XCircle size={16} /> </button>
												}
											</td>
											<td>
												<div className="d-flex justify-content-center">
													<button onClick={() => active(item)} className="btn btn-table" style={{ backgroundColor: '#F4F4F5' }}><CheckCircle color={'#5C3EBA'} size={16} /></button>
													<Link to={'/admin/company/update/' + item.id}><button className="btn btn-table" style={{ backgroundColor: '#F4F4F5' }}><Edit3 color={'#189ED3'} size={16} /></button></Link>
												</div>
											</td>
										</tr>
									)
								})
							}
						</tbody>
					</table>
				</div>
				{
					pageCount > 1 &&
					<ReactPaginate
						// breakLabel={'...'}
						// breakClassName={'break-me'}
						pageCount={pageCount}
						marginPagesDisplayed={2}
						pageRangeDisplayed={5}
						onPageChange={paginate}
						containerClassName={'pagination'}
						activeClassName={'active'}
						pageClassName={'page-item'}
					/>
				}
			</div>
		</div>
	)
}

export default Company
