import React, {useEffect, useState} from "react";
import {Save, XCircle} from "react-feather";
import Modal from "react-bootstrap/Modal";
import {useForm} from "react-hook-form";
import {POST} from "../../../api/adminApi";

export default function GiveGift({
                                              modal: {show, user},
                                              setModal,
                                              getItems,
                                          }) {
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        clearErrors,
    } = useForm();

    async function changeStatus(data) {
        const response = await POST(
            "/services/admin/api/master-change-point-status-to-given",
            {
                id: user.id,
            }
        );
        if (response) {
            console.log(response)
        }

        if (response && response.success === true) {
            getItems();
            onHide();
        }
    }

    function onHide() {
        setModal({show: false, user: null});
        setValue("comment", "");
        clearErrors();
    }

    return (
        <Modal
            show={show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Вы действительно хотите отдать приз к победителю
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(changeStatus)} autoComplete="off">
                    <div className="d-flex justify-content-end gap-4">
                        <button
                            type="button"
                            onClick={onHide}
                            className="btn btn-outline-purple d-flex align-items-center btn-bg-warning text-white"
                        >
                            <XCircle size={14} className="mr-2"/>
                            Отмена
                        </button>
                        <button
                            type="submit"
                            className="btn d-flex align-items-center btn-bg-dark text-white"
                        >
                            <Save size={14} className="mr-2"/>
                            Да отдать
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}
