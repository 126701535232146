import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import { XCircle, Save } from "react-feather";

import { POST, GET, PUT } from "../../../api/adminApi";

function SalePointAddUpdate() {
   let { id } = useParams();
   let history = useHistory();
   const [data, setData] = useState({
      name: "",
      description: "",
      bazarId: 0,
      regionId: 0,
      cityId: 0,
   });
   const [brands, setBrands] = useState([]);
   const [bazarId, setBazarId] = useState([]);
   const [regionId, setRegionId] = useState(0);
   const [cityId, setCityId] = useState(0);
   const [regions, setRegions] = useState([]);
   const [cities, setCities] = useState([]);

   async function create() {
      var response;
      if (id) {
         response = await PUT("/services/admin/api/salepoint", data);
      } else {
         response = await POST("/services/admin/api/salepoint", data);
      }
      if (response) {
         history.push("/admin/salepoint");
      }
   }

   function selectBrand(selectedOption) {
      setData({ ...data, bazarId: selectedOption.id });
      setBazarId(selectedOption);
   }

   function selectRegion(selectedOption) {
      setData({ ...data, regionId: selectedOption.id });
      setRegionId(selectedOption);
      getCities(selectedOption.id);
   }

   function selectCity(selectedOption) {
      setData({ ...data, cityId: selectedOption.id });
      setCityId(selectedOption);
      getBrands(selectedOption.id);
   }

   async function getData() {
      const response = await GET("/services/admin/api/salepoint/" + id, data);
      setData(response);
      const getBrands = await GET(`/services/admin/api/bazar-helper/`);
      setBrands(getBrands);

      for (let i = 0; i < getBrands.length; i++) {
         if (response.bazarHelperDTO?.id === getBrands[i]["id"]) {
            setBazarId(getBrands[i]);
         }
      }
      const getRegions = await GET("/services/admin/api/region-helper/1");

      setRegions(getRegions);

      for (let i = 0; i < getRegions.length; i++) {
         if (response.regionHelperDTO?.id === getRegions[i]["id"]) {
            setRegionId(getRegions[i]);
         }
      }
      const getCities = await GET(
         `/services/admin/api/city-helper/${response.regionHelperDTO?.id}`
      );
      setCities(getCities);

      for (let i = 0; i < getCities.length; i++) {
         if (response.cityHelperDTO?.id === getCities[i]["id"]) {
            setCityId(getCities[i]);
         }
      }
   }

   async function getRegions() {
      const response = await GET("/services/admin/api/region-helper/1");
      if (response.length > 0) {
         setRegions(response);
      }
   }

   async function getCities(id) {
      const response = await GET(`/services/admin/api/city-helper/${id || 0}`);
      if (response.length > 0) {
         setCities(response);
      }
   }

   async function getBrands(id) {
      const response = await GET(`/services/admin/api/bazar-helper/${id || 0}`);
      if (response.length > 0) {
         setBrands(response);
      }
   }

   useEffect(() => {
      if (id) {
         getData();
      } else {
         getBrands();
         getRegions();
         getCities();
      }
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div className="mb-2 mx-3 ">
         <h3 className="mb-5 mt-5">Добавить новый</h3>
         <div className="admin-card p-4 overflow-y-auto">
            <div className="row mb-3 pt-12">
               <div className="flex-column flex-wrap mb-3 justify-content-center ml-5">
                  <div className="col-md-3 d-flex align-items-center mb-4 ">
                     <label
                        className="vertical-center color-grey text-nowrap"
                        style={{ marginRight: "10px" }}
                     >
                        Название
                     </label>
                     <div>
                        <input
                           className="input-control"
                           style={{ width: "300px" }}
                           value={data.name}
                           onChange={(e) =>
                              setData({ ...data, name: e.target.value })
                           }
                        />
                     </div>
                  </div>
                  <div className="col-md-3 d-flex align-items-center">
                     <label className="vertical-center color-grey mr-2 text-nowrap">
                        Описание
                     </label>
                     <div>
                        <input
                           className="input-control"
                           style={{ width: "300px" }}
                           value={data.description}
                           onChange={(e) =>
                              setData({ ...data, description: e.target.value })
                           }
                        />
                     </div>
                  </div>
                  <div
                     className=" d-flex mb-3 mt-3"
                     style={{ marginLeft: "33px" }}
                  >
                     <label className="vertical-center text-nowrap color-grey mr-2">
                        Регион
                     </label>
                     <div style={{ width: "300px" }}>
                        <Select
                           defaultValue={regions[0]}
                           value={regionId}
                           placeholder="Выберите регион"
                           getOptionLabel={(option) => option.name}
                           getOptionValue={(option) => option.id}
                           options={regions}
                           onChange={selectRegion}
                        />
                     </div>
                  </div>
                  <div className=" d-flex mb-3 ">
                     <label className="vertical-center text-nowrap color-grey mr-1">
                        Город(район)
                     </label>
                     <div style={{ width: "300px" }}>
                        <Select
                           defaultValue={cities[0]}
                           value={cityId}
                           placeholder="Выберите город"
                           getOptionLabel={(option) => option.name}
                           getOptionValue={(option) => option.id}
                           options={cities}
                           onChange={selectCity}
                        />
                     </div>
                  </div>
                  <div
                     className=" d-flex mb-3 mt-3"
                     style={{ marginLeft: "4px" }}
                  >
                     <label
                        className="vertical-center text-nowrap color-grey d-flex"
                        style={{ width: "82px", paddingLeft: "37px" }}
                     >
                        Базар
                     </label>
                     <div style={{ width: "300px" }}>
                        <Select
                           defaultValue={brands[0]}
                           value={bazarId}
                           placeholder="Выберите базар"
                           getOptionLabel={(option) => option.name}
                           getOptionValue={(option) => option.id}
                           options={brands}
                           onChange={selectBrand}
                        />
                     </div>
                  </div>
               </div>
            </div>

            <div className="btn-postion d-flex">
               <Link to="/admin/salepoint" className="mr-3">
                  <button className="btn text-white btn-bg-primary d-flex align-items-center">
                     <XCircle size={14} className="mr-2" />
                     Отмена
                  </button>
               </Link>
               <button
                   className="btn text-white btn-bg-success d-flex align-items-center"
                   type="submit"
               >
                  <Save size={14} className="mr-2" />
                  Сохранить
               </button>
            </div>
         </div>
      </div>
   );
}

export default SalePointAddUpdate;
