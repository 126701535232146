import React, {useState, useEffect} from "react";
import {useParams, useHistory, Link} from "react-router-dom";
import Select from "react-select";
import {XCircle, Save, Calendar} from "react-feather";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import {isValidPhoneNumber} from "react-phone-number-input";

import {POST, GET, PUT, PGET} from "../../../api/adminApi";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import {getDateTime, getFullDate} from "../../../utils/Dates";

export default function SupportMessageDetail() {
    const [items, setItems] = useState([]);
    const {id: messageId} = useParams();

    async function getData() {
        const response = await PGET("/services/admin/api/support-message/" + messageId);
        setItems(response.data);
    }

    console.log(items)
    useEffect(() => {
        getData();
    }, [])
    return (
        <div className="mb-1 mx-3 ">
            <h3 className="mb-3 mt-1">
                Сообщение
            </h3>
            <div className="d-flex flex-column w-70 bg-white px-2 py-3">
                <div className="d-flex justify-content-between">
                    <p className="text-black fs-5">Мастер</p>
                    <p className="text-black">{items.masterName}</p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="text-black fs-5">Телефон</p>
                    <p className="text-black">{items.phone}</p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="text-black fs-5">Полученное сообщение</p>
                    <p className="text-black text-end" style={{width: 300}}>{items.receivedMessage}</p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="text-black fs-5">Полученное время</p>
                    <p className="text-black">{getDateTime(items.receivedTime)}</p>
                </div>
            </div>
        </div>
    )
        ;
}

