import React, {useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {XCircle, Save} from "react-feather";
import {useForm} from "react-hook-form";
import {GET, POST, PUT} from "../../../api/adminApi";
import {toast} from "react-toastify";
import Select from "react-select";

export default function NewProductAddUpdate() {
    const {id: productId} = useParams();
    const history = useHistory();
    const [type, setType] = useState(null);
    const [segment, setSegment] = useState(null);
    const [types, setTypes] = useState([]);
    const [segments, setSegments] = useState([]);
    const [data, setData] = useState({
        approved: 0,
    });
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        setValue,
        getValues,
    } = useForm();

    async function getTypes() {
        const response = await GET("/services/admin/api/product-type-helper");
        if (response?.length > 0) {
            setTypes(response);
        }
    }

    async function getSegments() {
        const response = await GET("/services/admin/api/product-segment-helper");
        if (response?.length > 0) {
            setSegments(response);
        }
    }

    function selectType(selectedOption) {
        if (selectedOption !== type) {
            setType(selectedOption);
        }
    }

    function selectSegment(selectedOption) {
        if (selectedOption !== type) {
            setSegment(selectedOption);
        }
    }

    function onSubmit(values) {
        const obj = {
            ...data,
            name: values.name,
            point: values.point,
            shortCode: values.shortCode,
            type: type ? type.id : null,
            segment: segment ? segment.id : null,
        };

        async function create() {
            if (productId) {
                const response = await PUT("/services/admin/api/product", obj);
                if (response?.success) {
                    history.push("/admin/products");
                }
            } else {
                const response = await POST("/services/admin/api/product", obj);
                console.log(response)
                if (response?.success) {
                    history.push("/admin/products");
                    toast.success("Успешно добавлен");
                }
            }
        }

        create()
    }

    async function getData() {
        const response = await GET("/services/admin/api/product/" + productId);
        console.log(response)
        if (response) {
            const {
                id,
                name,
                point,
                shortCode,
                type,
                segment
            } = response;
            setValue("name", name);
            setValue("point", point);
            setValue("shortCode", shortCode);
            setData((prev) => ({
                ...prev,
                id,
            }));
            const getTypes = await GET("/services/admin/api/product-type-helper");
            if (getTypes) {
                setTypes(getTypes);
                setType(
                    getTypes.find((item) => item?.id === type)
                );
            }

            const getSegments = await GET(
                `/services/admin/api/product-segment-helper`
            );
            if (getSegments) {
                setSegments(getSegments);
                setSegment(getSegments.find((item) => item?.id === segment));
            }
        }
    }

    useEffect(() => {
        if (productId) {
            getData();
        } else {
            getTypes();
            getSegments();
        }
    }, []);
    return (
        <div className="mb-2 mx-3 ">
            <h3 className="mb-5 mt-5">
                {productId ? "Изменить" : "Добавить новый"}
            </h3>
            <div className="admin-card py-4">
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="d-flex">
                        <div className="pt-12" style={{width: "500px"}}>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Название</label>
                                    </div>
                                    <input
                                        {...register("name")}
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Балл</label>
                                    </div>
                                    <input
                                        {...register("point")}
                                        placeholder=""
                                        type="number"
                                        className="input-control"
                                    />
                                </div>
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Короткий код</label>
                                    </div>
                                    <input
                                        {...register("shortCode")}
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                            </div>
                            <div className="admin-field">
                                <div className="d-flex flex-column w-100">
                                    <div className="mb-2 d-flex align-items-center gap-2">
                                        <label
                                            style={{width: "120px"}}
                                            className="color-grey text-end text-nowrap"
                                        >
                                            Тип
                                        </label>
                                        <div style={{width: "300px", fontSize: "12px"}}>
                                            <Select
                                                {...register("type", {
                                                    value: type,
                                                })}
                                                value={type}
                                                placeholder="Выберите тип"
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                options={types}
                                                onChange={selectType}
                                            />
                                            {errors.type && (
                                                <div className="text-danger mt-1 fz12">
                                                    Обязательное поле
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mb-2 d-flex align-items-center gap-2">
                                        <label
                                            style={{width: "120px"}}
                                            className="color-grey text-end text-nowrap"
                                        >
                                            Сегмент
                                        </label>
                                        <div style={{width: "300px", fontSize: "12px"}}>
                                            <Select
                                                {...register("segment", {
                                                    value: segment,
                                                })}
                                                value={segment}
                                                placeholder="Выберите сегмент"
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                options={segments}
                                                onChange={selectSegment}
                                            />
                                            {errors.segment && (
                                                <div className="text-danger mt-1 fz12">
                                                    Обязательное поле
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn-postion d-flex">
                        <Link to="/admin/products" className="mr-3">
                            <button className="btn text-white btn-bg-primary d-flex align-items-center">
                                <XCircle size={14} className="mr-2"/>
                                Отмена
                            </button>
                        </Link>
                        <button
                            className="btn text-white btn-bg-success d-flex align-items-center"
                            type="submit"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

