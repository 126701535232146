import React, {useEffect, useState} from "react";
import {Save, XCircle} from "react-feather";
import Modal from "react-bootstrap/Modal";
import {useForm} from "react-hook-form";
import Select from "react-select";
import {POST} from "../../../api/adminApi";

export default function ChangeStatusPrize({
                                              modal: {show, user},
                                              setModal,
                                              getItems,
                                              statusOptions,
    setStatusOptions
                                          }) {
    const [statusOpt, setStatusOpt] = useState(null);
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        clearErrors,
    } = useForm();

    const selectStatus = (selectedOpt) => {
        if (statusOpt !== selectedOpt) {
            setStatusOpt(selectedOpt);
        }
    }


    useEffect(() => {
        if ((user.statusIdAirfel === 1 || user.statusIdAirfel === 2 ) && user.kotelEnvolved) {
            setStatusOptions(statusOptions);
        } else if(user.statusIdAirfel === 3 && user.kotelEnvolved)  {
            const updatedStatusOptions = statusOptions.filter((option) => option.name === 'Отказано');
            console.log(updatedStatusOptions)
            setStatusOptions(updatedStatusOptions);
        }
    }, []);

    async function changeStatus(data) {
        const response = await POST(
            "/services/admin/api/master-change-point-status",
            {
                comment: data.comment,
                id: user.id,
                status: statusOpt.name,
                statusId: statusOpt.id,
            }
        );
        if (response) {
            console.log(response)
        }

        if (response && response.success === true) {
            getItems();
            onHide();
        }
    }

    function onHide() {
        setModal({show: false, user: null});
        setStatusOpt(null);
        setValue("comment", "");
        clearErrors();
    }

    return (
        <Modal
            show={show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Изменить статус
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(changeStatus)} autoComplete="off">
                    <div className="d-flex">
                        <div className="w-100">
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="mr-md-3 w-25">
                                        <label className="mb-0">Выберите статус</label>
                                    </div>
                                    <Select
                                        {...register("status", {
                                            // required: true,
                                            value: statusOpt,
                                        })}
                                        value={statusOpt}
                                        placeholder="Выберите статус"
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        options={statusOptions}
                                        onChange={selectStatus}
                                        className="w-75"
                                    />
                                </div>
                                {errors.status && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>

                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="mr-md-3 w-25">
                                        <label className="mb-0">Комментарии</label>
                                    </div>
                                    <textarea
                                        {...register("comment", {
                                            required: true,
                                        })}
                                        type="text"
                                        placeholder=""
                                        className="input-control w-75"
                                    />
                                </div>
                                {errors.comment && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end gap-4">
                        <button
                            type="button"
                            onClick={onHide}
                            className="btn btn-outline-purple d-flex align-items-center btn-bg-warning text-white"
                        >
                            <XCircle size={14} className="mr-2"/>
                            Отмена
                        </button>

                        <button
                            type="submit"
                            className="btn d-flex align-items-center btn-bg-dark text-white"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить{" "}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}
