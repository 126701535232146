import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import { XCircle, Save } from "react-feather";

import { POST, GET, PUT } from "../../../api/adminApi";

function UserAddUpdate() {
   let { id } = useParams();
   let history = useHistory();
   const [data, setData] = useState({
      fullName: "",
      phone: "",
      gender: "",
      language: "",
      userType: "",
      createdDate: new Date(),
      sectionList: [],
   });
   const [userType, setUserType] = useState([]);
   const [gender, setGender] = useState([]);
   const [language, setLanguage] = useState([]);

   const gen = [
      {
         id: 1,
         gender: "Муж.",
      },
      {
         id: 1,
         gender: "Жен.",
      },
   ];

   const lang = [
      {
         id: 1,
         language: "ru",
      },
      {
         id: 2,
         language: "uz",
      },
   ];

   const type = [
      {
         id: 1,
         sales: "SALES",
      },
      {
         id: 2,
         sales: "MASTER",
      },
   ];

   async function create() {
      var response;
      if (id) {
         response = await PUT("/services/admin/api/user", data);
      } else {
         response = await POST("/services/admin/api/user", data);
      }
      if (response) {
         history.push("/admin/user");
      }
   }

   function selectBrand(selectedOption) {
      setData({ ...data, userType: selectedOption.id });
      setUserType(selectedOption);
   }

   function selectGender(selectedOption) {
      setData({ ...data, gender: selectedOption.id });
      setGender(selectedOption);
   }

   function selectLanguage(selectedOption) {
      setData({ ...data, language: selectedOption.language });
      setLanguage(selectedOption);
   }

   async function getData() {
      const response = await GET("/services/admin/api/user/" + id, data);
      setData(response);
   }

   useEffect(() => {
      if (id) {
         getData();
      }
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div className="mb-2 mx-3 ">
         <h3 className="mb-3 mt-1">Добавить новый</h3>
         <div className="admin-card p-4 overflow-y-auto">
            <div className="row ">
               <div className="d-flex flex-column w-75 ml-5 flex-wrap mb-3">
                  <div className="w-100" style={{ marginTop: "25px" }}>
                     <div className=" d-flex align-items-center pb-3">
                        <label
                           style={{ width: "70px" }}
                           className="vertical-center color-grey mr-2 text-nowrap "
                        >
                           ФИО
                        </label>
                        <div>
                           <input
                              className="input-control "
                              style={{ width: "300px" }}
                              value={data.fullName}
                              onChange={(e) =>
                                 setData({ ...data, fullName: e.target.value })
                              }
                           />
                        </div>
                     </div>
                     <div className=" d-flex align-items-center">
                        <label
                           style={{ width: "70px", height: "50px" }}
                           className="vertical-center color-grey mr-2 text-nowrap"
                        >
                           Номер <br /> телефона
                        </label>
                        <div>
                           <input
                              className="input-control"
                              style={{ width: "300px" }}
                              value={data.phone}
                              onChange={(e) =>
                                 setData({ ...data, phone: e.target.value })
                              }
                           />
                        </div>
                     </div>
                  </div>
                  <div className="w-100 d-flex flex-column pl-3 mt-3">
                     <div className="align-items-center mb-3 mr-5 d-flex ">
                        <label className="vertical-center text-nowrap color-grey mr-3">
                           Филиал
                        </label>
                        <div className="" style={{ width: "300px" }}>
                           <Select
                              defaultValue={type[0]}
                              value={userType}
                              placeholder="Выберите"
                              getOptionLabel={(option) => option.sales}
                              getOptionValue={(option) => option.value}
                              options={type}
                              onChange={selectBrand}
                           />
                        </div>
                     </div>
                     <div className="align-items-center mb-3 mr-5 d-flex">
                        <label
                           style={{ width: "37px" }}
                           className="vertical-center text-nowrap color-grey mr-4"
                        >
                           Пол
                        </label>
                        <div className="" style={{ width: "300px" }}>
                           <Select
                              defaultValue={gen[0]}
                              value={gender}
                              placeholder="Выберите пол"
                              getOptionLabel={(option) => option.gender}
                              getOptionValue={(option) => option.value}
                              options={gen}
                              onChange={selectGender}
                           />
                        </div>
                     </div>
                     <div className="align-items-center mb-3 mr-5 d-flex">
                        <label
                           style={{ width: "37px" }}
                           className="vertical-center text-nowrap color-grey mr-4"
                        >
                           Язык
                        </label>
                        <div style={{ width: "300px" }}>
                           <Select
                              defaultValue={lang[0]}
                              value={language}
                              placeholder="Выберите язык"
                              getOptionLabel={(option) => option.language}
                              getOptionValue={(option) => option.language}
                              options={lang}
                              onChange={selectLanguage}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="btn-postion d-flex">
               <Link to="/admin/user" className="mr-3">
                  <button className="btn btn-outline-purple d-flex align-items-center">
                     <XCircle size={14} className="mr-2" />
                     Отмена
                  </button>
               </Link>
               <button
                  className="btn btn-purple d-flex align-items-center"
                  onClick={create}
               >
                  <Save size={14} className="mr-2" />
                  Сохранить
               </button>
            </div>
         </div>
      </div>
   );
}

export default UserAddUpdate;
