import { combineReducers } from 'redux'
import loader from './loader'
import basket from './basket' 
import modal from './modal'

const allReducers = combineReducers({
	loader,
  basket,
  modal,
})

export const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }

  return allReducers(state, action);
};

export default rootReducer;