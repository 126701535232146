import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import moment from 'moment'
import { PlusCircle } from 'react-feather';

import { PUT, FILE, GET } from '../../../api/adminApi'

import "react-datepicker/dist/react-datepicker.css";

function ProfileSettings({ user }) {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [pickedDate, setPickedDate] = useState(new Date());
  const [data, setData] = useState({ gender: true, imageUrl: null })
  const lastDateOfYear = `12/31/${new Date().getFullYear()}`;

  async function getInfo() {
    const response = await GET('/services/web/api/get-info')
    for (let key in response) {
			setValue(`${key}`, response[key])
		}
    if (response.gender === 0) {
      setData({...data, gender: true})
    } if(response.gender === 1) {
      setData({...data, gender: false})
    }
  }

  async function updateUser(data) {
    data.phone= data.phone.replace(/[^0-9]/g, '')
    console.log(data);
    await PUT('/services/web/api/client', data)
  }

  async function getImageUrl(e) {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      const formData = new FormData();
      formData.append('image', image);
      const response = await FILE("/services/web/api/client-image-upload", formData);
      setData({ ...data, imageUrl: response.url })
      setValue('imageUrl', response.url)
    }
  }


  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick} ref={ref} className="position-relative w-100 cursor d-flex flex-column-reverse" >
        <input value={props.value || props.placeholder} onChange={(e) => console.log(e.target.value)}
          type="text" className="profile-input" placeholder="00.00.0000" />
        <label className="profile-label">Дата рождения</label>
        {/* <input {...register("birthday", { required: true })} className="input-control" value={props.value || props.placeholder} />
				<div className='position-absolute' style={{ top: '7px', right: 10 }}>
					<Calendar size={18} />
				</div> */}
      </div>
    );
  });

  useEffect(() => {
    setValue('gender', 0)
    getInfo()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="pb-2 d-flex justify-content-center align-items-center">
        <div className="m-0 fz28">Профиль</div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-12 offset-lg-2 offset-md-2 bg-white p-5">
          <form autoComplete='off' method="POST" onSubmit={handleSubmit(updateUser)} action=''>
            <div className="d-flex flex-column align-items-center">
              <div className="add-photo">
                <div className="add-photo-block">
                  <input onChange={(e) => getImageUrl(e)} type="file" className="add-photo-input" />
                  <PlusCircle size={16} color='#9D9BA3' className="cursor" />
                  <div className="color-grey cursor fz12">Добавить Фото</div>
                </div>
                {
                  data.imageUrl &&
                    <img src={'https://colibri24.uz' + (data.imageUrl)} className="add-photo-image border" alt="" />
                }
              </div>
              <div className="profile-field">
                {errors.firstName?.type === 'required' && <div className='text-danger mt-1 fz10'>Обязательное поле</div>}
                <input {...register("firstName", { required: true, })} type="text" className="profile-input" placeholder="Имя пользователя" />
                <label className="profile-label">Имя</label>
              </div>
              <div className="profile-field">
                {errors.lastName?.type === 'required' && <div className='text-danger mt-1 fz10'>Обязательное поле</div>}
                <input {...register("lastName", { required: true, })} type="mail" className="profile-input" placeholder="Фамилия пользователя" />
                <label className="profile-label">Фамилия</label>
              </div>
              <div className="profile-field">
                {errors.phone?.type === 'required' && <div className='text-danger mt-1 fz10'>Обязательное поле</div>}
                <InputMask mask='+999-99-999-99-99' {...register("phone", { required: true })}
                  type="text" placeholder="+998-88-888-88-88" className="profile-input" />
                {/* <input {...register("phone", { required: true, minLength: 4 })} type="number" className="" placeholder="+998-88-888-88-88" /> */}
                <label className="profile-label">Телефон</label>
              </div>
              <div className="profile-field">
                <DatePicker
                  customInput={<CustomInput />}
                  className='profile-input'
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={60}
                  scrollableYearDropdown
                  maxDate={new Date(lastDateOfYear)}
                  // maxDate={moment().startOf('year')}
                  dateFormat="dd.MM.yyyy"
                  selected={pickedDate}
                  onChange={(date) => (setPickedDate(date) + setValue("birthday", moment(date).format("YYYY-MM-DD")))} />
                {/* <input {...register("username", { required: true, minLength: 4 })} type="text" className="profile-input" placeholder="00-00-0000" /> */}

              </div>
              <div className="profile-field">
                <div className="w-100 d-flex justify-content-center">
                  <input type="radio" onChange={() => setData({ ...data, gender: true }) + setValue('gender', 0)}
                    checked={data.gender} id="man" name='gender' className="custom-rb custom-rb-purple" />
                  <label className="mb-0" htmlFor="man">Мужской</label>
                  <div className="mr-3"></div>
                  <input type="radio" onChange={() => setData({ ...data, gender: false }) + setValue('gender', 1)}
                    checked={!data.gender} id="women" name='gender' className="custom-rb custom-rb-purple" />
                  <label className="mb-0" htmlFor="women">Женский</label>
                </div>
                <label className="profile-label">Пол</label>
              </div>
              <div className="w-50 d-flex justify-content-between">
                <button className="btn btn-grey b-r-50">Отменить</button>
                <button type="submit" className="btn btn-purple b-r-50">Сохранить</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ProfileSettings