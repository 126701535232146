import React, { useState, useEffect } from 'react'
import { Link, useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { TreeSelect } from 'antd';
import { XCircle, Save } from 'react-feather';

import { POST, GET, FILE, PUT } from '../../../api/adminApi'

import '../../../css/antd.css';

const TreeNode = TreeSelect.TreeNode;

function CategoriesAddUpdate() {
	let { id } = useParams();
	let history = useHistory();
	const [data, setData] = useState({
		imageUrl: '',
	})
	const [categories, setCategories] = useState([{ name: 'Самостоятельная категория', parentId: 0, id: 0 }])
	const { register, handleSubmit, formState: { errors }, setValue } = useForm()
	const [category, setCategory] = useState('')
	const [show, setShow] = useState(false)

	const onChange = (string) => {
		setCategory(string)
		setValue('parendId', string)
	};

	function sortCategories(arr) {
		var tree = [{ name: 'Самостоятельная категория', id: 0, children: [] }],
			treeChild = []
		for (var i = 0, len = arr.length; i < len; i++) {
			if (arr[i].parentId === 0) {
				arr[i]['children'] = []
				tree.push(arr[i]);
			} else {
				// arr[i]['children'] = []
				treeChild.push(arr[i])
			}
		}
		for (let i = 0; i < treeChild.length; i++) {
			for (let j = 0; j < treeChild.length; j++) {
				if (treeChild[i].id === treeChild[j].parentId) {
					treeChild[i]['children'] = []
				}
			}
		}

		for (let i = 0; i < treeChild.length; i++) {
			for (let j = 0; j < treeChild.length; j++) {
				if (treeChild[i].id === treeChild[j].parentId) {
					treeChild[i]['children'].push(treeChild[j])
				}
			}
		}

		for (let i = 0; i < tree.length; i++) {
			for (let j = 0; j < treeChild.length; j++) {
				if (tree[i]['id'] === treeChild[j]['parentId']) {
					tree[i]['children'].push(treeChild[j])
				}
			}
		}
		setCategories(tree)
		setShow(true)
		return tree;
	}

	async function getCategories() {
		const categories = await GET('/services/admin/api/categories-helper')
		sortCategories(categories)
		// for (let i = 0; i < categories.length; i++) {
		// 	categories[i].children = []
		// }
		// for (let i = 0; i < categories.length; i++) {
		// 	for (let j = 0; j < categories.length; j++) {
		// 		console.log(categories[i].id, categories[j].parentId);
		// 		if (categories[i].id === categories[j].parentId) {
		// 			console.log(categories[i], categories[j]);
		// 		}
		// 	}
		// }
		// const array = [{ name: 'Самостоятельная категория', parentId: 0, id: 0, children: [] }]
		// const result = array.concat(categories)
	}

	async function create(data) {
		var response;
		if (id) {
			response = await PUT('/services/admin/api/categories', data)
		} else {
			response = await POST('/services/admin/api/categories', data)
		}
		if (response) {
			history.push('/admin/directory/categories')
		}
	}

	async function getProduct() {
		const response = await GET('/services/admin/api/categories/' + id)
		for (let key in response) {
			setValue(`${key}`, response[key])
		}
		onChange(response.parentId)
		setData({ ...data, imageUrl: response.imageUrl })
	}

	async function getImageUrl(e) {
		if (e.target.files[0]) {
			const image = e.target.files[0];
			const formData = new FormData();
			formData.append('image', image);
			const response = await FILE("/services/admin/api/categories-image-upload", formData);
			setData({ ...data, imageUrl: response.url })
			setValue('imageUrl', response.url)
		}
	}

	useEffect(() => {
		getCategories()
		if (id) {
			getProduct()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="mb-2 mx-3 ">
			<h3 className="mb-3 mt-1">Добавить новый</h3>
			<div className="admin-card p-4 overflow-y-auto" style={{ height: "80vh" }} >
				<form onSubmit={handleSubmit(create)} action="" autoComplete='off'>
					<div className="row">
						<div className="col-md-6 mb-3">
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Название (рус)</label></div>
									<input {...register("nameRu", { required: true })} type="text" className="input-control" />
								</div>
								{errors.nameRu?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Название (узб)</label></div>
									<input {...register("nameUz", { required: true })} type="text" className="input-control" />
								</div>
								{errors.nameUz?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Описание (рус)</label></div>
									<input {...register("descriptionRu", { required: true })} type="text" className="input-control" />
								</div>
								{errors.descriptionRu?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Описание (узб)</label></div>
									<input {...register("descriptionUz", { required: true })} type="text" className="input-control" />
								</div>
								{errors.descriptionUz?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Категория</label></div>
									<div className="w-100 position-relative">
										{/* <TreeSelect
										// style={{ width: '100%' }}
										className='w-100'
										dropdownStyle={{ maxHeight: 400, overflow: 'auto', textAlign: 'center' }}
										placeholder="Выберите категорию"
										onChange={onChange}
										value={''}
										treeDefaultExpandAll
										filterTreeNode={false}
										
									>
										{categories.map((item, index) => {
											return (
												<TreeNode key={index} value={item.id} title={item.nameRu} className=''></TreeNode>
											)
										})}
									</TreeSelect> */}
										<TreeSelect
											style={{ width: '100%' }}
											value={category}
											dropdownStyle={{ maxHeight: 100, overflow: 'auto' }}
											placeholder="Please select"
											treeDefaultExpandAll
											onChange={onChange}
											showSearch={false}
										>
											{show &&
												categories.map((item, index) => {
													return (
														<TreeNode key={item.id} value={item.id} title={item.name}>
															{
																item.children.length &&
																item.children.map((item2, index2) => {
																	return (
																		<TreeNode key={item2.id} value={item2.id} title={item2.name}></TreeNode>
																	)
																})
															}
														</TreeNode>
													)
												})
											}
											{/* <TreeNode value="parent 1" title="parent 1" key="0-1">
											<TreeNode value="paernt 1-0" title="parent 1-0" key="0-1-1">
												<TreeNode value="leaf1" title="my leaf" key="random" />
												<TreeNode value="leaf2" title="your leaf" key="random1" />
											</TreeNode>
											<TreeNode value="parent 1-1" title="parent 1-1" key="random2">
												<TreeNode value="sss" title={<b style={{ color: '#08c' }}>sss</b>} key="random3" />
											</TreeNode>
										</TreeNode> */}
										</TreeSelect>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 mb-3 d-flex flex-column align-items-center color-grey">
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className='admin-label'><label className="mb-0">Логотип бренда</label></div>
									<div className='col-md-8 col-sm-8 col-10'>
										<div className="position-relative">
											<div className="image-block"
												style={{ width: '200px', minWidth: '100px', height: '200px', border: '1px dashed #D4D3D6' }}>
												Перетащите сюда файлы для загрузки
											</div>
											<input onChange={(e) => getImageUrl(e)} type="file" placeholder="Фамилия" style={{ width: '200px', minWidth: '100px', height: '200px', opacity: '0', top: 0, zIndex: 3 }} className="position-absolute cursor border border-radius-3 box outline-none color-grey" />
											{/* {errors.imageUrl?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>} */}
											{data.imageUrl &&
												<div className="position-absolute cursor  border-radius-3" style={{ width: '200px', minWidth: '100px', height: '200px', top: 0, zIndex: 2 }}>
													<img className="w-100 h-100 background-white border box-shadow" src={'https://colibri24.uz' + (data.imageUrl)} alt="" />
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="btn-postion d-flex">
						<Link to='/admin/categories' className="mr-3"><button className="btn btn-outline-purple d-flex align-items-center"><XCircle size={14} className="mr-2" />Отмена </button></Link>
						<button type='submit' className="btn btn-purple d-flex align-items-center"><Save size={14} className="mr-2" />Сохранить </button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default CategoriesAddUpdate
