import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { DebounceInput } from 'react-debounce-input';
import ReactPaginate from 'react-paginate';
import moment from 'moment'
import { Trash2, Search, StopCircle, CheckCircle, Truck } from 'react-feather';

import { formatMoney } from '../../../helper'
import { GET, PGET, DELETE, PUT } from '../../../api/adminApi'

function Orders() {
  const [items, setItems] = useState([])
  const [item, setItem] = useState(
    {
      "branchId": 0,
      "branchName": "string",
      "brandId": 0,
      "brandName": "string",
      "comment": "string",
      "courierName": "string",
      "gpsPointX": 0,
      "gpsPointY": 0,
      "id": 0,
      "orderDate": "2021-11-09T16:58:50.421Z",
      "orderNumber": 0,
      "orderPrice": 0,
      "orderStatus": 0,
      "paymentStatus": 0,
      "paymentType": 0,
      "productList": [
        {
          "comment": "string",
          "productId": 0,
          "productImageUrl": "string",
          "productName": "string",
          "productPrice": 0,
          "quantity": 0
        }
      ],
      "userAddressesName": "string",
      "userId": 0,
      "userName": "string"
    }
  )
  const [pageCount, setPageCount] = useState(0)
  const [value, setValue] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [totalAmount, setTotalAmount] = useState(0)

  async function getItems() {
    const data = await PGET('/services/admin/api/orders-pageList', true)
    setPageCount(Math.ceil(data.headers['x-total-count'] / 20))
    for (let i = 0; i < data.data.length; i++) {
      data.data[i].orderDate = moment(data.data[i].orderDate).format("DD.MM.YY HH:mm")
      // data.data[i].orderDate = moment(data.data[i].orderDate).format("HH:mm:ss - DD/MM/YY")
    }
    setItems(data.data)
  };

  async function getItem(id) {
    const data = await GET('/services/admin/api/orders/' + id, true)
    data.orderDate = moment(data.orderDate).format("DD.MM.YY HH:mm")
    var _totalAmount = 0
    for (let i = 0; i < data.productList.length; i++) {
      _totalAmount += data.productList[i].productPrice * data.productList[i].quantity
    }
    setTotalAmount(formatMoney(_totalAmount))
    setItem(data)
    setShowModal(true)
  }

  async function search(e) {
    const data = await PGET('/services/admin/api/orders-pageList?search=' + e.target.value, true)
    setPageCount(Math.ceil(data.headers['x-total-count'] / 20))
    setValue(e.target.value)
    for (let i = 0; i < data.data.length; i++) {
      data.data[i].orderDate = moment(data.data[i].orderDate).format("DD.MM.YY HH:mm")
    }
    setItems(data.data);
  };

  async function paginate(payload) {
    const data = await PGET('/services/admin/api/orders-pageList', true, { page: payload.selected, size: 20, search: value })
    for (let i = 0; i < data.data.length; i++) {
      data.data[i].orderDate = moment(data.data[i].orderDate).format("DD.MM.YY HH:mm")
    }
    setItems(data.data);
  };

  async function deleteItem(id) {
    await DELETE('/services/admin/api/orders/' + id)
    getItems()
  }

  async function cancel(id) {
    await PUT('/services/admin/api/orders-cancel/' + id)
    getItems()
  }

  useEffect(() => {
    getItems()
  }, [])

  return (
    <div className="background-white my-2 mx-3 box-shadow br-5">
      <div className="fz20 border-bottom pl-3 py-3 my-2 d-flex align-items-center">
        <Truck className='mr-2' color='#5C3EBA' /> <span>Заказы</span>
      </div>
      <div className="px-3 pb-4 pt-2">
        {/* <div className="mb-4">
          <Link to='/admin/product/create'>
            <button className="btn btn-purple d-flex align-items-center">
              <PlusCircle size={18} className='mr-1' />
              <div>Добавить новый</div>
            </button>
          </Link>
        </div> */}
        <div className="d-flex justify-content-end mb-3">
          <div className="position-relative">
            <Search size={14} color='#9D9BA3' className="input-search-icon" />
            <DebounceInput minLength={3} debounceTimeout={400} onChange={(e) => search(e)} type="text" className="input-search" size={24} placeholder='Поиск' />
          </div>
        </div>
        <div style={{ overflowX: 'auto' }}>
          <table className="table table-bordered">
            <thead>
              <tr className='backgroung-grey'>
                <th className="text-center">Время поступления заказа</th>
                <th className="text-center">Имя клиента</th>
                <th className="text-center">Название бренда</th>
                <th className="text-center">Сумма заказа</th>
                <th className="text-center">Тип оплаты</th>
                <th className="text-center">Статус оплаты</th>
                <th className="text-center">Курьер заказа</th>
                <th className="text-center">Статус заказа</th>
                <th className="text-center">Действия</th>
              </tr>
            </thead>

            <tbody style={{ overflowX: 'auto' }}>

              {/* <tr className="cursor" onClick={() => setShowModal(true)}>
                <td className="text-center">00:00:00 - 00/00/00</td>
                <td className="text-center">Имя клиента</td>
                <td className="text-center">Название бренда</td>
                <td className="text-center">999’999’999,00 сум</td>
                <td className="text-center">Наличный</td>
                <td className="text-center ">
                  <span className="btn-grey table-btn">
                    Не оплачен
                  </span>
                </td>
                <td className="text-center">Имя курьера</td>
                <td className="text-center">
                  <span className="table-btn btn-danger">
                    Новый
                  </span>
                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    <button className="btn btn-table" style={{ backgroundColor: '#F4F4F5' }}>
                      <StopCircle color={'#5C3EBA'} size={16} />
                    </button>
                    <button className="btn py-1 mr-2 px-2 border br-5 text-white" style={{ backgroundColor: '#F4F4F5' }}><Trash2 color={'#E63950'} size={16} />
                    </button>
                    <button className="btn btn-table mr-0" style={{ backgroundColor: '#F4F4F5' }}>
                      <CheckCircle color={'#0BC940'} size={16} />
                    </button>
                  </div>
                </td>
              </tr> */}

              {items.map((item) => {
                return (
                  <tr key={item.id} className="cursor" onClick={() => getItem(item.id)}>
                    <td className="text-center">{item.orderDate}</td>
                    <td className="text-center">{item.userName}</td>
                    <td className="text-center">{item.branchName}</td>
                    <td className="text-center">{item.orderPrice}</td>
                    <td className="text-center">Наличный</td>
                    <td className="text-center ">
                      <span className="btn-grey table-btn">
                        Не оплачен
                      </span>
                    </td>
                    <td className="text-center">{item.courierName}</td>
                    <td className="text-center">
                      <span className="table-btn btn-danger">
                        Новый
                      </span>
                    </td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <button className="btn btn-table" style={{ backgroundColor: '#F4F4F5' }}>
                          <StopCircle color={'#5C3EBA'} size={16} />
                        </button>
                        <button onClick={() => deleteItem(item.id)} className="btn py-1 mr-2 px-2 border br-5 text-white" style={{ backgroundColor: '#F4F4F5' }}><Trash2 color={'#E63950'} size={16} />
                        </button>
                        <button onClick={() => cancel(item.id)} className="btn btn-table mr-0" style={{ backgroundColor: '#F4F4F5' }}>
                          <CheckCircle color={'#0BC940'} size={16} />
                        </button>
                      </div>
                    </td>
                  </tr>
                  // <tr key={item.id}>
                  //   <td className="text-center vertical-middle">{item.orderDate}</td>
                  //   <td className="text-center">{item.orderPrice}</td>
                  //   <td className="text-center">{item.companyName}</td>
                  //   <td className="text-center">{item.categoriesName}</td>
                  //   <td className="text-center">{item.categoriesName}</td>
                  //   <td className="text-center">{item.categoriesName}</td>
                  //   <td className="text-center ">
                  //     <span className="btn-grey py-1 px-2 border-radius-15 text-white fz12" style={{ backgroundColor: '#D4D3D6' }}>
                  //       Не оплачен
                  //     </span>
                  //   </td>
                  //   <td className="text-center">{item.categoriesName}</td>
                  //   <td className="text-center">
                  //     <span className="py-1 px-2 border-radius-15 text-white fz12" style={{ backgroundColor: '#E63950' }}>
                  //       Новый
                  //     </span>
                  //   </td>
                  //   <td>
                  //     <div className="d-flex justify-content-center">
                  //       <button className="btn btn-table" style={{ backgroundColor: '#F4F4F5' }}><Eye color={'#5C3EBA'} size={16} /></button>
                  //       <Link to={'/admin/product/update/' + item.id}><button className="btn btn-table" style={{ backgroundColor: '#F4F4F5' }}><Edit3 color={'#189ED3'} size={16} /></button></Link>
                  //       <button onClick={() => deleteItem(item.id)} className="btn btn-table mr-0" style={{ backgroundColor: '#F4F4F5' }}><Trash2 color={'#E63950'} size={16} /></button>
                  //     </div>
                  //   </td>
                  // </tr>
                )
              })
              }
            </tbody>
          </table>
        </div>
        {pageCount > 1 &&
          <ReactPaginate
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={paginate}
            containerClassName={'pagination'}
            activeClassName={'active'}
            pageClassName={'page-item'}

          />
        }
      </div>
      <Modal
        show={showModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className='fz20 font-weight-normal'>
            ЗАКАЗ №{item.orderNumber}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="bg-light p-2 mb-3">
            <div className="d-flex mb-2">
              <div className="w-50">
                Время поступление заказа
              </div>
              <div className="w-50 font-weight-350">
                {item.orderDate}
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="w-50">
                Имя клиента
              </div>
              <div className="w-50 font-weight-350">
                {item.userName}
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="w-50">
                Название бренда
              </div>
              <div className="w-50 font-weight-350">
                {item.branchName}
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-5 mb-2">
                Время поступление заказа
              </div>
              <div className="col-md-6 mb-2 font-weight-350">
                00:00:00 - 00/00/00
              </div>
              <div className="col-md-5 mb-2">
                Имя клиента
              </div>
              <div className="col-md-6 mb-2 font-weight-350">
                Имя клиента
              </div>
              <div className="col-md-5 mb-2">
                Название бренда
              </div>
              <div className="col-md-6 mb-2 font-weight-350">
                Название бренда
              </div>
            </div> */}
          </div>
          <div className="p-2 border-bottom mb-3 px-3 d-flex flex-column">
            {/* <div className="d-flex flex-column w-100">
              <div className="row d-flex align-items-center p-2 mb-2">
                <div className="col-md-2">
                  <img src="https://colibri24.uz/images/BCH80c00e08059944a1833e2a804a14e01420211101.png" alt="" className="w-100" />
                </div>
                <div className="col-md-4">
                  Название продукта
                </div>
                <div className="col-md-6">
                  99 x 9’999.00 сум = <span className="text-dark font-weight-bold"> 99’999.00 сум </span>
                </div>
              </div>
              <div className="row d-flex align-items-center p-2 mb-2">
                <div className="col-md-2">
                  <img src="https://colibri24.uz/images/BCH80c00e08059944a1833e2a804a14e01420211101.png" alt="" className="w-100" />
                </div>
                <div className="col-md-4">
                  Название продукта
                </div>
                <div className="col-md-6">
                  99 x 9’999.00 сум = <span className="text-dark font-weight-bold"> 99’999.00 сум </span>
                </div>
              </div>
              <div className="row d-flex align-items-center p-2 mb-2">
                <div className="col-md-2">
                  <img src="https://colibri24.uz/images/BCH80c00e08059944a1833e2a804a14e01420211101.png" alt="" className="w-100" />
                </div>
                <div className="col-md-4">
                  Название продукта
                </div>
                <div className="col-md-6">
                  99 x 9’999.00 сум = <span className="text-dark font-weight-bold"> 99’999.00 сум </span>
                </div>
              </div>
            </div> */}
            {
              item &&
              item.productList.map((item) => {
                return (
                  <div className="d-flex w-100 mb-1" key={item.productId}>
                    <div className="d-flex align-items-center px-2 w-100">
                      <img src={"https://colibri24.uz" + item.productImageUrl} height={64} width={64} alt="" />
                      <div className="ml-2">
                        {item.productName}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end px-2 w-100">
                      {item.quantity} x {formatMoney(item.productPrice)} сум = <span className="text-dark font-weight-bold"> {formatMoney(item.quantity * item.productPrice)} сум </span>
                    </div>
                  </div>
                )
              })

            }

            <div className="text-end mb-3 px-2">
              Стоимость доставки:
              <span className="text-dark font-weight-bold ml-2"> 99’999.00 сум </span>
            </div>
            <div className="d-flex justify-content-between bg-light p-2 mb-3">
              <span>Сумма заказа</span>
              <span className="text-dark font-weight-bold ml-2"> {totalAmount} сум </span>
            </div>
            <div className="d-flex justify-content-between p-2 mb-3">
              <span>Тип оплаты</span>
              <span>Наличный</span>
            </div>
            <div className="d-flex justify-content-between align-items-center p-2 mb-3">
              <span>Статус оплаты</span>
              <span className="py-1 px-2 border-radius-15 text-white fz12" style={{ backgroundColor: '#FFA91A' }}>
                Частично оплачен
              </span>
            </div>
            <div className="d-flex justify-content-between p-2 mb-3">
              <span>Курьер заказа</span>
              <span>{item.courierName}</span>
            </div>
            <div className="d-flex justify-content-between align-items-center p-2 mb-3">
              <span>Статус заказа</span>
              <span className="py-1 px-2 border-radius-15 text-white fz12" style={{ backgroundColor: '#E63950' }}>
                Новый
              </span>
            </div>
          </div>
          <div className="p-2 mb-2 d-flex justify-content-end w-90 ml-auto">
            <button type='submit' className="btn btn-outline-orange mr-3 w-100">Приостановить</button>
            <button type='submit' className="btn btn-outline-danger br-5 mr-3 w-100">Отказать</button>
            <button type='submit' className="btn btn-outline-purple w-100">Принят</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Orders
